
import React from "react";

import { IconButton, Tooltip } from "@mui/material"
import { Settings } from "@mui/icons-material"
import { iconos } from "../../../data"




export const TooltipAndIcon = ({ titulo, icono, onClick, color='#2979ff', size=18 }) => {


    return (

        <Tooltip title={titulo} placement="top">
            <IconButton sx={{ color: 'primary.main'}} 
                onClick={ onClick } size="small">
                {React.cloneElement(iconos[icono] || <Settings />, { style:{ color:color, fontSize:`${size}px` }})}
            </IconButton>
        </Tooltip>

    )
}
