import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";





export const PrivateRoute2 = ({ children }) => {


    const { uid } = useSelector( state => state.admin );
    

    return (
        !!uid
        ? children 
        : <Navigate to="/admin/login" />
    )
}
