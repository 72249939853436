


export const manejoDeErrores = (error) => {
    let errores;
    const msg = error.errors;

    if (msg) {
        for (let key in msg) {
            if (msg[key] && typeof msg[key] === 'object' && msg[key].hasOwnProperty('msg')) {
                errores = msg[key].msg;
            }
        }
    } else {
        errores = error['msg'];
    }
    return errores;
}