


export const buscarProductoColor = (carrito, producto, color) => {
    return carrito.find((prod) => { 
        return prod.producto._id === producto && prod.color === color;
    });
}


export const buscarProductoPrendas = (carrito, producto, color, talla, genero) => {
    return carrito.find((prod) => { 
        return prod.producto._id === producto && prod.color === color && prod.talla === talla && prod.genero === genero;
    });
}



export const buscarProductoCorteVinilo = (carrito, producto, color, medida, alto, ancho) => {
    return carrito.find((prod) => { 
        return prod.producto._id === producto && prod.color === color && prod.unidadMedida === medida && prod.alto === alto && prod.ancho === ancho;
    });
}


export const buscarProductoEstructuras = (carrito, producto, medida, alto, ancho) => {
    return carrito.find((prod) => { 
        return prod.producto._id === producto && prod.unidadMedida === medida && prod.alto === alto && prod.ancho === ancho;
    });
}


export const buscarProducto = (carrito, producto) => {
    return carrito.find((prod) => { 
        return prod.producto._id === producto;
    });
}