
import { MenuItem, TextField } from "@mui/material";





export const SelectSencillo = ({ label, options=[], ...props }) => {




    return (
        <>
            <TextField 
                select
                fullWidth
                defaultValue=''
                label={ label } 
                { ...props }
            >
                {
                options?.map( (valor) => (
                    <MenuItem key={valor} value={valor}>{valor}</MenuItem>
                ))
                }
            </TextField>
        </>
    )


}
