import { Grid, Typography } from "@mui/material"
import { LayoutReturn } from "../layouts"



export const TerminosYCondiciones = () => {



    return (



        <LayoutReturn titulo='Terminos y Condiciones' url='/perfil'>

            <Grid container sx={{p:4}}>
                <Typography variant="body" sx={{fontSize:19, letterSpacing:-0.2, mb:2}}>
                Dando cumplimiento a lo dispuesto en la Ley 1581 de 2012, "Por el cual se dictan disposiciones 
                generales para la protección de datos personales" y de conformidad con lo señalado en el Decreto 
                1377 de 2013, con la firma y aprobado digital de este documento manifiesto que he sido informado por
                Seven For Ever S.A.S. de lo siguiente:
                </Typography>
                
                <ul>
                <li style={{fontSize:'17px'}}>
                    1. Seven For Ever S.A.S. actuará como Responsable del Tratamiento de datos personales de los cuales soy 
                    titular y que, conjunta o separadamente podrá recolectar, usar y tratar mis datos personales 
                    conforme la Política de Tratamiento de Datos Personales de Seven For Ever S.A.S. disponible en SGC en página 
                    web de la entidad. 
                </li>
                <li style={{fontSize:'17px'}}>
                    2. Que me ha sido informada la (s) finalidad (es) de la recolección de los datos personales, la cual 
                    consiste en LA CREACIÓN DE UN USUARIO PARA COMPRAS EN LINEA.
                </li>
                <li style={{fontSize:'17px'}}>
                    3. Es de carácter facultativo o voluntario responder preguntas que versen sobre Datos Sensibles 
                    o sobre menores de edad. 
                </li>
                <li style={{fontSize:'17px'}}>
                    4. Mis derechos como titular de los datos son los previstos en la Constitución y la ley, 
                    especialmente el derecho a conocer, actualizar, rectificar y suprimir mi información personal, así 
                    como el derecho a revocar el consentimiento otorgado para el tratamiento de datos personales. 
                </li>
                <li style={{fontSize:'17px'}}>
                    5. Los derechos pueden ser ejercidos a través de los canales dispuestos por Seven For Ever S.A.S. y 
                    observando la Política de Tratamiento de Datos Personales de Seven For Ever S.A.S. 
                </li>
                <li style={{fontSize:'17px'}}>
                    6. Mediante la página web de la entidad (www.7xever.com), podré radicar cualquier tipo de 
                    requerimiento relacionado con el tratamiento de mis datos personales. 
                </li>
                <li style={{fontSize:'17px'}}>
                    7. Seven For Ever S.A.S. garantizará la confidencialidad, libertad, seguridad, veracidad, transparencia, acceso 
                    y circulación restringida de mis datos y se reservará el derecho de modificar su Política de 
                    Tratamiento de Datos Personales en cualquier momento. Cualquier cambio será informado y 
                    publicado oportunamente en la página web. 
                </li>
                <li style={{fontSize:'17px'}}>
                    8. Teniendo en cuenta lo anterior, autorizo de manera voluntaria, previa, explícita, informada e 
                    inequívoca a Seven For Ever S.A.S. para tratar mis datos personales y tomar mi huella y fotografía de acuerdo 
                    con su Política de Tratamiento de Datos Personales para los fines relacionados con su objeto y 
                    en especial para fines legales, contractuales, misionales descritos en la Política de Tratamiento 
                    de Datos Personales de Seven For Ever S.A.S. 
                </li>
                <li style={{fontSize:'17px'}}>
                    9. La información obtenida para el Tratamiento de mis datos personales la he suministrado de 
                    forma voluntaria y es verídica.
                </li>
                </ul>
                
            </Grid>


        </LayoutReturn>




    )


}
