import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";




export const PublicRoute2 = ({ children }) => {

    // cheking,
    const { uid } = useSelector( state => state.admin );


    return (
        !!uid
        ? <Navigate to='/super'  />
        : children 
    )
}
