
import { useSelector } from "react-redux";
import { separadorMillares } from "../../hooks/useMillares"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";



export const TablaModeloItems = ({ activeCarrito }) => {

    const { role } = useSelector( state => state.admin );
    const rolesPermitidos = ['SUPERADMIN', 'ADMIN', 'DISTRIBUIDOR'];




    return (

        <>
        {(activeCarrito) && 
        <TableContainer sx={{ my:'15px' }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Nombre Producto</TableCell>
                        <TableCell>Cantidad</TableCell>
                        {
                        (activeCarrito.color) &&
                        <TableCell>Color</TableCell>
                        }
                        {
                        (activeCarrito.genero) &&
                        <TableCell>Genero</TableCell>
                        }
                        {
                        (activeCarrito.ancho) &&
                        <TableCell>Ancho</TableCell>
                        }
                        {
                        (activeCarrito.alto) &&
                        <TableCell>Alto</TableCell>
                        }
                        {
                        (activeCarrito.unidadMedida) &&
                        <TableCell>Uni. Medida</TableCell>
                        }
                        {
                        (activeCarrito.terminados) &&
                        <TableCell>Terminados</TableCell>
                        }
                        {
                        (activeCarrito.talla) &&
                        <TableCell>Talla</TableCell>
                        }
                        {
                        (activeCarrito.disenio) &&
                        <TableCell>Diseño</TableCell>
                        }
                        {
                        ( rolesPermitidos.includes(role) ) &&
                        <TableCell>Valor Uni.</TableCell>
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>{ activeCarrito.producto.nombre }</TableCell>
                        <TableCell>{ activeCarrito.cantidad }</TableCell>
                        {
                        (activeCarrito.color) &&
                        <TableCell>{ activeCarrito.color }</TableCell>
                        }
                        {
                        ( activeCarrito.genero ) &&
                        <TableCell>{ activeCarrito.genero }</TableCell>
                        }
                        {
                        (activeCarrito.ancho) &&
                        <TableCell>{ activeCarrito.ancho }</TableCell>
                        }
                        {
                        (activeCarrito.alto) &&
                        <TableCell>{ activeCarrito.alto }</TableCell>
                        }
                        {
                        (activeCarrito.unidadMedida) &&
                        <TableCell>{ activeCarrito.unidadMedida }</TableCell>
                        }
                        {
                        (activeCarrito.terminados) &&
                        <TableCell>{ activeCarrito.terminados.nombre }</TableCell>
                        }
                        {
                        (activeCarrito.disenio) &&
                        <TableCell>{ activeCarrito.disenio }</TableCell>
                        }
                        {
                        (activeCarrito.talla) &&
                        <TableCell>{ activeCarrito.talla }</TableCell>
                        }
                        {
                        ( rolesPermitidos.includes(role) ) &&
                        <TableCell>{ `$${ separadorMillares(activeCarrito.producto.precioVenta) }` }</TableCell>
                        }
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
        }
        </>
    )



}
