



import { useState } from "react";
import { useDispatch } from "react-redux";

import { Grid, Paper, InputBase, IconButton } from "@mui/material"
import SearchIcon from '@mui/icons-material/Search';
import { rastrearOpCliente } from "../../../actions/busquedas";





export const BuscarORastrearOP = () => {


    const dispatch = useDispatch();

    

    const [valor, setValor] = useState('');


    function handleKeyDown(event) {
        if (event.key === 'Enter') {
            event.preventDefault();
            hanldeValor(); 
        }
    }




    const hanldeValor = (e) => {
        if( valor.trim().length <= 0 ) { return; }
        dispatch( rastrearOpCliente(valor) );
    }



    return (

        
    
        <Grid item xs={12} sm={6} sx={{ my: 4 }} >

            <Paper
                component="form"
                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width:'100%' }}
            >
            
                <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    autoComplete="off"
                    placeholder="Rastrear O.P."
                    value={valor}
                    onChange={ (e) => setValor(e.target.value) }
                    onKeyDown={handleKeyDown}
                />
                <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={ hanldeValor }>
                    <SearchIcon />
                </IconButton>
            
            </Paper>

        </Grid>



    )


    
}
