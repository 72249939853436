
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow,  Typography, IconButton } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import LockResetIcon from '@mui/icons-material/LockReset';
import PageviewIcon from '@mui/icons-material/Pageview';
import EditIcon from '@mui/icons-material/Edit';

import { MensajeAlertaDanger } from "../../components";
import { cambiarPasswordPorElAdminCliente, clienteStartDelete } from "../../../actions/cliente";


import Swal from "sweetalert2";





export const TablaListadoClientes = () => {


    const { clientes } = useSelector(state => state.cliente);
    const { usuario } = useSelector(state => state.admin);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const location = useLocation();
    const currentPath = location.pathname;


    const paginaCliente = localStorage.getItem('pagcl') || 0;
    const pag = Number(paginaCliente);
    const [page, setPage] = useState(pag);
    const [ rowsPerPage ] = useState(50);

    
    const handleChangePage = (event, newPage) => {
        setPage( newPage );
        localStorage.setItem('pagcl', newPage);
    };



    const updateCliente = (e) => {
        return navigate(`/super/actualizarCliente/${ e.id }`) 
    }


    const handleEliminar = ( cliente ) => {
        Swal.fire({
            title: 'Eliminar Cliente?',
            text: `Esta Seguro De Borrar A ${ cliente.nombre }`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si, Borrar' 
        }).then( ( result ) => {
            if(result.value){
                dispatch( clienteStartDelete( cliente ) );
            }
        })
    }


    const cambioClave = async(a) => {
        const { value: password } = await Swal.fire({
            title: 'Cambiar Password',
            icon: 'question',
            input: 'text',
            inputPlaceholder: 'Ingrese Password'
        })
        if (password) {
            Swal.fire({
                title: 'Confirmar Password',
                text: `${password}`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Si, Seguro' 
            }).then( ( result ) => {
                if(result.value){
                    dispatch(cambiarPasswordPorElAdminCliente({
                        password,
                        uid: a.id
                    }));
                }
            })
        }
    } 


    const handleTrueFalse = (cli) => {
        Swal.fire({
            title: `${cli.activo ? 'Desactivar' : 'Activar'} Cliente`,
            text: `¿Seguro que desea ${cli.activo ? 'desactivar' : 'activar'} este Cliente?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si, Seguro' 
        }).then( ( result ) => {
            if(result.value){
                dispatch(clienteStartDelete(cli));
            }
        })
    }



    const handleVerOps = ( id ) => {
        navigate(`/super/ops-cliente/${ id }`)
    }



    return (

        <>
            {
            ( clientes.length > 0 )
            ?<Paper sx={{ width: '100%' }}>
                <TableContainer sx={{ my:'15px' }}>
                    <Table sx={{ minWidth: 700, }} aria-label="customized table">

                        <TableHead >
                            <TableRow>
                                <TableCell>A.</TableCell>
                                <TableCell className="w-300">Nombre</TableCell>
                                <TableCell>Ciudad</TableCell>
                                <TableCell>Movil</TableCell>
                                <TableCell>{(currentPath !== '/super/clientesxdistri') ? 'Distribuidor' : 'Establecimiento'}</TableCell>
                                <TableCell className="w-200">Acciones</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {
                            clientes
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map( (cli) => (
                            <TableRow  key={ cli.id }>

                                    <TableCell >
                                    {
                                    ( cli.activo )
                                    ? <IconButton 
                                        className='boton-estado trru'
                                        onClick={ () => handleTrueFalse(cli) }
                                    />
                                    : <IconButton 
                                        className='boton-estado fall'
                                        onClick={ () => handleTrueFalse(cli) }
                                    />
                                    }
                                    </TableCell>
                                <TableCell >
                                    <Typography variant="span" >{ cli.nombre }</Typography>
                                </TableCell>
                                <TableCell>
                                    {
                                    ( cli.ciudad )
                                    ?   <Typography variant="span" >{ cli.ciudad }</Typography>
                                    :   <Typography variant="span" color='error'>No Hay Dato</Typography>
                                    }
                                </TableCell>
                                <TableCell>
                                    {
                                    ( cli.movil )
                                    ?   <Typography variant="span" >{ cli.movil }</Typography>
                                    :   <Typography variant="span" color='error'>No Hay Dato</Typography>
                                    }
                                </TableCell>
                                {
                                (currentPath !== '/super/clientesxdistri') 
                                ?<TableCell>
                                    {
                                    ( cli.distribuidor )
                                    ?   <Typography variant="span" >{ cli.distribuidor.nombre }</Typography>
                                    :   <Typography variant="span" color='error'>No Hay Dato</Typography>
                                    }
                                </TableCell>
                                :<TableCell>
                                    {
                                    ( cli.establecimiento )
                                    ?   <Typography variant="span" >{ cli.establecimiento }</Typography>
                                    :   <Typography variant="span" color='error'>No Hay Datos</Typography>
                                    }
                                </TableCell>
                                }
                                <TableCell>

                                    <IconButton className="icon-boton" onClick={ () => updateCliente(cli) } >
                                        <EditIcon fontSize="small" />
                                    </IconButton>
                                    {
                                    (usuario.role === 'SUPERADMIN') &&
                                    <>
                                    <IconButton className="icon-boton" onClick={ () => handleEliminar(cli) } >
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>

                                    <IconButton className="icon-boton" onClick={ () => cambioClave(cli) } >
                                        <LockResetIcon fontSize="small" />
                                    </IconButton>
                                    </>
                                    }

                                    <IconButton className="icon-boton" onClick={ () => handleVerOps(cli.id) } >
                                        <PageviewIcon fontSize="small" />
                                    </IconButton>
                                    
                                </TableCell>
            
                            </TableRow>
                            ))
                            }
                        </TableBody>

                    </Table>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[ 50 ]}
                    component="div"
                    count={ clientes.length }
                    rowsPerPage={ rowsPerPage }
                    page={ page }
                    onPageChange={ handleChangePage }
                    // onRowsPerPageChange={handleChangeRowsPerPage}
                />

            </Paper>

            : <MensajeAlertaDanger titulo='No Tiene Clientes Creados' />
            }
        </>

    )




}
