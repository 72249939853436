
import { useSelector } from "react-redux";





export const usePrecioCarritoReal = () => {

    const { iva, view } = useSelector( state => state.iva );
    // const { view } = useSelector( state => state.iva );
    const { carritoCompra } = useSelector( state => state.carrito );
    const { role } = useSelector( state => state.admin );


    const obTenerTotalesRole = () => {

        let tCliente = carritoCompra.reduce( (total, prod) => total + prod.totalCliente, 0 );
        let tDistri = carritoCompra.reduce( (total, prod) => total + prod.totalDistri, 0);
        let tPublicista = carritoCompra.reduce( (total, prod) => total + prod.totalPublicista, 0 );
        let tEmpresa = carritoCompra.reduce( (total, prod) => total + prod.totalEmpresa, 0);

        if( iva ){
            return {
                clientes: tCliente + Math.ceil(tCliente / 100 * 19),
                distri: tDistri + Math.ceil(tCliente / 100 * 19),
                publicista: tPublicista + Math.ceil(tCliente / 100 * 19),
                empresa: tEmpresa + Math.ceil(tCliente / 100 * 19),
                ivas: Math.ceil(tCliente / 100 * 19),
            }
        }


        return {
            clientes: tCliente,
            distri: tDistri,
            publicista: tPublicista,
            empresa: tEmpresa,
            ivas: 0
        }
        
    }



    const obtenerTotalOrdenPorRole = (clientes, distri, publicista, empresa) => {
        
        if( role === 'SUPERADMIN' || role === 'ADMIN' ){
            switch(view){
                case 'cliente':
                    return clientes;
                case 'distri':
                    return distri;
                case 'disenio':
                    return publicista;
                case 'empresa':
                    return empresa;
            }
        }else if( role === 'DISTRIBUIDOR' ) {
            return distri;
        }else if( role === 'PUBLICISTA' ){
            return publicista;
        }else if( role === 'EMPRESA' ){
            return empresa;
        }
    }

    



    return {
        obTenerTotalesRole,
        obtenerTotalOrdenPorRole,
    }


    

}
