




export const sumaDeArrayPagosInventario = (items) => {
    const totales = items.reduce( (total, pago) => total + ((pago.porcentaje * pago.precioUnidad * pago.cantidad / 100) + pago.precioUnidad * pago.cantidad), 0 );
    return totales.toLocaleString('es-CO');
}




export const sumarTotalOps = (abonos) => {
    const ocurrenciasOp = {};
    let sumaPrecios = 0;

    abonos.forEach( ops => {
        if (!ocurrenciasOp[ops.op.op]) {
            ocurrenciasOp[ops.op.op] = true;
            sumaPrecios += ops.op.totalOrden;
        }
    });
    return sumaPrecios
}