

import { Box, Modal, Typography } from '@mui/material';
import { useSelector } from 'react-redux';






export const ModalFormularioDos = ({ titulo, children, datos=null, cerrarModal }) => {


    const { modalFormDos } = useSelector( state => state.modal );


    return (
        <Modal open={modalFormDos ?? false} closeAfterTransition onClose={ cerrarModal } >
            <>
                {(!datos)
                ?   <h1>Cargando Datos...</h1>

                :   <Box className="style-modal" sx={{width:{xs:'75%', sm:'65%', md:'50%'},}}>
                        <Typography align='center' variant='h2' sx={{ mb:2 }} >{ titulo }</Typography>

                        { children }

                    </Box>
                }
            </>
        </Modal>

    );







}