
import axios from 'axios';
const url = process.env.REACT_APP_API_URL;



const opsApi = axios.create({
    baseURL: `${url}/op`
});


// const token = localStorage.getItem('tokenCliente') ||  '';
// Configurar Interceptores


opsApi.interceptors.request.use( config => {
    config.headers = {
        ...config.headers,
        'x-token': localStorage.getItem('token')
    }
    return config;
})




export { opsApi }