


import { types } from "../types/types";




const initialState = {
    slideshow: [],
    activeSlide: null,
    modalSlide: false,
};



export const slideReducer = ( state = initialState, action ) => {

    switch ( action.type ) {

        case types.slideAddNew:
            return {
                ...state,
                slideshow: [
                    ...state.slideshow,
                    action.payload
                ],
                modalSlide: false,
            };

        case types.slideShowLista:
            return {
                ...state,
                slideshow: [ ...action.payload ],
                modalSlide: false,
            };

        case types.slideShowDelete:
            return {
                ...state,
                slideshow: state.slideshow.filter(
                    e => ( e.id !== action.payload.id )
                ),
                modalSlide: false,
            };
            
            
            case types.slideModalOpen:
                return {
                    ...state,
                    modalSlide: true,
                };
    
            case types.slideModalClose:
                return {
                    ...state,
                    modalSlide: false,
                };

        default:
            return state;
    }

}
