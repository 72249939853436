
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import PageviewIcon from '@mui/icons-material/Pageview';
import { MensajeAlertaDanger } from "../../components";
import { separadorMillares } from "../../../hooks";

import moment from "moment";





export const TablaAbonosFactura = () => {

    const { abonos } = useSelector( state => state.abonoFactura );
    const { activeGasto } = useSelector( state => state.gastos );


    const [totales, setTotales] = useState({ total: 0, abonosf: 0});
    const { total, abonosf } = totales;




    useEffect(() => {
        if(abonos && activeGasto){
            const gastos = activeGasto.items.reduce( (total, gasto) => total + gasto.total, 0 );
            const abonostotal = abonos.reduce( (total, acu) => total + acu.valor, 0 );
            setTotales({ total: gastos, abonosf: abonostotal });
        }
    }, [abonos, activeGasto]);  




    return (
        <>
            {(abonos.length > 0 && activeGasto)
            ?   <>
                    <TableContainer sx={{ mt:5 }}>
                        <Table sx={{ minWidth: 700, }} aria-label="customized table">
                            <TableHead >
                                <TableRow>
                                    <TableCell>FECHA</TableCell>
                                    <TableCell>FACTURA</TableCell>
                                    <TableCell>TOTAL</TableCell>
                                    <TableCell>ABONOS</TableCell>
                                    <TableCell>SALDOS</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow  key={ activeGasto._id }>
                                    <TableCell>{ moment(activeGasto?.createdAt).format('lll') }</TableCell>
                                    <TableCell>{ activeGasto.factura }</TableCell>
                                    <TableCell>{ `$${separadorMillares(total)}` }</TableCell>
                                    <TableCell>{ `$${separadorMillares(abonosf)}` }</TableCell>
                                    <TableCell>
                                         { `$${separadorMillares(total - abonosf)}` }
                                    </TableCell>

                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TableContainer sx={{ mt:5 }}>
                        <Table sx={{ minWidth: 700, }} aria-label="customized table">
                            <TableHead >
                                <TableRow>
                                    <TableCell>Fecha</TableCell>
                                    <TableCell>Cantidad</TableCell>
                                    <TableCell>Medio Pago</TableCell>
                                    <TableCell>Acciones</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                            { abonos.map( ( a ) => (
                                <TableRow  key={ a._id }>
                                    <TableCell>{ a.createdAt }</TableCell>
                                    <TableCell>{ `$${separadorMillares(a.valor)}` }</TableCell>
                                    <TableCell>{ a.medio }</TableCell>

                                    <TableCell>
                                        <IconButton className="icon-boton" >
                                            <PageviewIcon fontSize="small" />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>

            :   <MensajeAlertaDanger titulo="No hay abonos por los momentos." />
        }
    </>
    )
}
