
import { opsApi } from "../apis";
import { fetchConToken, fetchConTokenCliente } from "../helpers/fetch";
import { manejoDeErrores } from "../helpers/manejoDeErrores";
import { types } from "../types/types";

import Swal from "sweetalert2";






// =============================== Servicio Funcionando ==================================


export const carritoListaPorOP = ( id ) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken(`carrito/carrito-op/${ id }`);
            const body = await resp.json();
            if( body.ok ){
                let carrito = body.carrito;
                dispatch( itemListaStart(carrito) );
            }else {
                Swal.fire('Error', body.msg, 'error');
                dispatch( itemListaStart([]) );
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}




export const carritoListaPorOPCliente = ( id ) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConTokenCliente(`carrito/carrito-op/${ id }`);
            const body = await resp.json();

            if( body.ok ){
                let carrito = body.carrito;
                dispatch( itemListaStart(carrito) );
            }else {
                Swal.fire('Error', body.msg, 'error');
                dispatch( itemListaStart([]) );
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}





export const actualizarItemsXOpProduccion = ( op ) => {
    return async( dispatch ) => {
        try {
            const { data } = await opsApi.put(`/upload/items`, op);
            dispatch(opActivo(data.op));

            if( op.fase === 'create') {
                dispatch(itemCreateStart(data.data));
            } else {
                dispatch(itemUpdateStart(data.data));
            }
            return true;
        } catch (error) {
            const msg = error.response.data;
            const errores = manejoDeErrores(msg)
            Swal.fire('Error', errores, 'error');
            return false;
        }
    }
}




export const eliminarItemsXOpProduccion = (item) => {
    return async( dispatch ) => {
        console.log({item})
        try {
            const { data } = await opsApi.put(`/delete/items`, item);
            dispatch(opActivo(data.op));

            if( data.ok ) {
                dispatch(itemDeleteStart(data.data.id));
            }
            return true;
        } catch (error) {
            const msg = error.response.data;
            const errores = manejoDeErrores(msg)
            Swal.fire('Error', errores, 'error');
            return false;
        }
    }
}




export const eliminarItemDeOPEnProduccion = ( item ) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken(`carrito/items/${ item.id }`, {}, 'DELETE');
            const body = await resp.json();

            if( body.ok ) {
                dispatch( itemDeleteStart(item.id) );
                Swal.fire('Exitoso', 'Items Eliminado', 'success');
            } else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}






export const carritoPorTrabajar = ( ) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken(`carrito/solo-disponibles`);
            const body = await resp.json();

            if( body.ok ){
                let carrito = body.solucion;
                dispatch( itemListaStart(carrito) );
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}




export const carritoUpdateArea = ( carrito ) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken(`carrito/area/${ carrito.id }`, { area: carrito.area }, 'PUT');
            const body = await resp.json();

            if( body.ok ) {
                carrito.colaborador = null;
                dispatch( carritoUpdate(carrito) );
                Swal.fire('Exitoso', 'Area Actualizada', 'success');
            } else {
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            console.log(error);
        }
    }
}




//! Pendiente por rebisar
export const eliminarItemsDeOP = ( item ) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken(`carrito/items/${ item.id }`, {}, 'DELETE');
            const body = await resp.json();

            if( body.ok ) {
                dispatch( itemDeleteStart(item.id) );
                Swal.fire('Exitoso', 'Items Eliminado', 'success');
            } else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}





// Activar Proucto en el Redux
const opActivo = ( op ) => ({
    type: types.opActivo,
    payload: op
});



const itemListaStart = ( item ) => ({
    type: types.itemListaStart,
    payload: item 
});



const itemDeleteStart = ( id ) => ({ 
    type: types.itemDeleteStart,
    payload: id
});



const itemUpdateStart = ( item ) => ({
    type: types.itemUpdateStart,
    payload: item
});



const itemCreateStart = ( item ) => ({
    type: types.itemCreateStart,
    payload: item
});



//! PENDIENTE X ELIMINAR
const carritoUpdate = ( carrito ) => ({
    type: types.carritoUpdate,
    payload: carrito
});




































    
    

    
    
    
    export const carritoPorDisenio = ( id ) => {
        return async( dispatch ) => {
            try {
                const resp = await fetchConToken(`carrito/disenio-trabajo/${ id }`);
                const body = await resp.json();
    
                if( body.ok ){
                    let carrito = body.carrito;
                    dispatch( itemListaStart(carrito) );
                }else {
                    Swal.fire('Error', body.msg, 'error');
                }
                
            } catch (error) {
                console.log(error);
            }
        }
    }



    export const carritoUpdateTiempoCorte = ( carrito ) => {
        return async( dispatch ) => {

            try {
                const resp = await fetchConToken(`carrito/tiempo-corte/${ carrito.id }`, carrito, 'PUT');
                const body = await resp.json();
    
                if( body.ok ) {
                    dispatch( carritoUpdate(body.carrito) );
                } else {
                    Swal.fire('Error', body.msg, 'error');
                }
                
            } catch (error) {
                console.log(error);
            }
        }
    }



