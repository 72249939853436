

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";

import { Button, Grid, MenuItem, TextField } from '@mui/material';
import { LayoutReturn } from "../../layouts"
import { ListadoMedidasStart, activarInvetario, deleteInventarioActivo, inventarioStartUpdate } from "../../../actions";
import { productosListaStartTodos } from "../../../actions/producto";
import { AutocompleteNombre } from "../../components";

import Swal from "sweetalert2";





export const DetallesDeInventario = () => {


    const { id } = useParams();
    const dispatch = useDispatch();
    const { productos } = useSelector(state => state.producto);
    const { medidas }  = useSelector( state => state.medidas );
    const { activeInventario } = useSelector( state => state.inventario );

    const [ formInventario, setFormInventario ] = useState({ medida: "", producto: "", cantidad: "" });
    const { medida, producto, cantidad } = formInventario;



    useEffect(() => {
        if( id ){
            dispatch(activarInvetario(id));
        }
        return () => dispatch(deleteInventarioActivo());
    }, [id]);


    useEffect( () => {
        dispatch( productosListaStartTodos() );
    }, [dispatch] );


    useEffect(() => {
        dispatch(ListadoMedidasStart());
    }, []);



    const handleAutocompleteChange = (selectedOption) => {
        setFormInventario({
            ...formInventario,
            producto: selectedOption ? selectedOption.id : "",
        });
    }


    const handleInputChange = ({ target }) => {
        setFormInventario({
            ...formInventario,
            [target.name]: target.value
        });
    }




    useEffect( () => {
        if( activeInventario ){
            setFormInventario({
                ...formInventario,
                medida: activeInventario.medida,
                producto: activeInventario.producto,
                cantidad: activeInventario.cantidad,
            });
        }
    }, [activeInventario]);
    




    const handleActualizarInventario = async(e) => {
        e.preventDefault();
        if( [cantidad, producto, medida].includes('') ) {
            return Swal.fire('Error', 'Todos Los Campos Son Obligatorios', 'error');
        }
        Swal.fire({
            title: 'Actualizar Stock',
            text: `¿Esta Seguro De Actualizar Este Producto?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si, Actualizar' 
        }).then( ( result ) => {
            if(result.value){
                dispatch(inventarioStartUpdate({ ...formInventario, id }));
            }
        })
    }




    return (

        <LayoutReturn titulo="Editar Producto En Stock" url="/super/inventario/list">
            <form style={{ width:'100%'}} onSubmit={ handleActualizarInventario } autoComplete="off">
                <Grid container spacing={ 2 } my={5}> 

                    <Grid item xs={ 12 } sm={ 12 } mt={ 2 }>
                        <AutocompleteNombre
                            bloqueo={true}
                            label="Producto"
                            type='text'
                            fullWidth
                            placeholder='Producto'
                            name="producto"
                            value={producto}
                            options={productos}
                            onChange={handleAutocompleteChange}
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 12 } mt={ 2 }>
                        <TextField
                            label="Cantidad"
                            type='number'
                            fullWidth
                            placeholder='Cantidad'
                            name="cantidad"
                            value={ cantidad }
                            onChange={ handleInputChange }
                        >
                        </TextField>
                    </Grid>

                    <Grid item xs={ 12 } sm={12} mt={ 2 }>
                        <TextField
                            select
                            label="Unidad de Medida"
                            fullWidth
                            name="medida"
                            value={ medida }
                            onChange={ handleInputChange }  
                        >    
                            <MenuItem value=''>
                                Seleccione Medio de Pago
                            </MenuItem>
                            {
                            medidas.map( (m) => (
                                <MenuItem value={ m.id } key={ m.id }>        
                                    { m.nombre }
                                </MenuItem>
                            ))
                            }
                        </TextField>
                    </Grid>

                    <Grid container  sx={{ mt:2 }}  direccion='column' justifyContent='center'  >
                        <Grid item xs={ 12 } sm={ 8 } display='flex' justifyContent='center'>
                            <Button variant='contained' fullWidth type='submit'>
                                Actualizar Stock
                            </Button>
                        </Grid>
                    </Grid>

                </Grid>
            </form>
        </LayoutReturn>

        
    )


}
