
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Grid } from "@mui/material";

import { productosListaCategoriaStart } from "../../../actions/producto";
import { BuscarProductoVenta, CardsProductosVenta } from "../components";
import { MensajeCargandoData } from "../../components";
import { categoriaPorID } from "../../../actions/categoria";
import { LayoutReturn } from "../../layouts";





export const ExibirProductosScreen = () => {


    const { id } = useParams();
    const dispatch = useDispatch();
    const { productos } = useSelector(state => state.producto);
    const { activeCategoria } = useSelector( state => state.categoria );
    const { usuario } = useSelector( state => state.admin );



    useEffect( () => {
        dispatch( productosListaCategoriaStart( id ) );
    }, [dispatch] );




    useEffect( () => {
        if(id && usuario) {
            dispatch( categoriaPorID( id ) );
        }
    }, [id, usuario] );





    return (

        <LayoutReturn titulo={`Categoria de ${activeCategoria?.nombre}`} 
            url={`/super/ver-productos`} admin={!!usuario}
        >

            <BuscarProductoVenta />

            <Grid container spacing={4} sx={{ display:'flex', alignItems:'stretch'}}>
                {(productos.length > 0) 
                ?   productos.map( producto => (
                        <CardsProductosVenta
                            key={ producto.id } 
                            { ...producto }
                        />
                    ))

                :   <MensajeCargandoData titulo="Cargando Datos De Productos..." />
                }
            </Grid>

        </LayoutReturn>

    
    )
}
