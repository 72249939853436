
import { fetchConToken } from "../helpers/fetch";
import { types } from "../types/types";

import Swal from "sweetalert2";








export const reporteInventarioListaStart = ( id ) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken(`reporteinv/producto/${ id }`);
            const body = await resp.json();
            if( body.ok ){
                dispatch( inventarioListado(body.data) );
            }else {
                Swal.fire('Error', body.msg, 'error');
            }                
            
        } catch (error) {
            console.log(error);
        }
    }
}




const inventarioListado = ( inventario ) => ({
    type: types.listadoReporteInventario,
    payload: inventario 
});


