
import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

import { Button, Grid, TextField, Typography } from "@mui/material";
import { authClienteChekingLogin } from '../../../actions/authCliente';
import { clienteUpdateTerminos } from '../../../actions/cliente';
import { InputDePassword } from '../../home/components';
import Swal from 'sweetalert2';


const empresa = require.context('../../../assets/empresa', true);
const baseUrl = process.env.REACT_APP_API_URL;


const initialState = {
  cedula: "",
  password: "",
};


export const LoginClientes = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();



  const [formCliente, setFormCliente] = useState( initialState );
  const { cedula, password } = formCliente;


  const handleChange = ({ target }) => {
    setFormCliente({
      ...formCliente,
      [target.name]: target.value
    });
  }



const handleLoginCliente = async(e) => {
    e.preventDefault();

    try {

    const lastPath = localStorage.getItem('lastPath') || '/';
    const { data } = await axios.post(`${ baseUrl }/cliente/login`, formCliente);

    if( data.ok ) {
        localStorage.setItem('tokenCliente', data.token);
        localStorage.setItem('token-init-cli', new Date().getTime());
        dispatch( authClienteChekingLogin({
            uid: data.uid,
            nombre: data.nombre,
            role: data.role,
            cliente: data.cliente,
        }));
        const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
        })
        Toast.fire({
        icon: 'success',
        title: 'Inicio de Sección con Exito'
        });
        navigate(lastPath);
        
        if( !data.cliente.terminos ){
        Swal.fire({
            title: 'Importante',
            text: 'Debes Aceptar los Teminos y Condiciones',
            icon: 'info',
            footer: '<a class="enlace-termino" href="/terminos" target="_blank">Ver Terminos y Condiciones.</a>',
            allowOutsideClick: false,
            stopKeydownPropagation: true
        }).then((result) => {
            if (result.value) {
            Swal.fire('Guardado con Exito', '', 'success');
            dispatch( clienteUpdateTerminos({ terminos: true, id: data.uid }) );
            } 
        })
        }

    } else {
        Swal.fire('Error', data.msg, 'error');
    }
    }catch (error){
    console.log(error);
    Swal.fire('Error', error.response.data.msg, 'error');
    }
}


  return (

        <Grid container className="login-cliente">

            <Grid item className="img-cliente">
                <img src={ empresa(`./svtextoblanco.png`) } />
            </Grid>

            <Grid item>
                <Typography variant="h1" mt={3}>Login Cliente</Typography>
            </Grid>

            <Grid item xs={10} sx={{ width:'400px', padding:'20px' }}>
                <form onSubmit={ handleLoginCliente } autoComplete="off" >

                    <Grid item xs={12} mt={2} >
                        <TextField required
                            label='Cedula'
                            type='text'
                            placeholder='Cedula'
                            fullWidth
                            name="cedula" value={ cedula }
                            onChange={ handleChange }
                        />
                    </Grid>


                    <Grid item xs={12} mt={ 2 }>
                        <InputDePassword value={password}
                            name='password'
                            placeholder={'Password'} 
                            onChangeInput={ handleChange } 
                        /> 
                    </Grid>

                    <Grid container my={3} direccion='column' justifyContent='center'  >
                        <Grid item xs={ 12 } >
                            <Button variant="contained" color="primary" fullWidth type="submit" >
                                <Typography variant="span" color='white' >Ingresar</Typography>
                            </Button>
                        </Grid>
                    </Grid>

                </form>

                <Grid sx={{width:'100%', textAlign:'right' }}>

                    <Typography variant="h3" mb={1}>
                        ¿No Tienes Cuenta?,
                        <Link to="/register" className='underline' > Registrate</Link>
                    </Typography>
                    <Link to="/olvide-password" underline="hover" > 
                        <Typography className='underline' variant="h3">Olvide Mi Password</Typography>
                    </Link>
                    
                </Grid>
            </Grid>


        </Grid>
    
    )





}
