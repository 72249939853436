
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { AddOutlined } from "@mui/icons-material";
import RemoveIcon from '@mui/icons-material/Remove';





export const LayoutAddSubstra = ({ children, titulo, funcionAdd, funcionSubt }) => {





    const agregarInventario = () => {
        funcionAdd();
    }



    const descontarInventario = () => {
        funcionSubt();
    }



    return (

        <>
            <Grid container direccion='row' alignItems='center'>

                <IconButton
                    sx={{
                        color: '#fff',
                        backgroundColor: 'secondary.main',
                        ':hover': { backgroundColor: 'secondary.main', opacity: 0.3 },
                    }}
                    onClick={ agregarInventario } 
                >
                    <AddOutlined />
                </IconButton>

                <Box flex={ 1 } />

                <Typography variant='h1'>{ titulo }</Typography>

                <Box flex={ 1 } />

                <IconButton
                    sx={{
                        color: '#fff',
                        backgroundColor: 'secondary.main',
                        ':hover': { backgroundColor: 'secondary.main', opacity: 0.3 },
                    }}
                    onClick={ descontarInventario } 
                >
                    <RemoveIcon />
                </IconButton>

            </Grid>

            <Grid container alignItems='center' justifyContent='center' >

                { children }

            </Grid>
        </>

    )
}
