
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Grid } from "@mui/material";
import { AutocompleteNombre } from "../../components";
import { productoPorID } from "../../../actions/producto";






export const SelectorProductos = ({ formCarrito, setFormCarrito }) => {


    const dispatch = useDispatch();
    const { productos } = useSelector(state => state.producto);


    useEffect(() => {
        if(formCarrito.producto) {
            dispatch( productoPorID(formCarrito.producto) );
        }
    },[formCarrito.producto]);



    const handleInputProducto = (newValor) => {
        setFormCarrito({
            ...formCarrito,
            producto: (newValor) ? newValor.id : null,
        });
    }



    return (


        <Grid item xs={12} sx={{mt:1}}>
            <AutocompleteNombre
                size="small"
                name="producto"
                value={formCarrito.producto}
                onChange={ handleInputProducto }
                options={productos}
            />
        </Grid>

    )

}
