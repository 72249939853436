
import { Box, Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";






export const InformacionCliente = ({ urlEditar }) => {


    const navigate = useNavigate();
    const cliente = JSON.parse(localStorage.getItem('cliente') ) || null;



    const handleEditarInfo = () => {
        navigate(`${ urlEditar }`);
    }
    



    return (
        <Grid container sx={{background:'#1E1E1E', borderRadius:'10px', p:2, mb:3}}>

            <Box sx={{width:'100%', display:'flex', justifyContent:'space-between', alignItems:'center', mb:2}}>
                <Typography variant="h4" sx={{fontSize:'25px'}}>Informacion Personal</Typography>

                <Button variant="contained" color="secondary" onClick={ handleEditarInfo }>
                    Editar
                </Button>
            </Box>
        
            <Grid container> 
                <table style={{textAlign:'left', width:'100%', borderCollapse:'collapse'}}>
                    <tbody>
                        <tr>
                            <th>Nombre:</th>
                            <td style={{fontWeight:'lighter', fontSize:'14px'}}>{ cliente.nombre }</td>
                            <th>Movil:</th>
                            <td style={{fontWeight:'lighter', fontSize:'14px'}}>{ cliente.movil }</td>
                        </tr>
                        <tr>
                            <th>Dirección:</th>
                            <td style={{fontWeight:'lighter', fontSize:'14px'}}>{ cliente.direccion }</td>
                            <th>Ciudad:</th>
                            <td style={{fontWeight:'lighter', fontSize:'14px'}}>{ cliente.ciudad }</td>
                        </tr>
                    </tbody>
                </table>
            </Grid> 
        </Grid>
    )


}
