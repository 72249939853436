

import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { Box, Grid, Typography } from "@mui/material";
import { BusquedaPorItems, TablaTodosItems } from "../components";

import { todosCarrito } from "../../../actions/carrito";





export const TodosLosItems = () => {

        
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch( todosCarrito() );
    }, [dispatch] );




    return (


        <div>
            <Grid container direccion='row' alignItems='center'>
                <Box flex={ 1 } />
                <Typography variant='h2' sx={{ fontWeight:'bold' }} >TODOS LOS ITEMS</Typography>
                <Box flex={ 1 } />
            </Grid>

            <Grid container alignItems='center'>
                <BusquedaPorItems />
                <TablaTodosItems />
            </Grid>
        </div>

    )
}









