

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { opPorID } from "../../../actions/op";
import { deudaClienteListaStart } from "../../../actions/deudaCliente";
import { useParams } from "react-router-dom";
import { DetallesOpCliente, TablaAbonosCliente } from "../components";
import { LayoutReturn } from "../../layouts/LayoutReturn";





export const DeudaClienteOp = () => {


    const dispatch = useDispatch();
    const { id } = useParams();



    useEffect(() => {
        if( id ) {
            dispatch(opPorID( id ));
            dispatch( deudaClienteListaStart( id ));
        }
    }, [id, dispatch]);



    return (

        <LayoutReturn titulo="Abonos de Cliente" url={-1} >

            <DetallesOpCliente />
        
            <TablaAbonosCliente />
        
        </LayoutReturn>


    )
}
