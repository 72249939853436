
import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from "@mui/material"



export const AutocompleteParaStock = ({ label, options=[], onChange, bloqueo=false, ...props }) => {



    const [selectNombre, setSelectNombre] = useState(null);


    useEffect(() => {
        setSelectNombre(props.value);
    }, [props.value]);


    const handleChange = (event, newValue) => {
        setSelectNombre(newValue ? newValue.producto.id : null);
        if (onChange) {
            onChange(newValue);
        }
    }




    return (

        <>
            <Autocomplete
                disabled={bloqueo}
                value={options.find(valor => valor.producto.id === selectNombre) || null}
                options={ options }
                onChange={ (event, newValue) => handleChange(event, newValue)}
                renderInput={(params) => <TextField
                    disabled={bloqueo}
                    {...params} 
                    label={ label }  
                    { ...props }
                    autoComplete="off"
                />}
                getOptionLabel={(option) => option.producto.nombre}
            />
        </>

    )
}


