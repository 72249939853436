
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { HeaderDeCheckoutAdmin } from "../layouts";
import { distriListaStart, usuarioDeleteActivo } from "../../../actions/usuario";

import { FormCompartidoCheckout } from "../components";
import Swal from "sweetalert2";


const initCliente = {
    uid: "",
    nombre: "",
    email: "",
    cedula: "",
    ciudad: "",
    direccion: "",
    movil: "",
}


export const DistribuidorCheckout = () => {


    const { activeUser } = useSelector(state => state.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const [ formCliente, setFormCliente ] = useState( initCliente );
    const handleInputChange = ({ target }) => {
        setFormCliente({
            ...formCliente,
            [target.name]: target.value
        });
    }



    useEffect( () => {
        dispatch( distriListaStart('DISTRIBUIDOR') );
    }, [ dispatch ] );




    useEffect(() => {
        if( activeUser ) {
            setFormCliente({
                ...formCliente,
                uid: activeUser.id,
                nombre: activeUser.nombre,
                email: activeUser.email || 'No Registra',
                ciudad: activeUser.ciudad,
                direccion: activeUser.direccion,
                movil: activeUser.movil,
                cedula: activeUser.cedula,
            });
        }
    }, [activeUser] );



    const  handleInfoCliente = ( e ) => {
        e.preventDefault();
        if( formCliente.nombre.trim().length <= 0 ) {
            Swal('Error', 'El Nombre Es Obligatorio', 'error');
            return; 
        }
        if( formCliente.direccion.trim().length <= 0 ) {
          Swal('Error', 'La Direccion Es Obligatoria', 'error');
          return; 
        }
        localStorage.setItem('cliente', JSON.stringify(formCliente) );
        navigate('/super/comprar-ahora');
        dispatch(usuarioDeleteActivo());

    }


    return (

        
        <HeaderDeCheckoutAdmin title={'Informacion Personal Distribuidor'}>

            <form className="formulario-total mt-5" onSubmit={ handleInfoCliente } autoComplete="off" >

                <FormCompartidoCheckout formCliente={formCliente} onChange={handleInputChange} titulo="Distribuidor" />

            </form>

        </HeaderDeCheckoutAdmin>
        
    )
    
}
