
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { FormCrearAbonosGastos, TablaAbonosFactura } from "../components";
import { abonoListaStartFactura } from "../../../actions/abonoFactura";
import { deleteGastoActivo, gastosPorId } from "../../../actions/gastos";
import { ModalFormulario } from "../../components";
import { LayoutReturnAndAdd } from "../../layouts";
import { closeStartForm, openStartForm } from "../../../actions";




export const AbonosGastos = () => {


    const { id } = useParams();
    const dispatch = useDispatch();



    useEffect(() => {
        if( id ){
            dispatch(abonoListaStartFactura(id));
        }
    }, [id]);


    
    useEffect(() => {
        if(id){
            dispatch(gastosPorId(id));
        }
        return () => dispatch(deleteGastoActivo());
    }, [id]);



    const handleCrearAbono = () => {
        dispatch( openStartForm() );
    }



    const cerrarModal = () => {
        dispatch( closeStartForm() );
    }



    return (


        <LayoutReturnAndAdd titulo='Abonos A Factura' url='/super/gastos' funcion={handleCrearAbono}>
            
            <TablaAbonosFactura />


            <ModalFormulario titulo="Crear Abono" cerrarModal={cerrarModal} datos={{}}>
                <FormCrearAbonosGastos />
            </ModalFormulario>

        </LayoutReturnAndAdd >

        
    )
}
