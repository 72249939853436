import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { Route, Routes, useLocation } from "react-router-dom"

import { Box } from "@mui/material";

import { CheckoutCliente, FloatingActionButtom } from "../components/home/components";
import { Footer } from "../components/ui/Footer"
import { Navbar } from "../components/ui/Navbar"


// Paginas de Home
import { DashboardCliene, RastrearOP, MisPedidos, CambioClave, MisArchivados, MiPerfil, DetallesOpCliente, NoPagesFound, HomeScreen, ContactenosScreen, CategoriaScreenCliente, DetalleProductoCliente, CotizarCliente } from "../components/home/pages";
//Paginas de Productos
import { ExibirProductosScreen } from "../components/productos/pages";
// Pagina de Comprar Ahora
import { ComprarAhoraCliente } from "../components/home/pages";


import { DetalleItemCompra } from "../components/compras/pages";




import { startRenewCliente } from "../actions/authCliente"
import { AbonosCliente } from "../components/home/AbonosCliente"
import { ImagenPago } from "../components/home/ImagenPago"
import { ModalCarritoCliente } from "../components/ui/ModalCarritoCliente"








export const HomeRouter = () => {


    const dispatch = useDispatch();
    const location = useLocation();
    localStorage.setItem('lastPath', location.pathname);




    useEffect(() => {
      dispatch( startRenewCliente() );
    }, [dispatch] );



    return (

      <>
          <Navbar />
          <FloatingActionButtom />

          <Box sx={{width:'100%'}}>
            <Routes>

              {/* Primera Ruta Home */}
              <Route path="/" element={<HomeScreen />} />
              <Route path="/contactos" element={<ContactenosScreen />} />

              {/* Configuraciones de Perfil */}
              <Route path="/dashboard" element={<DashboardCliene />} />
              <Route path="/mis-pedidos" element={<MisPedidos />} />
              <Route path="/detallesop/:id" element={<DetallesOpCliente />} />
              <Route path="/mis-archivados" element={<MisArchivados />} />
              <Route path="/perfil" element={<MiPerfil />} />
              <Route path="/cambio-password" element={<CambioClave />} />

              {/* Rutas de Comprar Ahora */}
              <Route path="/comprar-cliente"  element={ <ComprarAhoraCliente />} />
              <Route path="/checkout-cliente" element={<Box sx={{px:2, mt:5, mb:9}}><CheckoutCliente /></Box> }/>
              <Route path="/detalle-item/:id" element={<Box sx={{px:7, mt:5, mb:9}}><DetalleItemCompra /></Box> }/>
              {/* Filtro de Productos al Comprar */}
              <Route path="/categorias" element={<Box sx={{px:7, mt:6, mb:9}}><CategoriaScreenCliente /></Box> }/>
              <Route  path="/filtro/:id" element={<Box sx={{px:7, mt:6, mb:9}}><ExibirProductosScreen /></Box> }/>
              <Route  path="/detalle-producto/:id" element={<Box sx={{px:8, mt:5, mb:9}}><DetalleProductoCliente /></Box> }/>
              <Route  path="/cotizar" element={<Box sx={{px:2, mt:6, mb:9}}><CotizarCliente /></Box> }/>




                {/* <div className="formTodoOp"> */}
                <Route path="/restrearop" element={<RastrearOP />} />
                <Route path="/foto-pago/:id" element={<ImagenPago />} />
                <Route path="/abono-cliente/:id" element={<AbonosCliente />} />


                <Route path="*" element={<NoPagesFound />} />
            </Routes>
          </Box>


          <Footer />
          
          <ModalCarritoCliente />
      </>

    )


}
