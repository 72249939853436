
import { useDispatch } from "react-redux";

import { Button, Grid, TextField, Typography } from "@mui/material";
import { useForm } from "../../../hooks/useForm";
import { LayoutUsuarios } from "../layouts";
import { clienteStartAddNew } from "../../../actions/cliente";
import Swal from 'sweetalert2';



const initCliente = {
    nombre: "",
    cedula: "",
    ciudad: "",
    direccion: "",
    movil: "",
    establecimiento: "",
    profesion: "",
    email: "",
    role: "CLIENTE",
};



export const CrearClientes = () => {


    const dispathch = useDispatch();

    const [ formCliente, handleInputChange, reset ] = useForm( initCliente );
    const { nombre, cedula, ciudad, direccion, movil, establecimiento, email,
        profesion } = formCliente;


    const  handleCrearCliente = ( e ) => {
        e.preventDefault();
        
        if(nombre.length < 4 ){
            Swal.fire('Error', 'El Nombre Debe De Tener Mas De 5 Caracteres', 'error');
            return;
        }
    
        if(email.length < 2 ){
            Swal.fire('Error', 'El Email Debe De Ser Obligatorio', 'error');
            return;
        }
        dispathch( clienteStartAddNew( formCliente ) );
        reset();
    
    }


    return (

        <LayoutUsuarios title={'Crear Cliente'}>

            <form className="formulario-total mt-5" onSubmit={ handleCrearCliente } >
            <Grid container spacing={ 4 }>

                <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                    <TextField required
                        variant="filled"
                        label='Nombre Cliente'
                        type='text'
                        placeholder='Nombre Cliente'
                        fullWidth
                        name="nombre"
                        value={ nombre }
                        onChange={ handleInputChange }
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                    <TextField required
                        variant="filled"
                        label='Cedula Cliente'
                        type='text'
                        placeholder='Cedula Cliente'
                        fullWidth
                        name="cedula"
                        value={ cedula }
                        onChange={ handleInputChange }
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                    <TextField required
                        variant="filled"
                        label='Correo Electronico'
                        type='text'
                        placeholder='Correo Electronico'
                        fullWidth
                        name="email"
                        value={ email }
                        onChange={ handleInputChange }
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                    <TextField required
                        variant="filled"
                        label='Movil'
                        type='text'
                        placeholder='Movil'
                        fullWidth
                        name="movil"
                        value={ movil }
                        onChange={ handleInputChange }
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                    <TextField 
                        variant="filled"
                        label='Establecimiento'
                        type='text'
                        placeholder='Establecimiento'
                        fullWidth
                        name="establecimiento"
                        value={ establecimiento }
                        onChange={ handleInputChange } 
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                    <TextField 
                        variant="filled"
                        label='Profesión'
                        type='text'
                        placeholder='Profesión'
                        fullWidth
                        name="profesion"
                        value={ profesion }
                        onChange={ handleInputChange } 
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                    <TextField 
                        variant="filled"
                        label='Ciudad'
                        type='text'
                        placeholder='Ciudad'
                        fullWidth
                        name="ciudad"
                        value={ ciudad }
                        onChange={ handleInputChange } 
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                    <TextField required
                        variant="filled"
                        label='Dirección'
                        type='text'
                        placeholder='Dirección'
                        fullWidth
                        name="direccion"
                        value={ direccion }
                        onChange={ handleInputChange }
                    />
                </Grid>


            </Grid>

                <Grid container 
                    my={7}
                    direccion='column'
                    justifyContent='center' 
                >
                    <Grid item xs={ 12 } sm={ 7 } >
                        <Button variant="contained" color="secondary" fullWidth type="submit" >
                            <Typography variant="span" color='white' >Crear Cliente</Typography>
                        </Button>
                    </Grid>
                </Grid>

            </form>


        </LayoutUsuarios>


    )




}
