
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";

import { Box, Button, Grid, TextField } from "@mui/material";
import { LayoutReturn } from "../../layouts";
import { TablaModeloItems } from "../../ui";
import { carritoActivo, carritoListaUnoStart, carritoUpdateStart } from "../../../actions/carrito";
import { startUploadingDetalles } from "../../../actions/uploads";
import { carritoUpdateStartCliente } from "../../../actions/carritoCliente";



const baseUrl = process.env.REACT_APP_API_URL;




export const DetalleItemCompra = () => {


    const { id } = useParams();
    const dispatch = useDispatch();
    const { nombre } = useSelector( state => state.admin );
    const { activeCarrito } = useSelector( state => state.carrito );

    const [detalles, setDetalles] = useState('');



    
    useEffect(() => {
        if( id ){
            dispatch(carritoListaUnoStart( id ) );
        }
        return () => {dispatch(carritoActivo(null) )};  
    }, [id]);



    useEffect(() => {
        if(activeCarrito){
            setDetalles(activeCarrito.detalles || '');
        }
    }, [activeCarrito]);




    const handleDetalles = (e) => {
        e.preventDefault();
        if( nombre ) {
            dispatch(carritoUpdateStart({ id, detalles }) );
        } else {
            dispatch(carritoUpdateStartCliente({ id, detalles }))
        }
    }



    const handlePictureClick = () => {
        document.querySelector('#fileSelector').click();
    }




    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if( file ) {
            dispatch( startUploadingDetalles( id, 'items', file ) );
        }
    }




    return (


        <LayoutReturn titulo="Detalles del Producto" url={-1}>

            { activeCarrito && 
            <Grid container sx={{my:4}}>
                <TablaModeloItems activeCarrito={ activeCarrito } />
            </Grid>
            }


            <Grid container sx={{display:'flex', justifyContent:'space-between', mb:2}}>

                <Grid item xs={12} sm={6}>
                    <Grid container>
                        <TextField
                            type="text"
                            fullWidth
                            multiline
                            placeholder="Escribe Como Quieres Tu Pedido..."
                            rows={10}
                            value={detalles} 
                            onChange={ ({ target }) => setDetalles(target.value) }
                        />
                    </Grid>

                    <Grid container>
                        <Button variant="contained" color="secondary" fullWidth onClick={ handleDetalles }> 
                            Enviar Información
                        </Button>
                    </Grid>
                </Grid>


                <Grid item xs={12} sm={5} >
                    <input type="file" id="fileSelector" className="boton-file" onChange={ handleFileChange } />

                    <Box sx={{width:'100%', height:260 }}>
                        <img src={`${ baseUrl }/uploads/items/${ activeCarrito?.imgCliente }`} 
                            alt="Imagen Detalle" style={{width:'100%', height:'100%'}} />
                    </Box>

                    <Grid container>
                        <Button variant="contained" color="secondary" fullWidth onClick={ handlePictureClick } > 
                            Subir Archivo
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

        </LayoutReturn>
    )
}
