
import { useNavigate } from "react-router-dom";

import { Box, Grid, IconButton, Typography } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';








export const LayoutDetallesProducto = ({ children, titulo = 'No hay Datos', url }) => {


    
    const navigate = useNavigate();



    const handleReturn = () => {
        navigate(url);
    }



    return (

        <>
            <Grid container direccion='row' alignItems='center' mb={10}>
                <IconButton onClick={ handleReturn }
                sx={{ color:'#fff', backgroundColor:'secondary.main', ':hover':{ backgroundColor:'secondary.main', opacity:0.3 }}}
                >
                    <ArrowBackIcon />
                </IconButton>

                <Box flex={ 1 } />

                <Typography variant='h1' sx={{textAlign:'center'}}>{ titulo }</Typography>

                <Box flex={ 1 } />

            </Grid>

            <Grid container alignItems='center' justifyContent='center' >


                { children }


            </Grid>
        </>

    )
}
