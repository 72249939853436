
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { LayoutListados } from "../../layouts";
import { BuscadorProductos, TablaProductosCategoria } from "../components";

import { productosListaStartTodos } from "../../../actions/producto";






export const ListadoProductos = () => {


    const dispatch = useDispatch();
    // const { productos } = useSelector(state => state.producto);



    useEffect( () => {
        dispatch( productosListaStartTodos() );
    }, [dispatch] );


    

    return (

        <LayoutListados  title={'Listado de Productos'} >
            <BuscadorProductos />
            <TablaProductosCategoria />
        </LayoutListados>


    )



}
