import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Button, Grid, MenuItem, TextField, Typography } from "@mui/material";

import { proveedorStartActivo, proveedorStartAddNew, proveedorStartUpdate } from "../../../actions/proveedor";
import { LayoutUsuarios } from "../../superAdmin/layouts";

import Swal from "sweetalert2";



const initUser = {
    nombre: "", nit: "", ciudad: "", direccion: "", movil: "", 
    formaPago: "", observacion: "", tipo: "", numeroCuenta: '',  tipoCuenta: '',  banco: ''
};





export const CrearProveedores = () => {


    const { id } = useParams();
    const dispatch = useDispatch();
    const { activeProveedor } = useSelector(state => state.proveedor);


    const [formUsuario, setFormUsuario] = useState( initUser );
    const { nombre, nit, ciudad, direccion, movil, formaPago, observacion, tipo, numeroCuenta, tipoCuenta, banco } = formUsuario;



    useEffect(() => {
        if(id){
            dispatch(proveedorStartActivo(id))
        }
    }, [id])



    useEffect(() => {
        if(activeProveedor){
            setFormUsuario({ ...activeProveedor, numeroCuenta: activeProveedor.numeroCuenta || '' });
        } else {
            setFormUsuario({ ...initUser });
        }
    }, [activeProveedor])
    



    const handleInputChange = ({ target }) => {
        setFormUsuario({
        ...formUsuario,
        [target.name]: target.value
        });
    }
        

    
    // Creacion o Actualizacion del Proveedor
    const handleCrearProveedor = async (e) => {
        e.preventDefault();
        if(id === 'new') {
            const resp = await dispatch( proveedorStartAddNew( formUsuario ) );
            if( resp ) return setFormUsuario( initUser );
        }

        Swal.fire({
            title: 'Editar Proveedor',
            text: `¿Esta Seguro De Editar Este Proveedor?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si, Editar' 
        }).then( ( result ) => {
            if(result.value){
                dispatch(proveedorStartUpdate({ ...formUsuario, id }));
            }
        })
        
    }




    return (


        <LayoutUsuarios title={`${(id === 'new') ? 'Crear' : 'Editar'} Proveedor`}>

            {formUsuario &&
            <form className="formulario-total mt-5" onSubmit={ handleCrearProveedor }>
                <Grid container spacing={ 4 }>

                    <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                        <TextField required
                            variant="filled"
                            label='Nombre Usuario'
                            type='text'
                            placeholder='Nombre Usuario'
                            fullWidth
                            name="nombre"
                            value={ nombre }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                        <TextField required
                            variant="filled"
                            label='Nit'
                            type='text'
                            placeholder='Nit'
                            fullWidth
                            name="nit"
                            value={ nit }
                            onChange={ handleInputChange } 
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                        <TextField
                            variant="filled"
                            label='Tipo'
                            type='text'
                            placeholder='Tipo'
                            fullWidth
                            name="tipo"
                            value={ tipo }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                        <TextField required
                            variant="filled"
                            label='Ciudad'
                            type='text'
                            placeholder='Ciudad'
                            fullWidth
                            name="ciudad"
                            value={ ciudad }
                            onChange={ handleInputChange } 
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                        <TextField 
                            variant="filled"
                            label='Dirección'
                            type='text'
                            placeholder='Dirección'
                            fullWidth
                            name="direccion"
                            value={ direccion }
                            onChange={ handleInputChange } 
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                        <TextField 
                            variant="filled"
                            label='Movil'
                            type='number'
                            placeholder='Movil'
                            fullWidth
                            name="movil"
                            value={ movil }
                            onChange={ handleInputChange }  
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                        <TextField 
                            variant="filled"
                            label='Forma de Pago'
                            type='text'
                            placeholder='Forma de Pago'
                            fullWidth
                            name="formaPago"
                            value={ formaPago }
                            onChange={ handleInputChange } 
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                        <TextField required
                            variant="filled"
                            label='Observación'
                            type='text'
                            placeholder='Observación'
                            fullWidth
                            name="observacion"
                            value={ observacion }
                            onChange={ handleInputChange } 
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } md={4} mt={ 2 } >
                        <TextField 
                            required
                            variant="filled"
                            select
                            label="Seleccione Banco"
                            fullWidth
                            name="banco"
                            value={ banco }
                            onChange={ handleInputChange } 
                        >
                            <MenuItem value=''>Seleccione Banco</MenuItem>
                            <MenuItem value='Davivienda'>Davivienda</MenuItem>
                            <MenuItem value='Banco de Bogota'>Banco de Bogota</MenuItem>
                            <MenuItem value='Bancolombia'>Bancolombia</MenuItem>
                            <MenuItem value='Caja Social'>Caja Social</MenuItem>
                        </TextField>
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } md={4} mt={ 2 } >
                        <TextField 
                            required
                            variant="filled"
                            select
                            label="Tipo de Cuenta"
                            fullWidth
                            name="tipoCuenta"
                            value={ tipoCuenta }
                            onChange={ handleInputChange } 
                        >
                            <MenuItem value="">Seleccione Cuenta</MenuItem>
                            <MenuItem value="Ahorros">Ahorros</MenuItem>
                            <MenuItem value="Corriente">Corriente</MenuItem>
                        </TextField>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                        <TextField required
                            variant="filled"
                            label='Numero Cuenta'
                            type='text'
                            placeholder='Numero Cuenta'
                            fullWidth
                            name="numeroCuenta"
                            value={ numeroCuenta }
                            onChange={ handleInputChange } 
                        />
                    </Grid>
                </Grid>

                <Grid container 
                    my={7}
                    direccion='column'
                    justifyContent='center' 
                >
                    <Grid item xs={ 12 } sm={ 7 } >
                        <Button variant="contained" color="secondary" fullWidth type="submit" >
                            <Typography variant="span" color='white'>
                                {(id === 'new') ? 'Crear' : 'Editar'} Proveedor
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>

            </form>
            }

        </LayoutUsuarios>

    )
}
