import { types } from "../types/types";



export const startActiveIva = (data) => ({
    type: types.ivaTrue,
    payload: data,
});



export const startRolVistas = (data) => ({
    type: types.viewRol,
    payload: data,
});



export const startChangeSidebar = (data) => ({
    type: types.changeSidebar,
    payload: data,
});