import { useEffect, useState } from "react";
import { useSelector } from "react-redux";



export const usePrecioCarritoCliente = () => {

    const { carritoCliente } = useSelector( state => state.carrito );
    // const porcentaje = JSON.parse(localStorage.getItem('iva'));
    const { iva: porcentaje } = useSelector( state => state.iva );



    const [ totales, setTotales ] = useState({
        subTotalCliente: 0,
        subTotalDistri: 0,
        totalCliente: 0,
        totalDistri: 0,
        iva: 0
    });
    const { subTotalCliente, subTotalDistri, totalCliente, totalDistri, iva } = totales;


    useEffect( () => {
        if( carritoCliente ){
            if( porcentaje ){
                const carritoTotal = carritoCliente.reduce( (total, prod) => total + prod.totalCliente, 0 );
                const distriTotal = carritoCliente.reduce( (total, prod) => total + prod.totalDistri, 0);
                setTotales({
                    subTotalCliente: carritoTotal, 
                    subTotalDistri: distriTotal, 
                    totalCliente: carritoTotal + (carritoTotal / 100 * 19),
                    totalDistri: distriTotal + (carritoTotal / 100 * 19),
                    iva: Math.ceil(carritoTotal / 100 * 19),
                });
            }else {
                const carritoTotal = carritoCliente.reduce( (total, prod) => total + prod.totalCliente, 0 );
                const distriTotal = carritoCliente.reduce( (total, prod) => total + prod.totalDistri, 0);
                setTotales({
                    subTotalCliente: carritoTotal, 
                    subTotalDistri: distriTotal,  
                    totalCliente: carritoTotal,
                    totalDistri: distriTotal,
                    iva: 0
                });
            }
        }
        
    }, [carritoCliente, porcentaje]);



    return {
        subTotalCliente,
        subTotalDistri,
        totalCliente,
        totalDistri,
        iva,
    }



}