import { Grid, Typography } from "@mui/material"





export const NoPagesFound = () => {


  return (

    <Grid container style={{display:'flex', justifyContent:'center', flexDirection:'column', alignItems:'center', height:'100vh'}}>
      <Typography variant="h2" sx={{mb:1}}>No-Pages-Found</Typography>
      <Typography variant="h4">Pagina No Disponible</Typography>
    </Grid>
 
  )
}
