

import { useSelector } from "react-redux";

import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { separadorMillares } from "../../../hooks/useMillares";
import { OpcionesAbono } from "./";
import { MensajeAlertaDanger } from "../../components";






export const TablaAbonosAOP = () => {


    const { role } = useSelector( state => state.admin );
    const { abonos } = useSelector( state => state.abono );


    return (

        <Grid container textAlign='center'>

            <Grid item xs={12} mt={5} >
              <Typography variant="h2" mb={2}>Abonos</Typography>
            </Grid>
        
            {

              ( abonos.length > 0 ) 
              ?<TableContainer sx={{ mb:5 }}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">

                      <TableHead >
                          <TableRow >
                            <TableCell>FECHA</TableCell>
                            <TableCell>ABONO</TableCell>
                            <TableCell>BANCO</TableCell>
                            <TableCell className="w-300">OBSERVACIÓN</TableCell>
                            {
                            ( role === 'SUPERADMIN' || role === 'ADMIN' ) && (
                            <TableCell >Opciones</TableCell>
                            )}
                          </TableRow>
                      </TableHead>

                        <TableBody>
                        {
                            abonos.map( a => (
                            <TableRow key={a.id} >

                                <TableCell >
                                    <Typography variant="span">{ a.createdAt }</Typography>
                                </TableCell>

                                <TableCell >
                                    <Typography variant="span">{ `$${separadorMillares(a.abono)}` }</Typography>
                                </TableCell>

                                <TableCell >
                                    <Typography variant="span">{ a.medio }</Typography>
                                </TableCell>

                                <TableCell >
                                    <Typography variant="span" >{ a.observacion }</Typography>
                                </TableCell>

                                {
                                ( role === 'SUPERADMIN' || role === 'ADMIN' ) && (
                                <TableCell>
                                    <OpcionesAbono a={a} />
                                </TableCell>
                                )}

                            </TableRow>
                            ))
                        }

                        </TableBody>

                  </Table>
              </TableContainer>
              
              : <MensajeAlertaDanger titulo={'No Hay Abonos A Esta O.P.'} />

            }

        </Grid>


    )



    
}
