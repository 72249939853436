

import { Box, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';





export const CargandoInformacion = ({ titulo }) => {

    return (

        <Box sx={{ display: 'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', width:'100%', height:'100vh' }}>
            <CircularProgress size="50px" />
            <Typography sx={{ fontSize:23, mt:2 }} >{ titulo }</Typography>
        </Box>

    )

}
