


import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { TablaProductosFactura } from "../components";
import { LayoutReturn } from "../../layouts";
import { CargandoInformacion } from "../../components";
import { pagoInventarioPorId } from "../../../actions/pagosInventario";

import moment from "moment";





export const DetallePagoInventario = () => {

    const { id } = useParams();
    const dispatch = useDispatch();
    const { activePagoInventario } = useSelector( state => state.pagoinv );



    useEffect(() => {
        if(id){
            dispatch(pagoInventarioPorId(id));
        }
    }, [id]);



    return (


        <LayoutReturn titulo={`Factura ${activePagoInventario?.factura}`} url='/super/pagos' >

            {(activePagoInventario) 
            ?   <>
                    <TableContainer sx={{ mt:5 }} >
                    <Table sx={{ minWidth: 700 }} >

                        <TableHead >
                            <TableRow>
                                <TableCell>FECHA</TableCell>
                                <TableCell>FACTURA</TableCell>
                                <TableCell>PAGO</TableCell>
                                <TableCell>PROVEEDOR</TableCell>
                                <TableCell>TIPO</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <TableRow key={ activePagoInventario.id } >
                                <TableCell>{ moment(activePagoInventario.fecha).format('lll') }</TableCell>
                                <TableCell>{ activePagoInventario.factura }</TableCell>   
                                <TableCell>{ activePagoInventario.tipoPago }</TableCell>
                                <TableCell>{ activePagoInventario.proveedor?.nombre }</TableCell>
                                <TableCell>{ activePagoInventario.tipo }</TableCell>
                            </TableRow>
                        </TableBody>

                    </Table>
                    </TableContainer>

                    <TablaProductosFactura productos={activePagoInventario.items} />

                </>

            :   <CargandoInformacion titulo="Cargando Pago Inventario..." />

            }
        </LayoutReturn>

    )
}
