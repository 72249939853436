import { types } from "../types/types";




const initialState = {
    servicios: [ ],
    activeServicio: null
};



export const servicioReducer = ( state = initialState, action ) => {

    switch ( action.type ) {

        case types.servicioAddNew:
            return {
                ...state,
                servicios: [
                    ...state.servicios,
                    action.payload
                ]
            };

        case types.servicioLista:
            return {
                ...state,
                servicios: [ ...action.payload ]
            };

        case types.servicioDelete:
            return {
                ...state,
                servicios: state.servicios.filter(
                    e => ( e.id !== action.payload.id )
                ),
                activeInventario: null
            };

        //     case types.inventarioUpdate:
        //         return {
        //             ...state,
        //             inventario: state.inventario.map(
        //                 e => ( e.id === action.payload.id ) ? action.payload : e
        //             ),
        //             activeInventario: null
        //         };

        // case types.inventarioActivo:
        //     return {
        //         ...state,
        //         activeInventario: action.payload
        //     };
            
        // case types.abonosDeleteActivo:
        //     return {
        //         ...state,
        //         activeAbono: null
        //     };

        default:
            return state;
    }

}
