

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { almacenarNuevoPasswordAdmin, validarTokenParaPasswordAdmin } from "../../actions/usuario";


const empresa = require.context('../../assets/empresa', true);


export const NuevoPasswordAdmin = () => {


    const { token } = useParams();
    const dispatch = useDispatch();

    const  [ password, setPassword ] = useState('');


    useEffect(() => {
        if( token ){
            dispatch(validarTokenParaPasswordAdmin( token ));
        }
    }, [ token ]);


    const handleSubmit = (e) => {
        e.preventDefault();
        if( password.length < 5 ) {
            Swal.fire('Error', 'El Password Debe De Ser Minimo De 5 Caracteres', 'error');
            return;
        }
        dispatch(almacenarNuevoPasswordAdmin( token, { password } ));
        setPassword('');
    }



    return (
        <>
            <div className="login-cliente">

            <div className="img-cliente">
                <img src={ empresa(`./svtextoblanco.png`) } />
            </div>

            <div className="login">

                <h1>Nuevo Password</h1>
                <form onSubmit={ handleSubmit } >
                    <input type="password" name="password" 
                            placeholder="Nuevo Password" required="required" 
                            onChange={ (e) => setPassword(e.target.value) } value={ password }
                    />

                    <button type="submit" className="btn boton-cliente">
                        Guardar Password
                    </button>
                </form>
                
                <div className='registrarme'>
                    <p><Link to="/admin/login">Entrar Ahora</Link></p>
                </div>

            </div>

            </div>
        </>
    )
}
