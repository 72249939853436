import { types } from "../types/types";




const initialState = {
    trasabilidad: [ ],
    activeTrasa: null
};



export const trasabilidadReducer = ( state = initialState, action ) => {

    switch ( action.type ) {

        case types.trasabilidadAddNew:
            return {
                ...state,
                trasabilidad: [
                    ...state.trasabilidad,
                    action.payload
                ]
            };

        case types.trasabilidadLista:
            return {
                ...state,
                trasabilidad: [ ...action.payload ]
            };

        case types.trasabilidadDelete:
            return {
                ...state,
                trasabilidad: state.trasabilidad.filter(
                    e => ( e.id !== action.payload.id )
                ),
                activeTrasa: null
            };

        case types.trasabilidadUpdate:
            return {
                ...state,
                trasabilidad: state.trasabilidad.map( 
                    e => ( e.id === action.payload.id ) ? action.payload : e
                )
            };

        default:
            return state;
    }

}
