import { types } from "../types/types";




const initialState = {
    usuarios: [ ],
    activeUser: null
};





export const usuarioReducer = ( state = initialState, action ) => {

    switch ( action.type ) {

        case types.usuarioAddNew:
            return {
                ...state,
                usuarios: [
                    ...state.usuarios,
                    action.payload
                ]
            };

        case types.usuarioLista:
            return {
                ...state,
                usuarios: [ ...action.payload ]
            };

        case types.usuarioDelete:
            return {
                ...state,
                usuarios: state.usuarios.filter(
                    e => ( e.id !== action.payload.id )
                ),
                activeUser: null
            };

        case types.usuarioActivo:
            return {
                ...state,
                activeUser: action.payload
            };
            
        case types.usuarioDeleteActivo:
            return {
                ...state,
                activeUser: null
            };

        case types.usuarioUpdate:
            return {
                ...state,
                usuarios: state.usuarios.map( 
                    e => ( e.id === action.payload.id ) ? action.payload : e
                )
            };

        default:
            return state;
    }

    



 
}
