


import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { startLoginSuper } from "../../../actions/admin";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { useForm } from "../../../hooks/useForm";
import { InputDePassword } from "../../home/components";


const empresa = require.context('../../../assets/empresa', true);



export const LoginSuperAdmin = () => {

  const dispatch = useDispatch();


  const [ formAdmin, handleInputChangeA ] = useForm({
    cedula: '',
    password: ''
  });
  
  const { cedula, password } = formAdmin;


const handleLogin = (e) => {
    e.preventDefault();
    dispatch( startLoginSuper( cedula, password ));
}



    return (

        <Grid container className="login-cliente">

            <Grid item className="img-cliente">
                <img src={ empresa(`./svtextoblanco.png`) } />
            </Grid>

            <Grid item>
                <Typography variant="h1" mt={3}>Login Admin</Typography>
            </Grid>

            <Grid item xs={10} sx={{ width:'400px', padding:'20px' }}>
                <form onSubmit={ handleLogin } autoComplete="off" >

                    <Grid item xs={12} mt={2} >
                        <TextField required
                            label='Cedula'
                            type='text'
                            placeholder='Cedula'
                            fullWidth
                            name="cedula" value={ cedula }
                            onChange={ handleInputChangeA }
                        />
                    </Grid>


                    <Grid item xs={12} mt={ 2 }>
                        <InputDePassword value={password}
                            name='password'
                            placeholder={'Password'} 
                            onChangeInput={ handleInputChangeA } 
                        /> 
                    </Grid>

                    <Grid container my={3} direccion='column' justifyContent='center'  >
                        <Grid item xs={ 12 } >
                            <Button variant="contained" color="primary" fullWidth type="submit" >
                                <Typography variant="span" color='white' >Ingresar</Typography>
                            </Button>
                        </Grid>
                    </Grid>

                </form>

                <Grid sx={{width:'100%', textAlign:'right' }}>
                    <Link to="/admin/olvide-password" underline="hover"> 
                        <Typography className='underline' variant="h3">Olvide Mi Password</Typography>
                    </Link>
                </Grid>
            </Grid>


        </Grid>

    )


}
