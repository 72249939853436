
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, Grid, MenuItem, TextField } from "@mui/material"
import { ListadoMedidasStart, closeStartForm, startCreateStock } from "../../../actions";
import { productosListaStartTodos } from "../../../actions/producto";

import Swal from "sweetalert2";
import { AutocompleteNombre } from "../../components";





export const FormCrearOAdiccionarStock = () => {


    const dispatch = useDispatch();
    const { medidas }  = useSelector( state => state.medidas );
    const { productos } = useSelector(state => state.producto);



    useEffect( () => {
        dispatch( productosListaStartTodos() );
    }, [dispatch] );

    

    useEffect(() => {
        dispatch(ListadoMedidasStart());
    }, []);



    const [ formInventario, setFormInventario ] = useState({
        medida: "",
        producto: "",
        cantidad: "",
    });
    const { medida, producto, cantidad } = formInventario;



    const handleInputChange = ({ target }) => {
        setFormInventario({
            ...formInventario,
            [target.name]: target.value
        });
    }



    const handleAutocompleteChange = (selectedOption) => {
        setFormInventario({
            ...formInventario,
            producto: selectedOption ? selectedOption.id : "",
        });
    }





    const handleCrearInventario = async(e) => {
        e.preventDefault();
        if( [medida, producto, cantidad].includes('') ) {
            return Swal.fire('Error', 'Todos Los Campos Son Obligatorios', 'error');
        }
        const resp = await dispatch(startCreateStock(formInventario));
        if( resp ){
            setFormInventario({
                medida: "",
                producto: "",
                cantidad: "",
            });
            dispatch( closeStartForm() );
        }
    }





    return (


        <form  onSubmit={ handleCrearInventario }>
            <Grid container spacing={ 2 }>

                <Grid item xs={ 12 } sm={ 12 } mt={ 2 }>
                    <AutocompleteNombre
                        label="Producto"
                        type='text'
                        fullWidth
                        placeholder='Producto'
                        name="producto"
                        value={producto}
                        options={productos}
                        onChange={handleAutocompleteChange}
                    />
                </Grid>

                <Grid item xs={ 12 } sm={ 12 } mt={ 2 }>
                    <TextField
                        label="Cantidad"
                        type='number'
                        fullWidth
                        placeholder='Cantidad'
                        name="cantidad"
                        value={ cantidad }
                        onChange={ handleInputChange }
                    >
                    </TextField>
                </Grid>

                <Grid item xs={ 12 } sm={12} mt={ 2 }>
                    <TextField
                        select
                        label="Unidad de Medida"
                        fullWidth
                        name="medida"
                        value={ medida }
                        onChange={ handleInputChange }  
                    >    
                        <MenuItem value=''>
                            Seleccione Medio de Pago
                        </MenuItem>
                        {
                        medidas.map( (m) => (
                            <MenuItem value={ m.id } key={ m.id }>        
                                { m.nombre }
                            </MenuItem>
                        ))
                        }
                    </TextField>
                </Grid>


                <Grid container  sx={{ mt:2 }}  direccion='column' justifyContent='center'  >
                    <Grid item xs={ 12 } sm={ 8 } display='flex' justifyContent='center'>
                        <Button variant='contained' fullWidth type='submit'>
                            Crear o adicionar
                        </Button>
                    </Grid>
                </Grid>

            </Grid>

        </form>
    )



}
