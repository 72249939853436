
import { useState } from "react";
import { useDispatch } from "react-redux";

import { Box, Button, Grid,  MenuItem,  TextField } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { TablaCajaAbonos } from "../components";
import { LayoutListados } from "../../layouts"
import { busquedaPorFecha } from "../../../actions/busquedas";
import { mediosPago } from "../../../data";

import moment from "moment";
const now = moment().hours(0).minutes(0).seconds(0);
const nowPlus1 = now.clone().add(1, 'days').subtract(1, 'seconds');




export const CajaScreen = () => {


    const dispatch = useDispatch();


    const [formValues, setFormValues] = useState({
        start: now.toDate(),
        end: nowPlus1.toDate(),
        medio: '',
        op: '',
    });
    const { start, end, medio, op } = formValues;


    const handleInputChange = ({ target }) => {
        setFormValues({
            ...formValues,
            [target.name]: target.value
        });
    }


    const handleChangeStart = (e) => {
        setFormValues({
            ...formValues,
            start: e.$d
        });
    }

    const handleChangeEnd = (e) => {
        const resultado = moment(e.$d).hours(0).minutes(0).seconds(0);
        setFormValues({
            ...formValues,
            end: resultado.clone().add(24, 'hours').subtract(1, 'seconds').toDate(),
        });
    } 



    const handleConsultaFacturas = (e) => {
        e.preventDefault();
        dispatch( busquedaPorFecha(formValues) );
    }



    return (

        <LayoutListados title='Filtro De Abonos'>

            <Grid container direccion='row' spacing={2} alignItems='center' my={7} justifyContent='center'>
                <Grid item xs={ 12 } sm={6} md={4} >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Fecha de Inicio"
                            value={ start }
                            onChange={ handleChangeStart }
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                    </LocalizationProvider>
                </Grid>

                <Box flex={ 1 } />

                <Grid item xs={ 12 } sm={6} md={4}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Fecha Final"
                            value={ end }
                            onChange={ handleChangeEnd }
                            minDate={ start }
                            
                            renderInput={(params) => <TextField {...params} fullWidth /> }
                        />
                    </LocalizationProvider>
                </Grid>

                <Box flex={ 1 } />

                <Grid item xs={ 12 } sm={6} md={4}>
                    <TextField
                        label="Medio Pago"
                        select
                        type="text"
                        fullWidth
                        name='medio'
                        value={medio}
                        onChange={ handleInputChange }
                    > 
                        <MenuItem value=''>
                            Seleccione Medio
                        </MenuItem>
                        {mediosPago.map( medio => (
                        <MenuItem key={medio} value={medio}>
                            { medio }
                        </MenuItem>
                        ))}
                    </TextField>
                </Grid>

                <Grid item xs={ 12 } sm={6} md={4}>
                    <TextField
                        label="Numero Op"
                        type="number"
                        fullWidth
                        name='op'
                        value={op}
                        onChange={ handleInputChange }
                    /> 
                </Grid>

                <Grid item xs={ 12 } sm={6} md={4} my={3}>
                    <Button variant="contained" color="secondary" fullWidth onClick={ handleConsultaFacturas } >
                        Consultar
                    </Button>
                </Grid>
            </Grid>


            <Grid container>
                <TablaCajaAbonos />
            </Grid>


        </LayoutListados>
    )
}


