
import { fetchConToken } from "../helpers/fetch";
import { types } from "../types/types";
import Swal from "sweetalert2";





const pagoInvenatarioAddNew = ( pago ) => ({
    type: types.pagosInventarioAddNew,
    payload: pago
});  




export const limpiarListaPagos = ( ) => ({
    type: types.pagosInventarioLista,
    payload: [] 
});




const pagosLista = ( pagos ) => ({
    type: types.pagosInventarioLista,
    payload: pagos 
});



const pagoActivo = ( pago ) => ({
    type: types.pagosInventarioActivo,
    payload: pago 
});




export const startCreatePagoInventario = ( pago ) => {
    return async( dispatch ) =>{
        try {
            const resp = await fetchConToken( 'pagosinv/new', pago, 'POST' );
            const body = await resp.json();

            if( body.ok ) {
                Swal.fire('Exitoso', 'Pago Creado', 'success');
                dispatch( pagoInvenatarioAddNew(body.data) );
                return true;
            } else {
                Swal.fire('Error', body.msg, 'error');
                return false;
            } 
        } catch (error) {
            return false;
        }
    }
}



export const pagoListaXFiltroFecha = (fechas) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken(`pagosinv/todos?start=${fechas.start}&end=${fechas.end}`);
            const body = await resp.json();

            if( body.ok ){
                dispatch( pagosLista(body.data) );
            }else {
                Swal.fire('Error', body.msg, 'error');
            }                
        } catch (error) {
            console.log(error);
        }
    }
}




export const pagoInventarioPorId = ( id ) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken(`pagosinv/xid/${id}`);
            const body = await resp.json();
            if( body.ok ){
                dispatch(pagoActivo(body.data));
            }                
        } catch (error) {
            Swal.fire('Error', error, 'error');
        }
    }
}   





