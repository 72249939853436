
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { SocketContext } from "../../../context/SocketContext";
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import { MensajeAlertaDanger } from "../../components";
import { trasaStartAddNew } from "../../../actions/trasabilidad";
import { useEventosSockets } from "../../../hooks";

import Swal from "sweetalert2";
import moment from "moment";




export const TablaItemsPorTrabajar = () => {


    const  { socket } = useContext( SocketContext );
    const { items } = useSelector( state => state.carrito );
    const { uid } = useSelector( state => state.admin );
    const { opEscogerStart, tomarOpSocket } = useEventosSockets();
    const dispatch = useDispatch();


    // Paginacion de Tabla
    const [page, setPage] = useState(0);
    const [ rowsPerPage ] = useState(20);
    const handleChangePage = (event, newPage) => {
        setPage( newPage );
    };



    // useEffect(() => {
    //     if( items ){
    //         setPage(0);
    //     }
    // }, [items]);



    const handleEscoger = async( op ) => {
        Swal.fire({
            title: '¿Seguro de Escoger Este Item?',
            text: `¿Esta Seguro De Trabajar El item  de la OP ${ op.op.op }?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si, Seguro' 
        }).then( ( result ) => {
            if(result.value){
                dispatch( opEscogerStart(op) );
                dispatch( trasaStartAddNew({
                    item: op.id,
                    colaborador: uid,
                    start: moment().toDate(),
                    antes: op.area,
                    ahora: op.area
                }));
            }
        })
    }



    useEffect(() => {
        socket.on('op-solicitada', (op) => {
            dispatch( tomarOpSocket(op) );
        });
    }, [dispatch]);




    return (

        <>
            {
            ( items.length > 0 )
            ?<Paper sx={{ width: '100%' }}>
                <TableContainer sx={{ my:'15px' }}>
                    <Table sx={{ minWidth: 700, }} aria-label="customized table">

                        <TableHead >
                            <TableRow>
                                <TableCell className="w-150">O.P.</TableCell>
                                <TableCell className="w-200">Area de Trabajo</TableCell>
                                <TableCell>Nombre</TableCell>
                                <TableCell className="w-200">Pago</TableCell>
                                <TableCell>Trabajar</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {
                            items
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map( item => (
                                <TableRow key={ item.id } >
                                    <TableCell>{ item.op.op }</TableCell>
                                    <TableCell>{ item.area }</TableCell>
                                    <TableCell>{ item.op.infoPersonal?.nombre }</TableCell>
                                    <TableCell>
                                        {
                                        ( item.op.pago === 'PENDIENTE' ) && (
                                        <Button variant="contained" color="error">PENDIENTE</Button>
                                        )}
                                        {
                                        ( item.op.pago === 'REALIZADO' ) && (
                                        <Button variant="contained" color="success">REALIZADO</Button>
                                        )}
                                            {
                                        ( item.op.pago === 'CREDITO' ) && (
                                        <Button variant="contained" color="info">CREDITO</Button>
                                        )}
                                    </TableCell>

                                    <TableCell>
                                        { 
                                        ( !item.produccion )

                                        ?<Button variant="contained" color="primary"
                                            onClick={ () => handleEscoger(item) }
                                        >
                                            DISPONIBLE
                                        </Button>
                                                
                                        :<Button variant="contained" disabled >
                                            EN TRABAJO
                                        </Button>
                                        }
                                    </TableCell> 

                                </TableRow>
                            ))
                            }
                        </TableBody>

                    </Table>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[ 20 ]}
                    component="div"
                    count={ items.length }
                    rowsPerPage={ rowsPerPage }
                    page={ page }
                    onPageChange={ handleChangePage }
                />

            </Paper>

            : <MensajeAlertaDanger titulo='No Hay Items Disponibles' />

            }
        </>

    )



}
