
import { useState } from 'react';
import { Toolbar, IconButton, Tooltip, Box, Menu, Badge, Button } from '@mui/material';
import { Menu as MenuIcon, ArrowBackIosNew, ShoppingCart } from '@mui/icons-material';

import { AppBar, originalTheme } from '../../theme';
import { useDispatch, useSelector } from 'react-redux';
import { startSuperLogout } from '../../actions/admin';
import { usuarioDeleteActivo } from '../../actions/usuario';
import { openModal, startChangeSidebar } from '../../actions';






export const NavBarScreen = () => {
    

    const dispatch = useDispatch();    
    const { carritoCompra } = useSelector( state => state.carrito );
    const { isSidebarOpen } = useSelector( state => state.iva ); 

    const [anchorElUser, setAnchorElUser] = useState(null);


    const handleLogout = () => {
        dispatch( startSuperLogout() );
        dispatch(usuarioDeleteActivo() );
        localStorage.clear();
    }


    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };


    const abrilModal = () => {
        dispatch( openModal() );
    }



    const handleChangeMenu = () => {
        dispatch(startChangeSidebar(!isSidebarOpen))
    }




    return (

        <AppBar sx={{background: originalTheme.palette.primary.main}} position="fixed" open={isSidebarOpen}>
            <Toolbar>

                <IconButton color="inherit" sx={{ mr:2 }} onClick={ handleChangeMenu }>
                    {
                    (isSidebarOpen)
                    ? <ArrowBackIosNew />
                    : <MenuIcon />
                    }
                </IconButton>


                <Box sx={{ flexGrow: 1 }} />

                <Box>
                    <IconButton sx={{background:'inherit', '&:hover': {backgroundColor: 'inherit'},}} onClick={ abrilModal }>
                        <Badge badgeContent={carritoCompra.length || 0} color="secondary">
                            <ShoppingCart />
                        </Badge>
                    </IconButton>
                </Box>


                <Box sx={{ ml:2 }}>
                    <Tooltip title="Salir de la aplicación">
                        <Button variant='contained' color='secondary' onClick={ handleLogout }>Logout</Button>
                    </Tooltip>
                    <Menu
                        sx={{ mt: '47px', width:'300px' }}
                        anchorEl={anchorElUser}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                        open={Boolean(anchorElUser)}
                        onClose={ handleCloseUserMenu }
                    >
                    </Menu>
                </Box>

            </Toolbar>
        </AppBar>

    )
}
