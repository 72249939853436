
import { fetchConToken, fetchSinToken } from "../helpers/fetch";
import { types } from "../types/types";
import Swal from "sweetalert2";





export const guardarCotizacion = ( cotizacion ) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchSinToken('cotizacion/new', cotizacion, 'POST');
            const body = await resp.json();

            if( body.ok ) {
                Swal.fire('Exito', 'Cotizacion Guardada', 'success');
                return body.resultado;
            } else {
                Swal.fire('Error', body.msg, 'error');
                return false;
            }
        } catch (error) {
            console.log(error);
            return false;
        }
    }
}




export const generarPDfCotizacion = ( cotizacion ) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchSinToken('cotizacion/generar', cotizacion, 'POST');
            const body = await resp.json();

            if( body.ok ) {
                return body.respuesta;
            } else {
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            console.log(error);
        }
    }
}




export const updateCotizacion = ( cotizacion ) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchSinToken(`cotizacion/update/${cotizacion._id}`, cotizacion, 'PUT');
            const body = await resp.json();

            if( body.ok ) {
                Swal.fire('Exito', 'Cotizacion Actutalizada', 'success');
                dispatch( updateCotiz(body.resultado) );
                return true;
                
            } else {
                Swal.fire('Error', body.msg, 'error');
                return false;
            }
        } catch (error) {
            console.log(error);
            return false;
        }
    }
}




export const deleteCotizacion = ( uid ) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchSinToken(`cotizacion/delete/${uid}`, {}, 'DELETE');
            const body = await resp.json();

            if( body.ok ) {
                Swal.fire('Exito', 'Cotizacion Eliminada', 'success');
                dispatch( deleteCotiz(uid) );
                return true;
                
            } else {
                Swal.fire('Error', body.msg, 'error');
                return false;
            }
        } catch (error) {
            console.log(error);
            return false;
        }
    }
}



export const listAllCotizaciones = () => {
    return async( dispatch ) => {
        try {
            const resp = await fetchSinToken('cotizacion/all');
            const body = await resp.json();

            if( body.ok ) {
                dispatch( listadoCotizacion(body.cotizaciones) );
            } else {
                Swal.fire('Error', body.msg, 'error');
                return false;
            }
        } catch (error) {
            console.log(error);
            return false;
        }
    }
}



export const cotizacionXId = (uid) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchSinToken(`cotizacion/xid/${uid}`);
            const body = await resp.json();

            if( body.ok ) {
                dispatch( activarCotizacion(body.cotizacion) );
                return true;
            }
        } catch (error) {
            console.log(error);
            return false;
        }
    }
}



export const opAddNewCotizacion = ( op ) => {
    return async( dispatch ) =>{

        try {
            const resp = await fetchConToken( 'cotizacion/new/op', op, 'POST' );
            const body = await resp.json();
            if( body.ok ) {
                Swal.fire(  `Tu Op es: ${ body.op.op }`, 
                    `Todo trabajo se inicia con un abono de <b>50% del valor total</b> y el saldo a la entrega del mismo. No se hace devolución de dinero una vez iniciado el trabajo. No nos hacemos responsables por trabajos pasados <b>30 dias desde la fecha de solicitud.</b> en caso de correcciones, se validaran maximo al siguiente dia de la entrega. Los diseños realizados por nosotros no seran impresos sin antes ser <b>aprobados por el cliente, REVISE MUY BIEN LOS DISEÑOS, no nos hacemos responsables por errores de ortografia, redacción, alineación, diagramación, tamaño del texto, logos, numeros telefonicos, correos, direcciones, fuentes, colores y demas después de la APROBACIÓN E IMPRESIÓN.</b> Tenga en cuenta que los colores en pantalla tienden a variar en la impresión, <b>No</b> nos comprometemos a sacar colores ni tamaños exactos. Recuerde que según la disponibilidad de tiempo se programa una posible fecha de entrega  <b>por ende los trabajos para entrega inmediata o a domicilio tienen un costo adicional el cual se validara con su distribuidor autorizado o directamente con la empresa.</b>`, 
                    'success'
                );
                dispatch( deleteCotiz(body.cotizacion._id) );
                return true;
            } else {
                Swal.fire('Error', body.msg, 'error');
                return false;
            } 
        } catch (error) {
            console.log(error);
            return false;
        }
    }
}








const listadoCotizacion = ( data ) => ({
    type: types.listadoCotizacion,
    payload: data
});



const updateCotiz = ( data ) => ({
    type: types.cotizacionUpdate,
    payload: data
});


const deleteCotiz = ( data ) => ({
    type: types.deleteCotizacion,
    payload: data
});


const activarCotizacion = ( data ) => ({
    type: types.activeCotizacion,
    payload: data
});


export const deleteActivado = () => ({
    type: types.deleteActiveCotizacion,
});