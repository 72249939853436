import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom"
import { mostrarClientePorId } from "../../actions/cliente";






export const InfoCliente = () => {


    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { activeCliente } = useSelector(state => state.cliente);


    useEffect( () => {
        if( id ) {
            dispatch( mostrarClientePorId( id ));
        }
    }, [dispatch, id] );



    const handleReturn = () =>{
        navigate( - 1 );
    }


    return (


        <div className="todo-op">

            <div className="atras a100">
                <button className="boton-redondo" 
                    onClick={ handleReturn } 
                >
                    <i className="fa-solid fa-circle-arrow-left"></i>
                </button> 
            </div>

            <h3 className="titleF my-5">Información Personal</h3>

            {
                activeCliente &&
                <table className="table table-bordered table-dark text-center table-hover">
                    <tbody>
                        <tr>
                            <th>Nombre</th>
                            <td>{ activeCliente.nombre }</td>
                        </tr>
                        <tr>
                            <th>Cedula</th>
                            <td>{ activeCliente.cedula }</td>
                        </tr>
                        <tr>
                            <th>Movil</th>
                            <td>{ activeCliente.movil }</td>
                        </tr>
                        <tr>
                            <th>Direccion</th>
                            <td>{ activeCliente.direccion }</td>
                        </tr>
                        <tr>
                            <th>Ciudad</th>
                            <td>{ activeCliente.ciudad }</td>
                        </tr>
                        <tr>
                            <th>Profesión</th>
                            <td>{ activeCliente.profesion }</td>
                        </tr>
                        <tr>
                            <th>Distribuidor</th>
                            <td>{ activeCliente.distribuidor.nombre }</td>
                        </tr>
                    </tbody>
                </table>
            }
        </div>
        
    )
}
