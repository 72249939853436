import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom"
import { usuarioPorID } from "../../actions/usuario";




export const InfoUsuario = () => {


    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { activeUser } = useSelector(state => state.user);



    useEffect( () => {
        if( id ) {
            dispatch( usuarioPorID( id ) );
        }
    }, [dispatch, id] );



    const handleReturn = () =>{
        navigate( - 1 );
    }


  return (


        <div className="todo-op">

            <div className="boton-title-atras">
                <div className="atras a100 prev">
                    <button className="boton-redondo" 
                        onClick={ handleReturn } 
                    >
                        <i className="fa-solid fa-circle-arrow-left"></i>
                    </button> 
                </div>
                <h3 className="op-titlee">Información Personal</h3>
            </div>

            {/* <div className="atras a100">
                <button className="boton-redondo" 
                    onClick={ handleReturn } 
                >
                    <i className="fa-solid fa-circle-arrow-left"></i>
                </button> 
            </div>
            <h3 className="titleF my-5">Información Personal</h3> */}

            {
            activeUser &&
            <table className="table table-bordered table-dark text-center table-hover mt-5">
                <tbody>
                    <tr>
                        <th>Nombre</th>
                        <td>{ activeUser.nombre }</td>
                    </tr>
                    <tr>
                        <th>Cedula</th>
                        <td>{ activeUser.cedula }</td>
                    </tr>
                    <tr>
                        <th>Movil</th>
                        <td>{ activeUser.movil }</td>
                    </tr>
                    <tr>
                        <th>Direccion</th>
                        <td>{ activeUser.direccion }</td>
                    </tr>
                    <tr>
                        <th>Ciudad</th>
                        <td>{ activeUser.ciudad }</td>
                    </tr>
                    <tr>
                        <th>Establecimiento</th>
                        <td>{ activeUser.establecimiento }</td>
                    </tr>
                    <tr>
                        <th>Profesión</th>
                        <td>{ activeUser.profesion }</td>
                    </tr>
                </tbody>
            </table>
            }
        </div>
    
  )


}
