
import { createContext } from "react";
import { useSockets } from "../hooks";




export const SocketContext = createContext();



export const SocketProvider = ({ children }) => {

    const { socket, online } = useSockets();


    return (
        <SocketContext.Provider value={{ socket, online }}>
            { children }
        </SocketContext.Provider>
    ) 
    


}