
import { useDispatch, useSelector } from 'react-redux';

import { Backdrop, Box, Modal } from '@mui/material';
import { closeModalImagen } from '../../../actions';
import { opDeleteActivo } from '../../../actions/op';




const baseUrl = process.env.REACT_APP_API_URL;


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 650,
    height: 550,
    bgcolor: '#424242' ,
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: '20px',
};




export const ModalImagenPagoTotalOP = () => {


    const dispatch = useDispatch();
    const { modalImagen }  = useSelector( state => state.modal );
    const { activeOP } = useSelector( state => state.op );


    const closeModal = () => {
        dispatch( closeModalImagen() );
        // dispatch( opDeleteActivo() );
    }



    return (

        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={ modalImagen }
            onClose={ closeModal }
            closeAfterTransition
            BackdropComponent={ Backdrop }
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Box sx={ style } className='img-modal' >
                {
                ( activeOP ) 
                ? <img src={ `${ baseUrl }/${ activeOP.imgPago }` } alt="" />
                : <img src={`${ baseUrl }/uploads/usuario/kfnmksd`} alt="Imagen Pago" />
                }
            </Box>

        </Modal>

        
    )




}
