import React from 'react';
// import { Link } from 'react-router-dom';


const empresa = require.context('../../assets/empresa', true);
const anio = new Date().getFullYear();



export const Footer = () => {



    return (
    
            <div className="footer">
                <div className="icon">
                    <img src={ empresa(`./svtextoblanco.png`) } alt="" />
                </div>

                <div className="informacion">

                    <div className="ofi">
                        <h2>Oficina Principal</h2>
                        <small>Carrera 6 # 17-38 Los Angeles - Madrid</small>
                    </div>

                    <div className="ofi">
                        <h2>Contáctenos</h2>
                        <small>316-577-1648</small>
                    </div>
                    
                    
                    <div className="ofi">
                        <h2 className="siguenos">Siguenos</h2>
                        <div className="redes">
                            <a href="https://www.instagram.com/7xever/?igshid=YmMyMTA2M2Y%3D" target="_blank">
                                <i className="fab fa-instagram icn  inpar"></i>
                            </a>
                            {/* <i className="fab fa-twitter icn par"></i>
                            <i className="fab fa-youtube icn inpar"></i> */}
                            <a href='https://www.facebook.com/Seven-For-Ever-367835633640768' target="_blank">
                                <i className="fab fa-facebook-f icn par"></i>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="fin">
                    <p className="copy">&copy; SevenForEver { anio }</p>
                    <p className="david">By DavidCortes</p>
                </div>
            </div>

    )
}
