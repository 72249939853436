
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Button, Grid, TextField, Typography } from "@mui/material"
import { updateSoloDetalles } from "../../../actions/carrito";
import { descargarImagen } from "../../../helpers/descargarArchivo";



const baseUrl = process.env.REACT_APP_API_URL;



export const DetallesImagenItem = () => {


    const { id } = useParams();
    const dispatch = useDispatch();
    const debounceRef = useRef();
    const { activeCarrito } = useSelector( state => state.carrito );


    const [detalles, setDetalles] = useState(activeCarrito?.detalles);


    const handleDescargar = (img) => {
        const url = `${ baseUrl }/uploads/items/${ img }`;
        fetch(url)
        .then( resp => descargarImagen(resp) );
    }


    

    useEffect(() => {
        if(activeCarrito){
            setDetalles(activeCarrito.detalles || '');
        }
    }, [activeCarrito]);




    const handleGuardarDetalle = ({ target }) =>{
        setDetalles( target.value );
        if( debounceRef.current )
            clearTimeout( debounceRef.current );

        debounceRef.current = setTimeout( () => {
                dispatch(updateSoloDetalles({ uid: id, detalles: target.value }))
        }, 600);
    }
    

    // const updateDetalles = (event) => {
    //     if (event.key === 'Enter') {
    //         event.preventDefault();
    //         dispatch(updateSoloDetalles({ uid: id, detalles }))
    //     }
    // }



    return (

        <>
        {(activeCarrito) &&
        <Grid container textAlign='center'>

            <Grid item xs={12} my={4}>
                <Typography variant="h4" my={2}>Detalles</Typography>
                <TextField
                    id="outlined-multiline-flexible"
                    label="Poner Detalles..."
                    multiline
                    fullWidth
                    value={ detalles }
                    // onChange={ (e) => setDetalles(e.target.value) }
                    // onKeyDown={ updateDetalles }
                    onChange={ handleGuardarDetalle }
                />
            </Grid>

            <Grid item xs={12}>
                <Typography variant="h4" sx={{my:2, fontSize:20}}>Imagen o Diseño</Typography>
                {( activeCarrito.imgCliente )
                ?   <img src={`${ baseUrl }/uploads/items/${ activeCarrito.imgCliente }`} 
                        alt="Imagen Detalle Producto" style={{width:600}}
                    />
                : <img src={`${ baseUrl }/uploads/categorias/no-img.jpg`} alt="Error en imagen" style={{width:500}} />
                }
            </Grid>

            <Grid item xs={12} my={3} >
                <Button variant="contained" color="info" 
                    onClick={ () => handleDescargar(activeCarrito.imgCliente) }
                >
                    Descargar Archivo
                </Button>
            </Grid>

        </Grid>
        }
        </>

    )
}
