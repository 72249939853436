
import { useDispatch, useSelector } from 'react-redux';

import { Button, Grid, TextField, Typography, Box, Backdrop, Modal } from '@mui/material';
import { clienteStartAddNew } from '../../../actions/cliente';
import { closeCrearCliente } from '../../../actions';

import { useForm } from '../../../hooks/useForm';
import Swal from 'sweetalert2';



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    bgcolor: '#424242' ,
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: '20px',
};


const initCliente = {
    nombre: "",
    cedula: "",
    ciudad: "",
    direccion: "",
    movil: "",
    email: "",
    role: "CLIENTE",
  };


export const ModalCrearCliente = () => {

    const dispatch = useDispatch();
    const { modalCrearCliente } = useSelector( state => state.modal );


    const [ formCliente, handleInputChange, reset ] = useForm( initCliente );
    const { nombre, cedula, ciudad, direccion, movil, email } = formCliente;



    const  handleCrearCliente = ( e ) => {
        e.preventDefault();
        if(nombre.length < 4 ){
          Swal.fire('Error', 'El Nombre Debe De Tener Mas De 5 Caracteres', 'error');
          return;
        }
        if(email.length < 2 ){
          Swal.fire('Error', 'El Email Debe De Ser Obligatorio', 'error');
          return;
        }
        dispatch( clienteStartAddNew( formCliente ) );
        reset();
        dispatch( closeCrearCliente());
    }




    const closeModal = () => {
        dispatch( closeCrearCliente() );
    }



return (

        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={ modalCrearCliente }
            onClose={ closeModal }
            closeAfterTransition
            BackdropComponent={ Backdrop }
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Box sx={ style } >

                <Typography align='center' variant='h2' sx={{ mb:2 }} >Crear Cliente</Typography>

                <form  onSubmit={ handleCrearCliente } >
                    <Grid container spacing={ 2 }>

                        <Grid item xs={ 12 } sm={ 6 } mt={ 2 }>
                            <TextField 
                                required
                                label="Nombre"
                                fullWidth
                                placeholder='Nombre'
                                name="nombre"
                                value={ nombre }
                                onChange={ handleInputChange }
                            >
                            </TextField>
                        </Grid>


                        <Grid item xs={ 12 } sm={ 6 } mt={ 2 }>
                            <TextField
                                required
                                label="Cedula"
                                fullWidth
                                placeholder="Cedula"
                                multiline
                                name="cedula"
                                value={ cedula }
                                onChange={ handleInputChange }  
                            />
                        </Grid>

                        <Grid item xs={ 12 } sm={ 6 } mt={ 2 }>
                            <TextField
                                required
                                label="Email"
                                fullWidth
                                placeholder="Email"
                                multiline
                                name="email"
                                value={ email }
                                onChange={ handleInputChange }  
                            />
                        </Grid>

                        <Grid item xs={ 12 } sm={ 6 } mt={ 2 }>
                            <TextField
                                required
                                label="Ciudad"
                                fullWidth
                                placeholder="Ciudad"
                                multiline
                                name="ciudad"
                                value={ ciudad }
                                onChange={ handleInputChange }  
                            />
                        </Grid>

                        <Grid item xs={ 12 } sm={ 6 } mt={ 2 }>
                            <TextField
                                label="Dirección"
                                fullWidth
                                placeholder="Dirección"
                                multiline
                                name="direccion"
                                value={ direccion }
                                onChange={ handleInputChange }  
                            />
                        </Grid>

                        <Grid item xs={ 12 } sm={ 6 } mt={ 2 }>
                            <TextField
                                required
                                label="Movil"
                                fullWidth
                                placeholder="Movil"
                                multiline
                                name="movil"
                                value={ movil }
                                onChange={ handleInputChange }  
                            />
                        </Grid>

                        <Grid container  sx={{ my:4 }}  direccion='column' justifyContent='center'  >
                            <Grid item xs={ 12 } sm={ 8 } display='flex' justifyContent='center'>
                                <Button variant='contained' color='secondary' fullWidth type='submit'>
                                    Crear Cliente
                                </Button>
                            </Grid>
                        </Grid>

                    </Grid>
                </form>
            </Box>
        </Modal>


)





}
