



export const separadorMillares = ( numero ) => {
    try {
        if( typeof numero !== 'number' ) return;

        return numero.toLocaleString('es-CO');
        
    } catch (error) {
        return console.log(error);
    }
}




export const sumaDeTotalPagos = ( valor, cantidad, iva ) => {
    return separadorMillares((valor * cantidad * iva / 100) + (valor * cantidad));
} 