
import { useDispatch, useSelector } from "react-redux";

import { carritoStartDeleteCompra } from "../../../actions/carrito";
import { carritoStartDeleteCliente } from "../../../actions/carritoCliente";
import { ViewPreciosPorRol } from "../../components";
import { Box, IconButton, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography } from "@mui/material";
import { Delete } from "@mui/icons-material";

const baseUrl = process.env.REACT_APP_API_URL;





export const TablaDeCarritoEnModal = () => {


    const dispatch = useDispatch();
    const { usuario } = useSelector( state => state.admin );
    const { role } = useSelector( state => state.authCliente );
    const { carritoCompra } = useSelector( state => state.carrito );


    const handleDelete = ( id ) => {
        dispatch( carritoStartDeleteCompra(id) );
    }


    const handleDeleteCliente = ( id ) => {
        dispatch( carritoStartDeleteCliente(id) );
    }


    if(!carritoCompra || carritoCompra.length === 0) {
        return (
            <Box sx={{ p:4, textAlign:'center'}}>
                <Typography variant="h4" sx={{color:'rgba(255, 255, 255, 0.7)'}}>No hay productos en el carrito</Typography>
            </Box>
        );
    }




    return (
        
        <TableContainer sx={{ width:'100%', border:'none', marginBottom:3 }}>
            <Table sx={{ width:'100%', border:'none' }}>
            <TableBody>
                {carritoCompra.map((c) => (
                <TableRow key={c.id} sx={{ borderBottom:'1px solid gray' }}>
                    {/* Imagen del Producto */}
                    <TableCell sx={{width:100, border:'none'}}>
                        <Box sx={{ width:80, height:80, borderRadius:2, overflow:'hidden', boxShadow:'0 4px 12px rgba(0,0,0,0.1)'}}>
                            <img src={`${baseUrl}/uploads/productos/${c.producto?.img}`} alt={c.producto?.nombre}
                                style={{ width:'100%', height:'100%', objectFit:'cover'}}
                            />
                        </Box>
                    </TableCell>
    
                    {/* Nombre y Cantidad */}
                    <TableCell sx={{display:'flex', flexDirection:'column', width:'auto', alignItems:'center' }}>
                        <Typography variant="span" sx={{color:'white', mb:0.5, fontSize:13 }}>
                            {c.producto?.nombre}
                        </Typography>
                        <TextField type="number" value={c.cantidad} name="cantidad" sx={{ width:80 }} size="small"
                        />
                    </TableCell>
    
                    {/* Precios */}
                    <TableCell sx={{border:'none', width:90 }}>
                        <ViewPreciosPorRol formCarrito={c} mostrar={false} />
                    </TableCell>
    

                    {/* Botón Eliminar */}
                    <TableCell align="right" sx={{ border: 'none' }}>
                        <IconButton size="small" sx={{ color:'error.main', '&:hover': {bgcolor: 'rgba(244, 67, 54, 0.08)'}}}
                            onClick={() => usuario && role !== 'CLIENTE'
                                ? handleDelete(c.id)
                                : handleDeleteCliente(c.id)
                            }
                        >
                            <Delete fontSize="small" />
                        </IconButton>
                    </TableCell>
                </TableRow>
                ))}
            </TableBody>
            </Table>
        </TableContainer>


    )
}
