
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Grid, TextField } from "@mui/material"
import { closeStartForm, newUnidadMedidaStart } from "../../../actions";




export const FormularioUnidadMedida = () => {

    
    const dispatch = useDispatch();


    const [formNombre, setFormNombre] = useState({ nombre: '' });
    const { nombre } = formNombre;

    const handleInputChange = ({ target }) => {
        setFormNombre({
            ...formNombre,
            [target.name]: target.value
        })
    }


    const cerrarMedida = () => {
        dispatch( closeStartForm() );
    }


    
    const handleCrearMedida = async(e) => {
        e.preventDefault();

        if( nombre.trim().length < 1 ){ return }
            dispatch(newUnidadMedidaStart( formNombre ));
            cerrarMedida();
            setFormNombre({ nombre: '' });
    }





    return (

        <form onSubmit={ handleCrearMedida } >
            <Grid container spacing={ 2 }>

                <Grid item xs={ 12 } sm={ 12 } mt={ 2 } >
                    <TextField required
                        label='Nombre'
                        type='text'
                        placeholder='Nombre'
                        fullWidth
                        name='nombre'
                        value={ nombre }
                        onChange={ handleInputChange }
                    />
                </Grid>

                <Grid container  sx={{ mt:2 }}  direccion='column' justifyContent='center'  >
                    <Grid item xs={ 12 } sm={ 8 } display='flex' justifyContent='center'>
                        <Button variant='contained' fullWidth type='submit'>
                            Crear Medida
                        </Button>
                    </Grid>
                </Grid>

            </Grid>

        </form>

    )
}
