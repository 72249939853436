
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Button, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { TooltipAndIcon } from "../../components";
import { openStartForm } from "../../../actions";
import { carritoActivoParaUpdate } from "../../../actions/carrito";
import { separadorMillares, usePrecioSegunRol } from "../../../hooks";

import Swal from "sweetalert2";
import { eliminarItemsXOpProduccion } from "../../../actions/items";




export const ItemsProduccionAndEditar = ({ ver=false, editar=false }) => {


    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { items } = useSelector( state => state.carrito );
    const { activeOP } = useSelector( state => state.op );

    const { precioSegunRol } = usePrecioSegunRol();



    
    const detallesItems = (uid) => {
        navigate(`/super/dt-pro-op/${ uid }`);
    }



    const abrirModalModificar = (item) => {
        dispatch(carritoActivoParaUpdate(item));
        dispatch( openStartForm() );
    }



    const eliminarItemDeOP = async(item) => {
        const resp = await Swal.fire({
            title: 'Eliminar Item',
            text: `¿Esta seguro de eliminar este item de la op.`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si'
        });
        if( resp.value ) {
            const resp = await dispatch(eliminarItemsXOpProduccion(item));
            if( resp ){
                Swal.fire('Exitoso', 'Item de OP eliminado.', 'success')
            }
        }
    }




    return (

        <>
            <TableContainer sx={{ my:5 }}>
                <Table sx={{ minWidth: 700, }}>
                    <TableHead >
                        <TableRow>
                            <TableCell>Cantidad</TableCell>
                            <TableCell>Nombre Producto</TableCell>
                            <TableCell className="w-200">Area</TableCell>
                            <TableCell>Valor</TableCell>
                            <TableCell>Produccion</TableCell>
                            <TableCell>Opciones</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {items.map( item => (
                        <TableRow key={ item.id } >
                            <TableCell>{ item.cantidad }</TableCell>
                            <TableCell>{ item.producto.nombre }</TableCell>
                            <TableCell>{ item.area }</TableCell>
                            <TableCell>{`$${separadorMillares(precioSegunRol(item))}`}</TableCell>
                            <TableCell>
                                {
                                ( item.produccion ) 
                                ?( <Button variant="contained" color="secondary">EN TRABAJO</Button> )
                                :( <Button variant="contained" color="primary">DISPONIBLE</Button> )
                                }
                            </TableCell>
                            <TableCell>
                                <Stack direction="row" spacing={1} justifyContent="center">
                                    {(ver) &&
                                    <TooltipAndIcon
                                        titulo="Detalles del Item"
                                        icono="ver"
                                        color="white"
                                        onClick={ () => detallesItems(item.id) }
                                    />  
                                    }
                                    {(editar) &&
                                        <>
                                        <TooltipAndIcon
                                            titulo="Editar Item"
                                            icono="editar"
                                            color="white"
                                            onClick={ () => abrirModalModificar(item) }
                                        /> 

                                        <TooltipAndIcon
                                            titulo="Eliminar Item"
                                            icono="eliminar"
                                            color="red"
                                            onClick={ () => eliminarItemDeOP(item) }
                                        /> 
                                        </>
                                    }
                                </Stack>
                            </TableCell> 
                        </TableRow>
                        ))
                        }
                        <TableRow>
                            <TableCell colSpan={4}>Total:</TableCell>
                            <TableCell colSpan={2}>{`$${separadorMillares(activeOP?.totalOrden || 0)}`}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}
