
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { LayoutArchivado } from "../../layouts"
import { TablaProveedores } from "../components";
import { proveedorListaStart } from "../../../actions/proveedor";




export const ListadoProveedores = () => {


    const dispatch = useDispatch();


    useEffect( () => {
        dispatch( proveedorListaStart() );
    }, [ dispatch ] );


    return (

        <LayoutArchivado titulo={'Listado de Proveedores'} url=''>

            {/* <BuscarUsuario activo={true}/> */}

            <TablaProveedores />

        </LayoutArchivado>

    )
    
}
