
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { LayoutReturn } from "../../layouts"
import { cambioClaveStart } from "../../../actions/usuario";
import Swal from "sweetalert2";
import { Button, Grid, Typography } from "@mui/material";
import { InputDePassword } from "../../home/components";






const initUser = {
    claveAntigua: "",
    password: "",
    confirmarClave: "",
};



export const CambioClave = () => {


    const dispatch = useDispatch();
    const [ cambioClave, setCambioClave ] = useState( initUser );
    const { claveAntigua, password, confirmarClave } = cambioClave;


    
    const handleInputChange = ({ target }) => {
        setCambioClave({
          ...cambioClave,
          [target.name]: target.value
        });
    }



    const handleCambioClave = (e) => {
        e.preventDefault();

        if( password !== confirmarClave ) {
          Swal.fire('Error', 'Las Claves Deben de Ser Iguales', 'error');
          return;
        }
        if( password.trim().length < 5){
          Swal.fire('Error', 'El Password debe ser mayor a 5 digitos', 'error');
          return;
        }
        dispatch(cambioClaveStart( cambioClave ) );
        setCambioClave( initUser );
    }







    return (

        <LayoutReturn titulo='Cambio de Clave' url='/super' >

            <form className="formulario-total mt-5" onSubmit={ handleCambioClave } >
                <Grid container spacing={ 4 } justifyContent='center'>

                <Grid item xs={12} md={ 7 }>
                    <InputDePassword value={claveAntigua}
                        name='claveAntigua'
                        placeholder={'Clave Anterior'} 
                        onChangeInput={ handleInputChange } 
                    /> 
                </Grid>

                <Grid item xs={12} md={ 7 }>
                    <InputDePassword value={password}
                        name='password'
                        placeholder={'Nuevo Password'} 
                        onChangeInput={ handleInputChange } 
                    /> 
                </Grid>


                <Grid item xs={12} md={ 7 }>
                    <InputDePassword value={confirmarClave}
                        name='confirmarClave'
                        placeholder={'Confirmar Password'} 
                        onChangeInput={ handleInputChange } 
                    /> 
                </Grid>

                </Grid>

                <Grid container 
                    my={7}
                    direccion='column'
                    justifyContent='center' 
                >
                    <Grid item xs={ 12 } md={7} >
                        <Button variant="contained" color="secondary" fullWidth type="submit" >
                            <Typography variant="span" color='white' >Cambiar Clave</Typography>
                        </Button>
                    </Grid>
                </Grid>

            </form>


        </LayoutReturn>


    )

}
