import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { carritoListaCompraCliente } from '../../actions/carritoCliente';
import { closeModalCliente } from '../../actions/modalCarrito';
import { separadorMillares } from '../../hooks/useMillares';
import { CardsCarrito } from '../compras/CardsCarrito';



const customStyles = {
    content: {
    top: '50%',
    left: 'auto',
    right: '-50%',
    zindex: '999999',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    },
};



export const ModalCarritoCliente = () => {

    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { modalCliente } = useSelector( state => state.modal );
    const { carritoCliente } = useSelector( state => state.carrito );


    const closeModal = () => {
        dispatch( closeModalCliente() );
    }


    useEffect(() => {
        dispatch( carritoListaCompraCliente() );
    }, [ dispatch ]);

    const [totalCliente, setTotalCliente] = useState();
    // const [totalDistri, setTotalDistri] = useState();


    useEffect( () => {
        const carritoTotal = carritoCliente.reduce( (total, prod) => total + prod.totalCliente, 0 );
        setTotalCliente(carritoTotal);
        // const distriTotal = carritoCompra.reduce( (total, prod) => total + prod.totalDistri, 0);
        // setTotalDistri(distriTotal);
    }, [carritoCliente] );
    

    const handlerComprar = () => {
        navigate('/comprar-cliente');
        dispatch( closeModalCliente() );
    }


    return (

        <Modal
        isOpen={ modalCliente }
        onRequestClose={ closeModal }
        style={customStyles}
        closeTimeoutMS={ 200 }
        className="modal"
        overlayClassName="modal-fondo"
        >

            <h3 className='title-carrito'>Productos del Carrito</h3>

            { 
                <table className="table text-center">
                    <tbody>
                        { 
                        carritoCliente.map( car => (
                            <CardsCarrito key={ car.id } { ...car } />
                        )) 
                        }
                    </tbody>
                </table> 
            }

            <div className='fin-carrito'>
                <h4>Total: {`$${separadorMillares(totalCliente)}`}</h4>

                {
                ( carritoCliente.length > 0 ) &&
                <button className='btn' 
                        onClick={ handlerComprar }
                >
                    Verificar
                </button>  
                }

                <button className='btn' 
                    onClick={ closeModal }
                >
                    Salir
                </button>  
            </div>

        </Modal >

    )



}
