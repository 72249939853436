import { combineReducers } from 'redux';

import { authAdminReducer } from './authAdminReducer';
import { authClienteReducer } from './authClienteReducer';
import { usuarioReducer } from './usuarioReducer';
import { clienteReducer } from "./clienteReducer";
import { productoReducer } from './productoReducer';
import { categoriaReducer } from './categoriaReducer';
import { proveedorReducer } from './proveedorReducer';
import { carritoReducer } from './carritoReducer';
import { opReducer } from './opReducer';
import { abonoReducer } from './abonoReducer';
import { trasabilidadReducer } from './trasabilidadReducer';
import { modalReducer } from './modalReducer';
import { deudaClienteReducer } from './deudaClienteReducer';
import { servicioReducer } from './servicioReducer';
import { gastoReducer } from './gastosReducer';
import { ivaReducer } from './ivaReducer';
import { slideReducer } from './slideshow';
import { unidadMedidaReducer } from './unidadMedidaReducer';
import { inventarioReducer } from './inventarioReducer';
import { abonoFacturaReducer } from './abonoFacturaReducer';
import { reportesInventarioReducer } from './reportesinventario';
import { cotizacionReducer } from './cotizacionReducer';
import { pagoInventarioReducer } from './pagosInventarioReducer';





export const rootReducer = combineReducers({
    abono: abonoReducer,
    abonoFactura: abonoFacturaReducer,
    admin: authAdminReducer,
    authCliente: authClienteReducer,
    carrito: carritoReducer,
    categoria: categoriaReducer,
    cliente: clienteReducer,
    cotizacion: cotizacionReducer,
    deudaCliente: deudaClienteReducer,
    gastos: gastoReducer,
    inventario: inventarioReducer,
    iva: ivaReducer,
    medidas: unidadMedidaReducer,
    modal: modalReducer,
    op: opReducer,
    pagoinv: pagoInventarioReducer,
    producto: productoReducer,
    proveedor: proveedorReducer,
    reporteinventario: reportesInventarioReducer,
    servicio: servicioReducer,
    slideshow: slideReducer,
    trasabilidad: trasabilidadReducer,
    user: usuarioReducer,
});