

import { useEffect, useMemo, useState } from 'react';
import io from 'socket.io-client';



const urlSocket = process.env.REACT_APP_SOCKET_URL;



    export const useSockets = () => {

        const [online, setOnline] = useState(false);



        const socket = useMemo( () => io.connect( urlSocket, {
            transports: ['websocket']
        }), [urlSocket] );



        useEffect(() => {
            setOnline( socket.connected );
        }, [socket]);



        useEffect(() => {
            socket.on('connect', () => {
                setOnline( true );
            });
        }, [socket]);



        useEffect(() => {
            socket.on('disconnect', () => {
                setOnline( false );
            });
        }, [socket]);
        


        return {
            socket,
            online
        }


    }