
import { Avatar, Checkbox, TableCell, TableRow, Typography } from "@mui/material";
import { separadorMillares, usePrecioSegunRol } from "../../../hooks";

import { useSelector } from "react-redux";
import { VerPrecioTotalCarrito } from "../../components";

import moment from "moment";
const baseUrl = process.env.REACT_APP_API_URL;






export const TablaCotizacionSinEditar = ({ selected, handleClick }) => {


    const { carritoCompra } = useSelector( state => state.carrito );
    const { precioSegunRol } = usePrecioSegunRol();

    const fecha = moment().add(8, 'days').format('D/MM/YYYY');





    return (
        <>
        {carritoCompra.map( car => (
            <TableRow key={ car.id } onClick={ () => handleClick(car.id)}>
                <TableCell padding="checkbox">
                    <Checkbox color="primary" checked={selected.some( (item) => item === car.id )} />
                </TableCell>

                <TableCell sx={{ display:'flex', justifyContent:'center' }}>
                    <Avatar
                        alt="Imagen de Producto"
                        src={`${ baseUrl }/uploads/productos/${ car.producto.img }`} 
                        sx={{ width: 50, height: 50 }}
                    /> 
                </TableCell>

                <TableCell className="w-300">
                    <Typography variant="span">{ car.producto.nombre }</Typography>
                </TableCell>

                <TableCell >
                    <Typography variant="span">{ car.cantidad }</Typography>
                </TableCell>

                <TableCell >
                    <Typography variant="span" >{`$${separadorMillares(precioSegunRol(car) / car.cantidad)}`}</Typography>
                </TableCell>
                <TableCell >
                    <Typography variant="span" >{`$${separadorMillares(precioSegunRol(car))}`} </Typography>
                </TableCell>

            </TableRow>
        ))}

            <TableRow >
                <TableCell colSpan="3">
                    <Typography variant="span">
                    { `OBSERVACIONES: Cotización valida hasta el ${ fecha }` }
                    </Typography>
                </TableCell>
                <TableCell ></TableCell>

                <TableCell rowSpan={2}><Typography variant="span">TOTAL</Typography></TableCell>
                <TableCell rowSpan={2}>
                    <Typography variant="span">
                        <VerPrecioTotalCarrito mostrar={false} />
                    </Typography>
                </TableCell>
            </TableRow>

            <TableRow >
                <TableCell colSpan="3">
                    <Typography variant="span">
                        Todos los valores descritos en esta cotización estan antes de IVA.
                    </Typography>
                </TableCell>
                <TableCell ></TableCell>
            </TableRow>
        </>

    )
}
