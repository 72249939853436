
import { createTheme } from "@mui/material";
import { red } from "@mui/material/colors";
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';

const drawer = process.env.REACT_APP_DRAWER;
const drawerWidth = +drawer;






export const originalTheme = createTheme({

    palette: {
        mode: 'dark',
        primary: {
            main: '#ed028b'
        },
        secondary: {
            main: '#17a9e2'
        },
        error: {
            main: red.A400
        },
        text: {
            primary: '#FFFFFF',
            secondary: '#FFFFFF',
            disabled: 'rgba(255, 255, 255, 0.5)',
        },
    },


    components: {
        MuiTypography: {
            styleOverrides: {
                h1: {
                    fontSize: 35,
                    fontWeight: "bold",  
                    fontFamily: 'Oswald'                
                },
                h2: {
                    fontSize: 30,
                    fontWeight: 'bold',
                    fontFamily: 'Oswald',
                },

                h3: {
                    fontSize: 18,
                    color: 'white',
                    fontFamily: 'Oswald'
                },

                h4: {
                    fontSize: 20,
                    fontWeight: 'bold',
                    color: 'white',
                    fontFamily: 'Oswald'
                },

                h5: {
                    fontSize: 18,
                    fontWeight: 'bold',
                    fontFamily: 'Oswald'
                },
                
                span: {
                    fontSize: 15,
                    fontWeight: 200,
                    fontFamily: 'Oswald'
                },
            }
        },

        MuiTextField: {
            styleOverrides: {
                root: {
                    color: '#17a9e2',
                    borderBottomColor: '#17a9e2',
                },
            }
        },


        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: '#17a9e2',
                },
            },
        },


        MuiTableContainer:{
            styleOverrides: {
                root: {
                    border: '1px solid white'
                }
            }
        },

        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontSize: '14px',
                    padding: '4px',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    // backgroundColor: 'primary',
                }
            }
        },


        MuiTableRow: {
            styleOverrides: {
                root: {
                    "&:nth-of-type(odd)":{
                        backgroundColor: 'rgba(255, 255, 255, 0.3)',
                    },
                    '&:last-child th': {
                        backgroundColor: '#ed028b',
                        fontSize: '17px',
                        fontWeight: 'bold',
                        color: '#fff',
                        padding: '8px',
                        textAlign: 'center',
                        borderBottom: '1px solid white'
                    }
                }
            }
        },

        MuiIconButton: {
            styleOverrides: {
                root: {
                    backgroundColor: '#17a9e2',
                    "&:hover": {
                        backgroundColor: '#17a9e2', opacity: 0.3,
                    }
                }
            }
        },


        MuiIcon: {
            styleOverrides: {
                root: {
                    color: 'red',
                    fontSize: 30
                }
            }
        },

        
        MuiButton:{
            styleOverrides: {
                root: {
                    color: 'white',
                    "&:disabled": {
                        color: 'white',
                    }
                }
            }
        },


    }


})





export const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(5),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#000000',
    minHeight:'100vh',
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
    }),
);



export const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));



export const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
    })(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));



export const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});