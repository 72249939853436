
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { Button, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { LayoutReturn } from "../../layouts"

import { AgregarItemsCotizacion } from "../components/AgregarItemsCotizacion";
import { userListCotizacion } from "../../../actions/usuario";
import { cotizacionXId, deleteActivado, updateCotizacion } from "../../../actions/cotizacion";

import moment from "moment";
import Swal from "sweetalert2";




const now = moment();

export const ActualizarCotizacion = () => {


    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const { activeCotizacion } = useSelector(state => state.cotizacion);



    useEffect(() => {
        if(id){
            dispatch(cotizacionXId(id));
        }
    }, [id, dispatch])    


    const [user, setUser] = useState([]);
    const [data, setData] = useState([]);
    const [formUsuario, setFormUsuario ] = useState({
        tipoUser: '',
        usuario: '',
        fechaFactura: now.toDate(),
        formaPago: '',
        observacion: '',
    });
    const { tipoUser, usuario, fechaFactura, formaPago, observacion } = formUsuario;


    const handleInputChange = ({ target }) => {
        setFormUsuario({
        ...formUsuario,
        [target.name]: target.value
        });
    }


    const handleChangeStart = (e) => {
        setFormUsuario({
            ...formUsuario,
            fechaFactura: e.$d
        });
    }

    useEffect(() => {
        if( activeCotizacion ){
            dispatch(userListCotizacion(activeCotizacion.tipoRol)).then( resp => {
                setUser(resp)
                setFormUsuario({
                    ...activeCotizacion,
                    tipoUser: activeCotizacion.tipoRol,
                    usuario: activeCotizacion.usuario,
                    fechaFactura: activeCotizacion.fechaFactura,
                    formaPago: activeCotizacion.formaPago,
                    observacion: activeCotizacion.observacion,
                })
                setData( activeCotizacion.items );
            })
        }
    }, [activeCotizacion, dispatch])
    



    const handleEnviarInfo = async () => {

        if([tipoUser, usuario, fechaFactura, formaPago, observacion].includes('')){
            return Swal.fire('Error', 'Los Datos Iniciales Son Obligatorios.', 'error');
        }
        if(data.length <= 0){
            return Swal.fire('Error', 'Debe Haber Al Menos Un Item Agregado.', 'error');
        }
        
        const resp = await Swal.fire({
            title: 'Actualizar Cotización',
            text: `¿Esta Seguro De Actualizar Esta Cotización?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si' 
        });

        if(resp.value){
            const result = await dispatch(updateCotizacion({ data, ...formUsuario }));
            if( result ) {
                navigate('/super/cotizacion/all')
                dispatch(deleteActivado());
            }
        }
    }



    
    return (

        <LayoutReturn titulo='Editar Cotización' url='/super/cotizacion/all'>

            <Grid container spacing={3} sx={{width:'100%', mt:3}}>
                <Grid item xs={ 12 } sm={6} md={4} >
                    <TextField 
                        required
                        disabled
                        variant="filled"
                        select
                        label="Seleccione Rol"
                        fullWidth
                        name='tipoUser'
                        value={ tipoUser }
                        onChange={ (e) => {
                            handleInputChange(e);
                        }}
                    >
                        <MenuItem value="">Seleccione Un Rol</MenuItem>
                        <MenuItem value="CLIENTE">CLIENTE</MenuItem>
                        <MenuItem value="DISTRIBUIDOR">DISTRIBUIDOR</MenuItem>
                        <MenuItem value="PUBLICISTA">PUBLICISTA</MenuItem>
                        <MenuItem value="EMPRESA">EMPRESA</MenuItem>
                    </TextField>
                </Grid>


                <Grid item xs={ 12 } sm={ 6 } md={4} >
                    <TextField 
                        required
                        variant="filled"
                        select
                        label="Seleccione Usuario"
                        fullWidth
                        name='usuario'
                        value={ usuario }
                        onChange={ handleInputChange }
                    >
                        <MenuItem value="">Seleccione Un Usuario</MenuItem>
                        {
                        user?.map( user => (
                            <MenuItem key={user.id} value={user.id}>{user.nombre}</MenuItem>
                        ))
                        }
                    </TextField>
                </Grid>

                <Grid item xs={ 12 } sm={6} md={4} >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Fecha de Factura"
                            value={ fechaFactura }
                            onChange={ handleChangeStart }
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                    </LocalizationProvider>
                </Grid>

                <Grid item xs={ 12 } sm={ 6 } md={4} >
                    <TextField 
                        required
                        variant="filled"
                        select
                        label="Forma de Pago"
                        fullWidth
                        name='formaPago'
                        value={ formaPago }
                        onChange={ handleInputChange }
                    >
                        <MenuItem value="">Seleccione Forma de Pago</MenuItem>
                        <MenuItem value="CREDITO">CREDITO</MenuItem>
                        <MenuItem value="CONTADO">CONTADO</MenuItem>
                    </TextField>
                </Grid>

                <Grid item xs={12} sm={12} md={8} >
                    <TextField required
                        variant="filled"
                        label='Observación'
                        type='text'
                        multiline
                        placeholder='Observación'
                        fullWidth
                        name="observacion"
                        value={ observacion }
                        onChange={ handleInputChange }
                    />
                </Grid>
            </Grid>

            {
            tipoUser &&
            <AgregarItemsCotizacion data={data} setData={setData} tipoUser={tipoUser} />
            }

            <Grid container  spacing={2} sx={{ direccion:'column', justifyContent:'center', my:7 }} 
                >
                <Grid item xs={ 12 } sm={ 7 } >
                    <Button variant="contained" color="secondary" fullWidth type="submit" onClick={ handleEnviarInfo } >
                        <Typography variant="span" color='white' >Actualizar Cambios</Typography>
                    </Button>
                </Grid>
            </Grid>


        </LayoutReturn>

    )


}
