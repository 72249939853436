import { types } from "../types/types";




const initialState = {
    inventarios: [ ],
    inventarioDes: [],
    activeInventario: null,
    materia: [],
    servicios: [],
    insumos: [],
};



export const inventarioReducer = ( state = initialState, action ) => {

    switch ( action.type ) {

        case types.inventarioAddNew:
            return {
                ...state,
                inventarios: [
                    ...state.inventarios,
                    action.payload
                ]
            };


        case types.listadoInventario:
            return {
                ...state,
                inventarios: [ ...action.payload ]
            };


        case types.inventarioUpdate:
            return {
                ...state,
                inventarios: state.inventarios.map( 
                    e => ( e._id === action.payload._id ) ? action.payload : e
                )
            };


        case types.inventarioActivo:
            return {
                ...state,
                activeInventario: action.payload
            };
            


        case types.inventarioDeleteActivo:
            return {
                ...state,
                activeInventario: null
            };




        // case types.listadoInventarioDes:
        //     return {
        //         ...state,
        //         inventarioDes: [ ...action.payload ]
        //     };


        // case types.deleteInventarioDes:
        //     return {
        //         ...state,
        //         inventarioDes: []
        //     };





        

        default:
            return state;
    }

}
