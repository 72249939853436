
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { LayoutReturn } from "../../layouts"
import { TablaReporteInventario } from "../components"
import { reporteInventarioListaStart } from "../../../actions";
import { MensajeAlertaDanger } from "../../components";






export const ReportesInventario = () => {


    const { id } = useParams();
    const dispatch = useDispatch();
    const { inventarios } = useSelector( state => state.reporteinventario );



    useEffect(() => {
        if( id ){
            dispatch(reporteInventarioListaStart(id));
        }
    }, [id]);





    return (

        <LayoutReturn titulo={`Reporte de Inventario`} url='/super/inventario/list'>

            { (inventarios.length > 0)

            ?   <TablaReporteInventario />

            :   <MensajeAlertaDanger titulo="No Hay Registro De Inventario" />
            
            }

        </LayoutReturn>

    )

    
}
