import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, BrowserRouter } from 'react-router-dom';


import { SuperAdminRouter } from './SuperAdminRouter';
import { HomeRouter } from './HomeRouter';
import { PrivateRoute2 } from './PrivateRoute2';
import { PublicRoute2 } from './PublicRoute2';
import { startChekingSuper } from '../actions/admin';
import { OlvideMiPassword } from '../components/auth/OlvideMiPassword';
import { NuevoPassword } from '../components/auth/NuevoPassword';
import { AuthAdminRoute } from './AuthAdminRoute';
import { LoginClientes, RegisterCliente } from '../components/auth/pages';
import { TerminosYCondiciones } from '../components/home/pages';
import { CargandoInformacion } from '../components/components';





export const AppRouter = () => {


  const dispatch = useDispatch();
  const { cheking } = useSelector( state => state.admin );


  useEffect(() => {
    dispatch( startChekingSuper() );
  }, [dispatch] );




  if( cheking ) {
    return ( <CargandoInformacion titulo="Cargando..." /> )
  }
  

  return (

    <BrowserRouter>

        <Routes>

            {/* <Route path="/login/super" element={ 
                <PublicRoute2>
                    <LoginSuperAdmin />
                </PublicRoute2>AuthAdminRoute
              } 
            /> */}

            <Route path="/admin/*" element={ 
                <PublicRoute2>
                    <AuthAdminRoute />
                </PublicRoute2>
              } 
            />


            <Route path="/super/*" element={
                <PrivateRoute2>
                    <SuperAdminRouter />
                </PrivateRoute2>
              } 
            />


            {/* Rutas del Home */}
            <Route path="/*" element={<HomeRouter />} />


            {/* Auth del Cliente */}
            <Route path="/login" element={<LoginClientes />} />
            <Route path="/register" element={<RegisterCliente />} />
            <Route path="/olvide-password" element={<OlvideMiPassword />} />
            <Route path="/nuevo-password/:token" element={<NuevoPassword />} />
            <Route path="/terminos" element={ <TerminosYCondiciones /> }/>



            {/* <Route path="/*" element={ <Navigate to="/home" replace /> }/> */}

        </Routes>
        

    </BrowserRouter>
  )
}
