
import { useDispatch, useSelector } from 'react-redux';
import { startUploadingProductos } from '../../../actions/uploads';



const baseUrl = process.env.REACT_APP_API_URL;


export const MostrarImagenProducto = () => {
    
    
        const dispatch = useDispatch();
        const { activeProducto } = useSelector(state => state.producto);
    
    
    
        const handlePictureClick = () => {
            document.querySelector('#fileSelector').click();
        }
    
    
        const handleFileChange = (e) => {
            const file = e.target.files[0];
            if( file ) {
                dispatch( startUploadingProductos( activeProducto.id, 'productos', file ) );
            }
        }
    
    
    
        return (
    
            <div className="imagen-user">
    
                <div className="contenedor-image">
    
                    {
                    ( activeProducto )
                    
                    ? <img src={`${ baseUrl }/uploads/productos/${ activeProducto.img }`} alt="Imagen Producto" />
                    : <img src={`${ baseUrl }/uploads/productos/no-img.jpg`} alt="Imagen Producto" />
                    }
                    
                </div>
    
                <button className="btn botonUpload"
                        onClick={ handlePictureClick }
                > 
                    Subir Imagen
                </button>
    
                <input type="file" 
                    id="fileSelector"
                    className="boton-file"
                    onChange={ handleFileChange }
                    name="file"
                />
            </div>
    
            )
    
    
}
    

