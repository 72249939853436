
import { Box, CircularProgress, Typography } from "@mui/material"




export const CargandoMedio = ({ titulo }) => {

  return (
    
    <Box sx={{ display: 'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', width:'100%', height:'70vh' }}>
        <CircularProgress size="50px" />
        <Typography sx={{ fontSize:18, mt:1 }} >{ titulo }</Typography>
    </Box>

  )


}
