import Swal from "sweetalert2";
import { fileUpload } from "../helpers/fetch";
import { types } from "../types/types";



export const startUploadingUsuariosLogin = ( id, tipo, archivo ) => {
    return async( dispatch, getState ) => {

        const { usuario } = getState().admin;
        Swal.fire({
            title: 'Cargando....',
            text: 'Espere Por Favor',
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });

        const imagenResp = await fileUpload( id, tipo, archivo );
        usuario.img = imagenResp;
        dispatch( authSuperChekingLogin(usuario) );

        Swal.close();
        Swal.fire('Exitoso', 'Imagen Subida', 'success');
    }
}

const authSuperChekingLogin = ( admin ) => ({ 
    type: types.authSuperChekingLogin,
    payload: admin
});



export const startUploadingUsuarios = ( id, tipo, archivo ) => {
    return async( dispatch, getState ) => {

        const { activeUser } = getState().user;
        Swal.fire({
            title: 'Cargando....',
            text: 'Espere Por Favor',
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });

        const imagenResp = await fileUpload( id, tipo, archivo );
        activeUser.img = imagenResp;
        dispatch(usuarioActivo(activeUser) );

        Swal.close();
        Swal.fire('Exitoso', 'Imagen Subida', 'success');
    }
}

const usuarioActivo = ( user ) => ({
    type: types.usuarioActivo,
    payload: user
});





export const startUploadingProductos = ( id, tipo, archivo ) => {
    return async( dispatch, getState ) => {

        const { activeProducto } = getState().producto;

        Swal.fire({
            title: 'Cargando....',
            text: 'Espere Por Favor',
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });

        const imagenResp = await fileUpload( id, tipo, archivo );
        activeProducto.img = imagenResp;
        dispatch(productosActivo(activeProducto));

        Swal.close();

        if( typeof imagenResp === 'string' ){
            Swal.fire('Exitoso', 'Imagen Subida', 'success');
        } 

        // Swal.close();
        // Swal.fire('Exitoso', 'Imagen Subida', 'success');
    }
}

const productosActivo = ( producto ) => ({
    type: types.productosActivo,
    payload: producto
});




export const startUploadingCategoria = ( id, tipo, archivo ) => {
    return async( dispatch, getState ) => {

        const { activeCategoria } = getState().categoria;

        Swal.fire({
            title: 'Cargando....',
            text: 'Espere Por Favor',
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });
        const imagenResp = await fileUpload( id, tipo, archivo );
        activeCategoria.img = imagenResp;
        dispatch(categoriaActivo(activeCategoria));

        Swal.close();
        Swal.fire('Exitoso', 'Imagen Subida', 'success');
    }
}

const categoriaActivo = ( categoria ) => ({
    type: types.categoriaActivo,
    payload: categoria
});



export const startUploadingPagos = ( id, tipo, archivo ) => {
    return async( dispatch, getState ) => {

        const { activeOP } = getState().op;


        Swal.fire({
            title: 'Cargando....',
            text: 'Espere Por Favor',
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });

        const imagenResp = await fileUpload( id, tipo, archivo );
        activeOP.imgPago = imagenResp;
        dispatch( opActivo(activeOP) );
        Swal.close();
        Swal.fire('Exitoso', 'Imagen Subida', 'success');

    }
}

const opActivo = ( op ) => ({
    type: types.opActivo,
    payload: op
});



export const startUploadingDetalles = ( id, tipo, archivo ) => {
    return async( dispatch, getState ) => {

        const { activeCarrito } = getState().carrito;

        Swal.fire({
            title: 'Cargando....',
            text: 'Espere Por Favor',
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });

        const imagenResp = await fileUpload( id, tipo, archivo );
        if( typeof imagenResp === 'string' ){
            activeCarrito.imgCliente = imagenResp;
            dispatch( carritoActivo(activeCarrito) );
    
            Swal.close();
            Swal.fire('Exitoso', 'Imagen Subida', 'success');
        }
       
    }
}

const carritoActivo = ( carrito ) => ({
    type: types.carritoActivo,
    payload: carrito 
});