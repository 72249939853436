

import { useSelector } from "react-redux";




export const useCalcularPrecios = () => {

    // const { role } = useSelector( state => state.admin );
    // const { view } = useSelector( state => state.iva );

    // const rolesExcluidos = ['PUBLICISTA', 'EMPRESA'];
    // const viewsExcluidos = ['disenio', 'empresa'];


    const calcularPrecioSoloCantidad = ( cantidad, usuario ) => {

        let cliente    = 0;
        let distri     = 0;
        let empresa    = 0;
        let publicista = 0;
        let precioReal = 0;


        cliente    = cantidad * usuario.precioVenta;
        distri     = cantidad * usuario.precioDistri;
        empresa    = cantidad * usuario.precioEmpresa;
        publicista = cantidad * usuario.precioPublicista;
        precioReal = cantidad * usuario.precioVenta;

        // if( rolesExcluidos.includes(role) ){
        //     return {
        //         cliente,
        //         distri,
        //         empresa,
        //         publicista,
        //         precioReal,
        //     }
        // }

        // if( viewsExcluidos.includes(view) ){
        //     return {
        //         cliente,
        //         distri,
        //         empresa,
        //         publicista,
        //         precioReal,
        //     }
        // }

        if( cantidad > 1 && cantidad < 7 ) {
            return {
                cliente: cliente - ( cliente * 5 / 100 ),
                distri: distri - ( distri * 5 / 100 ),
                // empresa: empresa - ( empresa * 5 / 100 ),
                // publicista: publicista - ( publicista * 5 / 100 ),
                empresa: empresa,
                publicista: publicista,
                precioReal,
            }
        }

        if( cantidad > 6 && cantidad < 13 ) {
            return {
                cliente: cliente - ( cliente * 10 / 100 ),
                distri: distri - ( distri * 10 / 100 ),
                // empresa: empresa - ( empresa * 10 / 100 ),
                // publicista: publicista - ( publicista * 10 / 100 ),
                empresa: empresa,
                publicista: publicista,
                precioReal,
            }
        }

        if( cantidad > 12 && cantidad < 25 ) {
            return {
                cliente: cliente - ( cliente * 15 / 100 ),
                distri: distri - ( distri * 15 / 100 ),
                // empresa: empresa - ( empresa * 15 / 100 ),
                // publicista: publicista - ( publicista * 15 / 100 ),
                empresa: empresa,
                publicista: publicista,
                precioReal,
            }
        }

        if( cantidad > 24 && cantidad < 51 ) {
            return {
                cliente: cliente - ( cliente * 20 / 100 ),
                distri: distri - ( distri * 20 / 100 ),
                // empresa: empresa - ( empresa * 20 / 100 ),
                // publicista: publicista - ( publicista * 20 / 100 ),
                empresa: empresa,
                publicista: publicista,
                precioReal,
            }
        }

        if( cantidad > 50 && cantidad < 100 ) {
            return {
                cliente: cliente - ( cliente * 25 / 100 ),
                distri: distri - ( distri * 25 / 100 ),
                // empresa: empresa - ( empresa * 25 / 100 ),
                // publicista: publicista - ( publicista * 25 / 100 ),
                empresa: empresa,
                publicista: publicista,
                precioReal,
            }
        }

        if( cantidad > 99 ) {
            return {
                cliente: cliente - ( cliente * 30 / 100 ),
                distri: distri - ( distri * 30 / 100 ),
                // empresa: empresa - ( empresa * 30 / 100 ),
                // publicista: publicista - ( publicista * 30 / 100 ),
                empresa: empresa,
                publicista: publicista,
                precioReal,
            }
        }
        
        return {
            cliente,
            distri,
            empresa,
            publicista,
            precioReal,
        }
    } 




    const calcularPrecioX50U = ( cantidad, carrito ) => {

        let cliente    = 0;
        let distri     = 0;
        let empresa    = 0;
        let publicista = 0;
        let precioReal = 0;
    
    
        cliente    = cantidad * carrito.precioVenta;
        distri     = cantidad * carrito.precioDistri;
        empresa    = cantidad * carrito.precioEmpresa;
        publicista = cantidad * carrito.precioPublicista;
        precioReal = cantidad * carrito.precioVenta;

       
    
        if( cantidad > 50 && cantidad < 101 ) {
            return {
                cliente: cliente - ( cliente * 5 / 100 ),
                distri: distri - ( distri * 5 / 100 ),
                // empresa: empresa - ( empresa * 5 / 100 ),
                // publicista: publicista - ( publicista * 5 / 100 ),
                empresa: empresa,
                publicista: publicista,
                precioReal,
            }
        }
    
        if( cantidad > 100 && cantidad < 301 ) {
            return {
                cliente: cliente - ( cliente * 10 / 100 ),
                distri: distri - ( distri * 10 / 100 ),
                // empresa: empresa - ( empresa * 10 / 100 ),
                // publicista: publicista - ( publicista * 10 / 100 ),
                empresa: empresa,
                publicista: publicista,
                precioReal,
            }
        }
    
    
        if( cantidad > 300 && cantidad < 501 ) {
            return {
                cliente: cliente - ( cliente * 15 / 100 ),
                distri: distri - ( distri * 15 / 100 ),
                // empresa: empresa - ( empresa * 15 / 100 ),
                // publicista: publicista - ( publicista * 15 / 100 ),
                empresa: empresa,
                publicista: publicista,
                precioReal,
            }
        }
    
        if( cantidad > 500 && cantidad < 751 ) {
            return {
                cliente: cliente - ( cliente * 20 / 100 ),
                distri: distri - ( distri * 20 / 100 ),
                // empresa: empresa - ( empresa * 20 / 100 ),
                // publicista: publicista - ( publicista * 20 / 100 ),
                empresa: empresa,
                publicista: publicista,
                precioReal,
            }
        }
    
        if( cantidad > 750 && cantidad < 1000 ) {
            return {
                cliente: cliente - ( cliente * 25 / 100 ),
                distri: distri - ( distri * 25 / 100 ),
                // empresa: empresa - ( empresa * 25 / 100 ),
                // publicista: publicista - ( publicista * 25 / 100 ),
                empresa: empresa,
                publicista: publicista,
                precioReal,
            }
        }
    
        if( cantidad > 999 ) {
            return {
                cliente: cliente - ( cliente * 30 / 100 ),
                distri: distri - ( distri * 30 / 100 ),
                // empresa: empresa - ( empresa * 30 / 100 ),
                // publicista: publicista - ( publicista * 30 / 100 ),
                empresa: empresa,
                publicista: publicista,
                precioReal,
            }
        }
        
        return {
            cliente,
            distri,
            empresa,
            publicista,
            precioReal,
        }
    }




    const calcularCantidadx10U = ( cantidad, carrito ) => {
    
        let cliente    = 0;
        let distri     = 0;
        let empresa    = 0;
        let publicista = 0;
        let precioReal = 0;
    
        cliente    = cantidad * carrito.precioVenta;
        distri     = cantidad * carrito.precioDistri;
        empresa    = cantidad * carrito.precioEmpresa;
        publicista = cantidad * carrito.precioPublicista;
        precioReal = cantidad * carrito.precioVenta;
    
    
        if( cantidad > 10 && cantidad < 21 ) {
            return {
                cliente: cliente - ( cliente * 5 / 100 ),
                distri: distri - ( distri * 5 / 100 ),
                // empresa: empresa - ( empresa * 5 / 100 ),
                // publicista: publicista - ( publicista * 5 / 100 ),
                empresa: empresa,
                publicista: publicista,
                precioReal,
            }
        }
    
        if( cantidad > 20 && cantidad < 31 ) {
            return {
                cliente: cliente - ( cliente * 10 / 100 ),
                distri: distri - ( distri * 10 / 100 ),
                // empresa: empresa - ( empresa * 10 / 100 ),
                // publicista: publicista - ( publicista * 10 / 100 ),
                empresa: empresa,
                publicista: publicista,
                precioReal,
            }
        }
    
        if( cantidad > 30 && cantidad < 41 ) {
            return {
                cliente: cliente - ( cliente * 15 / 100 ),
                distri: distri - ( distri * 15 / 100 ),
                // empresa: empresa - ( empresa * 15 / 100 ),
                // publicista: publicista - ( publicista * 15 / 100 ),
                empresa: empresa,
                publicista: publicista,
                precioReal,
            }
        }
    
        if( cantidad > 40 && cantidad < 51 ) {
            return {
                cliente: cliente - ( cliente * 20 / 100 ),
                distri: distri - ( distri * 20 / 100 ),
                // empresa: empresa - ( empresa * 20 / 100 ),
                // publicista: publicista - ( publicista * 20 / 100 ),
                empresa: empresa,
                publicista: publicista,
                precioReal,
            }
        }
    
        if( cantidad > 50 && cantidad < 100 ) {
            return {
                cliente: cliente - ( cliente * 25 / 100 ),
                distri: distri - ( distri * 25 / 100 ),
                // empresa: empresa - ( empresa * 25 / 100 ),
                // publicista: publicista - ( publicista * 25 / 100 ),
                empresa: empresa,
                publicista: publicista,
                precioReal,
            }
        }
    
        if( cantidad > 99 ) {
            return {
                cliente: cliente - ( cliente * 30 / 100 ),
                distri: distri - ( distri * 30 / 100 ),
                // empresa: empresa - ( empresa * 30 / 100 ),
                // publicista: publicista - ( publicista * 30 / 100 ),
                empresa: empresa,
                publicista: publicista,
                precioReal,
            }
        }
    
        return {
            cliente,
            distri,
            empresa,
            publicista,
            precioReal,
        }
    }





    const calcularPreciosTerminados = (unidadMedida, alto1 = 0, ancho1 = 0, cantidad1 = 0, carrito, terminados) => {

        let cliente    = 0;
        let distri     = 0;
        let empresa    = 0;
        let publicista = 0;
        let alto = Number(alto1);
        let ancho = Number(ancho1);
        let cantidad = Number(cantidad1);
    
        let precioReal = 0;
    
        let terminadosCliente    = 0;
        let terminadosDistri     = 0;
        let terminadosEmpresa    = 0;
        let terminadosPublicista = 0;
    
    
        if( terminados ){
            terminadosCliente    = terminados.precioVenta;
            terminadosDistri     = terminados.precioDistri;
            terminadosEmpresa    = terminados.precioEmpresa;
            terminadosPublicista = terminados.precioPublicista;
        }
    
    
        if( unidadMedida === 'Centimetros' ){
    
            cliente = (alto * ancho * cantidad) / 10000  * carrito.precioVenta + (terminadosCliente * cantidad);
            distri = (alto * ancho * cantidad) / 10000  * carrito.precioDistri + (terminadosDistri * cantidad);
            empresa = (alto * ancho * cantidad) / 10000  * carrito.precioEmpresa + (terminadosEmpresa * cantidad);
            publicista = (alto * ancho * cantidad) / 10000  * carrito.precioPublicista + (terminadosPublicista * cantidad);
            precioReal = cliente;
    
        } else if ( unidadMedida === 'Metros' ) {
    
            cliente = (alto * ancho * cantidad) * carrito.precioVenta + (terminadosCliente * cantidad);
            distri = (alto * ancho * cantidad) * carrito.precioDistri + (terminadosDistri * cantidad);
            empresa = (alto * ancho * cantidad) * carrito.precioEmpresa + (terminadosEmpresa * cantidad);
            publicista = (alto * ancho * cantidad) * carrito.precioPublicista + (terminadosPublicista * cantidad);
            precioReal = cliente;
    
        }

    
        if( cantidad > 1 && cantidad < 7 ) {
            return {
                cliente: cliente - ( cliente * 5 / 100 ),
                distri: distri - ( distri * 5 / 100 ),
                // empresa: empresa - ( empresa * 5 / 100 ),
                // publicista: publicista - ( publicista * 5 / 100 ),
                empresa: empresa,
                publicista: publicista,
                precioReal
            }
        }

        if( cantidad > 6 && cantidad < 13 ) {
            return {
                cliente: cliente - ( cliente * 10 / 100 ),
                distri: distri - ( distri * 10 / 100 ),
                // empresa: empresa - ( empresa * 10 / 100 ),
                // publicista: publicista - ( publicista * 10 / 100 ),
                empresa: empresa,
                publicista: publicista,
                precioReal
            }
        }


        if( cantidad > 12 && cantidad < 25 ) {
            return {
                cliente: cliente - ( cliente * 15 / 100 ),
                distri: distri - ( distri * 15 / 100 ),
                // empresa: empresa - ( empresa * 15 / 100 ),
                // publicista: publicista - ( publicista * 15 / 100 ),
                empresa: empresa,
                publicista: publicista,
                precioReal
            }
        }

        if( cantidad > 24 && cantidad < 51 ) {
            return {
                cliente: cliente - ( cliente * 20 / 100 ),
                distri: distri - ( distri * 20 / 100 ),
                // empresa: empresa - ( empresa * 20 / 100 ),
                // publicista: publicista - ( publicista * 20 / 100 ),
                empresa: empresa,
                publicista: publicista,
            }
        }

        if( cantidad > 50 && cantidad < 100 ) {
            return {
                cliente: cliente - ( cliente * 25 / 100 ),
                distri: distri - ( distri * 25 / 100 ),
                // empresa: empresa - ( empresa * 25 / 100 ),
                // publicista: publicista - ( publicista * 25 / 100 ),
                empresa: empresa,
                publicista: publicista,
                precioReal
            }
        }

        if( cantidad > 99 ) {
            return {
                cliente: cliente - ( cliente * 30 / 100 ),
                distri: distri - ( distri * 30 / 100 ),
                // empresa: empresa - ( empresa * 30 / 100 ),
                // publicista: publicista - ( publicista * 30 / 100 ),
                empresa: empresa,
                publicista: publicista,
                precioReal
            }
        }
    
        return {
            cliente,
            distri,
            empresa,
            publicista,
            precioReal
        }
    }



    const calcularPrecioLitografias = ( disenio = '', carrito, cantidad1, terminados ) => {
    
        let cliente    = 0;
        let distri     = 0;
        let empresa    = 0;
        let publicista = 0;
        let tipo = carrito.tipo;
    
        let cantidad = Number(cantidad1);
        let precioReal = 0;
    
        let terminadosCliente    = 0;
        let terminadosDistri     = 0;
        let terminadosEmpresa    = 0;
        let terminadosPublicista = 0;
    
        if( terminados ){
            terminadosCliente    = terminados.precioVenta;
            terminadosDistri     = terminados.precioDistri;
            terminadosEmpresa    = terminados.precioEmpresa;
            terminadosPublicista = terminados.precioPublicista;
        }
        
    
        if( disenio === 'Con Diseño' ){
            if( tipo === "TARJETAS-VOLANTES-MEMBRETES" ) {
                cliente = (carrito.precioVenta * cantidad) + 12000  + (terminadosCliente * cantidad);
                distri = (carrito.precioDistri * cantidad) + 12000 + (terminadosDistri * cantidad);
                empresa = (carrito.precioEmpresa * cantidad) + 12000 + (terminadosEmpresa * cantidad);
                publicista = (carrito.precioPublicista * cantidad) + 12000 + (terminadosPublicista * cantidad);
                precioReal = (carrito.precioVenta * cantidad) + 12000  + (terminadosCliente * cantidad);
            }
            if ( tipo === "CARPETAS-PLEGABLES" ){
                cliente = (carrito.precioVenta * cantidad) + (carrito.precioVenta * 7 / 100)  + terminadosCliente;
                distri = (carrito.precioDistri * cantidad) + (carrito.precioDistri * 7 / 100) + terminadosDistri;
                empresa = (carrito.precioEmpresa * cantidad) + (carrito.precioEmpresa * 7 / 100) + terminadosEmpresa;
                publicista = (carrito.precioPublicista * cantidad) + (carrito.precioPublicista * 7 / 100) + terminadosPublicista;
                precioReal = (carrito.precioVenta * cantidad) + (carrito.precioVenta * 7 / 100)  + terminadosCliente;
            }
    
        }else {
            cliente = (carrito.precioVenta * cantidad) + terminadosCliente;
            distri = (carrito.precioDistri * cantidad) + terminadosDistri;
            empresa = (carrito.precioEmpresa * cantidad) + terminadosEmpresa;
            publicista = (carrito.precioPublicista * cantidad) + terminadosPublicista;
            precioReal = (carrito.precioVenta * cantidad) + terminadosCliente;
        }

    
        if( cantidad > 3 && cantidad < 7 ) {
            return {    
                cliente: cliente - ( cliente * 5 / 100 ),
                distri: distri - ( distri * 5 / 100 ),
                // empresa: empresa - ( empresa * 5 / 100 ),
                // publicista: publicista - ( publicista * 5 / 100 ),
                empresa: empresa,
                publicista: publicista,
                precioReal
            }
        }
    
        if( cantidad > 6 && cantidad < 12 ) {
            return {    
                cliente: cliente - ( cliente * 10 / 100 ),
                distri: distri - ( distri * 10 / 100 ),
                // empresa: empresa - ( empresa * 10 / 100 ),
                // publicista: publicista - ( publicista * 10 / 100 ),
                empresa: empresa,
                publicista: publicista,
                precioReal
            }
        }
    
        if( cantidad > 11 && cantidad < 24 ) {
            return {    
                cliente: cliente - ( cliente * 15 / 100 ),
                distri: distri - ( distri * 15 / 100 ),
                // empresa: empresa - ( empresa * 15 / 100 ),
                // publicista: publicista - ( publicista * 15 / 100 ),
                empresa: empresa,
                publicista: publicista,
                precioReal
            }
        }
    
        return {
            cliente,
            distri,
            empresa,
            publicista,
            precioReal
        }
    }




    return {
        calcularPrecioSoloCantidad,
        calcularPrecioX50U,
        calcularCantidadx10U,
        calcularPreciosTerminados,
        calcularPrecioLitografias
    }


}















































