

import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { Box, Grid, Typography } from "@mui/material";
import { BuscadorOpDisponibles, TablaItemsPorTrabajar } from "../components";

import { carritoPorTrabajar } from "../../../actions/items";





export const OpDisponiblesTrabajo = () => {

    const dispatch = useDispatch();


    useEffect( () => {
        dispatch( carritoPorTrabajar() );
    }, [dispatch] );




    return (

        <div>
            <Grid container direccion='row' alignItems='center'>
                <Box flex={ 1 } />
                <Typography variant='h4' sx={{ fontWeight:'bold' }} >LISTADO ITEMS DISPONIBLES</Typography>
                <Box flex={ 1 } />
            </Grid>

            <Grid container alignItems='center'>
                <BuscadorOpDisponibles />
                <TablaItemsPorTrabajar />
            </Grid>
        </div>
    )
}
