

import { Route, Routes } from "react-router-dom"


import { LoginSuperAdmin } from "../components/auth/pages";
import { NuevoPasswordAdmin } from "../components/auth/NuevoPasswordAdmin"
import { OlvideMiPasswordAdmin } from "../components/auth/OlvideMiPasswordAdmin"






export const AuthAdminRoute = () => {

  
    return (
  
        <>

            <Routes>
  
                <Route path="/login" element={<LoginSuperAdmin />} />
                <Route path="/olvide-password" element={<OlvideMiPasswordAdmin/>} />
                <Route path="/nuevo-password/:token" element={<NuevoPasswordAdmin/>} />
  
            </Routes>
  
  
        </>
  
  
    )
  
  
  }