
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom";


import { opPorComprador } from "../../../actions/op";
import { mostrarClientePorId } from "../../../actions/cliente";

import { LayoutReturn } from "../../layouts/LayoutReturn";
import { TablaOpsPorCliente } from "../components/TablaOpsPorCliente";





export const VerOpsPorCliente = () => {


    const { id } = useParams();
    const dispatch = useDispatch();
    const { activeCliente } = useSelector(state => state.cliente);



    useEffect(() => {
        if( id ){
            dispatch( opPorComprador(id) );
            dispatch( mostrarClientePorId(id) );
        }
    }, [id, dispatch] );




    return (
        

        <LayoutReturn titulo={`OPS de ${ activeCliente?.nombre }`} url={-1} >


            <TablaOpsPorCliente />
        

        </LayoutReturn>
            
    )


}
