
import { useDispatch } from "react-redux";

import { Box, Grid, IconButton, Typography } from "@mui/material";
import { AddOutlined } from "@mui/icons-material";
import { startOpenSlide } from "../../actions/slideshow";








export const LayoutModalAdd = ({ children, titulo = 'No hay Datos'}) => {



    const dispatch = useDispatch();



    const handleCrear = () => {
        dispatch(startOpenSlide());
    }



    return (

        <>
        
            <Grid container direccion='row' alignItems='center'>

                <IconButton
                    sx={{
                        color: '#fff',
                        backgroundColor: 'secondary.main',
                        ':hover': { backgroundColor: 'secondary.main', opacity: 0.3 },
                    }}
                    onClick={ handleCrear } 
                >
                    <AddOutlined />
                </IconButton>

                <Box flex={ 1 } />

                <Typography variant='h2'>{ titulo }</Typography>

                <Box flex={ 1 } />

            </Grid>

            <Grid container alignItems='center' justifyContent='center' >


                { children }


            </Grid>
        
        
        
        </>


        
    )




}
