
import { Grid, Typography } from "@mui/material";


import escritorio  from '../../../assets/empresa/escritorio.jpg';


export const ContactenosScreen = () => {


    return (



        <Grid container className="img-contac" sx={{backgroundImage:`url(${escritorio})`, width:'100%', backgroundSize: 'cover',
            height:'calc(100vh - 70px)'}}
        >

            <Grid container sx={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>

                <Typography variant="h2" mb={2}>Contáctanos</Typography>


                <ul className="list-contactos">
                    <li>   
                        <a href="http://wa.me/573165771648?text=Area%20Comercial%20Seven%20For%20Ever."
                            className="enlace-contac" target="_blank">
                            Click Aqui, Area Comercial
                            <i className="icono-red fa-brands fa-whatsapp"></i>
                        </a>
                        <p>7xever.asisadm@gmail.com</p>
                    </li>

                    <li>   
                        <a href="http://wa.me/573157133325?text=Area%20De%20Diseño%20Seven%20For%20Ever." 
                            className="enlace-contac" target="_blank">
                            Click Aqui, Area de Diseño
                            <i className="icono-red fa-brands fa-whatsapp"></i>
                        </a>
                        <p>7xever.designmadrid@gmail.com</p>
                    </li>
                </ul>


            </Grid>
        </Grid>

    )
}
