
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Button, IconButton, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';

import { SocketContext } from "../../../context/SocketContext";
import { useEventosSockets } from "../../../hooks";  

import { MensajeAlertaDanger } from "../../components";
import { trasaStartAddNew } from "../../../actions/trasabilidad";

import Swal from "sweetalert2";
import moment from "moment";




export const TablaMisItems = () => {


    const  { socket } = useContext( SocketContext );
    const { carritoUpdateAreaColaborador, tomarOpSocket } = useEventosSockets();
    const { items } = useSelector( state => state.carrito );
    const { uid } = useSelector( state => state.admin );
    const dispatch = useDispatch();
    const navigate = useNavigate();



    // Paginacion de Tabla
    const [page, setPage] = useState(0);
    const [ rowsPerPage ] = useState(20);
    const handleChangePage = (event, newPage) => {
        setPage( newPage );
    };
    useEffect(() => {
        if( items ){
            setPage(0);
        }
    }, [items]);



    const handleCambioArea = ( e, item ) => {
        const primero = item.area;
        item.area = e.target.value;

        Swal.fire({
            title: 'Terminar Item',
            text: `¿Esta Seguro De Terminar Trabajo De Item?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si, Seguro' 
        }).then( ( result ) => {
            if(result.value){
                dispatch( carritoUpdateAreaColaborador({
                    ...item,
                }));
                dispatch(trasaStartAddNew({
                    item: item.id,
                    colaborador: uid,
                    start: moment().toDate(),
                    antes: primero,
                    ahora: item.area
                }));
            }
        })
    }


    useEffect(() => {
        socket.on('item-devuelto', (item) => {
            dispatch( tomarOpSocket(item) );
        });
    }, [dispatch]);




    const detallesOp = (item) => {
        navigate(`/super/dt-pro-op/${ item.id }`);
    }



    return (

        <>
            {
            ( items.length > 0 )
            ?<Paper sx={{ width: '100%' }}>
                <TableContainer sx={{ my:'15px' }}>
                    <Table sx={{ minWidth: 700, }} aria-label="customized table">
                        <TableHead >
                            <TableRow>
                                <TableCell className="w-150">O.P.</TableCell>
                                <TableCell className="w-200">Pago</TableCell>
                                <TableCell>Nombre</TableCell>
                                <TableCell className="w-200">Area</TableCell>
                                <TableCell>Opciones</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {
                            items
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map( item => (
                                <TableRow key={ item.id } >
                                    <TableCell>{ item.op.op }</TableCell>
                                    <TableCell>
                                        {
                                        ( item.op.pago === 'PENDIENTE' ) && (
                                        <Button variant="outlined" color="error">PENDIENTE</Button>
                                        )}
                                        {
                                        ( item.op.pago === 'REALIZADO' ) && (
                                        <Button variant="outlined" color="success">REALIZADO</Button>
                                        )}
                                            {
                                        ( item.op.pago === 'CREDITO' ) && (
                                        <Button variant="outlined" color="info">CREDITO</Button>
                                        )}
                                    </TableCell>

                                    <TableCell>{ item.op.infoPersonal?.nombre }</TableCell>

                                    <TableCell>
                                        <TextField
                                            select required
                                            size="small"
                                            name="areaItem"
                                            value={ item.area }
                                            onChange={ (e) => handleCambioArea(e, item) }
                                        >
                                            <MenuItem value="Diseño">Diseño</MenuItem>
                                            <MenuItem value="Pendiente Aprobacion">Pendiente Por Aprobación</MenuItem>
                                            <MenuItem value="Externos">Externos</MenuItem>
                                            <MenuItem value="Corte Laser">Corte Laser</MenuItem>
                                            <MenuItem value="Bordado">Bordado</MenuItem>
                                            <MenuItem value="Impresion Laminado">Impresion y Laminado</MenuItem>
                                            <MenuItem value="Impresion">Impresion</MenuItem>
                                            <MenuItem value="Tubos Plasticos">Tubos Plasticos</MenuItem>
                                            <MenuItem value="Tubos Metalicos">Tubos Metalicos</MenuItem>
                                            <MenuItem value="Arandelas">Arandelas</MenuItem>
                                            <MenuItem value="Terminado">Terminado</MenuItem>
                                            <MenuItem value="Entregado">Entregado</MenuItem>
                                        </TextField>
                                    </TableCell> 

                                    <TableCell>

                                        <IconButton variant="contained"
                                            onClick={ () => detallesOp(item) }
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    </TableCell>

                                </TableRow>
                            ))
                            }
                        </TableBody>

                    </Table>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[ 20 ]}
                    component="div"
                    count={ items.length }
                    rowsPerPage={ rowsPerPage }
                    page={ page }
                    onPageChange={ handleChangePage }
                />

            </Paper>

            : <MensajeAlertaDanger titulo='No Hay Items Disponibles' />

            }
        </>

    )


}
