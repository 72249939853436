
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Button, Grid } from "@mui/material";
import { useCalcularPrecios, separadorMillares } from "../../../hooks";
import { SelectorProductos } from "../components";
import { SelectSencillo, TextFormField, ViewPreciosPorRol } from "../../components";
import { coloresCarrito } from "../../../data";


import Swal from "sweetalert2";
import { buscarProductoColor } from "../../../helpers/FormulariosCarrito";






export const FormularioCantidadx50 = ({ formCarrito, setFormCarrito, funcionAgregar, titulo }) => {


    const  { activeProducto } = useSelector( state => state.producto );
    const { calcularPrecioX50U } = useCalcularPrecios();
    const { carritoCompra } = useSelector( state => state.carrito );


    const [ precioReal, setPrecioReal ] = useState(0);




    useEffect(() => {
        if( formCarrito.color === '' ){
            return;
        }
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })
        Toast.fire({
            icon: 'question',
            title: 'Los Productos y Colores dependen de disponibilidad de stock'
        });
    }, [formCarrito.color]);




    useEffect( () => {
        if( activeProducto ) {
            const { cliente, distri, empresa, publicista, precioReal } = calcularPrecioX50U( formCarrito.cantidad, activeProducto );
            
            setPrecioReal( precioReal - cliente );
            setFormCarrito({
                ...formCarrito,
                totalCliente: Math.ceil(cliente),
                totalDistri: Math.ceil(distri),
                totalEmpresa: Math.ceil(empresa),
                totalPublicista: Math.ceil(publicista),
            });
        }
    }, [activeProducto, formCarrito.cantidad]);




    const handleInputChange = ({ target }) => {
        setFormCarrito({
            ...formCarrito,
            [target.name]: target.value
        });
    }



    const handleAgregarAlCarro = (e) => {
        e.preventDefault();

        if( formCarrito.cantidad <= 0 ) {
            return Swal.fire('Error', 'Debes Poner Una Cantidad', 'error');
        }

        if( (formCarrito.cantidad % 50) !== 0 ){
            return Swal.fire('Error', 'La Cantidad debe Ser Multiplos de 50 en 50', 'error');
        }

        if( formCarrito.color === '' || !formCarrito.color ) { 
            return Swal.fire('Error', 'Por favor escoger un color', 'error') 
        }

        const product = buscarProductoColor(carritoCompra, formCarrito.producto, formCarrito.color);
        funcionAgregar(product)
    }

    



    return (

        <form onSubmit={ handleAgregarAlCarro }>

            <SelectorProductos formCarrito={formCarrito} setFormCarrito={setFormCarrito} />

            <Grid container sx={{width:'100%', mt:2, display:'flex', justifyContent:'space-between'}} >
                <Grid item xs={5.8}>
                    <TextFormField
                        size="small"
                        label={`Cantidad`}
                        type='number'
                        placeholder="Cantidad"
                        name="cantidad"
                        value={formCarrito.cantidad}
                        onChange={ handleInputChange } 
                    />
                </Grid>

                <Grid item xs={5.8}>
                    <SelectSencillo
                        size="small"
                        label="Color"
                        name="color"
                        value={formCarrito.color}
                        onChange={ handleInputChange } 
                        options={coloresCarrito}
                    />
                </Grid>

            </Grid>


            {/* Mostrar Precio Segun Rol */}
            <ViewPreciosPorRol formCarrito={formCarrito} />


            <Grid container sx={{display:'flex', justifyContent:'space-between', mt:2}}>
                <Grid item xs={(precioReal <= 0) ? 12 : 5.8}>
                    <Button type="submit" color='primary' variant="contained" fullWidth>
                        { titulo }
                    </Button>
                </Grid>

                {(precioReal > 0) && (
                <Grid item xs={5.8}>
                    <Button color='secondary' variant="contained" fullWidth>
                        Ahorra { `$${ separadorMillares( precioReal ) }`}
                    </Button>
                </Grid>
                )}
            </Grid>
        </form> 
        
    )
}
