import { fetchConTokenCliente, fetchSinToken } from "../helpers/fetch";
import { types } from "../types/types";
import Swal from "sweetalert2";



export const clienteLoginSuper = ( cliente ) => {

    return async( dispatch ) => {
        try {
            const resp = await fetchSinToken('cliente/login', cliente, 'POST');
            const body = await resp.json();

            if( body.ok ) {
                localStorage.setItem('tokenCliente', body.token);
                localStorage.setItem('token-init-cli', new Date().getTime());
                dispatch( authClienteChekingLogin({
                    uid: body.uid,
                    nombre: body.nombre,
                    role: body.role,
                    cliente: body.cliente,
                }));
                Swal.fire('Bienvenid@', body.nombre, 'success');
            } else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}


export const clienteRegisterNew = ( cliente ) => {

    return async( dispatch ) => {
        try {
            const resp = await fetchSinToken('cliente/new', cliente, 'POST');
            const body = await resp.json();

            if( body.ok ) {
                localStorage.setItem('tokenCliente', body.token);
                localStorage.setItem('token-init-cli', new Date().getTime());
                dispatch( authClienteChekingLogin({
                    uid: body.cliente.id,
                    nombre: body.cliente.nombre,
                    role: body.cliente.role,
                    cliente: body.cliente,
                }));

                Swal.fire('Exitoso', 'Registro Exitoso', 'success');
                return true;

            } else {
                Swal.fire('Error', body.msg, 'error');
                return false;
            }
            
        } catch (error) {
            console.log(error);
            return false;
        }
    }
}



export const startRenewCliente = () => {
    return async( dispatch ) => {
        try {

            const resp = await fetchConTokenCliente('cliente/renew');
            const body = await resp.json();

            if( body.ok ) {

                localStorage.setItem('tokenCliente', body.token);
                localStorage.setItem('token-init-cli', new Date().getTime());
                dispatch( authClienteChekingLogin({
                    uid: body.uid,
                    nombre: body.nombre,
                    role: body.role,
                    cliente: body.cliente,
                }));

            }else {
                dispatch( authClienteChekingFinish() );
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}


export const startClienteLogout = () => {
    return ( dispatch ) => {
        localStorage.clear();
        dispatch( authClienteLogout() );
        // dispatch( limpiarListaClientes() );
    }
}



// const limpiarListaClientes = () =>({ type: types.clienteListaClear });
const authClienteLogout = () => ({ type: types.authClienteLogout });



const authClienteChekingFinish = () => ({
    type: types.authSuperChekingFinish
});



export const authClienteChekingLogin = ( cliente ) => ({ 
    type: types.authClienteChekingLogin,
    payload: cliente
});



// Actualizar Cliente Por el Lado del Checkout
export const clienteStartUpdateCliente = ( cliente ) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConTokenCliente(`cliente/${ cliente.id }`, cliente, 'PUT'); 
            const body = await (await resp).json();

            if( body.ok ) {
                dispatch( clienteUpdate( cliente ) );
            }else {
                Swal.fire('Error', body.msg,  'error');
            }
        } catch (error) {
            console.log(error);
        }
    }
}

const clienteUpdate = ( cliente ) => ({
    type: types.clienteUpdate,
    payload: cliente
});


export const cambioClaveCliente = ( claves ) => {
    return async( dispatch, getState ) => {

        const { uid } = getState().authCliente; 

        try {

            const resp = await fetchConTokenCliente(`cliente/cambio-clave/${ uid }`, claves, 'PUT');
            const body = await resp.json();

            if( body.ok ) {
                Swal.fire('Exitoso', body.msg, 'success');
            } else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }

    }
}






// Recuperar Password
export const recuperarPassword = ( email ) => {
    return async( dispatch ) => {
        
        try {
            const resp = await fetchSinToken(`cliente/olvido-password`, email, 'POST');
            const body = await resp.json();
            
            if( body.ok ){
                Swal.fire('Exitoso', body.msg, 'success');
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}



export const validarTokenParaPassword = ( token ) => {
    return async( dispatch ) => {
        
        try {
            const resp = await fetchSinToken(`cliente/olvido-password/${ token }`);
            const body = await resp.json();
            
            if( body.ok ){
                Swal.fire('Exitoso', body.msg, 'success');
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}




export const almacenarNuevoPassword = ( token, password ) => {
    return async( dispatch ) => {
        
        try {
            const resp = await fetchSinToken(`cliente/olvido-password/${ token }`, password, 'POST');
            const body = await resp.json();
            
            if( body.ok ){
                Swal.fire('Exitoso', body.msg, 'success');
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}