import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { trasaListaStart, trasaStartDelete } from "../../actions/trasabilidad";




export const RegistroColaborador = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { trasabilidad } = useSelector( state => state.trasabilidad );



useEffect(() => {
    if( id ){
        dispatch( trasaListaStart(id) );
    }
}, [dispatch, id] );


  const handleReturn = () => {
    navigate('/super/op-todas');
  }


  const handleEliminar = ( trasa ) => {
      Swal.fire({
          title: 'Eliminar Registro Colaborador',
          text: `¿Esta Seguro De Eliminar Registro}?`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Obvio Si' 
        }).then( ( result ) => {
          if(result.value){
              dispatch( trasaStartDelete( trasa ) );
          }
        })
  }



    
  return (
    
    <div className="formTodo">

            <h2 className="titleF">Registros de Colaborador</h2>

            <div className="botones-abono">
                <button className="boton-redondo" 
                    onClick={ handleReturn } 
                >
                    <i className="fa-solid fa-circle-arrow-left"></i>
                </button> 
            </div>

            { trasabilidad && (
            <table className="table table-bordered table-dark text-center table-hover">
                <thead>
                    <tr>
                        <th scope="col">Colaborador</th>
                        <th scope="col">Role</th>
                        <th scope="col">Antes</th>
                        <th scope="col">Ahora</th>
                        <th scope="col">Fecha</th>
                        <th scope="col">Opciones</th>
                    </tr>
                </thead>

                <tbody>
                    {
                        trasabilidad.map( t => (
                        <tr key={ t.id } >
                            <td>{ t.colaborador?.nombre }</td>
                            <td>{ t.colaborador?.role }</td>
                            <td>{ t.antes }</td>
                            <td>{ t.ahora }</td>
                            <td>{ t.start }</td>
                            {/* <td>
                              {
                                ( !t.end || t.end === 'Invalid date' )
                                ? <small className="nocomenzo">En Proceso</small>
                                : <small>{ t.end }</small>
                              }
                            </td> */}
                            <td>
                                <button className="boton-redondo" 
                                    onClick={ () => handleEliminar(t) } 
                                >
                                    <i className="fa-solid fa-trash"></i>
                                </button> 
                            </td>
                        </tr>   
                        ))
                    }
                    
                </tbody>
            </table>
            )}

        </div>

  )



}
