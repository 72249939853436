import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { startUploadingUsuarios } from '../../actions/uploads';



const baseUrl = process.env.REACT_APP_API_URL;



export const MostrarImagen = () => {


    const dispatch = useDispatch();
    const { activeUser } = useSelector(state => state.user);



    useEffect(() => {

        if( activeUser ) {

            // const verImagen = () => {
            //     if( !activeUser.img ) {
            //         return `${ baseUrl }/uploads/usuarios/no-img.jpg`;
            //     }else {
            //         return `${ baseUrl }/uploads/usuarios/${ activeUser.img }`;
            //     }
            // }
    
            // console.log(verImagen());
        }
    }, [ activeUser ] );

    



    const handlePictureClick = () => {
        document.querySelector('#fileSelector').click();
    }


    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if( file ) {
            dispatch( startUploadingUsuarios( activeUser.id, 'usuarios', file ) );
        }
    }



    return (

        <div className="imagen-user">


            <div className="contenedor-image">

                {

                ( activeUser )
                    ? <img src={`${ baseUrl }/uploads/usuarios/${ activeUser.img }`} alt="Imagen User" />
                    : <img src={`${ baseUrl }/uploads/usuarios/no-img.jpg`} alt="Imagen User" />
                }


                
            </div>

            <button className="btn botonUpload"
                onClick={ handlePictureClick }
            > 
                Subir Imagen
            </button>

            <input type="file" 
                id="fileSelector"
                className="boton-file"
                onChange={ handleFileChange }
                name="file"
            />

        </div>

        )


}
