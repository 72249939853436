
import { useDispatch, useSelector } from 'react-redux';
import { originalTheme } from '../../../theme';

import { Card, CardActions, CardContent, CardMedia, Button, Typography, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { terminadoActivoDelete } from '../../../actions/producto';


const baseUrl = process.env.REACT_APP_API_URL;
const rolesPermitidos = ['SUPERADMIN', 'ADMIN', 'DISENIO'];



export const CardsProductosVenta = ({ img, nombre, id }) => {


    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { usuario } = useSelector( state => state.admin );
    const { role } = useSelector( state => state.authCliente );



    const eliminarTerminado = () => {
        dispatch( terminadoActivoDelete() );
    }

    
    const handleActualizarProd = () => {
        navigate(`/super/actualizar-prod/${id}`);
    }


    const handleRutaAdmin = () => {
        eliminarTerminado();
        navigate(`/super/detalle-producto/${ id }`);
    }


    const handleRutaCliente = () => {
        eliminarTerminado();
        navigate(`/detalle-producto/${ id }`);
    }



    return (

        <Grid item xs={12} sm={6} md={4} lg={3}>
            <Card sx={{ height: '100%', border:`2px solid ${originalTheme.palette.secondary.main}`, borderRadius:'20px' }}>
                <CardMedia
                    sx={{ height: 200 }}
                    image={`${ baseUrl }/uploads/productos/${ img }`}
                    title={nombre}
                />
                <CardContent>
                    <Typography variant="h4" component="div" sx={{ textAlign:'center' }}>
                        { nombre }
                    </Typography>
                </CardContent>

                <CardActions sx={{ display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center' }}>
                        {    
                        ( usuario && role !== 'CLIENTE' )
                        ?  <Button variant="contained" color="primary" onClick={ handleRutaAdmin }>
                                Ver Detalles
                            </Button>

                        :  <Button variant="contained" color="primary" onClick={ handleRutaCliente }>
                                Ver Detalles
                            </Button>
                        }

                        {
                        ( rolesPermitidos.includes(usuario?.role) ) &&
                        <Grid mt={1}>
                            <Button variant="contained" color="primary" onClick={ handleActualizarProd }>
                                Actu. Producto
                            </Button>
                        </Grid>
                        }
                </CardActions>
            </Card>
        </Grid>



    )



    
}
