

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { carritoListaUnoStart } from "../../../actions/carrito";
import { opPorID } from "../../../actions/op";
import { LayoutReturn } from "../../layouts";
import { TablaModelarItems, DetallesImagenItem, InformacionItemXId } from "../components"







export const DetallesItemProduccion = () => {


    const { id } = useParams();
    const dispatch = useDispatch();
    const { activeCarrito } = useSelector( state => state.carrito );


    useEffect(() => {
        if( id ){
            dispatch(carritoListaUnoStart( id ) );
        }
    }, [id] );


    useEffect(() => {
        if( activeCarrito ) {
            dispatch( opPorID(activeCarrito.op) );
        }
    }, [activeCarrito]);





    return (

        <div>
            <LayoutReturn titulo={'Información General'} url={-1} >

                <InformacionItemXId />
                <TablaModelarItems producto={activeCarrito} />
                <DetallesImagenItem />

            </LayoutReturn>
        </div>
    )
}
