

export const types = {
    // Estado de iva y pantalla de compra segun rol
    ivaTrue: '[iva] Activar Iva',
    viewRol: '[view] Saber Role de View',
    changeSidebar: '[sidebar] Change Sidebar',


    //?UI Estados
     // Servicios de Modal
    openModalForm: '[modal] Open Modal Form',
    closeModalForm: '[modal] Close Modal Form',
    openModalFormDos: '[modal] Open Modal Form Dos',
    closeModalFormDos: '[modal] Close Modal Form Dos',

    // Servicios de OP
    opStartAddNew: '[OP] Crearop Start',
    opAddNew: '[OP] Crearop',
    opListaStart: '[OP]. Listadoop Start',
    opListaStartDistri: '[OP]. Listado op Start Distri',
    opLista: '[OP] Lista op Todos',
    opStartDelete: '[OP] Eliminarop Start',
    opDelete: '[OP] Eliminar op',
    opUpdateStart: '[op] Update op Start',
    opUpdate: '[op] Update op',
    opActivo: '[op] Activar op',
    opDeleteActivo: '[op] Delete op Activo',
    totalOpsList: '[op] Total op list',












    openModalAbonoOp: '[ui] Abrir Modal Op',
    closeModalAbonoOp: '[ui] Cerrar Modal Op',
    openModalAbonoOpCliente: '[ui] Abrir Modal Abono Cliente',
    closeModalAbonoOpCliente: '[ui] Cerrar Modal Abono Cliente',
    
    openModal: '[ui] Abrir Modal',
    closeModal: '[ui] Cerrar Modal',
    openModalImagen: '[ui] Abrir Modal Imagen',
    closeModalImagen: '[ui] Cerrar Modal Imagen',
    openModalAbonos: '[ui] Abrir Modal Abonos',
    closeModalAbonos: '[ui] Cerrar Modal Abonos',
    openModalCliente: '[ui] open Modal Cliente',
    closeModalCliente: '[ui] closeModal Cliente',
    openCrearCliente: '[ui] open Crear Cliente',
    closeCrearCliente: '[ui] close Crear Cliente',


    //Servicios de SlideShow
    slideAddNew: '[slide] Add New',
    slideShowLista: '[SlideShow] Listar SlideShows',
    slideShowDelete: '[SlideShow] Delete SlideShows',
    slideModalOpen: '[SlideShow] Abrir SlideShows',
    slideModalClose: '[SlideShow] Cerrar SlideShows',
    // Servicios de Clientes
    clienteAddNew: '[cliente] Add New',
    clienteLista: '[Usuarios] Listar Clientes',
    clienteUpdate: '[cliente] Actualizar Cliente',
    clienteActivo: '[cliente] Cliente Activo',
    clienteActivoClear: '[cliente] Cliente Activo Eliminar',
    clienteDelete: '[cliente] Eliminar Cliente',
    clienteStartDelete: '[cliente] Eliminar Start Cliente',
    clienteStartUpdate: '[cliente] Update Start Cliente',
    // Servicios de Productos
    productosStartAddNew: '[productos] Crear productos Start',
    productosAddNew: '[productos] Crear productos',
    productosListaStart: '[productos]. Listado Productos Start',
    productosLista: '[productos] Listado productos',
    productosSoloTerminados: '[productos] Listado Productos Terminados',
    productosStartDelete: '[productos] Eliminar productos Start',
    productosDelete: '[productos] Eliminar productos',
    productosActivo: '[productos] Activar productos',
    terminadoActivo: '[productos] Activar Terminado',
    productosDeleteActivo: '[productos] Delete prodcutos Activo',
    productosUpdateStart: '[productos] Update productos Start',
    productosUpdate: '[productos] Update productos',
    productosListaDelete: '[productos] Limpiar lista de productos',
    terminadoActivoDelete: '[terminados] terminadoActivoDelete',

    listadoServicioTecnico: '[productos] Listado Servicio Tecnico',
    listadoPersonalizados: '[productos] Listado Personalizados',
    listadoPublicidad: '[productos] Listado Publicidad',


    // Servicios de Categoria
    categoriasStartAddNew: '[categoria] Crear categoria Start',
    categoriaAddNew: '[categoria] Crear categoria',
    categoriaListaStart: '[categoria]. Listado categoria Start',
    categoriaLista: '[categoria] Listado categoria',
    categoriaStartDelete: '[categoria] Eliminar categoria Start',
    categoriaDelete: '[categoria] Eliminar categoria',
    categoriaActivo: '[categoria] Activar categoria',
    categoriaDeleteActivo: '[categoria] Delete prodcutos Activo',
    categoriaUpdateStart: '[categoria] Update categoria Start',
    categoriaUpdate: '[categoria] Update categoria',


    // Servicios de Usuarios
    usuarioStartAddNew: '[Usuario] Crear Usuario Start',
    usuarioAddNew: '[Usuario] Crear Usuario',
    usuarioListaStart: '[Usuario] Listado Usuarios Start',
    usuarioLista: '[Usuario] Listado Usuarios',
    usuarioStartDelete: '[Usuario] Eliminar Usuario Start',
    usuarioDelete: '[Usuario] Eliminar Usuario',
    usuarioActivo: '[Usuario] Activar Usuario',
    usuarioDeleteActivo: '[Usuario] Delete Usuario Activo',
    usuarioUpdateStart: '[Usuario] Update Usuario Start',
    usuarioUpdate: '[Usuario] Update Usuario',


    // Servicios de Login Clientes
    authClienteChekingFinish: '[Auth] Cheking Cliente Finish Login',
    authClienteChekingLogin: '[Auth] Start Cliente Login',
    authClienteStartTokenRew: '[Auth] Start Cliente Token Renew',
    authClienteLogout: '[Auth] Cliente Logout',


    // Servicios de Login Administradores
    authSuperChekingFinish: '[Auth] Cheking Finish Login',
    authSuperChekingLogin: '[Auth] Start Login',
    authSuperStartTokenRew: '[Auth] Start Token Renew',
    authSuperLogout: '[Auth] Logout',


    // Servicios de Carrito
    carritoAddNewCliente: '[carrito] Crear carrito Cliente',
    carritoAddNew: '[carrito] Crear carrito',
    carritoListaCompra: '[carrito]. Listado carrito al comprar',
    carritoDeleteCompra: '[carrito] Eliminar carrito Start',
    carritoActivoParaUdate: '[carrito] Activar carrito para editar',
    carritoActivo: '[carrito] Activar carrito',
    carritoUpdate: '[carrito] Update carrito',

    itemListaStart: '[item] Listado item',
    itemDeleteStart: '[item] Eliminar item',
    itemUpdateStart: '[item] Update item Start',
    itemCreateStart: '[item] Create item Start',

    // carritoUpdateStart: '[carrito] Update carrito Start',

    carritoListaCliente: '[carrito] Listado carrito Cliente',
    carritoDeleteCliente: '[carrito] Eliminar carrito Cliente',
    carritoUpdateCliente: '[carrito] Update carrito Cliente',
    

     

    // Servicios de Proveedores
    proveedorAddNew: '[proveedor] Crear proveedor',
    proveedorLista: '[proveedor] Lista proveedores Todos',
    proveedorDelete: '[proveedor] Eliminar proveedor',
    proveedorActivo: '[proveedor] Activar proveedor',
    proveedorUpdate: '[proveedor] Editar proveedor',

    // Servicios de Abonos
    abonosAddNew: '[abonos] Crear abonos',
    abonosLista: '[abonos] Lista abonos por op',
    abonosDelete: '[abonos] Eliminar abonos',
    abonosActivo: '[abonos] Activar abonos',
    abonosDeleteActivo: '[abonos] Delete abonos Activo',

    abonosFacturaAddNew: '[abonos] Crear abonos Factura',
    abonosFacturaLista: '[abonos] Lista abonos Factura',
    abonosFacturaDelete: '[abonos] Eliminar abonos Factura',
    abonosFacturaActivo: '[abonos] Activar abonos Factura',
    abonosFacturaDeleteActivo: '[abonos] Delete abonos Activo Factura',

    // Servicios de Trasabilidad
    trasabilidadAddNew: '[trasabilidad] Crear trasabilidad',
    trasabilidadLista: '[trasabilidad] Lista trasabilidad por op',
    trasabilidadDelete: '[trasabilidad] Eliminar trasabilidad',
    trasabilidadUpdate: '[trasabilidad] Update trasabilidad',

    //Servicio de Deuda Cliente
    deudaClienteAddNew: '[deudaCliente] Crear Deuda Cliente',
    deudaClienteLista: '[deudaCliente] Lista Deuda Cliente por Cliente',


    //? Caja Estados
    // Pagos
    pagosInventarioAddNew: '[pagosInventario] Crear pagosInventario',
    pagosInventarioLista: '[pagosInventario] Lista pagosInventario',
    pagosInventarioActivo: '[pagosInventario] Activar pagosInventario',
    
    // Gastos
    gastosAddNew: '[gastos] Crear gastos',
    gastosLista: '[gastos] Lista gastos',
    gastosDelete: '[gastos] Eliminar gastos',
    gastosActivo: '[gastos] Activar gastos',
    // gastosUpdate: '[gastos] Actualizar gastos',

    // Unidades de Medida
    medidasNew: '[unidad medida] Medida Nueva',
    medidasLista: '[unidad medida] Medida Listado',

    // Servicio de Inventario
    inventarioAddNew: '[Add New] Crear Inventario',
    listadoInventario: '[listado Inventario] Listado',
    listadoInventarioDes: '[listado Inventario Des] listadoInventarioDes',
    listadoReporteInventario: '[listado Reportes Inventario] Listado',
    mostrarCantidad: '[Inventario] Mostrar Cantidad',
    quitarCantidad: '[Inventario] Quitar Cantidad',
    inventarioActivo: '[Inventario] Activar Inventario',
    inventarioUpdate: '[Inventario] Actualizar inventario',
    inventarioDeleteActivo: '[inventarioDeleteActivo] Delete Inventario Activo',
    // deleteInventarioDes: '[deleteInventarioDes] Delete Inventario Des',


    // Servicio de Cotizacion
    cotizacionPDF: '[Add New] Informacion Cotizacion',
    listadoCotizacion: '[Listado Cotizacion] Cotizacion',
    deleteCotizacion: '[Delete] Delete Cotizacion',
    activeCotizacion: '[Activar Cotizacion] Cotizacion',
    cotizacionUpdate: '[Update Cotizacion] Cotizacion',
    deleteActiveCotizacion: '[Delete Activar Cotizacion] Cotizacion',


    
    // Servicio
    servicioAddNew: '[servicio] Crear servicio',
    servicioUpdate: '[servicio] Actualizar servicio',
    servicioLista: '[servicio] Lista servicio',
    servicioDelete: '[servicio] Eliminar servicio',
    servicioActivo: '[servicio] Activar servicio',

} 