import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom";

import { DetallesDeOp, ModalAbonosOP, TablaAbonosAOP } from "../components";
import { ModalImagenAbonos } from "../../ui/ModalImagenAbonos";

import { abonoListaStart, abonosDeleteActivo } from "../../../actions/abono";
import { opPorID } from "../../../actions/op";
import { startUploadingPagos } from "../../../actions/uploads";
import { LayoutReturnAndAdd } from "../../layouts";
import { openModalAbonosOp } from "../../../actions";





export const AbonosDeOpNueva = () => {


    const { id } = useParams();
    const dispatch = useDispatch();
    const { activeAbono } = useSelector( state => state.abono );
    const { activeOP } = useSelector( state => state.op );



    useEffect(() => {
        if( id ){
            dispatch( abonoListaStart(id) );
        }
    }, [dispatch, id] );



    useEffect(() => {
        if( id ) {
            dispatch(opPorID( id ));
        }
    }, [id, dispatch] );




    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if( file ) {
            dispatch( startUploadingPagos( activeAbono.id, 'abonos', file ) );
            dispatch(abonosDeleteActivo() );
        }
    }



    const crearAbono = () => {
        dispatch(openModalAbonosOp() );
    }




    return (

        // <LayoutAbonosOp>

        <LayoutReturnAndAdd titulo={`Abonos Realizados a OP: ${activeOP?.op}`} url={-1} funcion={crearAbono}>

            <input type="file" id="fileSelector"
                className="boton-file" onChange={ handleFileChange } name="file"
            />

            <DetallesDeOp />
            <TablaAbonosAOP />
            <ModalImagenAbonos />
            <ModalAbonosOP />

        </LayoutReturnAndAdd>
        
            


        
        // </LayoutAbonosOp>


    )

}
