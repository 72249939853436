
import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from "@mui/material"



export const AutocompleteObjeto = ({ label, options=[], onChange, bloqueo=false, value, ...props }) => {



    return (
        <>
            <Autocomplete
                disabled={bloqueo}
                value={value}
                options={ options }
                onChange={ (event, newValue) => onChange(event, newValue)}
                renderInput={(params) => <TextField
                    disabled={bloqueo}
                    {...params} 
                    label={ label }  
                    { ...props }
                    autoComplete="off"
                    fullWidth
                />}
                getOptionLabel={(option) => option.nombre }
            />
        </>
    )
}


