import { fetchConToken } from "../helpers/fetch";
import { types } from "../types/types";

import Swal from "sweetalert2";






const proveedorAddNew = ( proveedor ) => ({
    type: types.proveedorAddNew,
    payload: proveedor
});


const proveedorLista = ( provedores ) => ({
    type: types.proveedorLista,
    payload: provedores 
});


const proveedorDelete = ( proveedor ) => ({ 
    type: types.proveedorDelete,
    payload: proveedor
});


const proveedorActivo = ( proveedor ) => ({ 
    type: types.proveedorActivo,
    payload: proveedor
});


const proveedorUpdate = ( proveedor ) => ({ 
    type: types.proveedorUpdate,
    payload: proveedor
});






export const proveedorStartAddNew = ( proveedor ) => {
    return async( dispatch ) =>{
        try {
            const resp = await fetchConToken( 'proveedor/new', proveedor, 'POST' );
            const body = await resp.json();

            if( body.ok ) {
                Swal.fire('Exitoso', 'Proveedor Creado', 'success');
                dispatch( proveedorAddNew(body.data) );
                return true;
            } else {
                Swal.fire('Error', body.msg, 'error');
                return false;
            } 
        } catch (error) {
            console.log(error);
            return false;
        }
    }
}




export const proveedorListaStart = () => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken('proveedor/todos');
            const body = await resp.json();
            if( body.ok ){
                const provedores = body.proveedor;
                dispatch( proveedorLista(provedores) );
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            console.log(error);
        }
    }
}



export const proveedorStartDelete = (id) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken(`proveedor/${ id }`, {}, 'DELETE');
            const body = await resp.json();
            if( body.ok ){
                dispatch( proveedorDelete(body.data) );
                Swal.fire('Exitoso', 'Proveedor Eliminado', 'success');
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            console.log(error);
        }
    }
}




export const proveedorStartActivo = (id) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken(`proveedor/proveedor/${ id }`);
            const body = await resp.json();
            if( body.ok ){
                dispatch( proveedorActivo(body.data) );
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            console.log(error);
        }
    }
}


export const proveedorStartUpdate = (proveedor) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken(`proveedor/${ proveedor.id }`, proveedor, 'PUT');
            const body = await resp.json();
            if( body.ok ){
                dispatch( proveedorUpdate(body.data) );
                Swal.fire('Exitoso', 'Proveedor Actualizado', 'success');
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            console.log(error);
        }
    }
}


