import { types } from "../types/types";




const initialState = {
    pagosinventario: [ ],
    activePagoInventario: null
};





export const pagoInventarioReducer = ( state = initialState, action ) => {

    switch ( action.type ) {

        case types.pagosInventarioAddNew:
            return {
                ...state,
                pagosinventario: [ ...state.pagosinventario, action.payload ]
            };


        case types.pagosInventarioLista:
            return {
                ...state,
                pagosinventario: [ ...action.payload ]
            };


        case types.pagosInventarioActivo:
            return {
                ...state,
                activePagoInventario: action.payload
            };
            

        case types.pagosInventarioActivoDelete:
            return {
                ...state,
                activePagoInventario: null
            };
            

        default:
            return state;
    }

}
