
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Avatar, Box, Checkbox, TableCell, TableRow, TextField, Typography } from "@mui/material";
import { separadorMillares, usePrecioSegunRol } from "../../../hooks";
import { updateCarritoCotizacion } from "../../../actions/carrito";
import { TextFormField, VerPrecioTotalCarrito } from "../../components";


import moment from "moment";
const baseUrl = process.env.REACT_APP_API_URL;





export const TablaCotizacionEditar = ({ selected, handleClick }) => {


    const dispatch = useDispatch();
    const nombredRef = useRef();
    const debounceRef = useRef();

    const { carritoCompra } = useSelector( state => state.carrito );
    const { precioSegunRol } = usePrecioSegunRol();

    const [items, setItems] = useState([])
    const [observacion, setObservacion] = useState(localStorage.getItem('observacion') || '')
    const fecha = moment().add(8, 'days').format('D/MM/YYYY');



    useEffect(() => {
        if( debounceRef.current )
            clearTimeout( debounceRef.current );
        debounceRef.current = setTimeout( () => {
            localStorage.setItem('observacion', observacion);
        }, 650);
    }, [observacion])



    useEffect(() => {
        if(carritoCompra && carritoCompra.length > 0){
            const newItems = carritoCompra.map( item => {
                if (!item.nombreModificado) {
                    item.nombreModificado = item.producto.nombre           
                }
                return item
            })
            setItems([ ...newItems ])
        }
    }, [])
    



    const handleObservacion = (e) => {
        setObservacion(e.target.value);
        if( debounceRef.current )
            clearTimeout( debounceRef.current );
        debounceRef.current = setTimeout( () => {
            localStorage.setItem('observacion', observacion);
        }, 600);
    }



    const cambiarNombreProduc = (e, id) => {
        const nombre = e.target.value;
        const pedidoActualizado = items.map( (p) => {
            if( p.id === id ){
                p = { ...p, nombreModificado: nombre }
                
                if( nombredRef.current ) clearTimeout( nombredRef.current );
                nombredRef.current = setTimeout( () => {
                    dispatch(updateCarritoCotizacion(p))
                }, 600);
            }
            return p;
        });
        setItems(pedidoActualizado);
    }



    return (

        <>
        {items.map( car => (
            <TableRow key={ car.id } onClick={ () => handleClick(car.id)}>
                <TableCell padding="checkbox">
                    <Checkbox color="primary" checked={selected.some( (item) => item === car.id )} />
                </TableCell>

                <TableCell sx={{ display:'flex', justifyContent:'center' }}>
                    <Avatar
                        alt="Imagen de Producto"
                        src={`${ baseUrl }/uploads/productos/${ car.producto.img }`} 
                        sx={{ width: 50, height: 50 }}
                    /> 
                </TableCell>

                <TableCell className="w-300">
                    <Box className="input-carrito">
                        <TextFormField
                            sx={{ width:'100%'}}
                            size="small"
                            multiline
                            value={ car.nombreModificado }
                            onChange={ (e) => cambiarNombreProduc( e, car.id ) } 
                            onClick={(e) => e.stopPropagation()}
                        />
                    </Box>
                </TableCell>

                <TableCell>{ car.cantidad }</TableCell>

                <TableCell>{`$${separadorMillares(precioSegunRol(car) / car.cantidad)}`}</TableCell>

                <TableCell> 
                    <Typography variant="span">
                        {`$${separadorMillares(precioSegunRol(car))}`} 
                    </Typography>
                </TableCell>
            </TableRow>
        ))}

            <TableRow>
                <TableCell colSpan="3">
                    <Typography variant="span">
                        { `OBSERVACIONES: Cotización valida hasta el ${ fecha }` }
                    </Typography>
                </TableCell>
                <TableCell ></TableCell>

                <TableCell rowSpan={2}><Typography variant="span">TOTAL</Typography></TableCell>
                <TableCell rowSpan={2}>
                    <VerPrecioTotalCarrito mostrar={false} />
                </TableCell>
                </TableRow>

                <TableRow >
                <TableCell colSpan="3">
                    <Typography variant="span">
                        Todos los valores descritos en esta cotización estan antes de IVA.
                    </Typography>
                </TableCell>
                <TableCell ></TableCell>
            </TableRow>

            <TableRow >
                <TableCell colSpan="2">
                    <Typography variant="span">
                        Nota
                    </Typography>
                </TableCell>

                <TableCell colSpan="4">
                    <TextField
                        name="observacion"
                        value={observacion}
                        size="small"
                        variant="standard"
                        placeholder="¿Alguna Observación?"
                        multiline
                        fullWidth
                        onChange={ handleObservacion }
                    />
                </TableCell>
            </TableRow>
        </>
    )
}
