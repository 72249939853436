
import { useState } from "react";
import { useDispatch } from "react-redux";

import { Box, Button, Grid,  TextField } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { busquedaEnComparativa } from "../../../actions/gastos";

import moment from "moment";


const now = moment().hours(0).minutes(0).seconds(0);
const nowPlus1 = now.clone().add(1, 'days').subtract(1, 'seconds');





export const BusquedaFechaComparativa = () => {


    const dispatch = useDispatch();


    const [formValues, setFormValues] = useState({
        start: now.toDate(),
        end: nowPlus1.toDate(),
    });
    const { start, end } = formValues;



    const handleChangeStart = (e) => {
        setFormValues({
            ...formValues,
            start: e.$d
        });
    }

    const handleChangeEnd = (e) => {
        const resultado = moment(e.$d).hours(0).minutes(0).seconds(0);
        setFormValues({
            ...formValues,
            end: resultado.clone().add(24, 'hours').subtract(1, 'seconds').toDate(),
        });
    } 



    const handleConsultaFacturas = (e) => {
        e.preventDefault();
        dispatch( busquedaEnComparativa(formValues) );
    }


    return (


        <Grid container direccion='row'
            spacing={2} alignItems='center' my={5} justifyContent='center'
        >
            <Grid item xs={ 12 } sm={6} md={4} >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label="Fecha de Inicio"
                        value={ start }
                        onChange={ handleChangeStart }
                        renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                </LocalizationProvider>
            </Grid>

            <Box flex={ 1 } />

            <Grid item xs={ 12 } sm={6} md={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label="Fecha Final"
                        value={ end }
                        onChange={ handleChangeEnd }
                        minDate={ start }
                        
                        renderInput={(params) => <TextField {...params} fullWidth /> }
                    />
                </LocalizationProvider>
            </Grid>

            <Box flex={ 1 } />



            <Grid item xs={ 12 } sm={6} md={4} my={3}>
                <Button variant="contained" color="secondary" fullWidth onClick={ handleConsultaFacturas } >
                    Consultar
                </Button>
            </Grid>

        </Grid>



    )



    
}
