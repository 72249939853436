

import { useDispatch } from "react-redux";
import { LayoutReturnArchivados } from "../../layouts"
import { TablaMisOpComoDistri, TablaOpsMisClientes } from "../components";

import { opListaStartDistriPropia, opsDeMisClientes } from "../../../actions/op";
import { useEffect } from "react";





export const MisPedidos = () => {


    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(opListaStartDistriPropia() );
    }, [dispatch] );


    useEffect(() => {
        dispatch(opsDeMisClientes() );
    }, [dispatch] );




    return (

        <LayoutReturnArchivados titulo='Mis Pedidos' url='/super'>

            <TablaMisOpComoDistri />


            <TablaOpsMisClientes />


        </LayoutReturnArchivados>
    )

}
