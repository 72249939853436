
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { BuscadorFechas, TablaPagosInventario } from "../components";
import { LayoutCrearDoc } from "../../layouts";
import { pagoListaXFiltroFecha } from "../../../actions/pagosInventario";


import moment from "moment";
const now = moment().hours(0).minutes(0).seconds(0);
const nowPlus1 = now.clone().add(1, 'days').subtract(1, 'seconds');





export const PagosScreen = () => {

    
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const [formValues, setFormValues] = useState({
        start: now.toDate(),
        end: nowPlus1.toDate(),
    });
    const { start, end } = formValues;



    const handleChangeStart = (e) => {
        setFormValues({
            ...formValues,
            start: e.$d
        });
    }

    const handleChangeEnd = (e) => {
        const resultado = moment(e.$d).hours(0).minutes(0).seconds(0);
        setFormValues({
            ...formValues,
            end: resultado.clone().add(24, 'hours').subtract(1, 'seconds').toDate(),
        });
    } 


    const handleConsultaFacturas = (e) => {
        e.preventDefault();
        dispatch( pagoListaXFiltroFecha(formValues) );
    }



    const handleCrearPagos = () => {
        navigate(`/super/pagos/create`)
    } 




    return (

        <LayoutCrearDoc titulo='Pagos Materia Prima' funcion={ handleCrearPagos }>

            <BuscadorFechas 
                start={start}
                end={end}
                handleChangeStart={handleChangeStart}
                handleChangeEnd={handleChangeEnd}
                funcion={ handleConsultaFacturas }
            />


            <TablaPagosInventario />

        </LayoutCrearDoc>

    )

}
