
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Checkbox, Fab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { red } from "@mui/material/colors";
import DeleteIcon from '@mui/icons-material/Delete';

import { carritoDeleteMultiple } from "../../../actions/carrito";
import { TablaCotizacionSinEditar } from "./TablaCotizacionSinEditar";
import { TablaCotizacionEditar } from "./TablaCotizacionEditar";

import Swal from "sweetalert2";





export const TablaCotizacionCliente = () => {


    const dispatch = useDispatch();
    const rolesPermitidos = ['SUPERADMIN', 'ADMIN']
    const { role } = useSelector( state => state.admin );
    const { carritoCompra } = useSelector( state => state.carrito );

    const [selected, setSelected] = useState([]);



    const handleSelectAllClick = ( event ) => {
        if (event.target.checked) {
            const newSelected = carritoCompra.map( (p) => p.id );
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };



    const handleClick = (name) => {
        (selected.some((item) => item === name)) 
        ?   setSelected((prevSelected) => prevSelected.filter((item) => item !== name))
        :   setSelected((prevSelected) => [...prevSelected, name])
    };



    const  eliminarMultiple = async () => {
        const resp = await Swal.fire({
            title: ( selected.length > 1 ) ? 'Eliminar Items' : 'Eliminar Item',
            text: ( selected.length > 1 ) ? `¿Esta Seguro De Eliminar Estos Items?` : `¿Esta Seguro De Eliminar Este Item?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si, Eliminar' 
        });
        if(resp.value){
            const result = await dispatch(carritoDeleteMultiple( selected ));
            if( result ) {
                setSelected([]);
            }
        }
    }



    return (

        <>
            <TableContainer>
                <Table sx={{ minWidth:750 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox">
                                <Checkbox
                                    color="secondary"
                                    indeterminate={ selected.length > 0 && selected.length < carritoCompra.length }
                                    checked={ carritoCompra.length > 0 &&  selected.length === carritoCompra.length }
                                    onChange={ handleSelectAllClick }
                                />
                            </TableCell>
                            <TableCell>PRODUCTO</TableCell>
                            <TableCell>DESCRIPCIÓN</TableCell>
                            <TableCell>CANTIDAD</TableCell>
                            <TableCell>PRECIO UNI.</TableCell>
                            {/* {
                            ( role !== 'SUPERADMIN' ) &&
                            <TableCell>DESCUENTO</TableCell>
                            } */}
                            <TableCell>PRECIO TOTAL</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        { ( rolesPermitidos.includes(role) )
                            ? <TablaCotizacionEditar selected={selected} handleClick={handleClick} />
                            : <TablaCotizacionSinEditar selected={selected} handleClick={handleClick} />
                        }
                    </TableBody>
                </Table>
            </TableContainer>


            {(selected.length > 0) &&
            <Box sx={{ position:'fixed', bottom:20, right:20, zIndex:9999 }} >
                <Fab sx={{ bgcolor: red[500] }} onClick={ eliminarMultiple }>
                    <DeleteIcon sx={{fontSize: 35, color: 'white'}} />
                </Fab>
            </Box>
            }
        
        </>
    )
}
