
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom";

import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import { MensajeAlertaDanger } from "../../components";

import { separadorMillares } from "../../../hooks/useMillares";

import { openModalAbonosOpCliente } from "../../../actions";
import { ModalAbonoOpCliente } from "./ModalAbonoOpCliente";
import { opStartActivo } from "../../../actions/op";




export const TablaOpsPorCliente = () => {


    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { op } = useSelector(state => state.op);

        const handleDebitar = async(op) => {
            dispatch(opStartActivo(op));
            dispatch(openModalAbonosOpCliente());
        }

    
    // const handleDebitar = async(op) => {
    //     const { value: debitar } = await Swal.fire({
    //         title: 'Ingresar Valor',
    //         icon: 'question',
    //         input: 'number',
    //         inputPlaceholder: 'Valor A Debitar'
    //     })
    //     if (debitar) {
    //         Swal.fire({
    //             title: 'Confirmar Valor',
    //             text: `$${ separadorMillares( Number(debitar) ) }`,
    //             icon: 'question',
    //             showCancelButton: true,
    //             confirmButtonText: 'Si, Seguro' 
    //         }).then( ( result ) => {
    //             if(result.value){
    //                 dispatch(debitarClienteStartNew({
    //                     debitar,
    //                     start: moment().toDate(),
    //                     uid: op.id
    //                 }));
    //             }
    //         })
    //     }
    // }


    const handleVerRegistros = (op) => {
        navigate(`/super/deuda-cliente/${ op.id }`);
    }



    
    return (

        <>
            { (op.length > 0)  
            ? (<Paper sx={{ width: '100%' }}>
                <TableContainer sx={{ my:'15px' }}>
                    <Table sx={{ minWidth: 700, }} aria-label="customized table">

                        <TableHead >
                            <TableRow>
                                <TableCell>O.P.</TableCell>
                                <TableCell className="w-250">Estado</TableCell>
                                <TableCell>TotalSinIva</TableCell>
                                <TableCell>Iva</TableCell>
                                <TableCell>TotalConIva</TableCell>
                                <TableCell>Deuda</TableCell>
                                <TableCell className="w-200">Acciones</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {
                            op.map( (op) => (
                            <TableRow  key={ op.id }>

                                <TableCell>{ op.op }</TableCell>
                                <TableCell>{ op.estado }</TableCell>
                                <TableCell>{ `$${separadorMillares(op.totalCliente - op.iva)}` }</TableCell>
                                <TableCell>{ `$${separadorMillares(op.iva)}` }</TableCell>
                                <TableCell>{ `$${separadorMillares((op.totalCliente))}` }</TableCell>
                                <TableCell>{ `$${ separadorMillares(op.deuda)}` }</TableCell>

                                <TableCell>

                                    <IconButton className="icon-boton"  onClick={ () => handleDebitar(op) } >
                                        <CurrencyExchangeIcon fontSize="small" />
                                    </IconButton>
                                    
                                    <IconButton className="icon-boton"  onClick={ () => handleVerRegistros(op) } >
                                        <RequestQuoteIcon fontSize="small" />
                                    </IconButton>
                                    
                                </TableCell>

                            </TableRow>
                            ))
                            }
                        </TableBody>

                    </Table>
                </TableContainer>
            </Paper>
            )

            : ( <MensajeAlertaDanger titulo={'Este Cliente No Tiene Ops Pendientes'} /> )

            }

            <ModalAbonoOpCliente />

        </>

    )


    
}
