
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Avatar, Box, Button, Grid, TextField } from '@mui/material';
import { originalTheme } from '../../../theme';

import { startUploadingUsuariosLogin } from "../../../actions/uploads";
import { LayoutReturn } from "../../layouts";


const baseUrl = process.env.REACT_APP_API_URL;




export const MiPerfilUsuario = () => {


    const dispatch = useDispatch();
    const { usuario }  = useSelector( state => state.admin );


    
    const [formUser, setFormUser] = useState({
        nombre: "",
        cedula: "",
        direccion: "",
        ciudad: "",
        movil: "",
    });
    const  { nombre, cedula, direccion, ciudad, movil } = formUser;


    const handleInputChange = ({ target }) => {
        setFormUser({
            ...formUser,
            [target.name]: target.value
        });
    }


    useEffect(() => {
        if(Object.entries(usuario).length > 0){
            setFormUser({ ...usuario });
        }
    }, [usuario]);
    


    // const handleUpdateUser = async(e) => {
    //     e.preventDefault();
    //     await Swal.fire({
    //         title: 'Acualizar Mi Perfil',
    //         text: `¿Esta Seguro De Actualizar Ahora?`,
    //         icon: 'question',
    //         showCancelButton: true,
    //         confirmButtonText: 'Si, Actualizar' 
    //     }).then( ( result ) => {
    //         if(result.value){
    //             startSavingUsuario( formUser );
    //         }
    //     });
    // }


    const handlePictureClick = () => {
        document.querySelector('#fileSelector').click();
    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if( file ) {
            dispatch( startUploadingUsuariosLogin( usuario.id, 'usuarios', file ) );
        }
    }

    return (

        <LayoutReturn titulo="Mi Perfil" url='/super' >


        { usuario && (
        <>

            <Box my={4} onClick={ handlePictureClick } >
                {/* <img src={`${ baseUrl }/uploads/usuarios/${ usuario.img }`} alt="Imagen User" /> */}
                <Avatar alt="Imagen User" src={`${ baseUrl }/uploads/usuarios/${ usuario.img }`} 
                    sx={{ width: 300, height: 300, border:`2px solid ${originalTheme.palette.primary.main}` }}
                />
            </Box>


            <input type="file" 
                id="fileSelector"
                className="boton-file"
                onChange={ handleFileChange }
                name="file"
            />


        </>

        )}


            <form >

                {/* <Grid container 
                    sx={{ my:2 }} 
                    direccion='column'
                    justifyContent='end' 
                >
                    <Grid item xs={12} sm={6} md={4} >
                        <Button variant='contained' fullWidth type='submit'>
                            Actualizar Perfil
                        </Button>
                    </Grid>
                </Grid> */}


                <Grid container spacing={2} my={3}>

                    <Grid item xs={12} sm={6} md={4}>
                        <TextField required disabled
                            variant="filled"
                            label='Nombre Completo'
                            type='text'
                            placeholder='Nombre Completo'
                            fullWidth
                            name='nombre'
                            value={ nombre }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <TextField required disabled
                            variant="filled"
                            label='Cedula'
                            type='text'
                            placeholder='Cedula'
                            fullWidth
                            name='cedula'
                            value={ cedula }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} >
                        <TextField required disabled
                            variant="filled"
                            label='Direccion'
                            type='text'
                            placeholder='Direccion'
                            fullWidth
                            name='direccion'
                            value={ direccion }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} >
                        <TextField required disabled
                            variant="filled"
                            label='Ciudad'
                            type='text'
                            placeholder='Ciudad'
                            fullWidth
                            name='ciudad'
                            value={ ciudad }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <TextField required disabled
                            variant="filled"
                            label='Telefono'
                            placeholder='Telefono'
                            fullWidth
                            name='movil'
                            value={ movil }
                            onChange={ handleInputChange }
                        />
                    </Grid>
                    
                </Grid>

            </form>


        </LayoutReturn>


    )

    
}
