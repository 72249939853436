
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Button, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { MensajeAlertaDanger } from "../../components";
import { separadorMillares } from "../../../hooks";
import { updateEstadoActivoCliente } from "../../../actions/op";
import Swal from "sweetalert2";





export const TablaOpsCliente = () => {



    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { op } = useSelector( state => state.op );


    const handleTrayectoria = (op) => {
        navigate(`/detallesop/${op.id}`);
    }


    const handleTrueFalse = (op) => {
        Swal.fire({
            title: (op.activoCliente) ? 'Archivar O.P.' : 'Activar O.P.',
            text: (op.activoCliente) ? `¿Esta Seguro De Archivar Esta O.P.?` : `¿Esta Seguro De Activar Esta O.P.?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: (op.activoCliente) ? 'Si, Archivar' : 'Si, Activar'
        }).then( ( result ) => {
            if(result.value){
                dispatch( updateEstadoActivoCliente(op.id) );
            }
        });
    }


    

    return (

        <Grid container textAlign='center' justifyContent='center' my={2}>

        {
          ( op.length > 0 ) 
          ?<TableContainer sx={{ mb:5 }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
            
                  <TableHead >
                      <TableRow >
                        <TableCell>A.</TableCell>
                        <TableCell>O.P.</TableCell>
                        <TableCell>PAGO</TableCell>
                        <TableCell>ESTADO</TableCell>
                        <TableCell>DEUDA</TableCell>
                        <TableCell className="w-200">ACCION</TableCell>
                      </TableRow>
                  </TableHead>

                    <TableBody>
                    {op.map( a => (
                        <TableRow key={a.id} >

                            <TableCell >
                                {
                                ( a.activoCliente )
                                ? <IconButton
                                    className='boton-estado trru'
                                    onClick={ () => handleTrueFalse(a) }
                                />
                                : <IconButton 
                                    className='boton-estado fall'
                                    onClick={ () => handleTrueFalse(a) }
                                />
                                }
                            </TableCell>

                            <TableCell >
                                <Typography variant="span">{ a.op }</Typography>
                            </TableCell>

                            <TableCell >
                                {
                                (a.pago === 'PENDIENTE') &&
                                <Button variant="outlined" size="small" color="error" sx={{ color:'red' }}>{a.pago}</Button>
                                }
                                {
                                (a.pago === 'REALIZADO') &&
                                <Button variant="outlined" size="small" color="success" sx={{ color:'green' }}>{a.pago}</Button>
                                }
                                {
                                (a.pago === 'CREDITO') &&
                                <Button variant="outlined" size="small" color="info" sx={{ color:'skyblue' }}>{a.pago}</Button>
                                }
                            </TableCell>

                            <TableCell >
                                <Typography variant="span">{ a.estado }</Typography>
                            </TableCell>

                            
                            <TableCell >
                                <Typography variant="span">{ `$${separadorMillares(a.deuda)}` }</Typography>
                            </TableCell>

                            <TableCell >
                                <IconButton className="icon-boton" onClick={ () => handleTrayectoria(a) }>
                                    <SearchIcon fontSize="small" />
                                </IconButton>
                            </TableCell>

                        </TableRow>
                        ))
                    }
                    </TableBody>
                </Table>
            </TableContainer>
        
            : <MensajeAlertaDanger titulo={'No Hay OPS Tuyas En El Momento'} />
        }

    </Grid>
        
    )

}
