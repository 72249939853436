


import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Alert, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Stack,  Typography, IconButton } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';

import { MensajeAlertaDanger } from "../../components";
import { cambioEstaUsuario } from "../../../actions/usuario";

import Swal from "sweetalert2";



export const TablaListadoUsuarios = () => {


    
    const { usuarios } = useSelector(state => state.user);
    const navigate = useNavigate();
    const dispatch = useDispatch();


    const [page, setPage] = useState(0);
    const [ rowsPerPage ] = useState(50);

    const handleChangePage = (event, newPage) => {
        setPage( newPage );
    };



    const updateUser = (e) => {
        return navigate(`/super/actualizar-user/${ e.id }`) 
    }


    // const handleEliminar = ( user ) => {
    //     Swal.fire({
    //         title: 'Eliminar Usuario',
    //         text: `¿Esta Seguro De Borrar A ${ user.nombre }?`,
    //         icon: 'question',
    //         showCancelButton: true,
    //         confirmButtonText: 'Si, Eliminar' 
    //     }).then( ( result ) => {
    //         if(result.value){
    //             dispatch( usuarioStartDelete(user) );
    //         }
    //     })
    // }


    // const cambioClave = async(a) => {
    //     const { value: password } = await Swal.fire({
    //         title: 'Cambiar Password',
    //         icon: 'question',
    //         input: 'text',
    //         inputPlaceholder: 'Ingrese Password'
    //     })
    //     if (password) {
    //         Swal.fire({
    //             title: 'Confirmar Password',
    //             text: `${password}`,
    //             icon: 'question',
    //             showCancelButton: true,
    //             confirmButtonText: 'Si, Seguro' 
    //         }).then( ( result ) => {
    //             // if(result.value){
    //             //     dispatch(cambiarPasswordPorElAdminCliente({
    //             //         password,
    //             //         uid: a.id
    //             //     }));
    //             // }
    //         })
    //     }
    // } 


    const handleTrueFalse = (user) => {
        Swal.fire({
            title: 'Confirmar Cambio de Estado',
            text: (user.activo) ? `Desea Bloquear Este Usuario` : 'Desea Activar Este Usuario',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si, Seguro' 
        }).then( ( result ) => {
            if(result.value){
                dispatch(cambioEstaUsuario(user));
            }
        })
    }



    return (

        <>
            {
            ( usuarios.length > 0 )
            ?<Paper sx={{ width: '100%' }}>
                <TableContainer sx={{ my:'15px' }}>
                    <Table sx={{ minWidth: 700, }} aria-label="customized table">

                        <TableHead >
                            <TableRow>
                                <TableCell>A.</TableCell>
                                <TableCell scope="col">Nombre</TableCell>
                                <TableCell scope="col">Ciudad</TableCell>
                                <TableCell scope="col">Movil</TableCell>
                                <TableCell scope="col">Profesion</TableCell>
                                <TableCell scope="col">Rol</TableCell>
                                <TableCell>Acciones</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {
                            usuarios
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map( (user) => (
                            <TableRow  key={ user.id }>

                                    <TableCell >
                                    {
                                        ( user.activo )
                                        ? <IconButton 
                                            className='boton-estado trru'
                                            onClick={ () => handleTrueFalse(user) }
                                        />
                                        : <IconButton 
                                            className='boton-estado fall'
                                            onClick={ () => handleTrueFalse(user) }
                                        />
                                    }
                                    </TableCell>
                                <TableCell >
                                    <Typography variant="span" >{ user.nombre }</Typography>
                                </TableCell>
                                <TableCell>
                                    {
                                    ( user.ciudad )
                                    ?   <Typography variant="span" >{ user.ciudad }</Typography>
                                    :   <Typography variant="span" color='error'>No Hay Datos</Typography>
                                    }
                                </TableCell>
                                <TableCell>
                                    {
                                    ( user.movil )
                                    ?   <Typography variant="span" >{ user.movil }</Typography>
                                    :   <Typography variant="span" color='error'>No Hay Datos</Typography>
                                    }
                                </TableCell>
                                <TableCell>
                                    {
                                    ( user.profesion )
                                    ?   <Typography variant="span" >{ user.profesion }</Typography>
                                    :   <Typography variant="span" color='error'>No Hay Datos</Typography>
                                    }
                                </TableCell>
                                <TableCell >
                                    <Typography variant="span" >{ user.role }</Typography>
                                </TableCell>
                                <TableCell>

                                    <IconButton className="icon-boton" onClick={ () => updateUser(user) } >
                                        <EditIcon fontSize="small" />
                                    </IconButton>

                                    {/* <IconButton className="icon-boton" onClick={ () => handleEliminar(user) } >
                                        <DeleteIcon fontSize="small" />
                                    </IconButton> */}

                                    {/* <IconButton className="icon-boton" onClick={ () => cambioClave(cli) } >
                                        <LockResetIcon fontSize="small" />
                                    </IconButton> */}
                                </TableCell>
            
                            </TableRow>
                            ))
                            }
                        </TableBody>

                    </Table>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[ 50 ]}
                    component="div"
                    count={ usuarios.length }
                    rowsPerPage={ rowsPerPage }
                    page={ page }
                    onPageChange={ handleChangePage }
                    // onRowsPerPageChange={handleChangeRowsPerPage}
                />

            </Paper>

            : <MensajeAlertaDanger titulo='No hay Usuarios Disponibles' />
            
            }

        </>



    )



    
}
