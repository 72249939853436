
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { clienteListaStart } from "../../../actions/cliente";
import { LayoutReturn } from "../../layouts"
import { BuscarCliente, TablaListadoClientes } from "../components"








export const ListadoClientesArchivados = () => {

    
    const dispatch = useDispatch();

    useEffect( () => {
        dispatch( clienteListaStart(false) );
    }, [ dispatch ] );





  return (

    <LayoutReturn titulo={'Listado Clientes Archivados'} url='/super/listadoClientes'>

        <BuscarCliente activo={false}/>

        <TablaListadoClientes />

    </LayoutReturn>


  )
}
