
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,  Typography, IconButton, Button, Tooltip, Stack } from "@mui/material";
import { BackdropScreen, MensajeAlertaDanger, TooltipAndIcon } from "../../components";
import { deleteCotizacion, generarPDfCotizacion } from "../../../actions/cotizacion";
import { descargarArchivo } from "../../../helpers/descargarArchivo";

import moment from "moment";
import Swal from "sweetalert2";




export const TablaListadoCotizaciones = () => {


    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { cotizaciones } = useSelector(state => state.cotizacion);

    const [cargando, setCargando] = useState(false)




    const handleUploadCoti = async (cotizacion) => {
        setCargando(true);
        const resp = await dispatch(generarPDfCotizacion({
            view: cotizacion.tipoRolRef,
            observacion: cotizacion.observacion,
            usuario: cotizacion.usuario, 
            items: cotizacion.items,
            fechaFactura: cotizacion.fechaFactura,
            formaPago: cotizacion.formaPago,
        }));
        descargarArchivo(resp.data);
        setCargando(false);
    }



    const handleActualizar = (uid) => {
        navigate(`/super/cotizacion/actu/${uid}`);
    }



    const handleEnviarAlCarrito = async (uid) => {
        navigate(`/super/modificar/coti/${uid}`);
    }



    const hendleDelete = async(uid) => {
        const resp = await Swal.fire({
            title: 'Eliminar Cotización',
            text: `¿Esta Seguro De Eliminar Esta Cotización?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si' 
        });

        if(resp.value){
            await dispatch(deleteCotizacion(uid));
        }
    }




    return (

        <>
            <BackdropScreen cargando={cargando} titulo='Espere Por Favor Estamos Generando Su Cotización...' />

            {
            ( cotizaciones.length > 0 )
            ?<Paper sx={{ width: '100%', my:4 }}>
                <TableContainer sx={{ my:'15px' }}>
                    <Table sx={{ minWidth: 700, }}>

                        <TableHead >
                            <TableRow>
                                <TableCell>Cotizacion</TableCell>
                                <TableCell>Fecha-Factura</TableCell>
                                <TableCell>Estado</TableCell>
                                <TableCell>Rol</TableCell>
                                <TableCell>Usuario</TableCell>
                                {/* <TableCell>Forma-Pago</TableCell> */}
                                <TableCell># Items</TableCell>
                                <TableCell>Acciones</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {cotizaciones.map( (co) => (
                            <TableRow  key={ co._id }>  
                                <TableCell >{ co.factura }</TableCell>
                                <TableCell >
                                    <Typography variant="span" >{ moment(co.fechaFactura).format('ll')  }</Typography>
                                </TableCell>
                                <TableCell>
                                    {
                                    ( co.estado === 'ACTIVA' )
                                    ?   <Button variant="contained" color="success">{co.estado}</Button>
                                    :   <Button variant="contained" color="error">{co.estado}</Button>
                                    }
                                </TableCell>
                                <TableCell>
                                    <Typography variant="span" >{ co.tipoRol }</Typography>
                                </TableCell>
                                <TableCell>{co.usuario?.nombre}</TableCell>
                                {/* <TableCell>{co.formaPago}</TableCell> */}
                                <TableCell>{co.items.length}</TableCell>

                                <TableCell>
                                    <Stack spacing={1} direction="row">
                                    
                                        <TooltipAndIcon
                                            titulo="Editar cotización"
                                            icono="editar"
                                            color="white"
                                            onClick={ () => handleActualizar(co._id) }
                                        /> 

                                        <TooltipAndIcon
                                            titulo="Descargar cotización"
                                            icono="descargar"
                                            color="white"
                                            onClick={ () => handleUploadCoti(co) }
                                        />  

                                        <TooltipAndIcon
                                            titulo="Comprar Ahora"
                                            icono="comprar"
                                            color="white"
                                            onClick={ () => handleEnviarAlCarrito(co._id) }
                                        /> 

                                        <TooltipAndIcon
                                            titulo="Eliminar cotización"
                                            icono="eliminar"
                                            color="white"
                                            onClick={ () => hendleDelete(co._id) }
                                        /> 

                                    </Stack>
                                </TableCell>
            
                            </TableRow>
                            ))
                            }
                        </TableBody>

                    </Table>
                </TableContainer>
            </Paper>

            : <MensajeAlertaDanger titulo='No Hay Cotizaciones Creadas En el Momento.' />
            }
        </>

    )


}
