
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { TablaMisItems } from "../components";
import { Box, Grid, Typography } from "@mui/material";
import { carritoPorDisenio } from "../../../actions/items";



export const MisOpsTrabajo = () => {


    const dispatch = useDispatch();
    const { uid } = useSelector( state => state.admin );


    useEffect( () => {
        dispatch( carritoPorDisenio(uid) );
    }, [dispatch] );




    return (

        <div >
            <Grid container direccion='row' alignItems='center'>
                <Box flex={ 1 } />
                    <Typography variant='h4' sx={{ fontWeight:'bold' }} >MIS ITEMS</Typography>
                <Box flex={ 1 } />
            </Grid>

            <Grid container alignItems='center'>
                <TablaMisItems />
            </Grid>
        </div>
    )
}
