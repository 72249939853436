
import React from "react"

import { Grid, Typography } from "@mui/material"
import { iconos } from "../../../data"



export const CarritoVacio = () => {


  return (

      <Grid container sx={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', height:'90Vh'}}>
        {React.cloneElement(iconos['compras'], {sx:{fontSize:80} })}
        <Typography variant="h4">Carrito Vacio</Typography>
        <Typography variant="h3">No hay ningun articulo en el carrito de compras.</Typography>
      </Grid>

)


}
