

import { types } from "../types/types";




const initialState = {
    deudas: [ ],
    activeAbono: null
};



export const deudaClienteReducer = ( state = initialState, action ) => {

    switch ( action.type ) {

        case types.deudaClienteAddNew:
            return {
                ...state,
                deudas: [
                    ...state.deudas,
                    action.payload
                ]
            };

        case types.deudaClienteLista:
            return {
                ...state,
                deudas: [ ...action.payload ]
            };


        default:
            return state;
    }

}
