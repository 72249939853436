



import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Grid, TextField, Typography, Box, Backdrop, Modal } from '@mui/material';

import { slideStartAddNew, startCloseSlide } from '../../../actions/slideshow';



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: '#424242' ,
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: '20px',
};




export const CrearSlideShow = () => {


    const dispatch = useDispatch();
    const { modalSlide }  = useSelector( state => state.slideshow );


    const [ formSlide, setFormSlide ] = useState({
        titulo: "",
        img: "",
    });
    const { titulo, img } = formSlide;
    const handleInputChange = ({ target }) => {
        setFormSlide({
            ...formSlide,
            [target.name]: target.value
        });
    }


    const [ imgSlide, setImgSlide ] = useState('');


    useEffect(() => {
        setFormSlide({
            ...formSlide,
            img: imgSlide
        });
    }, [ imgSlide ] );


    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if( file ) {
        setImgSlide(file);
        }
    }


    const handleCrearImagen = (e) => {
        e.preventDefault();
        dispatch(slideStartAddNew(formSlide));
        setFormSlide({
            titulo: "",
            img: "",
        });
        cerrarModal();
    }



    const cerrarModal = () => {
        dispatch( startCloseSlide() );
    }



    return (


        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={ modalSlide }
            onClose={ cerrarModal }
            closeAfterTransition
            // BackdropComponent={ Backdrop }
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Box sx={ style } >

                <Typography align='center' variant='h1' sx={{ mb:2 }} >Crear Imagen SlideShow</Typography>

                <form  onSubmit={ handleCrearImagen } >
                    <Grid container spacing={ 2 }>

                        <Grid item xs={ 12 } sm={ 12 } mt={ 2 }>
                            <TextField
                                label="Titulo"
                                fullWidth
                                placeholder="Titulo"
                                name="titulo"
                                value={ titulo }
                                onChange={ handleInputChange } 
                            />
                        </Grid>

                        <Grid item xs={ 12 } sm={ 12 } mt={ 2 }>
                            <TextField
                                type='file'
                                // label="Imagen"
                                fullWidth
                                // placeholder="Imagen"
                                name="imgSlide" 
                                onChange={ handleFileChange }
                            />
                        </Grid>

                        <Grid container  sx={{ mt:2 }}  direccion='column' justifyContent='center'  >
                            <Grid item xs={ 12 } sm={ 8 } display='flex' justifyContent='center'>
                                <Button variant='contained' fullWidth type='submit'>
                                    Crear Imagen
                                </Button>
                            </Grid>
                        </Grid>

                    </Grid>

                </form>
            </Box>
        </Modal>

    )

}
