
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Grid, Paper } from "@mui/material"
import { LayoutReturn } from "../../layouts"
import { CarritoVacio, CardsComprarAhora, BotonesDeCompletarOrden, InformacionCliente } from "../components";
import { BackdropScreen, VerPrecioTotalCarrito } from "../../components";
import { PagoOrdenOp } from "../views";
import { carritoListaCompraStart, carritoStartDeleteCompra } from "../../../actions/carrito";
import { usePrecioCarritoReal } from "../../../hooks";
import { opStartAddNew } from "../../../actions/op";





export const ComprarAhora = () => {

    const cliente = JSON.parse(localStorage.getItem('cliente') ) || '';

    const dispatch = useDispatch();
    const { iva, view } = useSelector( state => state.iva );
    const { uid } = useSelector( state => state.admin );
    const { carritoCompra } = useSelector( state => state.carrito );
    const { obTenerTotalesRole, obtenerTotalOrdenPorRole } = usePrecioCarritoReal();

    const [cargando, setCargando] = useState(false);

    
    const [formOp, setFormOp] = useState({
        view,
        usuario: uid, 
        totalCliente: 0,
        totalDistri: 0,
        totalPublicista: 0,
        totalEmpresa: 0,
        totalOrden: 0,
        iva: 0,
        infoPersonal: "",
    });



    useEffect(() => {
        dispatch( carritoListaCompraStart() );
    }, [dispatch]);




    useEffect( () => {
        if( carritoCompra ){
            const { clientes, distri, publicista, empresa, ivas } = obTenerTotalesRole();
            const ordenTotal = obtenerTotalOrdenPorRole( clientes, distri, publicista, empresa );
            setFormOp({
                ...formOp,
                infoPersonal: cliente,
                totalCliente: clientes,
                totalDistri: distri,
                totalPublicista: publicista,
                totalEmpresa: empresa,
                totalOrden: ordenTotal,
                iva: ivas
            });
        }
    }, [carritoCompra, iva]);  



    const eliminarItemCarrito = (id) => {
        dispatch( carritoStartDeleteCompra(id) );
    }



    const handleCrearOp = async() => {
        setCargando(true);
        setTimeout( () => {
            setCargando( false );
        }, 2000);
        const resp = await dispatch(opStartAddNew({ ...formOp }));
        setCargando( false );
        if( resp ){
            localStorage.removeItem('cliente');
            localStorage.removeItem('iva');
            localStorage.removeItem('view');
        }     
    }    



    return (
        <LayoutReturn titulo={`Pagar Orden`} url="/super/ver-productos">

            <BackdropScreen cargando={cargando} titulo='Espere por favor estamos generando su orden de pedido...' />

            {(carritoCompra.length > 0)
            ?   <Grid container mt={5}>

                    <Grid item xs={12} md={7} sx={{ pr:{ xs:0, md:3 }}}>

                        {cliente && 
                            <InformacionCliente urlEditar="/super/checkout" /> 
                        }

                        <CardsComprarAhora carritoCompra={carritoCompra} 
                            urlCotiz="/super/cotizacion" 
                            urlDetalle="/super/detalle-item"
                            urlModificar="/super/detalle-producto" 
                            functionDelete={eliminarItemCarrito}
                        />

                        <Paper sx={{mt:2, p:2, height:'50px', display:'flex', alignItems:'center', justifyContent:'end'}}>
                            <VerPrecioTotalCarrito />
                        </Paper>
                    </Grid>


                    <Grid item xs={12} md={5} sx={{mt:{xs:5, md:0}}}>
                        <PagoOrdenOp setFormOp={setFormOp} formOp={formOp} />
                        <BotonesDeCompletarOrden 
                            funcion={handleCrearOp} 
                            url="/super/checkout" 
                            setFormOp={setFormOp} formOp={formOp} 
                        />
                    </Grid>
                </Grid>

            :   <CarritoVacio />
            }
        </LayoutReturn>
    )

}
