


import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { MensajeAlertaDanger } from "../../components";





export const ParaQuienEsOp = () => {


    const { activeOP } = useSelector( state => state.op );



    return (

        <Grid container textAlign='center'>

            <Grid item xs={12} mt={5} >
              <Typography variant="h2" >Para Quien Es</Typography>
            </Grid>
        
            {

              ( activeOP ) 
              ?<TableContainer sx={{ mb:5 }}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">

                      <TableHead >
                          <TableRow >
                              <TableCell>NOMBRE</TableCell>
                              <TableCell >MOVIL</TableCell>
                              <TableCell>DIRECCION</TableCell>
                              <TableCell>CIUDAD</TableCell>
                          </TableRow>
                      </TableHead>

                      <TableBody>

                          <TableRow >

                              <TableCell className="w-300">
                                  <Typography variant="span">{ activeOP.infoPersonal.nombre }</Typography>
                              </TableCell>

                              <TableCell >
                                  <Typography variant="span">{ activeOP.infoPersonal.movil }</Typography>
                              </TableCell>

                              <TableCell >
                                  <Typography variant="span" >{ activeOP.infoPersonal.direccion }</Typography>
                              </TableCell>

                              <TableCell >
                                  <Typography variant="span" >{ activeOP.infoPersonal.ciudad }</Typography>
                              </TableCell>

                          </TableRow>

                      </TableBody>

                  </Table>
              </TableContainer>
              
              : <MensajeAlertaDanger titulo={'No hay O.P. En Este Modulo'} />

            }

        </Grid>

    )

}
