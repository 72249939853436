
import { Box, Grid, Typography } from "@mui/material";








export const LayoutUsuarios = ({ children, title }) => {



    return (

        <div >
            <Grid container direccion='row' alignItems='center'>

                <Box flex={ 1 } />

                <Typography variant='h2' sx={{ fontWeight:'bold' }} >{ title }</Typography>

                <Box flex={ 1 } />

            </Grid>

            <Grid container alignItems='center'>

                { children }


            </Grid>

        </div>
    )



}
