
import React from 'react';
import { TextField } from "@mui/material"



export const TextFormField = ({ label, disabled=false, variant=undefined, ...props } ) => {



    return (
        <>
            <TextField
                disabled={disabled}
                variant={variant}
                autoComplete="off"
                label={ label } 
                { ...props }
                fullWidth
            />
        </>
    )


}
