
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Grid, Typography } from "@mui/material";
import { CargandoMedio } from "../../components";
import { LayoutDetallesProducto } from "../layouts";
import { cantidadColor, cantidax10, cantidax50, corteVinilo, estructurasEcosolvente, litografia, nombreSoloCantidad, prendas } from "../../../data";
import { FormularioCantidadColor, FormularioCantidadx10, FormularioCantidadx50, FormularioCorteVinilo, FormularioEstructuras, FormularioLitografia, FormularioPrendas, FormularioSoloCantidad } from "../formularios";

import { productoPorID, productosDeleteActivo, productosListaCategoriaStart } from "../../../actions/producto";
import { carritoActivoParaUpdate, carritoStartAddNew, carritoUpdateStart } from "../../../actions/carrito";



const baseUrl = process.env.REACT_APP_API_URL;



export const DetalleProducto = () => {

    const dispatch = useDispatch();
    const { id } = useParams();
    const { uid } = useSelector( state => state.admin );
    const { activeProducto } = useSelector( state => state.producto );
    const { carritoCompra } = useSelector( state => state.carrito );
    const { activeCarritoUpdate } = useSelector( state => state.carrito );



    useEffect( () => {
        if( id ) { dispatch( productoPorID( id )) }
        return () => dispatch( productosDeleteActivo() );
    }, [id] );




    useEffect(() => {
        return () => {
            localStorage.removeItem('item-carrito');
            dispatch(carritoActivoParaUpdate(null));
        }
    }, [])


    useEffect(() => {
        if(activeProducto) {
            dispatch( productosListaCategoriaStart( activeProducto.categoria._id ) );
        }
    }, [activeProducto]);



    useEffect(() => {
        if(activeCarritoUpdate) {
            const { producto, terminados, ...resto } = activeCarritoUpdate;
            setFormCarrito({ ...formCarrito, terminados: (terminados) ? terminados._id : '', ...resto })
        }
    },[activeCarritoUpdate]);



    const [formCarrito, setFormCarrito] = useState({
        producto: id,
        usuario: uid,
        cantidad: '',
        color: '',
        talla: '',
        genero: '',
        disenio: '',
        unidadMedida: '',
        alto: '',
        ancho: '',
        terminados: '',
        totalCliente: '',
        totalDistri: '',
        totalEmpresa: '',
        totalPublicista: '',
    });




    const handleAgregarAlCarro = ( itemExistente=null ) => {
        // const productoExistente = carritoCompra.some( (prod) => prod.producto._id === formCarrito.producto);
        // if(productoExistente || activeCarritoUpdate) {
        //     carritoCompra.map( (car) => {
        //         if( car.producto._id === formCarrito.producto ||  car.producto._id === activeCarritoUpdate.producto._id ) {
        //             const data = { ...formCarrito }
        //             dispatch(carritoUpdateStart( data ) );
        //         } 
        //     });
        // } else {
        //     dispatch( carritoStartAddNew( formCarrito ) );
        //     setFormCarrito({
        //         ...formCarrito,
        //         cantidad: "",
        //         color: "",
        //         talla: "",
        //         genero: "",
        //         disenio: "",
        //         unidadMedida: '',
        //         alto: '',
        //         ancho: '',
        //         terminados: "",
        //         totalCliente: "",
        //         totalDistri: "",
        //         totalEmpresa: "",
        //         totalPublicista: "",
        //     });
        // }

        if( itemExistente ) {
            dispatch(carritoUpdateStart({ ...formCarrito, id: itemExistente.id }) );
        } else {
            dispatch( carritoStartAddNew( formCarrito ) );
            setFormCarrito({
                ...formCarrito,
                cantidad: "",
                color: "",
                talla: "",
                genero: "",
                disenio: "",
                unidadMedida: '',
                alto: '',
                ancho: '',
                terminados: "",
                totalCliente: "",
                totalDistri: "",
                totalEmpresa: "",
                totalPublicista: "",
            });
        }
    }



    return (

        <LayoutDetallesProducto titulo="Detalles de Producto" url={-1}>

            {(activeProducto) 
            ?   <>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={5}>
                        <img src={ `${baseUrl }/uploads/productos/${ activeProducto.img }`} style={{width:'100%'}} />
                    </Grid>

                    <Grid item xs={12} sm={7}>

                        <Typography variant="h4" sx={{fontSize:'22px', mt:-1}}>Seven For Ever S.A.S.</Typography>
                        <Typography variant="body">{ activeProducto.nombre }</Typography>


                        {(prendas.includes(activeProducto.categoria.nombre)) &&
                        <FormularioPrendas
                            titulo={'Agregar al Carrito'}
                            formCarrito={formCarrito} 
                            setFormCarrito={setFormCarrito} 
                            funcionAgregar={handleAgregarAlCarro}
                        />
                        }

                        {(nombreSoloCantidad.includes(activeProducto.categoria.nombre)) &&
                        <FormularioSoloCantidad
                            titulo={'Agregar al Carrito'}
                            formCarrito={formCarrito} 
                            setFormCarrito={setFormCarrito} 
                            funcionAgregar={handleAgregarAlCarro}
                        />
                        }

                        {(cantidadColor.includes(activeProducto.categoria.nombre)) &&
                        <FormularioCantidadColor 
                            titulo={'Agregar al Carrito'}
                            formCarrito={formCarrito} 
                            setFormCarrito={setFormCarrito} 
                            funcionAgregar={handleAgregarAlCarro}
                        />
                        }

                        {(litografia.includes(activeProducto.categoria.nombre)) &&
                        <FormularioLitografia
                            titulo={'Agregar al Carrito'}
                            formCarrito={formCarrito} 
                            setFormCarrito={setFormCarrito} 
                            funcionAgregar={handleAgregarAlCarro}
                        />
                        }

                        {(estructurasEcosolvente.includes(activeProducto.categoria.nombre)) &&
                        <FormularioEstructuras 
                            titulo={'Agregar al Carrito'}
                            formCarrito={formCarrito} 
                            setFormCarrito={setFormCarrito} 
                            funcionAgregar={handleAgregarAlCarro}
                        />
                        }

                        {(cantidax10.includes(activeProducto.categoria.nombre)) &&
                        <FormularioCantidadx10 
                            titulo={'Agregar al Carrito'}
                            formCarrito={formCarrito} 
                            setFormCarrito={setFormCarrito} 
                            funcionAgregar={handleAgregarAlCarro}
                        />
                        }

                        {(cantidax50.includes(activeProducto.categoria.nombre)) &&
                        <FormularioCantidadx50 
                            titulo={'Agregar al Carrito'}
                            formCarrito={formCarrito} 
                            setFormCarrito={setFormCarrito} 
                            funcionAgregar={handleAgregarAlCarro}
                        />
                        }

                        {(corteVinilo.includes(activeProducto.categoria.nombre)) &&
                        <FormularioCorteVinilo 
                            titulo={'Agregar al Carrito'}
                            formCarrito={formCarrito} 
                            setFormCarrito={setFormCarrito} 
                            funcionAgregar={handleAgregarAlCarro}
                        />
                        }
                    </Grid>
                </Grid>


                <Grid container sx={{mt:3, display:'flex', flexDirection:'column', mb:2}}>
                    <Typography variant="h2" gutterBottom>Uso del Producto</Typography>
                    <Typography variant="body" sx={{mt:-1}}>{ activeProducto.uso }</Typography>
                </Grid>


                <Grid container sx={{mt:3, display:'flex', flexDirection:'column', mb:2}}>
                    <Typography variant="h2" gutterBottom>Recomendaciones</Typography>
                    <Typography variant="body" sx={{mt:-1}}>{ activeProducto.recomendaciones }</Typography>
                </Grid>

                </>

            :   <CargandoMedio titulo="Cargando Producto..." />

            }
        </LayoutDetallesProducto>
    )
}
