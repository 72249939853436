
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { BuscadorProductosInventario, FormCrearOAdiccionarStock, FormularioDescontarStock, TablaInventarios } from "../components"
import { closeStartForm, closeStartFormDos, openStartForm, openStartFormDos, todosStockList } from "../../../actions";
import { ModalFormulario, ModalFormularioDos } from "../../components";
import { LayoutAddSubstra } from "../../layouts";





export const InventarioScreen = () => {


    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(todosStockList());
    }, [dispatch])



    const crearUnidadMedida = () => {
        dispatch( openStartForm() );
    }


    const cerrarModal = () => {
        dispatch( closeStartForm() );
    }


    const descontarStock = () => {
        dispatch( openStartFormDos() );
    }

    const cerrarModalDescontar = () => {
        dispatch( closeStartFormDos() );
    }

    
    
    
    
    return (
        
        <LayoutAddSubstra titulo='Inventario' funcionAdd={crearUnidadMedida} funcionSubt={descontarStock} >


            <BuscadorProductosInventario />
            <TablaInventarios />
            
            <ModalFormulario titulo="Crear o Adicionar Stock" cerrarModal={cerrarModal} datos={{}}>
                <FormCrearOAdiccionarStock />
            </ModalFormulario>


            <ModalFormularioDos titulo="Descontar Stock" cerrarModal={cerrarModalDescontar} datos={{}}>
                <FormularioDescontarStock />
            </ModalFormularioDos>


        </LayoutAddSubstra>


    )

    
}
