
import * as React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Typography, styled, Stack, Stepper, Step, StepLabel } from '@mui/material';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

import PaidIcon from '@mui/icons-material/Paid';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';



    const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 22,
        },
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            height: 3,
            border: 0,
            backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
            borderRadius: 1,
        },
    }));

    const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        ...(ownerState.active && {
            backgroundImage: 
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
            boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        }),
        ...(ownerState.completed && {
            backgroundImage: 
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        }),
    }));



    
    const misOpciones = [
        { label: "Verificar Pago", indice: 0 },
        { label: "Diseño", indice: 1 },
        { label: "Produccion", indice: 2 },
        { label: "Para Entregar", indice: 3 },
        { label: "Entregado", indice: 4 },   
    ]



export const TrayectoriaPedidos = () => {


    const { activeOP } = useSelector( state => state.op );
    const [estadop, setEstadop] = useState(0);



    const ColorlibStepIcon = (props) => {
        const { active, completed, className } = props;
    
        const icons = {
            1: <PaidIcon />,
            2: <DesignServicesIcon />,
            3: <PrecisionManufacturingIcon />,
            4: <LocalShippingIcon />,
            5: <CheckCircleIcon />,
        };
    
        return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
        );
    }

    useEffect(() => {
        if(activeOP){
            conocerEstadoOP(activeOP.estado);
        }
    }, [activeOP])
    



    
    const conocerEstadoOP = (estado) => {
        const estados = {
            "Verificar Pago": 0,
            "Diseño": 1,
            "Pendiente Aprobacion": 1,
            "Produccion": 2,
            "Para Entregar": 3,
            "Credito": 3,
            "Entregado": 4
        };
        setEstadop(estados[estado] || 0);
    };




    return (

        <Stack sx={{ width: '100%' }}>

            <Stepper alternativeLabel activeStep={estadop} connector={<ColorlibConnector />}>
            {
                misOpciones.map( (item) => (
                <Step key={item.label}>
                    <StepLabel StepIconComponent={ ColorlibStepIcon }>
                        <Typography variant="span" sx={{ fontSize: (item.indice === estadop) ? 22 : 'inherit' }}>{ item.label }</Typography>
                    </StepLabel>
                </Step>
                ))
            }
            </Stepper>

        </Stack>

    )


}
