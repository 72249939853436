import { Alert, Stack } from "@mui/material"




export const MensajeAlertaDanger = ({ titulo }) => {

    return (
        
        <Stack sx={{ width: '100%' }} mt={5} >
            <Alert variant="outlined" severity="error" color="primary" sx={{ display:'flex', justifyContent:'center' }}>
                { titulo }
            </Alert>
        </Stack>

    )



}
