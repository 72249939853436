
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Grid, TextField, MenuItem, Button } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { proveedorListaStart } from "../../../actions/proveedor";
import { LayoutReturn } from "../../layouts";
import { AgregarItemsPagos } from "../components";
import { startCreatePagoInventario } from "../../../actions/pagosInventario";

import Swal from "sweetalert2";
import moment from "moment";



const now = moment().toDate();
const initial = {
    factura: '',
    tipoPago: '',
    cuantosDias: '',
    proveedor: '',
    fecha: now,
}



export const FormularioCrearPagos = () => {

    const dispatch = useDispatch();
    const { proveedores } = useSelector(state => state.proveedor);

    const [data, setData] = useState([]);



    useEffect( () => {
        dispatch( proveedorListaStart() );
    }, [ dispatch ] );


    const [informacion, setInformacion] = useState(initial);
    const { factura, tipoPago, proveedor, fecha, cuantosDias } = informacion;



    const handleChangesInformacion = ({ target }) => {
        setInformacion( (resultado) => ({
            ...resultado,
            [target.name]: target.value
        }));
    }
    

    const handleChangeStart = (e) => {
        setInformacion( (resultado) => ({
            ...resultado,
            fecha: e.$d
        }));
    }



    const handleCrearPago = (e) => {
        e.preventDefault();
        if([factura, tipoPago, proveedor, fecha, cuantosDias].includes('')){
            return Swal.fire('Error', 'Todos los Campos Son Obligatorios', 'error');
        }

        if(data.length < 1){
            return Swal.fire('Error', 'Debes Añadir Al Menos Un Item', 'error');
        }

        const resp = dispatch(startCreatePagoInventario({informacion, data}));
        if( resp ) {
            setInformacion(initial);
            setData([]);
        }
    }




    return (

        <LayoutReturn titulo='Crear Pago Materia Prima' url="/super/pagos">

            <form autoComplete='off' style={{width:'100%'}} onSubmit={ handleCrearPago }>
                <Grid container spacing={2} my={3}>

                    <Grid item xs={ 12 } sm={ 4 }>
                        <TextField required
                            fullWidth
                            label='Factura'
                            placeholder='Factura'
                            name="factura"
                            value={factura}
                            onChange={ handleChangesInformacion }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 4 }>
                        <TextField required
                            select
                            fullWidth
                            label='Tipo de Pago'
                            placeholder='Tipo de Pago'
                            name="tipoPago"
                            value={tipoPago}
                            onChange={ handleChangesInformacion }
                        >
                            <MenuItem value=''>
                                Seleccione Tipo de Pago
                            </MenuItem>
                            <MenuItem value="CREDITO" >
                                CREDITO
                            </MenuItem>
                            <MenuItem value="CONTADO" >
                                CONTADO
                            </MenuItem>
                        </TextField>
                    </Grid>

                    <Grid item xs={ 12 } sm={ 4 }>
                        <TextField 
                            label='Cuantos Dias'
                            type='number'
                            placeholder='Cuantos Dias'
                            fullWidth
                            name='cuantosDias'
                            value={ cuantosDias }
                            onChange={ handleChangesInformacion }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 4 }>
                        <TextField 
                            select
                            fullWidth
                            label='Proveedor'
                            placeholder='Proveedor'
                            name="proveedor"
                            value={proveedor}
                            onChange={ handleChangesInformacion }
                        >
                            <MenuItem value=''>
                                Seleccione un Proveedor
                            </MenuItem>
                            {
                            proveedores?.map((p) => (
                            <MenuItem value={p.id} key={p.id}>
                                {p.nombre}
                            </MenuItem>
                            )) 
                            }
                        </TextField>
                    </Grid>


                    <Grid item xs={ 12 } sm={4}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Fecha de Factura"
                                value={ fecha }
                                onChange={ handleChangeStart }
                                renderInput={(params) => <TextField {...params} fullWidth />}
                            />
                        </LocalizationProvider>
                    </Grid>

                </Grid>


                <AgregarItemsPagos data={data} setData={setData} />


                <Grid container sx={{display:'flex', justifyContent:'center', my:4}}>
                    <Grid item xs={12} sm={6}>
                        <Button variant='contained' fullWidth type="submit">
                            Agregar Pago
                        </Button>
                    </Grid>
                </Grid>

            </form>

        </LayoutReturn>
    )


    
}
