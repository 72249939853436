
import moment from 'moment';
moment.locale('es');



// Preparar Fechas de Abono
export const prepararFechaAbono = ( abono = [] ) => {
    return abono.map(
        (e) => ({
            ...e,
            start: moment(e.start).format('LLL'),
            createdAt: moment(e.createdAt).format('LLL'),
        })
    )
}


export const prepararFechaUnAbono = ( a = {} ) => {
    return a = {
        ...a,
        start: moment(a.start).format('LLL'),
        createdAt: moment(a.createdAt).format('LLL'),
    }
}


export const prepararFecha = ( op = [] ) => {
    return op.map(
        (e) => ({
            ...e,
            startOp: moment(e.startOp).format('ll'),
        })
    )
}



export const prepararFechaPorId = ( op = {} ) => {
    return op = {
        ...op,
        startOp: moment(op.startOp).format('LLL'),
    }
}

































export const prepararFechaTrasabilidad = ( t = [] ) => {

    return t.map(
        (e) => ({
            ...e,
            start: moment(e.start).format('LLL'),
            end: moment(e.end).format('LLL'),
        })
    )
}


export const prepararFechaPorIdTrasa = ( t = {} ) => {
    return t = {
        ...t,
        start: moment(t.start).format('LLL'),
        end: moment(t.end).format('LLL'),
    }
}
