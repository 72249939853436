

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { separadorMillares } from "../../../hooks/useMillares";
import { MensajeAlertaDanger } from "../../components";



export const DetallesDeOp = () => {



    const { activeOP } = useSelector( state => state.op );
    const { abonos } = useSelector( state => state.abono );

    const [abono, setAbono] = useState(0);
    

    useEffect(() => {
        if( abonos ){
            const a = abonos.reduce( (total, abono) => total + abono.abono, 0 );
            setAbono( a );
        }
    }, [abonos] );
    



    return (

        <Grid container textAlign='center'>

            <Grid item xs={12} mt={5} >
                <Typography variant="h4" mb={2}>Detalles de la O.P.</Typography>
            </Grid>
        
            {( activeOP ) 
            ?   <TableContainer sx={{ mb:5 }}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead >
                            <TableRow >
                                <TableCell>AREA</TableCell>
                                <TableCell>PAGO</TableCell>
                                <TableCell>IVA</TableCell>
                                <TableCell>VALOR TOTAL</TableCell>
                                <TableCell>ABONOS</TableCell>
                                <TableCell>DEUDA</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <TableRow >
                                <TableCell >
                                    <Typography variant="span">{ activeOP.estado }</Typography>
                                </TableCell>
                                <TableCell >
                                    <Typography variant="span">{ activeOP.pago }</Typography>
                                </TableCell>
                                <TableCell >
                                    <Typography variant="span">{ `$${activeOP.iva}` }</Typography>
                                </TableCell>
                                <TableCell >
                                    <Typography variant="span" >{ `$${separadorMillares(activeOP.totalOrden)}` }</Typography>
                                </TableCell>
                                <TableCell >
                                    <Typography variant="span" >{ `$${separadorMillares(abono)}` }</Typography>
                                </TableCell>
                                <TableCell >
                                    <Typography variant="span" >{ `$${separadorMillares(activeOP.totalOrden - abono)}` }</Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            
            :   <MensajeAlertaDanger titulo={'No hay O.P. En Este Modulo'} />

            }
        </Grid>
    )
}
