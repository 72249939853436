
import { Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import { Main } from "../theme";
import { Box, Toolbar } from "@mui/material";
import { NavBarScreen, SlideShowMenu } from "../components/ui";


//Rutas de Dashboard
import { MisOpciones, MisPedidos, CambioClave, DetallesDeMiOP, MiPerfilUsuario } from "../components/dashboard/pages";
// Rutas de Ops
import { DetallesItemProduccion, DetallesOpCard, ItemsDeOpOpciones, MisOpsTrabajo, OpDisponiblesTrabajo, TodasOps, AbonosDeOpNueva, InformacionDeUsuarios, TodosLosItems, EditarOAgregarOP } from "../components/op/pages";
// Rutas de Usuarios
import { ActualizarCliente, ListadoTodosClientes, CrearUsuarios, ActualizarUsuario, ListadoUsuarios, ListadoDeSocios, CrearSocios, MisClientes, CrearClientes, VerOpsPorCliente, DeudaClienteOp, ListadoUsuariosArchivados, ListadoSociosArchivados, ListadoClientesArchivados } from "../components/superAdmin/pages";
// Rutas de Cotizacion 
import { ActualizarCotizacion, CotizacionNewScreen, ListadoTodasCotizaciones, ModificarParaComprar } from "../components/cotizacion/pages";
// Rutas de Productos
import { ListadoProductos, CrearProducto, ActualizarProducto, CategoriasScreen, ExibirProductosScreen, DetalleProducto } from "../components/productos/pages";
// Rutas de Caja
import { CajaScreen, ComparativaScreen, DetallesDeInventario, GastosScreen, InventarioScreen, ListadoUnidadMedida, ReportesInventario, PagosScreen, FormularioCrearPagos, DetallePagoInventario, FormularioCrearGasto, DetallesGasto, AbonosGastos } from "../components/caja/pages";
// Rutas de Proveedores
import { CrearProveedores, ListadoProveedores } from "../components/proveedores/pages/";
// Rutas de Compra y Checkout
import { CheckoutScreen, ComprarAhora, CotizacionScreen, DetalleItemCompra } from "../components/compras/pages";




// Rutas de Categoria
import { CrearCategoria, ListadoCategorias, ActualizarCategoria } from "../components/categorias/pages";
// Rutas de los Settings
import { ConfiguracionSlideShow } from "../components/settings/pages";





// Rutas de Productos
// import { DetalleProducto } from "../components/productos/DetalleProducto"
import { InfoUsuario } from "../components/op/InfoUsuario"

// Rutas de Comprar Y Carrito
import { SubirImagenPago } from "../components/dashboard/SubirImagenPago"


// Abonos
import { RegistroColaborador } from "../components/op/RegistroColaborador"
import { InfoCliente } from "../components/op/InfoCliente"






export const SuperAdminRouter = () => {


  const { isSidebarOpen } = useSelector( state => state.iva ); 



  return (

        <>
            {/* <SidebarScreen />
            <section className="total">
              <div className="formTodoOp"> */}

            <Box sx={{ display:'flex' }}>
              <NavBarScreen />
              <SlideShowMenu />

              <Main open={isSidebarOpen}>
              <Toolbar />

              <Box>
              <Routes>

                  {/* Rutas de Configuracion de la Pagina */}
                  <Route path="/slideshow" element={<ConfiguracionSlideShow />} />
                  {/* Dashboard */}
                  <Route path="/" element={ <MisOpciones /> } />
                  <Route path="/mis-pedidos" element={ <MisPedidos /> } />
                  <Route path="/cambio-clave" element={ <CambioClave /> } />
                  <Route path="/trayectoria/:id" element={ <DetallesDeMiOP /> } />
                  <Route path="/perfil" element={ <MiPerfilUsuario /> } />   

                  
                  <Route path="/foto-pago/:id" element={ <SubirImagenPago /> } />

                  {/* Servicio de O.P. */}
                  <Route path="/op-todas" element={ <TodasOps /> } />
                  <Route path="/op-disponibles" element={ <OpDisponiblesTrabajo /> } />
                  <Route path="/todos-items" element={ <TodosLosItems /> } />
                  <Route path="/mis-op" element={<MisOpsTrabajo />} />
                  <Route path="/detalles/:id" element={<InformacionDeUsuarios />} />
                  <Route path="/detalles-op/:id" element={<DetallesOpCard />} />
                  <Route path="/items-produccion/:id" element={<ItemsDeOpOpciones />} />
                  <Route path="/dt-pro-op/:id" element={<DetallesItemProduccion />} />
                  <Route path="/ops-cliente/:id" element={<VerOpsPorCliente />} />
                  <Route path="/editar/:id" element={<EditarOAgregarOP />} />


                  {/* servicios de Clientes */}
                  <Route path="/listadoClientes" element={<ListadoTodosClientes />} />
                  <Route path='/cli-archi' element={<ListadoClientesArchivados />} />
                  <Route path="/actualizarCliente/:id" element={<ActualizarCliente />} />
                  <Route path="/crearcliente" element={<CrearClientes />} />
                  <Route path="/clientesxdistri" element={<MisClientes />} />

                  {/* Servicio Usuarios  */}
                  <Route path="/crearUsuario" element={ <CrearUsuarios />} />
                  <Route path="/actualizar-user/:id" element={<ActualizarUsuario />} />
                  <Route path='/listado-super' element={<ListadoUsuarios />} />
                  <Route path='/super-archi' element={<ListadoUsuariosArchivados />} />
                  <Route path='/listadosocios' element={<ListadoDeSocios />} />
                  <Route path='/socios-archi' element={<ListadoSociosArchivados />} />
                  <Route path="/crear-distri" element={<CrearSocios />} />

                   {/* Servicio de Proveedores */}
                  <Route path="/crear-proveedor/:id" element={ <CrearProveedores /> } />
                  <Route path="/list-proveedor" element={ <ListadoProveedores />} />

                  {/* Cotizacion */}       
                  <Route path="/cotizacion/new" element={<CotizacionNewScreen />} />
                  <Route path="/cotizacion/all" element={<ListadoTodasCotizaciones />} />
                  <Route path="/cotizacion/actu/:id" element={<ActualizarCotizacion />} />
                  <Route path="/modificar/coti/:id" element={<ModificarParaComprar />} />

                  {/* Abonos */}
                  <Route path="/deuda-cliente/:id" element={<DeudaClienteOp />} />
                  <Route path="/abonos/nueva/:id" element={ <AbonosDeOpNueva /> } />

                  {/* Servicio de Productos y Categorias */}
                  <Route path="/listaProducto" element={ <ListadoProductos />} />
                  <Route path="/crearProducto" element={ <CrearProducto />} />
                  <Route path="/actualizar-prod/:id" element={<ActualizarProducto />} />
                  <Route path="/detalle-producto/:id" element={ <DetalleProducto />} />



                  {/* Servicios de Carrito y Compras */}
                  <Route path="/comprar-ahora" element={ <ComprarAhora /> } />
                  <Route path="/checkout" element={ <CheckoutScreen />} />
                  <Route path="/detalle-item/:id" element={ <DetalleItemCompra />} />
                  <Route path="/cotizacion" element={ <CotizacionScreen />} />





                  <Route path="/crear-categoria" element={<CrearCategoria/>} />
                  <Route path="/lista-categoria" element={<ListadoCategorias />} />
                  <Route path="/actualizar-categ/:id" element={<ActualizarCategoria />} />
                  <Route path="/ver-productos" element={ <CategoriasScreen />} />
                  <Route path="/filtro/:id"  element={ <ExibirProductosScreen />} />



                  


                  <Route path="/registro-cola/:id" element={<RegistroColaborador />} />
                  <Route path="/info-user/:id" element={<InfoUsuario />} />
                  <Route path="/info-clien/:id" element={<InfoCliente />} />

                  {/* Caja o PyG*/}
                  <Route path="/abonos/:id/gasto" element={<AbonosGastos />} />   
                  <Route path="/caja" element={<CajaScreen />} />
                  <Route path="/pagos" element={<PagosScreen />} />     
                  <Route path="/pagos/create" element={<FormularioCrearPagos />} />
                  <Route path="/pagos/detalle/:id/pago" element={<DetallePagoInventario />} />
                  <Route path="/gastos" element={<GastosScreen />} />
                  <Route path="/gasto/create" element={<FormularioCrearGasto />} />
                  <Route path="/factura/:id" element={<DetallesGasto />} />   
                  <Route path="/uni-medi" element={<ListadoUnidadMedida />} />
                  <Route path="/reportes/:id" element={<ReportesInventario />} />
                  <Route path="/stock/:id/update" element={<DetallesDeInventario />} />
                  


                  <Route path="/comparativa" element={<ComparativaScreen />} />
                  <Route path="/inventario/list" element={ <InventarioScreen /> } />   

                </Routes>
              </Box>


              </Main>
            </Box>

              {/* </div>
            </section> */}
        </>

  )


}
