
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Button, Grid, TextField, Typography } from "@mui/material";
import { clienteStartUpdateCliente } from "../../../actions/authCliente";
import { LayoutReturn } from "../layouts";


import Swal from "sweetalert2";


const initCliente = {
    nombre: "",
    cedula: "",
    ciudad: "",
    direccion: "",
    direccion2: "",
    movil: "",
    id: "",
    distribuidor: ""
}


export const CheckoutCliente = () => {


    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { cliente } = useSelector( state => state.authCliente );


    const [ formCliente, setFormCliente ] = useState( initCliente );
    const { nombre, cedula, ciudad, direccion, direccion2, movil } = formCliente;


    useEffect(() => {
      if( cliente ) {
          setFormCliente({ ...formCliente,
            nombre: cliente.nombre, ciudad: cliente.ciudad,
            direccion: cliente.direccion, movil: cliente.movil,
            cedula: cliente.cedula, id: cliente.id, distribuidor: cliente.distribuidor
          });
      }
    }, [cliente] );



    const handleInputChange = ({ target }) => {
        setFormCliente({
            ...formCliente,
            [target.name]: target.value
        });
    }


    const handleInfoCliente = (e) => {
        e.preventDefault();

        if([nombre, cedula, ciudad, direccion, movil].includes('')) {
            Swal.fire('Error', 'Todos los datos son obligatoria excepto Dirección 2', 'error');
            return; 
        }
        localStorage.setItem('cliente', JSON.stringify( formCliente ) );
        dispatch( clienteStartUpdateCliente( formCliente ) );
        navigate('/comprar-cliente');
    }




  return (

      <LayoutReturn titulo={`Información Personal`} url='/comprar-cliente'>

        <form className="formulario-total" onSubmit={ handleInfoCliente }>
          <Grid spacing={3} container sx={{mt:5}}>

              <Grid item xs={12} sm={6}>
                  <TextField
                      label='Nombre'
                      type='text'
                      placeholder='Nombre'
                      fullWidth
                      name="nombre"
                      value={ nombre }
                      onChange={ handleInputChange }
                  />
              </Grid>

              <Grid item xs={12} sm={6}>
                  <TextField required
                      label='Cedula'
                      type='text'
                      placeholder='Cedula'
                      fullWidth
                      name="cedula"
                      value={ cedula }
                      onChange={ handleInputChange }
                  />
              </Grid>

              <Grid item xs={12} sm={6}>
                  <TextField required
                      label='Ciudad'
                      type='text'
                      placeholder='Ciudad'
                      fullWidth
                      name="ciudad"
                      value={ ciudad }
                      onChange={ handleInputChange }
                  />
              </Grid>


              <Grid item xs={12} sm={6}>
                  <TextField required
                      label='Dirección'
                      type='text'
                      placeholder='Dirección'
                      fullWidth
                      name="direccion"
                      value={ direccion }
                      onChange={ handleInputChange }
                  />
              </Grid>

              <Grid item xs={12} sm={6}>
                  <TextField
                      label='Dirección 2'
                      type='text'
                      placeholder='Dirección 2 (opcional)'
                      fullWidth
                      name="direccion2"
                      value={ direccion2 }
                      onChange={ handleInputChange }
                  />
              </Grid>

              <Grid item xs={12} sm={6}>
                  <TextField
                      label='Movil'
                      type='text'
                      placeholder='Movil'
                      fullWidth
                      name="movil"
                      value={ movil }
                      onChange={ handleInputChange }
                  />
              </Grid>
          </Grid>

          <Grid container sx={{ my:7, flexDirection:'column', justifyContent:'center' }}>
              <Grid item xs={ 12 } sm={ 7 } >
                  <Button variant="contained" color="secondary" type="submit">
                      <Typography variant="span" color='white'>Revisar Pedido</Typography>
                  </Button>
              </Grid>
          </Grid>

        </form>
      </LayoutReturn>
  )
}
