
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Grid, TextField, Typography, Box, Backdrop, Modal } from '@mui/material';

import { closeModalAbonosOpCliente } from '../../../actions';
import { debitarClienteStartNew } from "../../../actions/deudaCliente";
import { opDeleteActivo } from '../../../actions/op';

import { NumberFormatCustom } from '../../../hooks';





export const ModalAbonoOpCliente = () => {


    const dispatch = useDispatch();
    const { modalAbonoOpCliente }  = useSelector( state => state.modal );
    const { activeOP }  = useSelector( state => state.op );
 
    const millares = NumberFormatCustom;


    const [ formAbono, setFormAbono ] = useState({
        abono: "",
        observacion: "",
        uid: "",
    });
    const { abono, observacion } = formAbono;

    const handleInputChange = ({ target }) => {
        setFormAbono({
            ...formAbono,
            [target.name]: target.value
        });
    }



    useEffect(() => {
        if( activeOP ) {
            setFormAbono({
                ...formAbono,
                uid: activeOP.id
            });
        }
    }, [activeOP]);




    const handleCrearAbono = (e) => {
        e.preventDefault();
        dispatch(debitarClienteStartNew(formAbono));
        setFormAbono({
            ...formAbono,
            abono: "",
            observacion: "",
        });
        cerrarModal();
    }



    const cerrarModal = () => {
        dispatch( closeModalAbonosOpCliente() );
        dispatch( opDeleteActivo() );
    }



    return (

        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={ modalAbonoOpCliente }
            onClose={ cerrarModal }
            closeAfterTransition
            BackdropComponent={ Backdrop }
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Box className="style-modal">

                <Typography align='center' variant='h1' sx={{ mb:2 }} >Abono Cliente A OP</Typography>

                <form  onSubmit={ handleCrearAbono } >
                    <Grid container spacing={ 2 }>

                        <Grid item xs={ 12 } sm={ 12 } mt={ 2 }>
                            <TextField 
                                required
                                label="Abono"
                                fullWidth
                                placeholder='Abono'
                                name="abono"
                                value={ abono }
                                onChange={ handleInputChange }
                                InputProps={{
                                    inputComponent: millares,
                                }}
                            >
                            </TextField>
                        </Grid>


                        <Grid item xs={ 12 } sm={ 12 } mt={ 2 }>
                            <TextField
                                label="Observacion"
                                fullWidth
                                placeholder="Observacion"
                                multiline
                                name="observacion"
                                value={ observacion }
                                onChange={ handleInputChange } 
                            />
                        </Grid>

                        <Grid container  sx={{ mt:2 }}  direccion='column' justifyContent='center'  >
                            <Grid item xs={ 12 } sm={ 8 } display='flex' justifyContent='center'>
                                <Button variant='contained' fullWidth type='submit'>
                                    Crear Abono
                                </Button>
                            </Grid>
                        </Grid>

                    </Grid>

                </form>
            </Box>
        </Modal>

    )

}
