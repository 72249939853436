import Swal from "sweetalert2";
import { fetchConToken, fetchSinToken } from "../helpers/fetch";
import { types } from "../types/types";






export const distriListaStart = (texto) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken(`admin/socios/${texto}`);
            const body = await resp.json();
            if( body.ok ){
                dispatch( usuarioLista(body.distri) );
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}



export const userListCotizacion = (texto) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken(`admin/usercoti/${texto}`);
            const body = await resp.json();
            if( body.ok ){
                return body.usuario;
            }else {
                Swal.fire('Error', body.msg, 'error');
                return [];
            }
        } catch (error) {
            console.log(error);
            return [];
        }
    }
}




export const usuarioPorID = ( id ) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchSinToken(`admin/por-id/${ id }`);
            const body = await resp.json();
            if( body.ok ){
                dispatch( usuarioActivo(body.usuario) );
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            console.log(error);
        }
    }
}



export const usuarioStartDelete = (usuario) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken(`admin/${ usuario.id }`, {}, 'DELETE');
            const body = await resp.json();

            if( body.ok ){
                dispatch( usuarioDelete(usuario) );
                Swal.fire('Exitoso', 'Usuario Eliminado', 'success');
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            console.log(error);
        }
    }
}



export const usuarioStartAddNew = ( user ) => {
    return async( dispatch, getState ) =>{
        const { uid, nombre } = getState().admin;
        try {
            const resp = await fetchConToken('admin/new', user, 'POST');
            const body = await resp.json();
            if( body.ok ) {
                Swal.fire('Exitoso', 'Usuario Creado', 'success');
                user.id = body.usuario.id;
                user.role = body.usuario.role;
                user.usuario = {
                    _id: uid,
                    nombre
                };
                dispatch( usuarioAddNew(user) );
            } else {
                Swal.fire('Error', body.msg, 'error');
            } 
        } catch (error) {
            console.log(error);
        }
    }
}




export const superListaStart = (datos) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken(`admin/listuser/${datos.estado}/${datos.user}`);
            const body = await resp.json();
            if( body.ok ){
                const admin = body.usuario;
                dispatch( usuarioLista(admin) );
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            console.log(error);
        }
    }           
}





export const cambioEstaUsuario = (user) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken(`admin/cambioestado/${ user.id }`, user, 'PUT');
            const body = await resp.json();
  
            if( body.ok ){
                Swal.fire('Exitoso', 'Usuario Cambio de Estado', 'success');
                dispatch( usuarioUpdate(body.usuario) );
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}



export const usuarioUpdateStart = (usuario) => {
    return async( dispatch ) => {

        try {

            const resp = await fetchConToken(`admin/${ usuario.id }`, usuario, 'PUT');
            const body = await resp.json();

            if( body.ok ) {
                Swal.fire('Exitoso', 'Usuario Actualizado', 'success');
                dispatch( usuarioUpdate(body.usuario) );
            } else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }

    }
}




const usuarioUpdate = ( usuario ) => ({
    type: types.usuarioUpdate,
    payload: usuario
});



const usuarioAddNew = ( user ) => ({
    type: types.usuarioAddNew,
    payload: user
});




const usuarioDelete = ( user ) => ({ 
    type: types.usuarioDelete,
    payload: user
});


// Activar Usuario en el Redux
const usuarioActivo = ( user ) => ({
    type: types.usuarioActivo,
    payload: user
});


// Listados de Usuarios
const usuarioLista = ( usuarios ) => ({
    type: types.usuarioLista,
    payload: usuarios 
});



//  Desactivar Usuario Activo en el Redux
export const usuarioDeleteActivo = () => ({ type: types.usuarioDeleteActivo });






















export const cambioClaveStart = (claves) => {
    return async( dispatch ) => {

        try {

            const resp = await fetchConToken(`admin/cambio-clave`, claves, 'PUT');
            const body = await resp.json();

            if( body.ok ) {
                Swal.fire('Exitoso', 'Cambio de Clave Exitoso', 'success');
            } else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }

    }
}


// Recuperacion de Password
export const recuperarPasswordAdmin = ( email ) => {
    return async( dispatch ) => {
        
        try {
            const resp = await fetchSinToken(`admin/olvido-password`, email, 'POST');
            const body = await resp.json();
            
            if( body.ok ){
                Swal.fire('Exitoso', body.msg, 'success');
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}


export const validarTokenParaPasswordAdmin = ( token ) => {
    return async( dispatch ) => {
        
        try {
            const resp = await fetchSinToken(`admin/olvido-password/${ token }`);
            const body = await resp.json();
            
            if( body.ok ){
                Swal.fire('Exitoso', body.msg, 'success');
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}



export const almacenarNuevoPasswordAdmin = ( token, password ) => {
    return async( dispatch ) => {
        
        try {
            const resp = await fetchSinToken(`admin/olvido-password/${ token }`, password, 'POST');
            const body = await resp.json();
            
            if( body.ok ){
                Swal.fire('Exitoso', body.msg, 'success');
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}



export const cambiarPasswordPorElAdmin = ( user ) => {
    return async( dispatch ) => {
        
        try {
            const resp = await fetchConToken(`admin/password-cambiar/${ user.uid }`, user, 'POST');
            const body = await resp.json();
            
            if( body.ok ){
                Swal.fire('Exitoso', body.msg, 'success');
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}