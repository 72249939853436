
import { useNavigate } from "react-router-dom";

import { Box, Grid, IconButton, Typography } from "@mui/material";
import { AddOutlined, ArrowBack } from "@mui/icons-material";





export const LayoutReturnAndAdd = ({ children, titulo = 'No hay Datos', url= '', funcion }) => {

    
    const navigate = useNavigate();


    const handleReturn = () => {
        navigate(url);
    }


    const agregarInformacion = () => {
        funcion();
    }



    return (

        <>
            <Grid container direccion='row' alignItems='center'>
                <IconButton
                    sx={{ color: '#fff', backgroundColor: 'secondary.main',
                        ':hover': { backgroundColor: 'secondary.main', opacity: 0.3 } }}
                    onClick={ handleReturn } 
                >
                    <ArrowBack />
                </IconButton>

                <Box flex={ 1 } />

                <Typography variant='h2'>{ titulo }</Typography>

                <Box flex={ 1 } />

                <IconButton
                    sx={{ color: '#fff', backgroundColor: 'secondary.main',
                        ':hover': { backgroundColor: 'secondary.main', opacity: 0.3 } }}
                    onClick={ agregarInformacion } 
                >
                    <AddOutlined />
                </IconButton>

            </Grid>

            <Grid container alignItems='center' justifyContent='center' >


                { children }


            </Grid>
        </>
    )
}
