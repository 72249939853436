import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { opPorID, updateOpTodo } from "../../actions/op";
import { startUploadingPagos } from "../../actions/uploads";

const baseUrl = process.env.REACT_APP_API_URL;



export const SubirImagenPago = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { activeOP } = useSelector( state => state.op );
    const { id } = useParams();


    useEffect(() => {
        if( id ) {
            dispatch(opPorID( id ));
        }
    }, [ id, dispatch ] );


    useEffect(() => {
        if( activeOP ) {
            setBanco({
                ...qbanco,
                banco: activeOP.banco
            });
        }
    }, [activeOP] );


    const handlePictureClick = () => {
        document.querySelector('#fileSelector').click();
    }


  const [ qbanco, setBanco ] = useState({ banco: '' });
  const { banco } = qbanco;

  const handleCambioBanco = ({ target }) => {
    setBanco({
        ...qbanco,
        [target.name]: target.value
      });
  }


    const handleBanco = () => {
        dispatch(updateOpTodo({ banco, id }) );
    }



    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if( file ) {
            dispatch( startUploadingPagos( activeOP.id, 'pagos', file ) );
            // dispatch(opPorID( id ));
        }
    }


    const handleReturn = () => {
        navigate(-1)
    }



  return (
    
        <div className="img-pago">


            <div className="atras">
                <button className="boton-redondo" 
                    onClick={ handleReturn } 
                >
                    <i className="fa-solid fa-circle-arrow-left"></i>
                </button> 
            </div>

            {
                activeOP && (
                <h3>O.P:  { activeOP.op }</h3>
            )}
            <div className="contenedor-image-pago">
                {
                    ( activeOP )
                    ? <img src={`${ baseUrl }/uploads/pagos/${ activeOP.imgPago }`} alt="Imagen Categoria" />
                    : <img src={`${ baseUrl }/uploads/categorias/no-img.jpg`} alt="Imagen Categoria" />
                }

                <input type="file" 
                    id="fileSelector"
                    className="boton-file"
                    onChange={ handleFileChange }
                    name="file"
                />

                <div className="boton-pago">
                    <button className="btn subirimg"
                        onClick={ handlePictureClick }
                    > 
                        Subir Imagen
                    </button>
                </div>

                <div className="bancos">
                    <select type="text" required 
                        className="seleccioneeee"
                        name="banco"
                        value={ banco }
                        onChange={ handleCambioBanco } 
                    >
                        <option value="">Seleccione Un Banco</option>
                        <option value="Nequi">Nequi</option>
                        <option value="Daviplata">Daviplata</option>
                        <option value="Bancolombia">Bancolombia</option>
                        <option value="Daviviendad">Daviviendad</option>
                        <option value="Efectivo">Efectivo</option>
                    </select>
                </div>

                <div className="boton-pago pagooo">
                    <button className="botonCrear btn"
                        onClick={ handleBanco }
                    > 
                        Enviar
                    </button>
                </div>

            </div>
        </div>


  )



}
