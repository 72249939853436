
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { MensajeAlertaDanger, TooltipAndIcon } from "../../components";





export const TablaInventarios = () => {

    const navigate = useNavigate();
    const { inventarios } = useSelector( state => state.inventario );
    

    const handleVerInventario = (inventario) => {
        navigate(`/super/reportes/${inventario._id}`);
    }


    const handleDetalles = (inventario) => {
        navigate(`/super/stock/${inventario._id}/update`);
    }



    return (

        <>

        {(inventarios.length > 0)
        ?   <TableContainer sx={{ mt:5 }}>
                <Table sx={{ minWidth: 700, }} aria-label="customized table">

                    <TableHead >
                        <TableRow>
                            <TableCell>Producto</TableCell>
                            <TableCell>Cantidad</TableCell>
                            <TableCell>UnidadMedida</TableCell>
                            <TableCell>Tipo</TableCell>
                            <TableCell>Acciones</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                    {inventarios.map( ( a ) => (
                        <TableRow  key={ a._id }>
                            <TableCell>{ a.producto.nombre }</TableCell>
                            <TableCell>{ a.cantidad }</TableCell>
                            <TableCell>{ (a.medida) ? a.medida.nombre :<Typography variant="span" color='error'>No Hay Datos</Typography> }</TableCell>
                            <TableCell>{ a.tipo }</TableCell>

                            <TableCell>
                                <Stack direction="row" spacing={1} justifyContent="center">
                                    <TooltipAndIcon
                                        titulo="Detalles de Registro"
                                        icono="ver"
                                        color="white"
                                        onClick={ () => handleVerInventario(a) }
                                    />  

                                    <TooltipAndIcon
                                        titulo="Editar Stock"
                                        icono="editar"
                                        color="white"
                                        onClick={ () => handleDetalles(a) }
                                    />  
                                </Stack>
                            </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>

                </Table>
            </TableContainer>

        :   <MensajeAlertaDanger titulo="No Existen Datos De Inventario" />
        }
    </>

    )
}
