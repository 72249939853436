
import { Typography, Backdrop } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';


export const BackdropScreen = ({ cargando, titulo }) => {


    return (

            <Backdrop
                sx={{ color: 'white', zIndex: (theme) => theme.zIndex.drawer + 1, display: 'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', backgroundColor:'rgba(0,0,0,0.9)' }}
                open={cargando}
                // onClick={handleClose}
            >
                <CircularProgress color="inherit" />
                <Typography sx={{ fontSize:23, fontWeight:'bold', mt:2 }} >{ titulo }</Typography>
            </Backdrop>

    )




}
