
import { TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';




export const DateFormScreen = ({ label, bloqueo=false, ...props }) => {


                            

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label={label}
                    disabled={bloqueo} 
                    value={(props.value) ? dayjs(props.value) : null} 
                    onChange={ props.onChange }
                    renderInput={(params) => <TextField {...params} variant="filled" fullWidth />}
                />
            </LocalizationProvider>
        </>
    )
}

