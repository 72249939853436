import Swal from "sweetalert2";
import { fetchConToken, fetchSinToken } from "../helpers/fetch";
import { types } from "../types/types";





export const productosStartAddNew = ( producto ) => {
    return async( dispatch ) =>{

        try {

            const resp = await fetchConToken( 'producto/new', producto, 'POST' );
            const body = await resp.json();

            if( body.ok ) {

                Swal.fire('Exitoso', 'Producto Creado', 'success');
                producto.id = body.producto.id;

                dispatch( productosAddNew( producto ) );

            } else {
                Swal.fire('Error', body.msg, 'error');
            } 
            
        } catch (error) {
            console.log(error);
        }
    }
}

const productosAddNew = ( producto ) => ({
    type: types.productosAddNew,
    payload: producto
});



export const productosListaStart = ( desde ) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken(`producto/todos?desde=${ desde }`);
            const body = await resp.json();

            if( body.ok ){
                const productos = body.producto;
                dispatch( productosLista(productos) );
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}


export const productosListaStartTodos = ( desde ) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken(`producto/todos/todos`);
            const body = await resp.json();

            if( body.ok ){
                const productos = body.producto;
                dispatch( productosLista(productos) );
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}



export const productosTodosCotizacion = ( desde ) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken(`producto/todos/cotizacion`);
            const body = await resp.json();

            if( body.ok ){
                const productos = body.producto;
                dispatch( productosLista(productos) );
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}







export const productosListaCategoriaStart = ( id ) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchSinToken(`producto/categoria/${ id }`);
            const body = await resp.json();

            if( body.ok ){
                const productos = body.productos
                dispatch( productosLista(productos) );
            }else {
                dispatch(productosListaDelete() );
                // Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}


const productosListaDelete = ( productos ) => ({
    type: types.productosListaDelete, 
});


export const productosLista = ( productos ) => ({
    type: types.productosLista,
    payload: productos 
});




export const productosSoloTerminadosStart = ( ) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken(`producto/solo-terminados`);
            const body = await resp.json();

            if( body.ok ){
                const productos = body.productos;
                dispatch( productosSoloTerminados(productos) );
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}

const productosSoloTerminados = ( productos ) => ({
    type: types.productosSoloTerminados,
    payload: productos 
});





export const productosStartDelete = (producto) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken(`producto/${ producto.id }`, {}, 'DELETE');
            const body = await resp.json();

            if( body.ok ){
                dispatch( productosDelete(producto) );
                Swal.fire('Exitoso', 'Producto Eliminado', 'success');
            }else {
                Swal.fire('Error', body.msg, 'error');
            }

            
        } catch (error) {
            console.log(error);
        }

    }
}

const productosDelete = ( producto ) => ({ 
    type: types.productosDelete,
    payload: producto
});



export const productoPorID = ( id ) => {
    return async(dispatch) => {

        try {

            const resp = await fetchConToken(`producto/producto/${ id }`);
            const body = await resp.json();

            if( body.ok ){
                dispatch( productosActivo(body.producto) );
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}

// Activar Proucto en el Redux
const productosActivo = ( producto ) => ({
    type: types.productosActivo,
    payload: producto
});



export const obtenerTerminadoID = ( id ) => {
    return async(dispatch) => {


        try {

            if( id.length < 3 ){
                return dispatch( terminadoActivo({
                    nombre: 'No Existe',
                    costoMateria: 0,
                    costoTotal: 0,
                    precioDistri: 0,
                    precioVenta: 0,
                }) );
            }

            const resp = await fetchConToken(`producto/producto/${ id }`);
            const body = await resp.json();
            if( body.ok ){
                dispatch( terminadoActivo(body.producto) );
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}

const terminadoActivo = ( producto ) => ({
    type: types.terminadoActivo,
    payload: producto
});





export const productosUpdateStart = (producto) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken(`producto/${ producto.id }`, producto, 'PUT');
            const body = await resp.json();

            if( body.ok ) {
                dispatch( productosUpdate(producto) );
                Swal.fire('Exitoso', 'Producto Actualizado', 'success');
            } else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }

    }
}


const productosUpdate = ( producto ) => ({
    type: types.productosUpdate,
    payload: producto
});


export const productosDeleteActivo = () => ({ type: types.productosDeleteActivo });



export const addProductoInventario = ( inventario ) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken(`producto/add-inventa/${ inventario.id }`, inventario, 'PUT');
            const body = await resp.json();

            if( body.ok ){
                const producto = body.producto;
                dispatch( productosUpdate(producto) );
                Swal.fire('Exitoso', 'Producto Sumado', 'success');
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}



export const substraProductoInventario = ( inventario ) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken(`producto/subs-inventa/${ inventario.id }`, inventario, 'PUT');
            const body = await resp.json();

            if( body.ok ){
                const producto = body.producto;
                dispatch( productosUpdate(producto) );
                Swal.fire('Exitoso', 'Producto Restado', 'success');
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}





export const obtenerPublicidad = () => {
    return async(dispatch) => {

        try {
            const resp = await fetchSinToken(`producto/publicidad`);
            const body = await resp.json();
            if( body.ok ){
                const publicidad = body.publicidad;
                const presonalizado = body.personalizados;
                const servicio = body.servicio;
                dispatch( listadoPublicidad( publicidad ) );
                dispatch( listadoPersonalizados( presonalizado ) );
                dispatch( listadoServicioTecnico( servicio ) );
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}



const listadoPublicidad = ( publicidad ) => ({
    type: types.listadoPublicidad,
    payload: publicidad
});


const listadoPersonalizados = ( personalizado ) => ({
    type: types.listadoPersonalizados,
    payload: personalizado
});


const listadoServicioTecnico = ( servicio ) => ({
    type: types.listadoServicioTecnico,
    payload: servicio
});





export const terminadoActivoDelete = ( producto ) => ({
    type: types.terminadoActivoDelete,
    payload: producto
});