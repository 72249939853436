
import { Box, Grid, Typography } from "@mui/material";





export const LayoutSoloTitulo = ({ children, titulo }) => {

    return (

        <Grid sx={{ minHeight:'100vh', px:8, pt:5}}>
            <Grid container direccion='row' alignItems='center'>
                <Box flex={ 1 } />
                <Typography variant='h1'>{ titulo }</Typography>
                <Box flex={ 1 } />
            </Grid>

            <Grid container alignItems='center' justifyContent='center' >
                { children }
            </Grid>
        </Grid>

    )

    
}
