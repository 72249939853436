
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";
import { separadorMillares } from "../../../hooks/useMillares";


const basePrecio = +process.env.REACT_APP_PRECIO_BASE;



export const ViewPrecioRolMinimoSeis = ({ formCarrito }) => {


    const { view } = useSelector( state => state.iva );
    const { ancho, alto, totalCliente, totalDistri, totalEmpresa, totalPublicista } = formCarrito;



    const handleSwitch = (role) => {
        switch(role){
            case "cliente":
            return   <div className="comprar-role">
                <Typography variant="h5">Total Cliente</Typography>
                {
                (ancho > 0 && alto > 0) 
                ?<Typography variant="h5">{ `$${ separadorMillares(( totalCliente < basePrecio ) ? basePrecio : totalCliente) }` }</Typography>
                :<Typography variant="h5">$0</Typography>
                }
            </div> 

            case "distri": 
            return   <div className="comprar-role">
                <Typography variant="h5" color={'primary'}>Total Distribuidor</Typography>
                {
                (ancho > 0 && alto > 0)
                ?<Typography variant="h5" color={'primary'}>{ `$${ separadorMillares((totalDistri < basePrecio ) ? basePrecio : totalDistri) }` }</Typography>
                :<Typography variant="h5" color={'primary'}>$0</Typography>
                }
            </div>

            case "disenio": 
            return <div className="comprar-role">
                <Typography variant="h5"color={'secondary'}>Total Publicista</Typography>
                {
                (ancho > 0 && alto > 0)
                ?<Typography variant="h5"color={'secondary'}>{ `$${ separadorMillares((totalPublicista < basePrecio ) ? basePrecio : totalPublicista) }` }</Typography>
                :<Typography variant="h5"color={'secondary'}>$0</Typography>
                }
            </div>

            case "empresa":
            return <div className="comprar-role">
                <Typography variant="h5" color={'#b27d00'}>Total Empresa</Typography>
                {
                (ancho > 0 && alto > 0)
                ?<Typography variant="h5" color={'#b27d00'}>{ `$${ separadorMillares((totalEmpresa < basePrecio ) ? basePrecio : totalEmpresa) }` }</Typography>
                :<Typography variant="h5" color={'#b27d00'}>$0</Typography>
                }
            </div>

            default:
                return;
        }
    }




    return (

        <>
        {
        view && 
        <div className="caja-precios">
            {
                handleSwitch(view)
            }
        </div>
        }
        </>


    )

    
}
