
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { LayoutListados } from "../../layouts";
import { TablaListadoCategoria } from "../components";
import { categoriaListaStart } from "../../../actions/categoria";




export const ListadoCategorias = () => {


    const dispatch = useDispatch();



    useEffect( () => {
        dispatch( categoriaListaStart() );
    }, [ dispatch ] );


    return (


        <LayoutListados  title={'Listado de Categorias'} >
            <TablaListadoCategoria />
        </LayoutListados>



    )


    
}
