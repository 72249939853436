
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { Avatar, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';


import { LayoutModalAdd } from "../../layouts";
import { deleteStartSlide, slideListaStart } from "../../../actions/slideshow";
import { CrearSlideShow } from "./CrearSlideShow";
import Swal from "sweetalert2";



const baseUrl = process.env.REACT_APP_API_URL;



export const ConfiguracionSlideShow = () => {

    const dispatch = useDispatch();
    const { slideshow } = useSelector(state => state.slideshow);


    useEffect(() => {
        dispatch( slideListaStart() );
    }, [ dispatch ]);
    


    const handleEliminar = (slide) => {
        Swal.fire({
            title: 'Eliminar SlideShow',
            text: `¿Esta Seguro De Eliminar Este SlideShow?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Obvio Si' 
        }).then( ( result ) => {
            if(result.value){
                dispatch( deleteStartSlide( slide.id ) );
            }
        })
    }


    // const handleUpdateslide = (sli) => {
    //     // navigate(`/admin/actualizar-inm/${ inm.id }`);
    //     console.log(sli);
    // }


    return (

        <LayoutModalAdd titulo={'Listado de SlideShow'}>

            <TableContainer sx={{ my:'15px' }}>
                <Table sx={{ minWidth: 700, }} aria-label="customized table">

                    <TableHead >
                        <TableRow>
                            <TableCell className="w-350">Imagen</TableCell>
                            <TableCell >Titulo</TableCell>
                            <TableCell >Acciones</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {
                        slideshow.map( (s) => (
                        <TableRow  key={ s.id }>

                            <TableCell sx={{ display:'flex', justifyContent:'center' }}>
                                <Avatar
                                    alt="Imagen SlideShow"
                                    src={`${ baseUrl }/uploads/slideshow/${ s.img }`} 
                                    sx={{ width: 80, height: 70 }}
                                /> 
                            </TableCell>
                            <TableCell>
                                { (s.titulo)
                                    ? s.titulo
                                    :'No Hay.'
                                }
                            </TableCell>

                            <TableCell>
                                <IconButton className="icon-boton" onClick={ () => handleEliminar(s) } >
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            </TableCell>
                        
                        </TableRow>
                        ))
                        }
                    </TableBody>

                </Table>
            </TableContainer>



            <CrearSlideShow />



        </LayoutModalAdd>
        
    )


}
