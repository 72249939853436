
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button, Card, CardContent, CardMedia, Grid, Stack, Typography } from "@mui/material";
import { carritoActivoParaUpdate, carritoUpdateValorRol } from "../../../actions/carrito";
import { TextFormNumber, TooltipAndIcon } from "../../components";
import { separadorMillares, usePrecioSegunRol } from "../../../hooks";
import { useAdmin } from "../../../hooks/useAdmin";

import Swal from "sweetalert2";
const baseUrl = process.env.REACT_APP_API_URL;




export const CardsComprarAhora = ({ carritoCompra, urlCotiz, urlDetalle, urlModificar, functionDelete }) => {

    const cliente = JSON.parse(localStorage.getItem('cliente') ) || '';

    const debounceRef = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const admin = useAdmin();
    const { view } = useSelector( state => state.iva );
    const { nombre } = useSelector( state => state.admin );
    const { precioSegunRol } = usePrecioSegunRol();



    const handleDelete = async(id) => {
        const resp = await Swal.fire({
            title: 'Eliminar Item Del Carrito',
            text: `¿Esta seguro de eliminar este item del carrito?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si' 
        });
        if(resp.value){
            functionDelete(id);
        }
    }



    const handleModificarItem = async(item) => {
        const resp = await Swal.fire({
            title: 'Modificar Item',
            text: `¿Esta seguro de modifcar los valores de este item, cantidad, color, medidas etc..?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si' 
        });
        if(resp.value){
            localStorage.setItem('item-carrito', JSON.stringify(item));
            dispatch(carritoActivoParaUpdate(item));
            navigate(`${urlModificar}/${item.producto._id }`);
        }
    }




    const handleDetalleItem = (id) => {
        navigate(`${urlDetalle}/${id}`);
    }


    

    const handleModificarPrecioItem = async(item, value) => {
        if( debounceRef.current )
            clearTimeout( debounceRef.current );

        debounceRef.current = setTimeout( () => {
            if( value.trim().length > 0 ){
                dispatch(carritoUpdateValorRol({...item, value, view}));
            }
        }, 600);
    }



    const VerCotizacion = () => {
        navigate(`${urlCotiz}`);
    }



    return (

        <Grid container>
            <Box sx={{width:'100%', mb:2, display:'flex', justifyContent:'space-between'}}>
                <Typography variant="h4" sx={{fontSize:'25px'}}>Items Disponibles</Typography>
                {(carritoCompra.length > 0 && cliente) &&
                    <Button color='secondary' variant="contained" onClick={ VerCotizacion }>
                        Ver Cotización
                    </Button>
                }
            </Box>

            <Stack spacing={2} direction="column" sx={{width:'100%'}}>
                {carritoCompra.map( car => (
                <Card key={car.id} sx={{width:'100%', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                    <CardContent sx={{width:"170px"}}>
                        <CardMedia
                            component="img"
                            width="100%"
                            height="100"
                            image={`${ baseUrl }/uploads/productos/${ car.producto.img }`}
                            alt="Imagen Item"
                        />
                    </CardContent>
                    
                    <CardContent sx={{width:"190px"}}>
                        <Typography variant="span" component="div" textAlign="center">
                            {car.producto.nombre}
                        </Typography>
                    </CardContent>

                    <CardContent sx={{width:"150px"}}>
                        {( nombre && admin )
                        ?   <Box className="input-carrito" sx={{mb:0.5}}>
                                <TextFormNumber
                                    size="small"
                                    fullWidth
                                    value={precioSegunRol(car)}
                                    onChange={ (e) => handleModificarPrecioItem(car, e.target.value) } 
                                />
                            </Box>

                        :   <Typography variant="h3" sx={{textAlign:'center', fontSize:'15px'}}>
                                {`$${separadorMillares(precioSegunRol(car))}`}
                            </Typography>
                        }
                        
                        <Typography variant="h3" sx={{textAlign:'center', fontSize:'15px'}} >
                            {`$${separadorMillares(precioSegunRol(car) / car.cantidad)}`}
                        </Typography>
                    </CardContent>

                    <CardContent>
                        <Typography variant="span" component="div" textAlign="center">
                            Cantidad
                        </Typography>
                        <Typography variant="span" component="div" textAlign="center">
                            { car.cantidad }
                        </Typography>
                    </CardContent>

                    <CardContent sx={{width:80}}>
                        <Stack spacing={0.5} direction="column" sx={{width:30}}>
                            <TooltipAndIcon
                                titulo="Detalles del item"
                                icono="ver"
                                color="white"
                                onClick={ () => handleDetalleItem( car.id ) }
                            />

                            <TooltipAndIcon
                                titulo="Editar Item"
                                icono="editar"
                                color="white"
                                onClick={ () => handleModificarItem( car ) }
                            /> 

                            <TooltipAndIcon
                                titulo="Eliminar Item del Carrito"
                                icono="eliminar"
                                color="red"
                                onClick={ () => handleDelete( car.id ) }
                            /> 
                        </Stack>
                    </CardContent>
                </Card>
                ))}
            </Stack>
        </Grid>

    )
}
