
import { useNavigate } from "react-router-dom";

import { Box, Grid, IconButton, Typography } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';







export const LayoutReturn = ({ children, titulo = 'No hay Datos', url, admin=true }) => {


    const navigate = useNavigate();



    const handleReturn = () => {
        navigate(url);
    }



    return (

        <>
            {(admin) &&
            <Grid container direccion='row' alignItems='center'>
                <IconButton
                    sx={{ color: '#fff', backgroundColor: 'secondary.main',
                        ':hover': { backgroundColor: 'secondary.main', opacity: 0.3 }, }}
                    onClick={ handleReturn } 
                >
                    <ArrowBackIcon />
                </IconButton>

                <Box flex={ 1 } />
                <Typography variant='h2'>{ titulo }</Typography>

                <Box flex={ 1 } />
                
            </Grid>
            }

            <Grid container alignItems='center' justifyContent='center' >
                { children }
            </Grid>
        
        </>


        
    )




}
