
import { useEffect } from "react"
import { useDispatch } from "react-redux"

import { LayoutArchivado } from "../../layouts"
import { TablaListadoCotizaciones } from "../components";
import { listAllCotizaciones } from "../../../actions/cotizacion";





export const ListadoTodasCotizaciones = () => {


    const dispatch = useDispatch();



    useEffect(() => {
        dispatch(listAllCotizaciones())
    }, [dispatch])
    





    return (
        <LayoutArchivado titulo="Listado Cotizaciones" url="">
            <TablaListadoCotizaciones />
        </LayoutArchivado>
    )
}
