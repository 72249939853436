import { types } from "../types/types";



const initialState = {
    gastos: [ ],
    activeGasto: null
};




export const gastoReducer = ( state = initialState, action ) => {

    switch ( action.type ) {
        case types.gastosAddNew:
            return {
                ...state,
                gastos: [ ...state.gastos, action.payload ]
            };

        case types.gastosLista:
            return {
                ...state,
                gastos: [ ...action.payload ]
            };


        case types.gastosActivo:
            return {
                ...state,
                activeGasto: action.payload
            };

        default:
            return state;
    }

}
