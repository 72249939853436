import Swal from "sweetalert2";
import { fetchConTokenCliente } from "../helpers/fetch";
import { prepararFechaAbono, prepararFechaPorId } from "../helpers/prepararFecha";
import { types } from "../types/types";







export const updateOpTodoCliente = ( op ) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConTokenCliente(`op/${ op.id }`, op, 'PUT');
            const body = await resp.json();
            
            if( body.ok ){
                dispatch( opUpdate(op) );
                Swal.fire('Exitoso', 'O.P. Actualizada', 'success');
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}


const opUpdate = ( op ) => ({
    type: types.opUpdate,
    payload: op 
});


export const opPorIDCliente = ( id ) => {
    return async( dispatch ) => {

        try {

            const resp = await fetchConTokenCliente(`op/opxid/${ id }`);
            const body = await resp.json();

            if( body.ok ){
                const op = prepararFechaPorId( body.op );
                dispatch( opActivo(op) );
            }else {
                Swal.fire('Error', body.msg, 'error');
            }

        } catch (error) {
            console.log(error);
        }
    }
}


export const opPorIDConsultaCliente = ( id ) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConTokenCliente(`op/opxid/${ id }`);
            const body = await resp.json();

            if( body.ok ){
                const op = prepararFechaPorId( body.op );
                dispatch( opActivo(op) );
            }else {
                Swal.fire('Error', body.msg, 'error');
            }

        } catch (error) {
            console.log(error);
        }
    }
}


// Activar Proucto en el Redux
const opActivo = ( op ) => ({
    type: types.opActivo,
    payload: op
});



// Servicio de Abonos del lado de Cliente
export const abonoListaStartCliente = ( id ) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConTokenCliente(`abono/abono-op/${ id }`);
            const body = await resp.json();

            if( body.ok ){
                const abonos = prepararFechaAbono(body.abono);
                dispatch( abonosLista(abonos) );
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}

const abonosLista = ( abono ) => ({
    type: types.abonosLista,
    payload: abono 
});