

import { Fab, Box } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { green } from "@mui/material/colors"






export const FloatingActionButtom = () => {


    return (
        // <a href="http://wa.me/573165771648?text=Area%20Comercial%20Seven%20For%20Ever." 

        <>
            <a href="http://wa.me/573165771648?text=Hola%20Estoy%20Interesado%20En%20Un%20Producto." 
                target="_blank">
              <Box sx={{ position:'fixed', bottom:20, right:20, zIndex:9999 }} >
                  <Fab sx={{ bgcolor: green[500] }}>
                      <WhatsAppIcon sx={{fontSize: 35, color: 'white'}} />
                  </Fab>
              </Box>
            </a>
        </>


    )


}
