import { types } from "../types/types";


const initialState = {
    cheking: true,
    // uid: null,
    // nombre: null,
    // role: null
};


export const authAdminReducer = ( state = initialState, action ) => {


    switch ( action.type ) {

        case types.authSuperChekingLogin:
            return {
                ...state,
                ...action.payload,
                cheking: false
            };

        case types.authSuperChekingFinish:
            return {
                ...state,
                cheking: false
            };

        case types.authSuperLogout:
            return {
                cheking: false,
            };
            
    
        default:
            return state;

    }
}
