
import { useSelector } from "react-redux";
import { ModalCrearCliente } from "../components/ModalCrearCliente";

import { ClienteCheckout, ClienteCheckoutPorDistri, DistribuidorCheckout, DistriPropioCheckout, EmpresaCheckout, PublicistaCheckout } from "./";





export const CheckoutScreen = () => {


  const { usuario } = useSelector( state => state.admin );
  const { view } = useSelector( state => state.iva );
  



    const viewsAdmin = (view) => {
        switch (view) {
          case 'cliente':
            return <ClienteCheckout />
          case 'distri':
            return <DistribuidorCheckout />
            case 'disenio':
              return <PublicistaCheckout />
            case 'empresa':
              return <EmpresaCheckout />
          default:
            break;
        }
    }





    return (


        <>

          <>
            {
              (usuario.role === 'SUPERADMIN' || usuario.role === 'ADMIN') && (
                <>
                  {
                    viewsAdmin(view)
                  }
                </>
              )
            }
          </>

          <>
            {
              ( usuario.role === 'DISTRIBUIDOR' ) && (
                <>
                  {
                    ( view === 'cliente' ) && 
                    <ClienteCheckoutPorDistri />
                  }

                  {
                    ( view === 'distri' ) && 
                    <DistriPropioCheckout />
                  }
                </>
              )
            }
          </>

          <>
            {
              (usuario.role === 'PUBLICISTA') && 
                <DistriPropioCheckout />
            }
          </>

          <>
            {
              (usuario.role === 'EMPRESA') && 
                <DistriPropioCheckout />
            }
          </>

        
          <ModalCrearCliente />
        
        </>


        


    
    )



}
