
import { fetchConToken } from "../helpers/fetch";
import { prepararFechaAbono, prepararFechaUnAbono } from "../helpers/prepararFecha";
import { types } from "../types/types";
import Swal from "sweetalert2";






// ========================== Servicio Funcionando  =========================================
export const abonoStartAddNew = ( abono ) => {
    return async( dispatch ) =>{

        try {
            const resp = await fetchConToken( 'abono/new', abono, 'POST' );
            const body = await resp.json();

            if( body.ok ) {
                Swal.fire('Exitoso', 'Abono Creado', 'success');
                const abonoFecha = prepararFechaUnAbono(body.abono);
                dispatch( abonosAddNew(abonoFecha) );

            } else {
                Swal.fire('Error', body.msg, 'error');
            } 
            
        } catch (error) {
            console.log(error);
        }
    }
}


export const abonoListaStart = ( id ) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken(`abono/abono-op/${ id }`);
            const body = await resp.json();

            if( body.ok ){
                const abonos = prepararFechaAbono(body.abono);
                dispatch( abonosLista(abonos) );
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}

export const abonoStartDelete = (abono) => {
    return async( dispatch ) => {

        try {

            const resp = await fetchConToken(`abono/${ abono.id }`, {}, 'DELETE');
            const body = await resp.json();

            if( body.ok ){
                dispatch( abonosDelete(abono) );
                Swal.fire('Exitoso', 'Abono Eliminado', 'success');
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}


const abonosDelete = ( abono ) => ({ 
    type: types.abonosDelete,
    payload: abono
});



const abonosLista = ( abono ) => ({
    type: types.abonosLista,
    payload: abono 
});

const abonosAddNew = ( abono ) => ({
    type: types.abonosAddNew,
    payload: abono
});



export const abonosActivo = ( abono ) => ({
    type: types.abonosActivo,
    payload: abono
});


export const limpiarListaAbono = () => ({
    type: types.abonosLista,
    payload: [] 
});



































export const abonosDeleteActivo = () => ({
    type: types.abonosDeleteActivo,
});