
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { superListaStart } from "../../../actions/usuario";
import { LayoutArchivado } from "../../layouts";
import { BuscarUsuario, TablaListadoUsuarios } from "../components";




export const ListadoUsuarios = () => {


    const dispatch = useDispatch();


    useEffect( () => {
        dispatch( superListaStart({estado: true}) );
    }, [dispatch]);




    return (


        <LayoutArchivado titulo={'Listado de Usuarios'} url='/super/super-archi'>
            <BuscarUsuario activo={true}/>
            <TablaListadoUsuarios />
        </LayoutArchivado>


    )
}
