

import { useDispatch } from "react-redux";

import { IconButton, Tooltip } from "@mui/material";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';



import { openModalAbonos } from "../../../actions";
import { abonosActivo, abonoStartDelete } from "../../../actions/abono";
import Swal from "sweetalert2";




export const OpcionesAbono = ({ a }) => {



    const dispatch = useDispatch();



    const handleEliminar = ( abono ) => {
        Swal.fire({
            title: 'Eliminar Abono',
            text: `¿Esta Seguro De Eliminar Abono?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Obvio Si' 
          }).then( ( result ) => {
            if(result.value){
                dispatch( abonoStartDelete( abono ) );
            }
          })
    }


    const  subirImagen = (a) => {
        dispatch( abonosActivo(a) );
        document.querySelector('#fileSelector').click();
    }

    const handleVerImg = (a) => {
        dispatch( abonosActivo(a) );
        dispatch( openModalAbonos() );

    }


  return (

    <>

        <Tooltip title="Subir Imagen de Abono" placement="top">
            <IconButton className="icon-boton"  onClick={ () => subirImagen(a) } >
                <AddAPhotoIcon fontSize="small" />
            </IconButton>
        </Tooltip>

        <Tooltip title="Ver Imagen de Abono" placement="top">
            <IconButton className="icon-boton"  onClick={ () => handleVerImg(a) } >
                <VisibilityIcon fontSize="small" />
            </IconButton>
        </Tooltip>

        <Tooltip title="Eliminar Abono" placement="top">
            <IconButton className="icon-boton"  onClick={ () => handleEliminar(a) } >
                <DeleteIcon fontSize="small" />
            </IconButton>
        </Tooltip>

    </>

  )



}
