
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { fetchConToken } from "../helpers/fetch";
import { prepararFechaPorIdTrasa, prepararFechaTrasabilidad } from "../helpers/prepararFecha";
import { types } from "../types/types";




export const trasaStartAddNew = ( trasa ) => {
    return async( dispatch ) =>{


        try {
            const resp = await fetchConToken( 'trasabili/new', trasa, 'POST' );
            const body = await resp.json();

            if( body.ok ) {
                
                const tras = body.trasabilidad
                dispatch( trasabilidadAddNew(tras) );

            } else {
                Swal.fire('Error', body.msg, 'error');
            } 
            
        } catch (error) {
            console.log(error);
        }
    }
}


const trasabilidadAddNew = ( trasa ) => ({
    type: types.trasabilidadAddNew,
    payload: trasa
});


































export const trasaListaStart = ( id ) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken(`trasabili/trasa-op/${ id }`);
            const body = await resp.json();

            if( body.ok ){
                const trasa = prepararFechaTrasabilidad(body.trasabilidad);
                dispatch( trasabilidadLista(trasa) );
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}

const trasabilidadLista = ( trasa ) => ({
    type: types.trasabilidadLista,
    payload: trasa 
});



export const trasaStartDelete = (trasa) => {
    return async( dispatch ) => {

        try {

            const resp = await fetchConToken(`trasabili/${ trasa.id }`, {}, 'DELETE');
            const body = await resp.json();

            if( body.ok ){
                dispatch( trasabilidadDelete(trasa) );
                Swal.fire('Exitoso', 'Registro Eliminado', 'success');
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}


const trasabilidadDelete = ( trasa ) => ({ 
    type: types.trasabilidadDelete,
    payload: trasa
});




export const trasaStartUpdate = (trasa) => {
    return async( dispatch ) => {

        try {

            const resp = await fetchConToken(`trasabili/${ trasa.id }`, {}, 'PUT');
            const body = await resp.json();

            if( body.ok ){
                dispatch( trasabilidadUpdate(body.trasabilidad) );
                Swal.fire('Exitoso', 'Cerrado Trabajo OP', 'success');
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}


const trasabilidadUpdate = ( trasabilidad ) => ({
    type: types.trasabilidadUpdate,
    payload: trasabilidad
});


