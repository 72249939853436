
import { useRef, useState } from "react";

import { Grid, InputAdornment, TextField } from "@mui/material"
import { useDispatch } from "react-redux";
import { productosListaStartTodos } from "../../../actions/producto";
import { busquedaTodosProductos } from "../../../actions/busquedas";
import Search from "@mui/icons-material/Search";





export const BuscadorProductos = () => {


    const dispatch = useDispatch();
    const debounceRef = useRef();

    const [valor, setValor] = useState('');


    const hanldeInputChange = ({ target }) => {
        setValor(target.value);
        if( debounceRef.current )
            clearTimeout( debounceRef.current );

        debounceRef.current = setTimeout( () => {
            if( target.value.trim().length > 0 ){
                localStorage.removeItem('pagpd');
                dispatch(busquedaTodosProductos(target.value));
            }else {
                dispatch( productosListaStartTodos() );
            }
        }, 600);
    }



    return (
        
        <Grid item xs={ 12 } sm={ 6 } sx={{ my: 4 }}>
            <TextField  
                autoComplete="off"
                label="Buscar Producto..." 
                placeholder='Buscar Producto...'
                variant="filled" 
                fullWidth
                name="valor"
                value={ valor }
                onChange={ hanldeInputChange }
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Search />
                        </InputAdornment>
                    ),
                }}
            />
        </Grid>

    )
}
