
import { fetchConToken } from "../helpers/fetch";
import { types } from "../types/types";

import Swal from "sweetalert2";







export const newUnidadMedidaStart = ( medida ) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken(`medidas/new`, medida, 'POST');
            const body = await resp.json();
            
            if( body.ok ){
                dispatch( newUnidadMedida(body.unidadMedida) );
                Swal.fire('Exitoso', 'Medida Creada', 'success');
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}



export const ListadoMedidasStart = () => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken(`medidas/`);
            const body = await resp.json();

            if( body.ok ){
                dispatch( listadoMedidas(body.medidas) );
            }else {
                Swal.fire('Error', body.msg, 'error');
            }

        } catch (error) {
            console.log(error);
        }
    }
}



const listadoMedidas = ( medidas ) => ({
    type: types.medidasLista,
    payload: medidas
});



const newUnidadMedida = ( medida ) => ({
    type: types.medidasNew,
    payload: medida
});