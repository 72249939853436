
import { useState } from "react";

import { Box, Tab, Tabs } from '@mui/material';
import { TabPanel, TabContext } from '@mui/lab';
import { OpPorVerificarPago, OpPorDisenio, OpPorPendAprobacion, OpPorProduccion, OpPorEntregar, OpPorCredito, OpEntregadas, OpArchivadas, OpTodasAbiertas } from "./";





export const TodasOps = () => {

    const tabb = localStorage.getItem('tab') || '1';
    const [value, setValue] = useState(tabb);


    const handleChange = (event, newValue) => {
        setValue(newValue);
        localStorage.setItem('tab', newValue);
        localStorage.removeItem('pagop');
    };



    return (

        <Box sx={{width:'100%', overflowX:'auto'}}>
            <Box sx={{ maxWidth: { xs: 340, sm: 500, md: 800, lg: 950, xl: 1280 } }}>

                <TabContext value={value} >
                    <Box >
                        <Tabs onChange={ handleChange } value={value} variant="scrollable" scrollButtons textColor="secondary" >
                            <Tab label="Todas" value="1" />
                            <Tab label="Verificar Pago" value="2" />
                            <Tab label="Diseño" value="3" />
                            <Tab label="Pendiente Aprobacion" value="4" />
                            <Tab label="Produccion" value="5" />
                            <Tab label="Por Entregar" value="6" />
                            <Tab label="Credito" value="7" />
                            <Tab label="Entregado" value="8" />
                            <Tab label="Archivadas" value="9" />
                        </Tabs>
                    </Box>

                    <TabPanel value="1" sx={{p:0, my:4}}>
                        <OpTodasAbiertas />
                    </TabPanel>

                    <TabPanel value="2" sx={{p:0, my:4}}>
                        <OpPorVerificarPago />
                    </TabPanel>

                    <TabPanel value="3" sx={{p:0, my:4}}>
                        <OpPorDisenio />
                    </TabPanel>
                    
                    <TabPanel value="4" sx={{p:0, my:4}}>
                        <OpPorPendAprobacion />
                    </TabPanel>

                    <TabPanel value="5" sx={{p:0, my:4}}>
                        <OpPorProduccion />
                    </TabPanel>
                    
                    <TabPanel value="6" sx={{p:0, my:4}}>
                        <OpPorEntregar />
                    </TabPanel>

                    <TabPanel value="7" sx={{p:0, my:4}}>
                        <OpPorCredito />
                    </TabPanel>
                    
                    <TabPanel value="8" sx={{p:0, my:4}}>
                        <OpEntregadas />
                    </TabPanel>

                    <TabPanel value="9" sx={{p:0, my:4}}>
                        <OpArchivadas />
                    </TabPanel>

                </TabContext>

            </Box>
        </Box>
    )
}
