
import { fetchConToken, fetchSinToken } from "../helpers/fetch"
import { types } from "../types/types";
import Swal from "sweetalert2";






export const clienteStartDelete = (cliente) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken(`cliente/cambioestado/${ cliente.id }`, {}, 'PUT');
            const body = await resp.json();

            if( body.ok ){
                dispatch( clienteUpdate(body.cliente) );
                Swal.fire('Exitoso', 'Cliente Eliminado', 'success');
            }else {
                Swal.fire('Error', body.msg, 'error');
            }

        } catch (error) {
            console.log(error);
        }

    }
}



export const clienteListaStart = (estado) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken(`cliente/todos/${estado}`);
            const body = await resp.json();

            const clientes = body.cliente;
            dispatch( clienteLista(clientes) );
        } catch (error) {
            console.log(error);
        }
    }
}




export const clienteStartUpdate = ( cliente ) => {
    return async( dispatch) => {

        try {
            const resp = await fetchConToken(`cliente/${ cliente.id }`, cliente, 'PUT'); 
            const body = await resp.json();
            if( body.ok ) {
                dispatch( clienteUpdate( cliente ) );
                Swal.fire('Exitoso', 'Cliente Actualizado', 'success');
            }else {
                Swal.fire('Error', body.msg,  'error');
            }
        } catch (error) {
            console.log(error);
        }
    }
}


export const mostrarClientePorId = ( id ) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken(`cliente/cliente/${ id }`);
            const body = await resp.json();
            
            if( body.ok ){
                dispatch( clienteActivo( body.cliente ) );
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}


export const clienteStartAddNew = ( cliente ) => {
    return async( dispatch, getState ) => {

        const { uid, nombre } = getState().admin;

        cliente.distribuidor = uid;

        try {
            const resp = await fetchConToken('cliente/new', cliente, 'POST');
            const body = await resp.json();
        
            if( body.ok ) {
                Swal.fire('Exitoso', 'Cliente Creado', 'success');
                cliente.id = body.cliente.id;
                cliente.distribuidor = {
                    nombre: nombre 
                }
                dispatch( clienteAddNew(cliente) );
            } else {
                Swal.fire('Error', body.msg, 'error');
            } 
            
        } catch (error) {
            console.log(error);
        }
    }
}



export const clientesStartPorDistri = () => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken('cliente/por-distri');
            const body = await resp.json();
            
            if( body.ok ) {
                const clientes = body.clientes;
                dispatch( clienteLista(clientes) );

            }else {
                Swal.fire('Error', body.msg, 'error');
            }

        } catch (error) {
            console.log(error);
        }
    }
}




export const clienteAddNew = ( data ) => ({
    type: types.clienteAddNew,
    payload: data
});



const clienteLista = ( clientes ) => ({
    type: types.clienteLista,
    payload: clientes
});


const clienteUpdate = ( cliente ) => ({
    type: types.clienteUpdate,
    payload: cliente
});


const deleteCliente = ( cliente ) => ({
    type: types.clienteDelete,
    payload: cliente
});


export const clienteActivo = ( cliente ) => ({
    type: types.clienteActivo,
    payload: cliente
});


export const clienteActivoClear = () =>({ type: types.clienteActivoClear });







































export const clienteUpdateTerminos = ( cliente ) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchSinToken(`cliente/terminos-condiciones/${ cliente.id }`, cliente, 'PUT'); 
            const body = await resp.json();
            if( body.ok ) {
                dispatch( clienteUpdate( body.cliente ) );
            }else {
                Swal.fire('Error', body.msg,  'error');
            }
        } catch (error) {
            console.log(error);
        }
    }
}












export const cambiarPasswordPorElAdminCliente = ( user ) => {
    return async( dispatch ) => {
        
        try {
            const resp = await fetchConToken(`cliente/password-cambiar/${ user.uid }`, user, 'POST');
            const body = await resp.json();
            
            if( body.ok ){
                Swal.fire('Exitoso', body.msg, 'success');
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}


