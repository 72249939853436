
import { useOpTanStack } from '../../../hooks/cache/useOpTanStack';
import { BuscardorOpCategoria, TablaDeOpsXCategoria } from "../components";
import { LayoutOps } from "../layouts";
import { CargandoMedio } from "../../components";
import { Grid } from '@mui/material';






export const OpTodasAbiertas = () => {


    const { page, chagePage, chageTermino, chageInicio, chageFinal, opsQuery } = useOpTanStack({ estado:'TODAS'});



    return (

        <Grid container>
            <BuscardorOpCategoria  chageTermino={chageTermino} chageInicio={chageInicio} chageFinal={chageFinal} /> 
            {(opsQuery.isLoading)
            ?   <CargandoMedio titulo="Cargando Información..." />
            :   <TablaDeOpsXCategoria data={opsQuery?.data} chagePage={ chagePage } page={ page } />
            }
        </Grid>

    )
}
