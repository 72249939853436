import { Button, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { carritoListaCompraStart } from '../../actions/carrito';
import { closeModalCarrito } from '../../actions/modalCarrito';
import { TablaDeCarritoEnModal } from './components';
import { VerPrecioTotalCarrito } from '../components';


const customStyles = {
    content: {
        top: '0',
        left: 'auto',
        right: '0',
        bottom: '0',
        transform: 'none',
        width: '400px',
        height: '100vh',
        borderRadius: '0',
        padding: '10px',
        backgroundColor: '#000000',
        color: '#fff',
        overflowY: 'auto'
    },
};


Modal.setAppElement('#root');



export const ModalCarrito = () => {


    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { modalOpen } = useSelector( state => state.modal );
    const { carritoCompra } = useSelector( state => state.carrito );



    const closeModal = () => {
        dispatch( closeModalCarrito() );
    }

    useEffect(() => {
        dispatch( carritoListaCompraStart() );
    }, [ dispatch ]);



    const handlerComprar = () => {
        navigate('/super/comprar-ahora');
        dispatch( closeModalCarrito() );
    }



return (
    
    <Modal
        isOpen={ modalOpen }
        onRequestClose={ closeModal }
        style={customStyles}
        closeTimeoutMS={ 200 }
        // className="modal"
        overlayClassName="modal-fondo"
    >

        <h3 className='title-carrito'>Productos del Carrito</h3>

        <TablaDeCarritoEnModal />

        <div className='fin-carrito'>

            {carritoCompra.length > 0 &&
                <Grid container sx={{display:'flex', justifyContent:'center'}}>
                    <VerPrecioTotalCarrito />
                </Grid>
            }


            <Grid container direction="column" sx={{ alignItems:'center', mt:1, mb:3}}>
                {carritoCompra.length > 0 && (
                <Grid item>
                    <Button variant="outlined" color="secondary" onClick={handlerComprar} fullWidth sx={{minWidth: '200px'}}>
                        Verificar
                    </Button>
                </Grid>
                )}
                <Grid item>
                    <Button variant="outlined" color="primary" onClick={closeModal} fullWidth sx={{minWidth: '200px'}}>
                        Salir
                    </Button>
                </Grid>
            </Grid>

        </div>
    </Modal >

)

}
