import { types } from "../types/types";




const initialState = {
    proveedores: [ ],
    activeProveedor: null
};



export const proveedorReducer = ( state = initialState, action ) => {

    switch ( action.type ) {

        case types.proveedorAddNew:
            return {
                ...state,
                proveedores: [ ...state.proveedores, action.payload ]
            };


        case types.proveedorLista:
            return {
                ...state,
                proveedores: [ ...action.payload ]
            };


        case types.proveedorDelete:
            return {
                ...state,
                proveedores: state.proveedores.filter(e => ( e.id !== action.payload.id ) )
            };

        case types.proveedorActivo:
            return { ...state, activeProveedor: action.payload };

            
        case types.proveedorUpdate:
            return { ...state,
                proveedores: state.proveedores.map( 
                    e => ( e.id === action.payload.id ) ? action.payload : e
                )
            };

        default:
            return state;
    }

    



 
}
