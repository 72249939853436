
import { useNavigate } from 'react-router-dom';
import { Box, CardActionArea, Grid, Typography } from '@mui/material';
import { separadorMillares } from '../../../hooks';





export const CardsComparativa = ({ url, color, titulo, titulo2, titulo3, total = 0, cantidad = 0 }) => {

    const navigate = useNavigate();


    const handleRuta = () => {
        navigate(url);
    }



    return (

        <Grid item xs={12} sm={6} md={4} textAlign='center'>
            <CardActionArea onClick={ handleRuta } sx={{borderRadius: '20px'}}>
                <Box width='100%' sx={{border:`2px solid ${color}`, minHeight:'200px', padding:'10px', borderRadius: '20px'}}>
                    <Box width='100%' display='flex' justifyContent='center' alignItems='start' >
                        <Typography variant='h4' sx={{fontSize:'22px'}}>{ titulo }</Typography>
                    </Box>

                    <Box width='100%' my={3} >
                        <Typography variant="h3" sx={{color:color}} >{ titulo2 }</Typography>
                        <Typography variant="h3" sx={{fontSize: 21, fontWeight:'500', mt:0.5}}>{ `$${ separadorMillares(total) }` }</Typography>
                    </Box>

                    <Box width='100%'>
                        <Typography variant="h3" sx={{color:color }}>{ titulo3 }</Typography>
                        <Typography sx={{fontSize: 15, fontWeight:'200', mt:0.5}}>{ cantidad }</Typography>
                    </Box>
                </Box>
            </CardActionArea>
        </Grid>


    )


    
}
