import { fetchConToken, fetchSinToken } from "../helpers/fetch";
import { types } from "../types/types";
import Swal from "sweetalert2";


export const startLoginSuper = ( cedula, password ) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchSinToken('admin/login', { cedula, password }, 'POST');
            const body = await resp.json();

            if( body.ok ) {
                localStorage.setItem('token', body.token);
                localStorage.setItem('token-init', new Date().getTime());
                // console.log(body);

                dispatch( authSuperChekingLogin({
                    uid: body.uid,
                    nombre: body.nombre,
                    role: body.role,
                    img: body.usuario.img || '',
                    usuario: body.usuario,
                    menu: body.menu,
                }));

                Swal.fire('Bienvenido', body.nombre, 'success');

            } else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}




const authSuperChekingLogin = ( admin ) => ({ 
    type: types.authSuperChekingLogin,
    payload: admin
});











export const startChekingSuper = () => {
    return async( dispatch ) => {
        try {

            const resp = await fetchConToken('admin/renew');
            const body = await resp.json();

            if( body.ok ) {
                
                localStorage.setItem('token', body.token);
                localStorage.setItem('token-init', new Date().getTime());
                dispatch( authSuperChekingLogin({
                    uid: body.uid,
                    nombre: body.nombre,
                    role: body.role,
                    img: body.usuario.img || '',
                    usuario: body.usuario,
                    menu: body.menu,
                }));

            }else {
                dispatch( authSuperChekingFinish() );
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}


export const startSuperLogout = () => {
    return ( dispatch ) => {
        localStorage.clear();
        dispatch( authSuperLogout() );
        // dispatch( limpiarListaClientes() );
    }
}



// const limpiarListaClientes = () =>({ type: types.clienteListaClear });
const authSuperLogout = () => ({ type: types.authSuperLogout });



const authSuperChekingFinish = () => ({
    type: types.authSuperChekingFinish
});



