import { types } from "../types/types";




const initialState = {
    inventarios: [],
    activeInventario: null,
};



export const reportesInventarioReducer = ( state = initialState, action ) => {

    switch ( action.type ) {


        case types.listadoReporteInventario:
            return {
                ...state,
                inventarios: [ ...action.payload ]
            };

        // case types.gastosDelete:
        //     return {
        //         ...state,
        //         gastos: state.gastos.filter(
        //             e => ( e.id !== action.payload.id )
        //         ),
        //         activeGasto: null
        //     };

        // case types.abonosActivo:
        //     return {
        //         ...state,
        //         activeAbono: action.payload
        //     };
            
        // case types.abonosDeleteActivo:
        //     return {
        //         ...state,
        //         activeAbono: null
        //     };

        default:
            return state;
    }

}
