
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Avatar, Box, Card, CardContent, Grid, Typography, CardActionArea } from '@mui/material';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import InfoIcon from '@mui/icons-material/Info';
import PaidIcon from '@mui/icons-material/Paid';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import LogoutIcon from '@mui/icons-material/Logout';

import { LayoutSoloTitulo } from "../layouts";
import { startClienteLogout } from '../../../actions/authCliente';








export const DashboardCliene = () => {


    const navigate = useNavigate();
    const dispatch = useDispatch();



    const redirecPage = ( page ) => {
        navigate(`/${ page }`);
    }


    const handleLogout = () => {
        dispatch( startClienteLogout() );
        navigate('/');
    }


    return (

        <LayoutSoloTitulo titulo='Mi Perfil'>

            <Grid container spacing={3} display='flex' mb={6} mt={2}>

                <Grid item xs={12} sm={6} md={4} lg={3} >
                    <Card onClick={ () => redirecPage('mis-pedidos') }>
                        <CardActionArea sx={{ height: 150, display:'flex', justifyContent:'space-evenly'}} >
                            <Box pl={2}>
                                <Avatar sx={{ width: 65, height: 65 }}>
                                    <PaidIcon sx={{ fontSize:40 }} />
                                </Avatar>
                            </Box>
                            <CardContent sx={{ textAlign:'center' }}>
                                <Typography variant='h5' component="div" sx={{ fontSize:20 }}>
                                    Tus Pedidos
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Mira y rastrea tus pedidos o compra algo de nuevo.
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>


                <Grid item xs={12} sm={6} md={4} lg={3} >
                    <Card >
                        <CardActionArea sx={{ height: 150, display: 'flex', justifyContent:'space-evenly' }} >

                            <Box pl={2}>
                                <Avatar sx={{ width: 65, height: 65 }}>
                                    <PaidIcon sx={{ fontSize:40 }} />
                                </Avatar>
                            </Box>
                            <CardContent sx={{ textAlign:'center' }}>
                                <Typography variant='h5' component="div" sx={{ fontSize:20 }}>
                                    Tus Pagos
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Ver todas las transacciones y metodos de pagos.
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>


                <Grid item xs={12} sm={6} md={4} lg={3} >
                    <Card onClick={ () => redirecPage('perfil') }>
                        <CardActionArea sx={{ height: 150, display: 'flex', justifyContent:'space-evenly' }} >

                            <Box pl={2}>
                                <Avatar sx={{ width: 65, height: 65 }}>
                                    <InfoIcon sx={{ fontSize:40 }} />
                                </Avatar>
                            </Box>
                            <CardContent sx={{ textAlign:'center' }}>
                                <Typography variant='h5' component="div" sx={{ fontSize:20 }}>
                                    Perfil
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Administrar, agregar o eliminar datos para una mejor experiencia.
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>


                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Card onClick={ () => redirecPage('mis-archivados') }>
                        <CardActionArea sx={{ height: 150, display: 'flex', justifyContent:'space-evenly' }} >
                            <Box pl={2}>
                                <Avatar sx={{ width: 65, height: 65 }}>
                                    <PersonPinIcon sx={{ fontSize:40 }} />
                                </Avatar>
                            </Box>
                            <CardContent sx={{ textAlign:'center' }}>
                                <Typography variant='h5' component="div" sx={{ fontSize:20 }}>
                                    Pedidos Archivados
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Ver y administrar tus pedidos archivados.
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>


                <Grid item xs={12} sm={6} md={4} lg={3} >
                    <Card onClick={ () => redirecPage('cambio-password') }>
                        <CardActionArea sx={{ height: 150, display: 'flex', justifyContent:'space-evenly' }} >
                            <Box pl={2}>
                                <Avatar sx={{ width: 65, height: 65 }}>
                                    <CameraAltIcon sx={{ fontSize:40 }} />
                                </Avatar>
                            </Box>
                            <CardContent sx={{ textAlign:'center' }}>
                                <Typography variant='h5' component="div" sx={{ fontSize:20 }}>
                                    Seguridad de Cuenta
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Cambia tu contraseña periodicamente para mejor seguridad.
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3} >
                    <Card onClick={ handleLogout }>
                        <CardActionArea sx={{ height: 150, display: 'flex', justifyContent:'space-evenly' }} >
                            <Box pl={2}>
                                <Avatar sx={{ width: 65, height: 65 }}>
                                    <LogoutIcon sx={{ fontSize:40 }} />
                                </Avatar>
                            </Box>
                            <CardContent sx={{ textAlign:'center' }}>
                                <Typography variant='h5' component="div" sx={{ fontSize:20 }}>
                                    Logout
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Salir de tu cuenta con seguridad.
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>

            </Grid>
        </LayoutSoloTitulo>

    )

    
}
