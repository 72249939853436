

import Swal from "sweetalert2";



const baseUrl = process.env.REACT_APP_API_URL;



const fetchSinToken = ( endpoint, data, method = 'GET' ) => {

    const url = `${ baseUrl }/${ endpoint }`;

    if( method === 'GET' ) {
        return fetch( url );
    } else {
        return fetch( url, {
            method,
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify( data )
        });
    }
};


const fetchConToken = ( endpoint, data, method = 'GET' ) => {

    const url = `${ baseUrl }/${ endpoint }`;
    const token = localStorage.getItem('token') ||  '';

    if( method === 'GET' ) {
        return fetch( url, {
            method,
            headers: {
                'x-token': token
            }
        } );
    } else {
        return fetch( url, {
            method,
            headers: {
                'Content-type': 'application/json',
                'x-token': token
            },
            body: JSON.stringify( data )
        });
    }
}



const fetchConTokenCliente = ( endpoint, data, method = 'GET' ) => {

    const url = `${ baseUrl }/${ endpoint }`;
    const token = localStorage.getItem('tokenCliente') ||  '';

    if( method === 'GET' ) {
        return fetch( url, {
            method,
            headers: {
                'x-token': token
            }
        } );
    } else {
        return fetch( url, {
            method,
            headers: {
                'Content-type': 'application/json',
                'x-token': token
            },
            body: JSON.stringify( data )
        });
    }
}



const fileUpload = async( id, tipo, archivo ) => {

    const base =`${ baseUrl }/uploads/${ tipo }/${ id }`;
    const formData = new FormData();
    formData.append('imagen', archivo);

    try {

        const resp = await fetch( base, {
            method: 'PUT',
            body: formData
        });

        const body = await resp.json();

        if( body.ok ) {
            return body.nombreArchivo;
        } else {
            return Swal.fire('Error', body.msg, 'error');
        }
        
    } catch (error) {
        console.log(error);
    }
}



const fileForData = async( endpoint, data, method = 'GET' ) => {

    const token = localStorage.getItem('token') ||  '';
    const base =`${ baseUrl }/${ endpoint }`;

    const formData = new FormData();
    const keys = Object.keys( data )
    keys.forEach( key => {
        let value;
        if( typeof data[key] === "object" && (data[key]  instanceof File) === false ) {
            value = JSON.stringify(data[key]);
        }else {
            value = data[key];
        }
        // console.log(key, data[key]  instanceof File, data[key], value );
        formData.append(key, value);
    });

    try {
        const resp = await fetch( base, {
            method,
            headers: {
                'x-token': token,
            },
            body: formData,
        });

        return resp;
        
    } catch (error) {
        console.log(error);
    }
}



const fileForDataCliente = async( endpoint, data, method = 'GET' ) => {

    const token = localStorage.getItem('tokenCliente') ||  '';
    const base =`${ baseUrl }/${ endpoint }`;

    const formData = new FormData();
    const keys = Object.keys( data )
    keys.forEach( key => {
        let value;
        if( typeof data[key] === "object" && (data[key]  instanceof File) === false ) {
            value = JSON.stringify(data[key]);
        }else {
            value = data[key];
        }
        formData.append(key, value);
    });

    try {
        const resp = await fetch( base, {
            method,
            headers: {
                'x-token': token,
            },
            body: formData,
        });
        return resp;
    } catch (error) {
        console.log(error);
    }
}




export {
    fetchSinToken,
    fetchConToken,
    fetchConTokenCliente,
    fileUpload,
    fileForData,
    fileForDataCliente,
}