import { types } from "../types/types";





const initialState = {
    modalForm: false,
    modalFormDos: false,




    modalOpen: false,
    modalImagen: false,
    modalAbonos: false,
    modalCliente: false,
    modalCrearCliente: false,
    modalAbonoOp: false,
    modalAbonoOpCliente: false,
    mostrarCantidad: false,
}


export const modalReducer = ( state = initialState, action ) => {

    switch (action.type) {

        case types.openModalForm:
            return {
                modalForm: true,
            };
        
        case types.closeModalForm:
            return {
                modalForm: false,
            };


        case types.openModalFormDos:
            return {
                modalFormDos: true,
            };
        
        case types.closeModalFormDos:
            return {
                modalFormDos: false,
            };







        case types.openModalAbonoOp:
            return {
                modalOpen: false,
                modalImagen: false,
                modalAbonos: false,
                modalCliente: false,
                modalCrearCliente: false,
                modalAbonoOp: true,
                modalAbonoOpCliente: false,
            };

        case types.closeModalAbonoOp:
            return {
                modalOpen: false,
                modalImagen: false,
                modalAbonos: false,
                modalCliente: false,
                modalCrearCliente: false,
                modalAbonoOp: false,
                modalAbonoOpCliente: false,
            };


            case types.openModalAbonoOpCliente:
            return {
                modalOpen: false,
                modalImagen: false,
                modalAbonos: false,
                modalCliente: false,
                modalCrearCliente: false,
                modalAbonoOp: false,
                modalAbonoOpCliente: true,
            };

        case types.closeModalAbonoOpCliente:
            return {
                modalOpen: false,
                modalImagen: false,
                modalAbonos: false,
                modalCliente: false,
                modalCrearCliente: false,
                modalAbonoOp: false,
                modalAbonoOpCliente: false,
            };


        case types.openModal:
            return {
                ...state,
                modalOpen: true,
                modalImagen: false,
                modalAbonos: false
            };

        case types.closeModal:
            return {
                ...state,
                modalOpen: false,
                modalImagen: false,
                modalAbonos: false,
            };

        case types.openModalImagen:
            return {
                ...state,
                modalImagen: true,
                modalOpen: false,
                modalAbonos: false,
            };

        case types.closeModalImagen:
            return {
                ...state,
                modalImagen: false,
                modalOpen: false,
                modalAbonos: false,
            };

        case types.openModalAbonos:
            return {
                ...state,
                modalAbonos: true,
                modalImagen: false,
                modalOpen: false,
            };

        case types.closeModalAbonos:
            return {
                ...state,
                modalImagen: false,
                modalOpen: false,
                modalAbonos: false,
            };

        case types.openModalCliente:
            return{
                ...state,
                modalCliente: true,
            };

        case types.closeModalCliente:
            return{
                ...state,
                modalCliente: false
            };

        case types.openCrearCliente:
            return{
                ...state,
                modalCliente: false,
                modalCrearCliente: true,
            };

        case types.closeCrearCliente:
            return{
                ...state,
                modalCrearCliente: false
            };



            case types.quitarCantidad:
                return{
                    ...state,
                    mostrarCantidad: false
                };
                
                
            case types.mostrarCantidad:
                return{
                    ...state,
                    mostrarCantidad: true,
                };
            
    
        default:
            return state;
    }

}