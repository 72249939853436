import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { abonosDeleteActivo } from '../../actions/abono';
import { closeModalAbonos } from '../../actions/modalCarrito';


const baseUrl = process.env.REACT_APP_API_URL;



const customStyles = {
    content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-60%',
    marginBottom: '-30%',
    transform: 'translate(-50%, -50%)',
    },
};


Modal.setAppElement('#root');


export const ModalImagenAbonos = () => {

    const dispatch = useDispatch();
    const { modalAbonos }  = useSelector( state => state.modal );
    const { activeAbono } = useSelector( state => state.abono );



    const closeModal = () => {
        dispatch( closeModalAbonos() );
        dispatch( abonosDeleteActivo() );
    }



return (
    
    <Modal
        isOpen={ modalAbonos }
        onRequestClose={ closeModal }
        style={customStyles}
        closeTimeoutMS={ 200 }
        className="modalimg"
        overlayClassName="modal-fondo"
    >

       <div className='img-modal'>
           {
                activeAbono && (
                <img src={ `${ baseUrl }/uploads/abonos/${ activeAbono.img }` } alt="" />
            )}
            
       </div>



    </Modal>

)



}
