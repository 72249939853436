

import React from 'react';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import { Grid, TextField } from '@mui/material';






export const InputDePassword = ({ value, placeholder, onChangeInput, name }) => {


    const [showPassword, setShowPassword] = React.useState(false);


    const handleClickShowPassword = () => { 
        setShowPassword((show) => !show);
    };


    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };



    return (

        <Grid item xs={ 12 } my={2}>
            <TextField required
                label={ placeholder }
                type={showPassword ? 'text' : 'password'}
                placeholder={ placeholder }
                fullWidth
                name={name}
                value={value}
                onChange={ onChangeInput }
                InputProps={{
                    endAdornment:(
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                            { showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
        </Grid>


    )

    
}
