
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { BackdropScreen } from "../../components";
import { LayoutReturn } from "../layouts";
import { BotonesDeCompletarOrden, CardsComprarAhora, CarritoVacio, InformacionCliente } from "../../compras/components";
import { Grid, Paper, Typography } from "@mui/material";
import { carritoStartDeleteCliente, opStartAddNewCliente } from "../../../actions/carritoCliente";
import { PagoOrdenOp } from "../../compras/views";
import { separadorMillares } from "../../../hooks";
import { usePrecioCarritoCliente } from "../../../hooks/usePrecioCarritoCliente";
import { redondearNumero } from "../../../helpers/quitarComa";





export const ComprarAhoraCliente = () => {

    const cliente = JSON.parse(localStorage.getItem('cliente') ) || '';
    const dispatch = useDispatch();

    const { uid } = useSelector( state => state.authCliente );
    const { carritoCliente } = useSelector( state => state.carrito );
    const { totalCliente, totalDistri, iva, subTotalCliente } = usePrecioCarritoCliente();

    const [cargando, setCargando] = useState(false);

    
    const [formOp, setFormOp] = useState({
        cliente: uid, 
        totalOrden: 0,
        totalCliente: 0,
        totalDistri: 0,
        iva: 0,
        infoPersonal: "",
        start: ''
    });



    useEffect(() => {
        if(carritoCliente) {
            setFormOp( (formOp) => ({
                ...formOp,
                cliente: uid, 
                infoPersonal: { ...cliente, uid: cliente.id },
                totalCliente: redondearNumero(totalCliente),
                totalDistri: redondearNumero(totalDistri),
                totalOrden: redondearNumero(totalCliente),
                iva
            }));
        }
    }, [carritoCliente, totalCliente, totalDistri, iva] );




    const eliminarItemCarrito = (id) => {
        dispatch( carritoStartDeleteCliente(id) );
    }



    const handleCrearOp = async() => {
        setCargando( true );
        const resp = await dispatch( opStartAddNewCliente( formOp ) );
        setCargando( false );
        if( resp ){
            localStorage.removeItem('cliente');
            localStorage.removeItem('iva');
        }
    }  



    return (


        <LayoutReturn titulo={`Pagar Orden`} url='/categorias'>

        <BackdropScreen cargando={cargando} titulo='Espere por favor estamos generando su orden de pedido...' />

        {(carritoCliente.length > 0)
        ?   <Grid container mt={5} mb={7}>
                <Grid item xs={12} md={7} sx={{pr:{ xs:0, md:3}}}>

                    {cliente && 
                        <InformacionCliente urlEditar="/checkout-cliente" /> 
                    }

                    <CardsComprarAhora carritoCompra={carritoCliente} 
                        urlCotiz="/cotizar" 
                        urlDetalle="/detalle-item" 
                        urlModificar="/detalle-producto"
                        functionDelete={eliminarItemCarrito}
                    />

                    <Paper sx={{mt:2, p:2, height:'50px', display:'flex', alignItems:'center', justifyContent:'end'}}>
                        <Typography variant="h4">
                            Total: {`$${ separadorMillares(subTotalCliente)}` }
                        </Typography>
                    </Paper>
                </Grid>


                <Grid item xs={12} md={5} sx={{mt:{xs:5, md:0}}}>
                    <PagoOrdenOp setFormOp={setFormOp} formOp={formOp} />
                    <BotonesDeCompletarOrden funcion={handleCrearOp}  url="/checkout-cliente"
                        setFormOp={setFormOp} formOp={formOp} 
                    />
                </Grid>
            </Grid>

        :   <Grid container sx={{width:'100%', mt:-7}}>
                <CarritoVacio />
            </Grid>
        }

        </LayoutReturn>

    )

}
