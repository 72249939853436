
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Typography } from "@mui/material";
import { separadorMillares } from "../../../hooks/useMillares";





export const VerPrecioTotalCarrito = ({ mostrar=true }) => {



    const { carritoCompra } = useSelector( state => state.carrito )
    const { view } = useSelector( state => state.iva );

    const [totales, setTotales] = useState({
        cliente: '',
        distri: '',
        empresa: '',
        publicista: '',
    });
    const { cliente, distri, empresa, publicista } = totales;



    useEffect( () => {
        if( carritoCompra ) {
            const cliente = carritoCompra.reduce( (total, prod) => total + prod.totalCliente, 0);
            const distri = carritoCompra.reduce( (total, prod) => total + prod.totalDistri, 0);
            const empresa = carritoCompra.reduce( (total, prod) => total + prod.totalEmpresa, 0);
            const publicista = carritoCompra.reduce( (total, prod) => total + prod.totalPublicista, 0);
            setTotales({
                cliente,
                distri,
                empresa,
                publicista,
            });
        }
    }, [carritoCompra] );




    const handleSwitch = (role) => {
        switch(role){
            case "cliente":
            return  <div>
                    {
                    <Typography variant="h3">{(mostrar) ? 'Total Cliente:' : ''} {`$${ separadorMillares(cliente) }`}</Typography>
                    }
                    </div>;

            case "distri": 
            return  <div>
                    {
                    <Typography variant="h3" color={'primary'}>{(mostrar) ? 'Total Distribuidor:' : ''} {`$${ separadorMillares(distri)}`}</Typography>
                    }
                    </div>;

            case "disenio": 
            return <div>
                {
                <Typography variant="h3" color={'secondary'}>{(mostrar) ? 'Total Publicista:' : ''} {`$${ separadorMillares(publicista)}`}</Typography>
                }
            </div>;

            case "empresa":
            return <div>
                    {
                    <Typography variant="h3" color={'#b27d00'}>{(mostrar) ? 'Total Empresa:' : ''} {`$${ separadorMillares(empresa)}`}</Typography>
                    }
                    </div> 

            default:
                return;
        }
    }





    return (

        <>
        {view &&
            <div className="caja-precios">
                {
                    handleSwitch(view)
                }
            </div>
        }
        </>
    )
}
