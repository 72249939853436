
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Avatar, Box, Card, CardContent, Grid, Typography, CardActionArea } from '@mui/material';
import { ModalImagenPagoTotalOP } from '../components';
import { LayoutReturn } from '../../layouts';
import { openModalImagen } from '../../../actions/modalCarrito';
import { opPorID, opStartDelete } from '../../../actions/op';
import { iconos } from '../../../data';

import Swal from 'sweetalert2';






export const DetallesOpCard = () => {


    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { activeOP } = useSelector( state => state.op );



    useEffect(() => {
        if( id ) {
            dispatch( opPorID(id) );
        }
    }, [id] );


    const redirecPage = ( page ) => {
        navigate(`/super/${ page }/${id}`);
    }


    const abrirModalOP = () => {
        dispatch(openModalImagen());
    }




    const handleEliminarOP = async() => {
        const resp = await Swal.fire({
            title: 'Eliminar O.P.',
            text: `¿Esta seguro de eliminar esta op y todas sus relaciones.`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si'
        });
        if( resp.value ) {
            const resp = await dispatch(opStartDelete(id));
            if( resp ){
                navigate(`/super/op-todas`);
            }
        }
    }




    return (

        <div>
        
            <LayoutReturn titulo={`Opciones de O.P. ${activeOP?.op}`} url={'/super/op-todas'} >

                <Grid container spacing={3} display='flex' mt={4}>

                    <Grid item xs={12} sm={6} md={6} lg={4} >
                        <Card onClick={ () => redirecPage('abonos/nueva') }>
                            <CardActionArea sx={{ height: 150, display: 'flex', justifyContent:'space-evenly' }} >
                                <Box pl={2}>
                                    <Avatar sx={{ width: 65, height: 65 }}>
                                        {React.cloneElement(iconos['abono'], {sx:{fontSize:40} })}
                                    </Avatar>
                                </Box>
                                <CardContent sx={{ textAlign:'center' }}>
                                    <Typography variant='h5' component="div" sx={{ fontSize:20 }}>
                                        Abonos
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        abonos realizados a esta o.p.
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>


                    <Grid item xs={12} sm={6} md={6} lg={4} >
                        <Card onClick={ () => redirecPage('items-produccion') }>
                            <CardActionArea sx={{ height: 150, display: 'flex', justifyContent:'space-evenly' }} >

                                <Box pl={2}>
                                    <Avatar sx={{ width: 65, height: 65 }}>
                                        {React.cloneElement(iconos['informacion'], {sx:{fontSize:40} })}
                                    </Avatar>
                                </Box>
                                <CardContent sx={{ textAlign:'center' }}>
                                    <Typography variant='h5' component="div" sx={{ fontSize:20 }}>
                                        Items
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        mirar items de esta op y sus respectivas caracteristicas.
                                    </Typography>
                                </CardContent>

                            </CardActionArea>
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} lg={4} >
                        <Card onClick={ () => redirecPage('detalles') }>
                            <CardActionArea sx={{ height: 150, display: 'flex', justifyContent:'space-evenly' }} >
                                <Box pl={2}>
                                    <Avatar sx={{ width: 65, height: 65 }}>
                                        {React.cloneElement(iconos['user'], {sx:{fontSize:40} })}
                                    </Avatar>
                                </Box>
                                <CardContent sx={{ textAlign:'center' }}>
                                    <Typography variant='h5' component="div" sx={{ fontSize:20 }}>
                                        Detalles O.P.
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        ver detalles de usuario sobre quien solicito esta o.p. 
                                    </Typography>
                                </CardContent>

                            </CardActionArea>
                        </Card>
                    </Grid>


                    <Grid item xs={12} sm={6} md={6} lg={4} >
                        <Card onClick={ abrirModalOP }>
                            <CardActionArea sx={{ height: 150, display: 'flex', justifyContent:'space-evenly' }} >

                                <Box pl={2}>
                                    <Avatar sx={{ width: 65, height: 65 }}>
                                        {React.cloneElement(iconos['camara'], {sx:{fontSize:40} })}
                                    </Avatar> 
                                </Box>
                                <CardContent sx={{ textAlign:'center' }}>
                                    <Typography variant='h5' component="div" sx={{ fontSize:20 }}>
                                        Imagen de Pago
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        ver imagen de pago total de la o.p.  
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} lg={4} >
                        <Card onClick={ () => redirecPage('editar') }>
                            <CardActionArea sx={{ height: 150, display: 'flex', justifyContent:'space-evenly' }} >
                                <Box pl={2}>
                                    <Avatar sx={{ width: 65, height: 65 }}>
                                        {React.cloneElement(iconos['editar'], {sx:{fontSize:40} })}
                                    </Avatar>
                                </Box>
                                <CardContent sx={{ textAlign:'center' }}>
                                    <Typography variant='h5' component="div" sx={{ fontSize:20 }}>
                                        Editar O.P.
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        modificar, agregar, o eliminar items de o.p.
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>


                    <Grid item xs={12} sm={6} md={6} lg={4} >
                        <Card onClick={ handleEliminarOP }>
                            <CardActionArea sx={{ height: 150, display: 'flex', justifyContent:'space-evenly' }} >

                                <Box pl={2}>
                                    <Avatar sx={{ width: 65, height: 65 }}>
                                        {React.cloneElement(iconos['eliminar'], {sx:{fontSize:40} })}
                                    </Avatar> 
                                </Box>
                                <CardContent sx={{ textAlign:'center' }}>
                                    <Typography variant='h5' component="div" sx={{ fontSize:20 }}>
                                        Eliminar O.P.
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        elimina todo lo relacionado y enlazado a esta o.p.  
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>

                </Grid>
                
            </LayoutReturn>


            <ModalImagenPagoTotalOP />

        </div>


    )



}
