import { useContext } from "react";
import { SocketContext } from "../context/SocketContext";

import { fetchConToken } from "../helpers/fetch";
import { types } from "../types/types";

import Swal from "sweetalert2";



export const useEventosSockets = () => {

    const { socket } = useContext( SocketContext );


    const opEscogerStart = ( op ) => {
        return async( dispatch ) => {
            try {
                const resp = await fetchConToken(`carrito/escoger/${ op.id }`, op, 'PUT');
                const body = await resp.json();
                if( body.ok ){
                    op.produccion = true;
                    socket.emit('solicitar-op', op);
                    // dispatch( carritoUpdate(op) );
                }else {
                    Swal.fire('Error', body.msg, 'error');
                }
                
            } catch (error) {
                console.log(error);
            }
        }
    }


    const carritoUpdateAreaColaborador = ( carrito ) => {
        return async( dispatch ) => {
    
            try {
                const resp = await fetchConToken(`carrito/area/${ carrito.id }`, carrito, 'PUT');
                const body = await resp.json();
    
                if( body.ok ) {
                    socket.emit('devolver-produccion', body.carrito);
                    dispatch( carritoDelete(carrito.id) );
                    Swal.fire('Exitoso', 'Area Actualizada', 'success');
                } else {
                    Swal.fire('Error', body.msg, 'error');
                }
                
            } catch (error) {
                console.log(error);
            }
        }
    }


    const carritoDelete = ( id ) => ({ 
        type: types.carritoDelete,
        payload: id
    });



    // =============================    Eventos de Sockets    ============================================
    const tomarOpSocket = ( op ) => ({
        type: types.carritoUpdate,
        payload: op
    });





    return {
        tomarOpSocket,
        carritoUpdateAreaColaborador,

        // Eventos de Sockets
        opEscogerStart,
    }
    


}