
import { fetchConToken } from "../helpers/fetch";
import { prepararFechaAbono, prepararFechaUnAbono } from "../helpers/prepararFecha";
import { types } from "../types/types";
import Swal from "sweetalert2";




const abonosAddNew = ( abono ) => ({
    type: types.abonosFacturaAddNew,
    payload: abono
});





const abonosLista = ( abono ) => ({
    type: types.abonosFacturaLista,
    payload: abono 
});









// ========================== Servicio Funcionando  =========================================
export const abonoStartFacturaNew = ( abono ) => {
    return async( dispatch ) =>{
        try {
            const resp = await fetchConToken( 'abonosf/new', abono, 'POST' );
            const body = await resp.json();
            if( body.ok ) {
                Swal.fire('Exitoso', 'Abono Creado', 'success');
                const abonoFecha = prepararFechaUnAbono(body.data);
                dispatch( abonosAddNew(abonoFecha) );
                return true;
            } else {
                Swal.fire('Error', body.msg, 'error');
                return false;
            } 
        } catch (error) {
            console.log(error);
            return false;
        }
    }
}




export const abonoListaStartFactura = ( id ) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken(`abonosf/porfactura/${ id }`);
            const body = await resp.json();
            
            if( body.ok ){
                const abonos = prepararFechaAbono(body.abonos);
                dispatch( abonosLista(abonos) );
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}





























