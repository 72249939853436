
import { useSelector } from "react-redux";

import { Avatar, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';

import { MensajeAlertaDanger } from "../../components";


const baseUrl = process.env.REACT_APP_API_URL;





export const TablaDetallesItemsOP = () => {


    
    const { items } = useSelector( state => state.carrito );



    return (

        <>
        { items &&
        <Grid container>

            {
            ( items.length > 0 )
            ? <TableContainer sx={{ my:'15px' }}>
                <Table sx={{ minWidth: 700, }} aria-label="customized table">
                    <TableHead >
                        <TableRow>
                            <TableCell className="w-150">Producto</TableCell>
                            <TableCell className="w-200">Nombre</TableCell>
                            <TableCell>Cantidad</TableCell>
                            <TableCell className="w-200">Valor</TableCell>
                            <TableCell>Acción</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {
                        items.map( item => (
                            <TableRow key={ item.id } >
                                <TableCell sx={{ display:'flex', justifyContent:'center' }}>
                                    <Avatar
                                        alt="Imagen de Producto"
                                        src={`${ baseUrl }/uploads/productos/${ item.producto.img }`} 
                                        sx={{ width: 70, height: 70 }}
                                    /> 
                                </TableCell>

                                <TableCell>{ item.producto.nombre }</TableCell>

                                <TableCell>{ item.cantidad }</TableCell>

                                <TableCell>{ item.totalDistri }</TableCell>



                                <TableCell>
                                    <IconButton variant="contained" className="icon-boton"
                                        // onClick={ () => detallesOp(item) }
                                    >
                                        <EditIcon fontSize="small" />
                                    </IconButton>
                                </TableCell>

                            </TableRow>
                        ))
                        }
                    </TableBody>

                </Table>
            </TableContainer>


            : <MensajeAlertaDanger titulo='No Hay Items Disponibles' />

            }
        </Grid>
        }
        </>

    )


}
