
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Button, Grid } from "@mui/material";
import { useCalcularPrecios, separadorMillares } from "../../../hooks";
import { SelectorProductos } from "../components";
import { TextFormField, ViewPreciosPorRol } from "../../components";
import { buscarProducto } from "../../../helpers/FormulariosCarrito";

import Swal from "sweetalert2";



export const FormularioCantidadx10 = ({ formCarrito, setFormCarrito, funcionAgregar, titulo }) => {

    const  { activeProducto } = useSelector( state => state.producto );
    const { calcularCantidadx10U } = useCalcularPrecios();
    const { carritoCompra } = useSelector( state => state.carrito );


    const [ precioReal, setPrecioReal ] = useState(0);


    const handleInputChange = ({ target }) => {
        setFormCarrito({
            ...formCarrito,
            [target.name]: target.value
        });
    }




    useEffect( () => {
        if( activeProducto ) {
            const  { cliente, distri, empresa, publicista, precioReal } = calcularCantidadx10U( formCarrito.cantidad, activeProducto);
            setPrecioReal( precioReal - cliente );
            setFormCarrito({
                ...formCarrito,
                totalCliente: cliente,
                totalDistri: distri,
                totalEmpresa: empresa,
                totalPublicista: publicista,
            });
        }
    }, [activeProducto, formCarrito.cantidad]);




    const handleAgregarAlCarro = (e) => {
        e.preventDefault();

        if( formCarrito.cantidad <= 0 ) {
            return Swal.fire('Error', 'Debes Poner Una Cantidad', 'error');
        }

        if( (formCarrito.cantidad % 10) !== 0 ){
            return Swal.fire('Error', 'La Cantidad debe Ser Multiplos de 10 en 10', 'error');
        }

        const product = buscarProducto(carritoCompra, formCarrito.producto);
        funcionAgregar(product)
    }



    return (


        <form onSubmit={ handleAgregarAlCarro }>

            <SelectorProductos formCarrito={formCarrito} setFormCarrito={setFormCarrito} />

            <Grid container sx={{width:'100%', mt:2, display:'flex', justifyContent:'space-between'}} >
                <Grid item xs={5.8}>
                    <TextFormField
                        size="small"
                        label={`Cantidad`}
                        type='number'
                        placeholder="Cantidad"
                        name="cantidad"
                        value={formCarrito.cantidad}
                        onChange={ handleInputChange } 
                    />
                </Grid>

            </Grid>


            {/* Mostrar Precio Segun Rol */}
            <ViewPreciosPorRol formCarrito={formCarrito} />


            <Grid container sx={{display:'flex', justifyContent:'space-between', mt:2}}>
                <Grid item xs={(precioReal <= 0) ? 12 : 5.8}>
                    <Button type="submit" color='primary' variant="contained" fullWidth>
                        { titulo }
                    </Button>
                </Grid>

                {(precioReal > 0) && (
                <Grid item xs={5.8}>
                    <Button color='secondary' variant="contained" fullWidth>
                        Ahorra { `$${ separadorMillares( precioReal ) }`}
                    </Button>
                </Grid>
                )}
            </Grid>
        </form> 
        
    )
}
