
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, TextField, Button, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Autocomplete } from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import { NumberFormatCustom } from '../../../hooks';
import { productosTodosCotizacion } from '../../../actions/producto';
import { PrecioCotizacionSegunRol } from '../../../helpers/PrecioCotizacionSegunRol';






export const AgregarItemsCotizacion = ({ data, setData, tipoUser }) => {


    const millares = NumberFormatCustom;
    const precioRol = PrecioCotizacionSegunRol;
    const dispatch = useDispatch();
    const { productos } = useSelector(state => state.producto);


    useEffect( () => {
        dispatch( productosTodosCotizacion() );
    }, [dispatch] );


    
    const hamdleAgregarRow = () => {
        const newId = data.length + 1;
        setData([ 
            ...data, 
            { 
                id: newId,
                producto: '',
                nombre: '',
                cantidad: '',
                precioUni: '',
                total: '',
            }
        ]);
    };

    
    const removeRow = (id) => {
        setData( data.filter((row) => row.id !== id) );
    };


    const handleNameChange = (e, id) => {
        setData( (prevData) =>
            prevData.map((row) =>
                row.id === id ? { ...row, [e.target.name]: e.target.value } : row
            )
        );
    };


    const cambiarCantidad = (e, id) => {
        const newCantidad = e.target.value;
        const pedidoActualizado = data.map(( p ) => {
            if( p.id === id ){
                p = {
                    ...p, cantidad: newCantidad,
                    total: newCantidad * p.precioUni,
                }
            }
            return p;
        });
        setData(pedidoActualizado);
    }


    const cambiarPrecio = (e, id) => {
        const newPrecio = e.target.value;
        const pedidoActualizado = data.map( ( p ) => {
            if( p.id === id ){
                p = {
                    ...p, precioUni: newPrecio,
                    total: newPrecio * p.cantidad,
                }
            }
            return p;
        });
        setData(pedidoActualizado);
    }



    const handleProductoSelect = (producto, id) => {
        const productoSeleccionado = productos.find((p) => p.id === producto.id);
        const precio = precioRol(productoSeleccionado, tipoUser);
        setData((prevData) =>
            prevData.map((row) =>
            row.id === id
                ? {
                    ...row,
                    // producto: { id: productoSeleccionado.id, nombre: productoSeleccionado.nombre },
                    producto: productoSeleccionado,
                    nombre: productoSeleccionado.nombre,
                    precioUni: precio,
                    total: precio * row.cantidad,
                }
                : row
            )
        );
    };




    return (

        <>
        <Grid container sx={{ mt:7, mb:3, diplay:'flex', justifyContent:'space-between' }}>
            <Grid item >
                <Typography variant='h2'>Agregar Items</Typography>
            </Grid>

            <Grid item xs={12} sm={4}>
                <Button variant='contained' fullWidth 
                    onClick={ hamdleAgregarRow }
                >
                    Agregar Items
                </Button>
            </Grid>
        </Grid>

        
        <Grid container>
        <TableContainer>
            <Table>
            <TableHead>
                <TableRow>
                <TableCell className="w-220">Producto</TableCell>
                <TableCell className="w-220">Nombre</TableCell>
                <TableCell className="w-100">Cantidad</TableCell>
                <TableCell>Precio Uni.</TableCell>
                <TableCell>Total</TableCell>
                <TableCell>Accion</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((row) => (
                <TableRow key={row.id}>
                    <TableCell>
                        <Autocomplete
                            size="small"
                            fullWidth
                            options={productos}
                            sx={{ width: 200 }}
                            renderInput={(params) => <TextField {...params} placeholder="Productos" />}
                            value={ row.producto || null }
                            // getOptionLabel={(option) => option.nombre || ''}
                            getOptionLabel={(option) => `${option.nombre} (${option.id})`}
                            // getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(event, newValue) => handleProductoSelect(newValue, row.id)}
                        />
                    </TableCell>

                    <TableCell>
                        <TextField
                            required
                            autoComplete='off'
                            size="small"
                            fullWidth
                            multiline
                            sx={{ width: 300 }}
                            type="text"
                            placeholder='Descripción'
                            name='nombre'
                            value={ row.nombre }
                            onChange={ (e) => handleNameChange(e, row.id) }
                        >
                        </TextField>
                    </TableCell>

                    <TableCell>
                        <TextField
                            required
                            autoComplete='off'
                            size="small"
                            fullWidth
                            type="number"
                            placeholder='Cantidad'
                            name='cantidad'
                            value={ row.cantidad }
                            onChange={ (e) => cambiarCantidad(e, row.id) }
                        >
                        </TextField>
                    </TableCell>

                    <TableCell>
                        <TextField
                            required
                            autoComplete='off'
                            size="small"
                            fullWidth
                            placeholder='Precio Unitario'
                            name='precioUni'
                            value={ row.precioUni }
                            // onChange={ (e) => handleNameChange(e, row.id) }
                            onChange={ (e) => cambiarPrecio(e, row.id) }
                            InputProps={{
                                inputComponent: millares,
                            }}
                        >
                        </TextField>
                    </TableCell>

                    <TableCell>
                        <TextField
                            required
                            autoComplete='off'
                            size="small"
                            fullWidth
                            placeholder='Precio Total'
                            name='total'
                            value={ row.total }
                            onChange={ (e) => handleNameChange(e, row.id) }
                            InputProps={{
                                inputComponent: millares,
                            }}
                        >
                        </TextField>
                    </TableCell>

                    <TableCell>
                        <IconButton className="icon-boton" onClick={ () => removeRow(row.id) }>
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                    </TableCell>
                </TableRow>
                ))}
            </TableBody>
            </Table>
        </TableContainer>
        </Grid>
    </>

    )
    
}
