import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom"

import { Box, Grid, IconButton, Typography } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { ParaQuienEsOp, QuienSolicitaOp, DetallesDeOp } from "../components";
import { opPorID } from "../../../actions/op";
import { abonoListaStart } from "../../../actions/abono";




export const InformacionDeUsuarios = () => {

    
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { activeOP } = useSelector( state => state.op );

    
    useEffect(() => {
        if( id ) {
            dispatch( opPorID(id) );
        }
    }, [id] );


    useEffect(() => {
        if( id ){
            dispatch( abonoListaStart(id) );
        }
    }, [dispatch, id] );



    const handleReturn = () =>{
        navigate(`/super/detalles-op/${ id }`);
    }


    return (

        <>

            <Grid 
                container
                direccion='row'
                alignItems='center'
            >
                <IconButton
                    sx={{
                        color: '#fff',
                        backgroundColor: 'primary',
                        ':hover': { backgroundColor: 'primary', opacity: 0.3 },
                    }}
                    onClick={ handleReturn } 
                >
                    <ArrowBackIcon />
                </IconButton>

                <Box flex={ 1 } />

                <Typography sx={{ fontWeight:'bold', fontSize:'35px', mt:2 }} >Resumen de la Op: { activeOP?.op }</Typography>

                <Box flex={ 1 } />

            </Grid>


            <DetallesDeOp />


            <QuienSolicitaOp />


            <ParaQuienEsOp />



        </>

    )
}
