
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { MensajeAlertaDanger, ModalFormulario, TooltipAndIcon } from "../../components";
import { ListadoMedidasStart, closeStartForm, openStartForm } from "../../../actions";
import { FormularioUnidadMedida } from "../components";
import { LayoutCrearDoc } from "../../layouts";

import Swal from "sweetalert2";




export const ListadoUnidadMedida = () => {


    const dispatch = useDispatch();
    const { medidas }  = useSelector( state => state.medidas );



    useEffect(() => {
        dispatch(ListadoMedidasStart());
    }, []);

    
    
    const crearUnidadMedida = () => {
        dispatch( openStartForm() );
    }


    const cerrarMedida = () => {
        dispatch( closeStartForm() );
    }



    const eliminarMedida = ( uni ) => {
        Swal.fire({
            title: 'Eliminar Unidad Medida',
            text: `¿Esta Seguro De Eliminar La Medida: ${ uni.nombre }?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si, Eliminar' 
        }).then( ( result ) => {
            if(result.value){
                // deleteUnidadMedida( uni );
            }
        });
    }


    const handleActualizarMedida = async( uid ) => {
        console.log('Holis');
    }




    return (

        <LayoutCrearDoc titulo='Listado de Medidas' funcion={ crearUnidadMedida }>

            { ( medidas.length > 0 )
            
            ? <TableContainer sx={{ minWidth: 700, my:5 }} >
                <Table >
                    <TableHead>
                        <TableRow>
                            <TableCell>NOMBRE</TableCell>
                            <TableCell>OPCIONES</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                    { medidas.map( ( uni ) => (

                        <TableRow key={ uni.id } >
                            <TableCell> { uni.nombre } </TableCell>

                            <TableCell >
                                <Stack direction="row" spacing={1} justifyContent="center">
                                    <TooltipAndIcon
                                        titulo="Editar Unidad-Medida"
                                        icono="editar"
                                        color="white"
                                        onClick={ () => handleActualizarMedida(uni.id) }
                                    />  

                                    <TooltipAndIcon
                                        titulo="Eliminar Unidad-Medida"
                                        icono="eliminar"
                                        color="red"
                                        onClick={ () => eliminarMedida(uni) }
                                    />  
                                </Stack>
                            </TableCell>

                        </TableRow>

                    ))}
                    </TableBody>
                </Table>
            </TableContainer>

            : <MensajeAlertaDanger titulo="No Existen Datos De Unidad de Medida" />

            }


            <ModalFormulario titulo="Crear unidad de medida" cerrarModal={cerrarMedida} datos={{}}>
                <FormularioUnidadMedida />
            </ModalFormulario>


        </LayoutCrearDoc>

    )
}
