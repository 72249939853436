
import { fetchConToken } from "../helpers/fetch";
import { types } from "../types/types";

import Swal from "sweetalert2";





export const carritoListaUnoStart = ( id ) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken(`carrito/carrito-uno/${ id }`);
            const body = await resp.json();

            if( body.ok ){
                let carrito = body.carrito;
                dispatch( carritoActivo(carrito) );
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            console.log(error);
        }
    }
}



export const carritoCleanActivo = () => {
    return async( dispatch ) => {
        dispatch( carritoActivo(null) );
    }
}



export const todosCarrito = () => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken('carrito/todos');
            const body = await resp.json();
            if( body.ok ){
                dispatch( itemListaStart(body.carrito) );
            }else {
                console.log('Carrito Vacio');
            }
        } catch (error) {
            console.log(error);
        }
    }
}


export const updateSoloDetalles = ( item ) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken(`carrito/update/detalles`, item, 'PUT');
            const body = await resp.json();

            if( body.ok ){
                Swal.fire('Exitoso', 'Detalles Actualizados', 'success');
                // dispatch( carritoUpdate(body.item) );
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            console.log(error);
        }
    }
}



export const updateCarritoCotizacion = ( item ) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken(`carrito/update/cotizacion/${item.id}`, item, 'PUT');
            const body = await resp.json();

            if( body.ok ){
                dispatch( carritoUpdate(body.carrito) );
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            console.log(error);
        }
    }
}



export const carritoListaCompraStart = () => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken('carrito/carrito-usuario');
            const body = await resp.json();

            if( body.ok ){
                let carrito = body.carrito;
                dispatch( carritoListaCompra(carrito) );
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}




export const carritoStartAddNew = ( carrito ) => {
    return async( dispatch, getState ) => {
        try {
            // const { activeProducto } = getState().producto;
            for (var clave in carrito) {
                if (carrito.hasOwnProperty(clave) && carrito[clave] === "") { carrito[clave] = undefined }
            }

            const resp = await fetchConToken( 'carrito/new', carrito, 'POST' );
            const body = await resp.json();

            if( body.ok ) {
                dispatch( carritoAddNew( body.carrito ) );
                Swal.fire('Exitoso', 'Producto Agregado Al Carrito', 'success');
            } else {
                Swal.fire('Error', body.msg, 'error');
            } 
        } catch (error) {
            console.log(error);
        }
    }
}




export const carritoStartDeleteCompra = (id) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken(`carrito/${ id }`, {}, 'DELETE');
            const body = await resp.json();

            if( body.ok ){
                dispatch( carritoDeleteCompra(id) );
                Swal.fire('Exitoso', 'Producto Eliminado del Carrito', 'success');
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            console.log(error);
        }
    }
}




export const carritoUpdateStart = ( carrito ) => {
    return async( dispatch ) => {
        try {

            for (var clave in carrito) {
                if (carrito.hasOwnProperty(clave) && carrito[clave] === "") { carrito[clave] = null }
            }

            const resp = await fetchConToken(`carrito/${ carrito.id }`, carrito, 'PUT');
            const body = await resp.json();
            if( body.ok ) {
                dispatch(carritoUpdate(body.carrito) );
                Swal.fire('Exitoso', 'Carrito Actualizado', 'success');
            } else {
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            console.log(error);
        }
    }
}



export const carritoUpdateValorRol = ( carrito ) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken(`carrito/editar/valor`, carrito, 'PUT');
            const body = await resp.json();
            if( body.ok ) {
                dispatch(carritoUpdate(body.carrito) );
            } else {
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            console.log(error);
        }
    }
}




export const carritoActivo = (carrito) => ({
    type: types.carritoActivo,
    payload: carrito 
});


export const carritoActivoParaUpdate = (carrito) => ({
    type: types.carritoActivoParaUdate,
    payload: carrito 
});




const itemListaStart = ( carrito ) => ({
    type: types.itemListaStart,
    payload: carrito 
});



const carritoUpdate = ( carrito ) => ({
    type: types.carritoUpdate,
    payload: carrito
});



const carritoListaCompra = ( car ) => ({
    type: types.carritoListaCompra,
    payload: car
});


const carritoAddNew = ( carrito ) => ({
    type: types.carritoAddNew,
    payload: carrito
});



const carritoDeleteCompra = ( id ) => ({ 
    type: types.carritoDeleteCompra,
    payload: id
});







































// Eliminar Item en trabajo de Produccion
export const carritoStartDelete = (id) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken(`carrito/${ id }`, {}, 'DELETE');
            const body = await resp.json();

            if( body.ok ){
                dispatch( carritoDelete(id) );
                Swal.fire('Exitoso', 'Producto Eliminado del Carrito', 'success');
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }

    }
}

const carritoDelete = ( id ) => ({ 
    type: types.carritoDelete,
    payload: id
});



// Eliminar Item de Carrito de Compra




// Eliminar Item de Carrito de Compra en Multiple Seleccion
export const carritoDeleteMultiple = (items) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken(`carrito/multiple/delete`, items, 'PUT');
            const body = await resp.json();

            if( body.ok ){
                for( let p of items ){
                    dispatch(carritoDeleteCompra(p));
                }
                Swal.fire('Exitoso', 'Items Eliminado del Carrito', 'success');
                return true;
            }else {
                Swal.fire('Error', body.msg, 'error');
                return false;
            }

        } catch (error) {
            console.log(error);
            return false;
        }
    }
}














