import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Grid, MenuItem, TextField } from "@mui/material";
import { LayoutReturnAndAdd } from "../../layouts"
import { carritoListaPorOP } from "../../../actions/items";
import { opPorID, openStartForm } from "../../../actions";
import { FormularioEditarItemOp, ItemsProduccionAndEditar } from "../components";
import { ModalFormulario } from "../../components";
import { closeStartForm, startRolVistas } from "../../../actions";
import { carritoActivoParaUpdate, carritoCleanActivo } from "../../../actions/carrito";
import { rolesPedido } from "../../../data";






export const EditarOAgregarOP = () => {



    const { id } = useParams();
    const dispatch = useDispatch();
    const { view } = useSelector( state => state.iva );
    const { activeOP } = useSelector( state => state.op );
    const { activeCarritoUpdate } = useSelector( state => state.carrito );



    useEffect(() => {
        if( activeOP ) {
            dispatch(startRolVistas(activeOP.view || 'cliente'));
            localStorage.setItem('view', activeOP.view);
            localStorage.removeItem('cliente');
        }
    }, [activeOP] );




    useEffect(() => {
        if( id ) {
            dispatch( carritoListaPorOP(id) );
            dispatch( opPorID(id) )
        }
    }, [id] );

    
    
    const cerrarModal = () => {
        dispatch( carritoCleanActivo() )
        dispatch( closeStartForm() );
    }



    const handleCreateNewItem = () => {
        dispatch(carritoActivoParaUpdate({}));
        dispatch( openStartForm() );
    }



    return (

        <LayoutReturnAndAdd titulo={`Editar o Modificar Items de Op ${activeOP?.op}`} url={-1} funcion={handleCreateNewItem}>

            <Grid item xs={12} sm={6} sx={{mt:5, textAlign:"left"}}>
                <TextField disabled select label="Rol"
                    fullWidth value={ view || 'cliente' } size="small">    
                    {rolesPedido.map( item => (
                        <MenuItem key={item} value={item}>
                            {item}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>

            <ItemsProduccionAndEditar editar />

            <ModalFormulario titulo="Editar Item" cerrarModal={cerrarModal} datos={activeCarritoUpdate}>
                <FormularioEditarItemOp />
            </ModalFormulario>

        </LayoutReturnAndAdd>
    )

    
}
