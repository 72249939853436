
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { Grid, InputAdornment, TextField } from "@mui/material"
import { useParams } from "react-router-dom";
import { buscarPorProductoSegunCategoria } from "../../../actions/busquedas";
import { productosListaCategoriaStart } from "../../../actions/producto";
import { Search } from "@mui/icons-material";





export const BuscarProductoVenta = () => {


    const { id } = useParams();
    const dispatch = useDispatch();
    const debounceRef = useRef();
    const [valor, setValor] = useState('');



    const hanldeInputChange = ({ target }) => {
        setValor(target.value);
        if( debounceRef.current )
            clearTimeout( debounceRef.current );

        debounceRef.current = setTimeout( () => {
            if( target.value.trim().length > 0 ){
                dispatch( buscarPorProductoSegunCategoria({ id, termino: target.value }) );
            }else {
                dispatch( productosListaCategoriaStart( id ) );
            }
        }, 700);
    }


    return (


        <Grid container sx={{mb:4, mt:3, justifyContent:{sm:'center', md:'left'}, display:'flex'}}>
            <Grid item xs={12} sm={8}>
                <TextField
                    autoComplete="off"
                    label="Buscar Producto..." 
                    placeholder="Buscar Producto..."
                    variant="filled" 
                    fullWidth
                    name="valor"
                    value={valor}
                    onChange={ hanldeInputChange }
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>
        </Grid>

    )

}
