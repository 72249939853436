
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,  Typography } from "@mui/material";
import { MensajeAlertaDanger, TooltipAndIcon } from "../../components";
import { proveedorStartDelete } from "../../../actions/proveedor";
import Swal from "sweetalert2";




export const TablaProveedores = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { proveedores } = useSelector(state => state.proveedor);



    const handleActualizarProveedor = (p) => {
        navigate(`/super/crear-proveedor/${p.id}`);
    }



    const eliminarProveedor = (p) => {
        Swal.fire({
            title: 'Eliminar Proveedor',
            text: `¿Esta Seguro De Eliminar A ${ p.nombre }?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si, Eliminar' 
        }).then( ( result ) => {
            if(result.value){
                dispatch(proveedorStartDelete(p.id));
            }
        })
    }




    return (

        <>
            {
            ( proveedores.length > 0 )
            ? <Paper sx={{ width: '100%', my:3 }}>
                <TableContainer sx={{ my:'15px' }}>
                    <Table sx={{ minWidth: 700, }} aria-label="customized table">

                        <TableHead >
                            <TableRow>
                                <TableCell scope="col">Nombre</TableCell>
                                <TableCell scope="col">Ciudad</TableCell>
                                <TableCell scope="col">Movil</TableCell>
                                <TableCell scope="col">Tipo</TableCell>
                                <TableCell>Acciones</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {
                            proveedores.map( (user) => (
                            <TableRow  key={ user.id }>

                                <TableCell >
                                    <Typography variant="span" >{ user.nombre }</Typography>
                                </TableCell>
                                <TableCell>
                                    {
                                    ( user.ciudad )
                                    ?   <Typography variant="span" >{ user.ciudad }</Typography>
                                    :   <Typography variant="span" color='error'>No Hay Datos</Typography>
                                    }
                                </TableCell>
                                <TableCell>
                                    {
                                    ( user.movil )
                                    ?   <Typography variant="span" >{ user.movil }</Typography>
                                    :   <Typography variant="span" color='error'>No Hay Datos</Typography>
                                    }
                                </TableCell>
                                <TableCell>
                                    {
                                    ( user.profesion )
                                    ?   <Typography variant="span" >{ user.tipo }</Typography>
                                    :   <Typography variant="span" color='error'>No Hay Datos</Typography>
                                    }
                                </TableCell>

                                <TableCell>
                                    <Stack direction="row" spacing={1} justifyContent="center">
                                        <TooltipAndIcon
                                            titulo="Editar Proveedor"
                                            icono="editar"
                                            color="white"
                                            onClick={ () => handleActualizarProveedor(user) }
                                        />  

                                        <TooltipAndIcon
                                            titulo="Eliminar Proveedor"
                                            icono="eliminar"
                                            color="red"
                                            onClick={ () => eliminarProveedor(user) }
                                        />  
                                    </Stack>
                                </TableCell>
            
                            </TableRow>
                            ))
                            }
                        </TableBody>

                    </Table>
                </TableContainer>
            </Paper>

            : <MensajeAlertaDanger titulo='No hay Proveedores Disponibles' />
        
            }
        </>

    )
}
