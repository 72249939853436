
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { separadorMillares } from "../../../hooks";
import { MensajeAlertaDanger } from "../../components";



export const DetallesOpCliente = () => {



    const { activeOP } = useSelector( state => state.op );
    const { deudas } = useSelector(state => state.deudaCliente);


    const [abono, setAbono] = useState(0);
    

    useEffect(() => {
        if( deudas ){
            const a = deudas.reduce( (total, abono) => total + abono.abono, 0 );
            setAbono( a );
        }
    }, [deudas] );
    



    return (

        <Grid container textAlign='center'>

            <Grid item xs={12} mt={5} mb={2} >
              <Typography variant="h2" >Detalles de la O.P.</Typography>
            </Grid>
        
            {

              ( activeOP ) 
              ?<TableContainer sx={{ mb:5 }}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">

                      <TableHead >
                          <TableRow >
                              <TableCell>AREA</TableCell>
                              <TableCell>PAGO</TableCell>
                              <TableCell>IVA</TableCell>
                              <TableCell>TOTAL PARTNER</TableCell>
                              <TableCell>TOTAL CLIENTE</TableCell>
                              <TableCell>ABONOS</TableCell>
                              <TableCell>SALDO</TableCell>
                          </TableRow>
                      </TableHead>

                      <TableBody>

                          <TableRow >

                              <TableCell >
                                  <Typography variant="span">{ activeOP.estado }</Typography>
                              </TableCell>

                              <TableCell >
                                  <Typography variant="span">{ activeOP.pago }</Typography>
                              </TableCell>

                              <TableCell >
                                  <Typography variant="span">{ `$${activeOP.iva}` }</Typography>
                              </TableCell>

                              <TableCell >
                                  <Typography variant="span" >{ `$${separadorMillares(activeOP.totalDistri)}` }</Typography>
                              </TableCell>

                              <TableCell >
                                  <Typography variant="span" >{ `$${separadorMillares(activeOP.totalCliente)}` }</Typography>
                              </TableCell>

                              <TableCell >
                                  <Typography variant="span" >{ `$${separadorMillares(abono)}` }</Typography>
                              </TableCell>

                              <TableCell >
                                  <Typography variant="span" >{ `$${separadorMillares(activeOP.totalCliente - abono)}` }</Typography>
                              </TableCell>

                          </TableRow>

                      </TableBody>

                  </Table>
              </TableContainer>
              
              : <MensajeAlertaDanger titulo={'No hay O.P. En Este Modulo'} />

            }

        </Grid>

    )


    
}
