

// Medios de Pagos 
export const mediosPago = ['Efectivo', 'Nequi', 'Daviplata', 'Bancolombia', 'Daviviendad'];

// Estados en Ops de Produccion
export const estadosOp = ['Verificar Pago', 'Diseño', 'Pendiente Aprobacion', 'Produccion', 'Para Entregar', 'Credito', 'Entregado'];

// Estados de Pagos de Op
export const pagosOp = ['PENDIENTE', 'REALIZADO', 'CREDITO'];

// Roles de Usuarios
export const rolesClientes = ['CLIENTE', 'DISTRIBUIDOR', 'PUBLICISTA', 'EMPRESA'];

// Roles de pedidos
export const rolesPedido = ['cliente', 'distri', 'publicista', 'empresa'];




// Estados de Tipos de pago
export const tiposPago = ['CREDITO', 'CONTADO'];

// Colores para comprar en Carrito
export const coloresCarrito = [ 'Negro', 'Blanco', 'Morado', 'Rosado', 'Fucsia', 'Azul Oscuro', 'Azul Rey', 'Gris Claro', 'Gris Oscuro', 'Azul Cielo', 'Rojo', 'Verde', 'Vinotinto', 'Lila', 'Naranja', 'Amarillo' ] 

// Tallas para comprar en Carrito
export const tallasCarrito = [ '3-6', '6-12', '12-18', '2', '4', '6', '8', '10', '12', '14', '16', 'S', 'M', 'L', 'XL'];

// Genero para comprar en Carrito
export const generoCarrito = [ 'Niño', 'Mujer', 'Hombre' ];

// Diseño para comprar en Carrito
export const disenioCarrito = [ 'Con Diseño', 'Sin Diseño' ];

// Unidades de medida comprar en Carrito
export const unidadesMedida = [ 'Centimetros', 'Metros' ];



// Datos de Formulario
export const nombreSoloCantidad = ["MARKETING DIGITAL", "CARAMAÑOLAS", "SERVICIOS", "PAD MOUSE", "LLAVEROS", "FOTOS CUADROS RETABLOS", "SELLOS", "BOTONES", "AGENDAS", "SEÑALIZACION", "IMPRESION LASER", "SERVICIO-TECNICO"];
export const cantidadColor = ["CINTAS-PORTACARNETS", "TULAS", "MUGGS", "ALMOHADAS"];
export const prendas = ["PRENDAS"];
export const litografia = ["LITOGRAFIA"];
export const estructurasEcosolvente = ["ESTRUCTURAS", "IMPRESION ECOSOLVENTE", "CORTE LASER", "SOLO MATERIAL"];
export const cantidax50 = ["MANILLAS", "BOMBAS", "ESFEROS"];
export const cantidax10 = ["TALONARIOS"];
export const corteVinilo = ["CORTE-VINILO"];