
import { useEffect } from "react";
import { useDispatch } from "react-redux"

import { clienteListaStart } from "../../../actions/cliente";
import { BuscarCliente, TablaListadoClientes } from "../components";
import { LayoutArchivado } from "../../layouts";






export const ListadoTodosClientes = () => {

    const dispatch = useDispatch();

    useEffect( () => {
        dispatch( clienteListaStart(true) );
    }, [ dispatch ] );



  return (

      <LayoutArchivado titulo={'Listado de Clientes'} url='/super/cli-archi' >
          <BuscarCliente activo={true}/>
          <TablaListadoClientes />
      </LayoutArchivado>
  )
}
