
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, Grid, Typography} from "@mui/material";

import { CotizacionDatosPersonales, TablaCotizacionCliente } from "../components";
import { generarPDfCotizacion } from "../../../actions/cotizacion";
import { descargarArchivo } from "../../../helpers/descargarArchivo";

import { BackdropScreen } from "../../components";
import { LayoutReturn } from "../../layouts";

import moment from "moment";
const empresa = require.context('../../../assets/empresa', true);




export const CotizacionScreen = () => {

    const dispatch = useDispatch();
    const { nombre } = useSelector( state => state.admin );
    const { view } = useSelector( state => state.iva );
    const { carritoCompra } = useSelector( state => state.carrito );
    const cliente = JSON.parse(localStorage.getItem('cliente') ) || '';
    const observacion = localStorage.getItem('observacion');

    const [cargando, setCargando] = useState(false)



    const generarArchivo = async() => {
        setCargando(true);
        const newCarrito = carritoCompra.map( item => {
            if (item.nombreModificado) {
                item.producto.nombre = item.nombreModificado;
            }
            return item
        });

        const resp = await dispatch(generarPDfCotizacion({
            view,
            observacion: localStorage.getItem('observacion'),
            usuario: cliente, 
            items: newCarrito,
            fechaFactura: moment().format('YYYY-MM-DD HH:mm:ss'),
            formaPago: "CONTADO",
        }));
        descargarArchivo(resp.data);
        setCargando(false);
    }



    return (

        <LayoutReturn titulo="Cotización" url={'/super/comprar-ahora'}>

            <BackdropScreen cargando={cargando} titulo='Espere Por Favor Estamos Generando Su Cotización...' />

            <Grid container sx={{width:'100%', display:'flex', flexDirection:'column', alignContent:'center', justifyContent:'center', mt:4}}>
                <img src={ empresa(`./sevennegro.png`) } style={{width:110}} className="img-coti" />
                <Typography variant="h3" color="secondary" sx={{textAlign:'center'}}>Seven For Ever S.A.S.</Typography>
                <Typography variant="h3" color="secondary" sx={{textAlign:'center'}}>NIT: 901253145-3 Régimen Común</Typography>
                <Typography variant="h3" color="primary" sx={{textAlign:'center'}}>PUBLICIDAD PERSONALIZADOS BORDADOS</Typography>
            </Grid>

            <Typography variant="h3" color="secondary" sx={{my:2, fontSize:'17px'}}> 
                Atendiendo su solicitud estamos enviando cotización de los productos y servicios requeridos, para nosotros es un placer poner nuestra compañia a su servicio.
            </Typography>


            <Grid container>
                {(carritoCompra.length > 0) &&
                    <>
                        {/* Tabla de Datos personales */}
                        <CotizacionDatosPersonales />
                        {/* Tabla de Prodcutos */}
                        <TablaCotizacionCliente />
                    </>
                }
            </Grid>


            
            <Grid container sx={{ my:7, direccion:'column', justifyContent:'center'}}>
                <Grid item xs={ 10 } sm={ 5 }>
                    <Button variant='contained' color="secondary" fullWidth onClick={ generarArchivo }>
                        Descargar
                    </Button>
                </Grid>
            </Grid>

        </LayoutReturn>

    )
}
