
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { iconos } from "../../data";







export const LayoutCrearDoc = ({ children, titulo, funcion }) => {


    const handleFuncion = () => {
        funcion();
    }



    return (

        <>
        
            <Grid container direccion='row' alignItems='center'>

                <IconButton onClick={ handleFuncion } 
                    sx={{ color: '#fff', backgroundColor: 'secondary.main',
                        ':hover': { backgroundColor: 'secondary.main', opacity: 0.3 },
                    }}
                >
                    { iconos['plus']}
                </IconButton>

                <Box flex={ 1 } />

                <Typography variant='h2'>{ titulo }</Typography>

                <Box flex={ 1 } />

            </Grid>

            <Grid container alignItems='center' justifyContent='center' >

                { children }

            </Grid>

        </>
        
    )
}
