

import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom"

import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, TextField, Typography } from "@mui/material";

import { v4 as uuidv4 } from 'uuid';
import Swal from "sweetalert2";
import { clienteRegisterNew } from "../../../actions/authCliente";
import { InputDePassword } from "../../home/components";




const empresa = require.context('../../../assets/empresa', true);



const iniCliente = {
    nombre: "",
    cedula: "",
    password: "",
    confirmarPassword: "",
    distribuidor: "621ccf6e46b1b24654c1e163",
    terminos: false
}


export const RegisterCliente = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();


  const [ formCliente, setFormCliente ] = useState( iniCliente );
  const { nombre, cedula, password, confirmarPassword, distribuidor } = formCliente;

  const [ termino, setTermino ] = useState(false);


  const handleTerminoChange = (event) => {
    setTermino( !termino ); 
    // setTermino(event.target.checked); 
    // console.log(event.target.checked);

    setFormCliente({
      ...formCliente,
      terminos: !termino
    });
  }

  const handleInputChange = ({ target }) => {
    setFormCliente({
      ...formCliente,
      [target.name]: target.value
    });
  }


const handleCrearCliente = async(e) => {
    e.preventDefault();
    if( password !== confirmarPassword ) {
        return Swal.fire('Error', 'Las Contraseñas deben de ser Iguales', 'error');
    } 
    if( password.length <= 4 ) {
        return Swal.fire('Error', 'El Password Debe de tener mas de 5 Caracteres', 'error');
    }
    const resp = await dispatch( clienteRegisterNew({ nombre, cedula, password, distribuidor, email: uuidv4(), }) );
    if( !resp ) {
        return;
    }
    navigate(`/`);
}




    return (

        <Grid container className="login-cliente" my={4}>

            <Grid item className="img-cliente">
                <img src={ empresa(`./svtextoblanco.png`) } />
            </Grid>

            <Grid item>
                <Typography variant="h1" mt={3}>Registrarme</Typography>
            </Grid>

            <Grid item xs={10} sx={{ width:'400px', padding:'20px' }}>
                <form onSubmit={ handleCrearCliente } autoComplete="off" >

                    <Grid item xs={12} mt={2} >
                        <TextField required
                            label='Nombre Completo'
                            type='text'
                            placeholder='Nombre Completo'
                            fullWidth
                            name="nombre" value={ nombre }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                    <Grid item xs={12} mt={2} >
                        <TextField required
                            label='Cedula'
                            type='text'
                            placeholder='Cedula'
                            fullWidth
                            name="cedula" value={ cedula }
                            onChange={ handleInputChange }
                        />
                    </Grid>


                    <Grid item xs={12} mt={ 2 }>
                        <InputDePassword value={password}
                            name='password'
                            placeholder={'Password'} 
                            onChangeInput={ handleInputChange } 
                        /> 
                    </Grid>

                    <Grid item xs={12} mt={ 2 }>
                        <InputDePassword value={confirmarPassword}
                            name='confirmarPassword'
                            placeholder={'Confirmar Password'} 
                            onChangeInput={ handleInputChange } 
                        /> 
                    </Grid>
                    

                    <Link to="/terminos" target="_blank" >
                        <Checkbox onChange={ handleTerminoChange }
                            checked={ termino } color="primary"
                        />
                        <span>Aceptar términos y condiciones</span>
                    </Link>


                    {/* 
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox"
                                value={ termino } name="termino" required
                                onChange={ handleTerminoChange }
                                checked={ termino }
                        />
                        <small className="form-check-label">
                            Aceptar <Link to="/terminos" target="_blank">Teminos y Condiciones.</Link> 
                        </small>
                    </div>   */}


                    <Grid container my={3} direccion='column' justifyContent='center'  >
                        <Grid item xs={ 12 } >
                            <Button variant="contained" color="primary" fullWidth type="submit" >
                                <Typography variant="span" color='white' >Registrarme</Typography>
                            </Button>
                        </Grid>
                    </Grid>

                </form>

                <Grid sx={{width:'100%', textAlign:'right' }}>

                    <Typography variant="h3" mb={1}>
                        ¿Ya Tienes Cuenta?
                        <Link to="/login" className='underline' > Entrar Ahora</Link>
                    </Typography>
                    
                </Grid>
            </Grid>


        </Grid>

        
    )
}
