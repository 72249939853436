
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { Grid, InputAdornment, TextField } from "@mui/material"
import { busquedaPorCategoriaSinTeminados } from "../../../actions/busquedas";
import { categoriaListaSinTerminados } from "../../../actions/categoria";
import { Search } from "@mui/icons-material";



export const BuscarCategoriaVentas = () => {


    const dispatch = useDispatch();
    const debounceRef = useRef();
    const [valor, setValor] = useState('');


    const hanldeInputChange = ({ target }) => {
        setValor(target.value);
        if( debounceRef.current )
            clearTimeout( debounceRef.current );

        debounceRef.current = setTimeout( () => {
            if( target.value.trim().length > 0 ){
                dispatch( busquedaPorCategoriaSinTeminados(target.value) );
            }else {
                dispatch( categoriaListaSinTerminados() );
            }
        }, 700);
    }


    return (

        <Grid container sx={{mb:4, mt:1, justifyContent:{sm:'center', md:'left'}, display:'flex'}}>
            <Grid item xs={12} sm={8}>
                <TextField
                    autoComplete="off"
                    label="Buscar Producto Por Categoria" 
                    placeholder="Buscar Producto Por Categoria"
                    variant="filled" 
                    fullWidth
                    name="valor"
                    value={ valor }
                    onChange={ hanldeInputChange }
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>
        </Grid>
    )
}
