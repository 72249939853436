
import { useSelector } from "react-redux";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid, Button } from "@mui/material";
import { separadorMillares } from "../../../hooks/useMillares";
import { usePrecioSegunRol } from "../../../hooks";







export const TablaModelarItems = ({ producto }) => {


    const { role } = useSelector( state => state.admin );
    const { precioSegunRol } = usePrecioSegunRol();
    const rolesPermitidos = ['SUPERADMIN', 'ADMIN', 'DISTRIBUIDOR'];


    // const calcularColSpan = (producto, rolesPermitidos, role) => {
    //     let colSpan = 2;
    
    //     if (producto.color) colSpan++;
    //     if (producto.genero) colSpan++;
    //     if (producto.ancho) colSpan++;
    //     if (producto.alto) colSpan++;
    //     if (producto.unidadMedida) colSpan++;
    //     if (producto.terminados) colSpan++;
    //     if (producto.talla) colSpan++;
    //     if (producto.disenio) colSpan++;
    
    //     if (rolesPermitidos.includes(role)) colSpan += 2;
    //     return colSpan;
    // };


    return (

        <>
            {(producto) && 
            <TableContainer>
                <Table sx={{ minWidTableCell: 700, }}>
                    {/* {(mostrar) &&
                    <TableHead>
                        <tr>
                            <TableCell sx={{backgroundColor:'secondary.main'}} colSpan={calcularColSpan(producto, rolesPermitidos, role)}>
                                Información de Item
                            </TableCell>
                        </tr>
                    </TableHead>
                    } */}
                    <TableHead>
                        <TableRow>
                            <TableCell scope="col">Nombre Producto</TableCell>
                            <TableCell scope="col">Cantidad</TableCell>

                            {(producto.color) &&
                                <TableCell scope="col">Color</TableCell>
                            }

                            {(producto.genero) &&
                                <TableCell scope="col">Genero</TableCell>
                            }

                            {(producto.ancho) &&
                                <TableCell scope="col">Ancho</TableCell>
                            }

                            {(producto.alto) &&
                                <TableCell scope="col">Alto</TableCell>
                            }

                            {(producto.unidadMedida) &&
                                <TableCell scope="col">Uni. Medida</TableCell>
                            }

                            {(producto.terminados) &&
                                <TableCell scope="col">Terminados</TableCell>
                            }

                            {(producto.talla) &&
                                <TableCell scope="col">Talla</TableCell>
                            }

                            {(producto.disenio) &&
                                <TableCell scope="col">Diseño</TableCell>
                            }

                            {(rolesPermitidos.includes(role)) &&
                            <>
                                <TableCell scope="col">Valor Uni.</TableCell>
                                <TableCell scope="col">Total</TableCell>
                            </>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>{ producto.producto.nombre }</TableCell>
                            <TableCell>{ producto.cantidad }</TableCell>
                            {(producto.color) &&
                                <TableCell>{ producto.color }</TableCell>
                            }

                            {( producto.genero ) &&
                                <TableCell>{ producto.genero }</TableCell>
                            }
                            {(producto.ancho) &&
                                <TableCell>{ producto.ancho }</TableCell>
                            }

                            {(producto.alto) &&
                                <TableCell>{ producto.alto }</TableCell>
                            }

                            {(producto.unidadMedida) &&
                                <TableCell>{ producto.unidadMedida }</TableCell>
                            }

                            {(producto.terminados) &&
                                <TableCell scope="col">{ producto.terminados.nombre }</TableCell>
                            }

                            {(producto.disenio) &&
                                <TableCell>{ producto.disenio }</TableCell>
                            }

                            {(producto.talla) &&
                                <TableCell>{ producto.talla }</TableCell>
                            }

                            {(rolesPermitidos.includes(role)) &&
                                <>
                                    <TableCell>
                                        {`$${separadorMillares(precioSegunRol(producto) / producto.cantidad)}`}
                                    </TableCell>
                                    <TableCell>
                                        {`$${separadorMillares(precioSegunRol(producto))}`}
                                    </TableCell>
                                </>
                            }

                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            }
        </>

    )


    
}
