

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import required modules
import { Navigation, Pagination, History, Autoplay } from "swiper";
import { slideListaStart } from "../../actions/slideshow";





// const empresa = require.context('../../assets/empresa', true);
const baseUrl = process.env.REACT_APP_API_URL;




export const SlideShowScreen = () => {


    const dispatch = useDispatch();
    const { slideshow } = useSelector(state => state.slideshow);


    useEffect(() => {
        dispatch( slideListaStart() );
    }, [ dispatch ]);




    return (
    
        <div className="contenedor-principal">
            <Swiper
                slidesPerView={1}
                spaceBetween={30}
                loop={true}
                pagination={{
                clickable: true,
                }}
                navigation={true}
                autoplay={{
                delay: 5000,
                disableOnInteraction: false,
                }}
                modules={[ Navigation, Pagination, History, Autoplay ]}
                className="mySwiper"
            >

                {/* <SwiperSlide>
                    <img src={ empresa(`./seven1.png`) } alt="" />
                </SwiperSlide> */}
                

                {slideshow &&
                <>
                {
                    slideshow.map( (slide) => (
                    <SwiperSlide key={slide.img} >
                        <img src={`${ baseUrl }/uploads/slideshow/${ slide.img }`} alt="Imagen SlideShow" />
                    </SwiperSlide>
                    ))
                }
                    
                </>
                }
            
            </Swiper>
        </div>

    )
}
