
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Grid, TextField, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Autocomplete, Paper, Button } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { productosListaStartTodos } from '../../../actions/producto';
import { NumberFormatCustom } from "../../../hooks";




export const AgregarItemsPagos = ({ data, setData }) => {


    const dispatch = useDispatch();
    const { productos } = useSelector(state => state.producto);




    useEffect( () => {
        dispatch( productosListaStartTodos() );
    }, [dispatch] );



    
    const hamdleAgregarRow = () => {
        const newId = data.length + 1;
        setData([ 
            { 
                _id: newId,
                producto: '',
                cantidad: '',
                precioUnidad: '',
                porcentaje: 0,
                total: 0,
            }, 
            ...data,
        ]);
    };

    

    const removeRow = (id) => {
        setData( data.filter((row) => row._id !== id) );
    };




    const cambiarCantidad = (e, id) => {
        const newCantidad = e.target.value;
        const pedidoActualizado = data.map(( p ) => {
            if( p._id === id ){
                p = {
                    ...p, cantidad: newCantidad,
                    total: (+newCantidad * +p.precioUnidad) * (+p.porcentaje / 100) + (+newCantidad * +p.precioUnidad),
                }
            }
            return p;
        });
        setData(pedidoActualizado);
    }



    const handlePrecio = (e, id) => {
        const newPrecio = e.target.value;
        const pedidoActualizado = data.map( ( p ) => {
            if( p._id === id ){
                p = {
                    ...p, precioUnidad: newPrecio,
                    total: (+newPrecio * p.cantidad) * (+p.porcentaje / 100) + (+newPrecio * p.cantidad),
                }
            }
            return p;
        });
        setData(pedidoActualizado);
    }



    const handlePorcentaje = (e, id) => {
        const newPrecio = e.target.value;
        const pedidoActualizado = data.map( ( p ) => {
            if( p._id === id ){
                p = { ...p, porcentaje: newPrecio,
                    total: (+p.precioUnidad * p.cantidad) * (+newPrecio / 100) + (+p.precioUnidad * p.cantidad),
                }
            }
            return p;
        });
        setData(pedidoActualizado);
    }



    const handleProductoSelect = (producto, id) => {
        if( producto ){
            const productoSeleccionado = productos.find((p) => p.id === producto.id);
            setData((prevData) =>
                prevData.map((row) =>
                row._id === id
                    ? {
                        ...row,
                        producto: {id: productoSeleccionado.id, nombre: productoSeleccionado.nombre }
                    }
                    : row
                )
            );
        }
    };




    return (

        <>

            <Grid container sx={{ mt:4, mb:2, diplay:'flex', alignItems:'center', justifyContent:'space-between' }}>
                <Grid item xs={6} sm={4}>
                    <Typography variant='h4'>Agregar Item</Typography>
                </Grid>

                <Grid item xs={5} sm={4}>
                    <Button variant='contained' fullWidth 
                        onClick={ hamdleAgregarRow } sx={{ fontSize: '13px' }}
                    >
                        Agregar Item
                    </Button>
                </Grid>
            </Grid>

            <Grid container >
                <Paper sx={{width:'100%', maxWidth:{xs:450, sm:'100%', md:'100%'}, overflowX:'auto'}}>
                    <TableContainer >
                        <Table sx={{minWidth:700, overflowX:'auto'}}>
                            <TableHead>
                                <TableRow>
                                <TableCell sx={{width:'35%'}}>Producto</TableCell>
                                <TableCell sx={{width:'12%'}}>Cantidad</TableCell>
                                <TableCell sx={{width:'15%'}}>Precio Uni.</TableCell>
                                <TableCell sx={{width:'12%'}}>Porcentaje</TableCell>
                                <TableCell sx={{width:'15%'}}>Total</TableCell>
                                <TableCell sx={{width:'10%'}}>Accion</TableCell>
                                </TableRow>
                            </TableHead>

                        {(data.length > 0) 

                        ?   <TableBody>
                                {data.map((row) => (
                                <TableRow key={row._id}>
                                    <TableCell>
                                        <Autocomplete
                                            size="small"
                                            fullWidth
                                            options={productos}
                                            // options={productos.map((producto) => ({ ...producto, key: producto.id }))} 
                                            renderInput={(params) => <TextField {...params} placeholder="Producto" required/>}
                                            value={ row.producto || null }
                                            getOptionLabel={(option) => `${option.nombre}`}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            onChange={(_, newValue) => handleProductoSelect(newValue, row._id)}
                                        />
                                    </TableCell>

                                    <TableCell>
                                        <TextField
                                            required
                                            autoComplete='off'
                                            size="small"
                                            fullWidth
                                            type="number"
                                            placeholder='Cantidad'
                                            name='cantidad'
                                            value={ row.cantidad }
                                            onChange={ (e) => cambiarCantidad(e, row._id) }
                                        />
                                    </TableCell>

                                    <TableCell>
                                        <TextField
                                            required
                                            autoComplete='off'
                                            size="small"
                                            fullWidth
                                            placeholder='Precio Unidad'
                                            name='precioUnidad'
                                            value={row.precioUnidad}
                                            onChange={ (e) => handlePrecio(e, row._id) }
                                            InputProps={{
                                                inputComponent: NumberFormatCustom
                                            }}
                                        >
                                        </TextField>
                                    </TableCell>

                                    <TableCell>
                                        <TextField
                                            required
                                            autoComplete='off'
                                            size="small"
                                            fullWidth
                                            type="number"
                                            placeholder='Porcentaje'
                                            name='procentaje'
                                            value={ row.procentaje }
                                            onChange={ (e) => handlePorcentaje(e, row._id) }
                                        />
                                    </TableCell>

                                    <TableCell>
                                        <TextField
                                            required
                                            autoComplete='off'
                                            size="small"
                                            fullWidth
                                            placeholder='Total'
                                            name='total'
                                            value={ row.total }
                                            InputProps={{
                                                inputComponent: NumberFormatCustom
                                            }}
                                        >
                                        </TextField>
                                    </TableCell>

                                    <TableCell>
                                        <IconButton className="icon-boton" onClick={ () => removeRow(row._id) }>
                                            <Delete fontSize="small" color='error' />
                                        </IconButton>
                                    </TableCell>

                                </TableRow>
                                ))}
                            </TableBody>

                        :   <TableBody>
                                <TableRow>
                                    <TableCell sx={{py:3}} align="center" colSpan={7}>
                                        <Typography variant='h3'>Agregue Items Por Favor</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>

                        }
                        </Table>
                    </TableContainer>
                </Paper>
            </Grid>

        </>
    )
}
