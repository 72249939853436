
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { LayoutReturn } from "../layouts"
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import moment from "moment";





export const MiPerfil = () => {


    const { cliente }  = useSelector( state => state.authCliente );
    const navigate = useNavigate();


    const [formUser, setFormUser] = useState({
        nombre: "",
        cedula: "",
        direccion: "",
        ciudad: "",
        movil: "",
    });
    const  { nombre, cedula, direccion, ciudad, movil, establecimiento, profesion } = formUser;



    const handleInputChange = ({ target }) => {
        setFormUser({
            ...formUser,
            [target.name]: target.value
        });
    }


    useEffect(() => {
        if( cliente ){
            setFormUser({ ...cliente });
        }
        // if(Object.entries(cliente).length > 0){
        //     setFormUser({ ...cliente });
        // }
    }, [cliente]);


    const handleTerminos = () => {
        navigate('/terminos')
    }


    return (

        <LayoutReturn titulo='Vista De Mi Perfil' url='/dashboard'>

            <Box sx={{width:'100%'}}>
                <Grid container spacing={2} my={3}>

                    <Grid item xs={12} sm={6} md={4}>
                        <TextField required disabled
                            variant="filled"
                            label='Nombre Completo'
                            type='text'
                            placeholder='Nombre Completo'
                            fullWidth
                            name='nombre'
                            value={ nombre }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <TextField required disabled
                            variant="filled"
                            label='Cedula'
                            type='text'
                            placeholder='Cedula'
                            fullWidth
                            name='cedula'
                            value={ cedula }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} >
                        <TextField required disabled
                            variant="filled"
                            label='Direccion'
                            type='text'
                            placeholder='Direccion'
                            fullWidth
                            name='direccion'
                            value={ direccion }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} >
                        <TextField required disabled
                            variant="filled"
                            label='Ciudad'
                            type='text'
                            placeholder='Ciudad'
                            fullWidth
                            name='ciudad'
                            value={ ciudad }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <TextField required disabled
                            variant="filled"
                            label='Telefono'
                            placeholder='Telefono'
                            fullWidth
                            name='movil'
                            value={ movil }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <TextField required disabled
                            variant="filled"
                            label='Establecimiento'
                            placeholder='Establecimiento'
                            fullWidth
                            name='establecimiento'
                            value={ establecimiento }
                            onChange={ handleInputChange }
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <TextField required disabled
                            variant="filled"
                            label='Profesión'
                            placeholder='Profesión'
                            fullWidth
                            name='profesion'
                            value={ profesion }
                            onChange={ handleInputChange }
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                    </Grid>


                    <Grid item xs={12} sm={10}>
                        <TextField required disabled
                            variant="filled"
                            label='Fecha Aceptada de Terminos y Condiciones'
                            placeholder='Fecha Aceptada de Terminos y Condiciones'
                            fullWidth
                            name="fechaTermino"
                            value={ (cliente?.fechaTermino) ? moment(cliente?.fechaTermino).format('lll') : 'Tienes que Aceptar Terminos y condiciones' }
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                    </Grid>

                
                </Grid>
            </Box>

            <Grid container direccion='column' justifyContent='start'>
                <Grid item xs={ 12 } sm={ 3 } >
                    <Button variant="contained" color="secondary" fullWidth onClick={ handleTerminos } >
                        <Typography variant="span" color='white' >Ver Terminos Y Condiciones</Typography>
                    </Button>
                </Grid>
            </Grid>

        </LayoutReturn>

    )


    
}
