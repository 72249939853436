
import { useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";

import { separadorMillares } from "../../../hooks/useMillares";





export const ViewPreciosPorRol = ({ formCarrito, mostrar=true }) => {


    const { view } = useSelector( state => state.iva );
    const { cantidad, totalCliente, totalDistri, totalEmpresa, totalPublicista } = formCarrito;


    

    const handleSwitch = (role) => {
        switch(role){
            case "cliente":
            return  <Grid container sx={{display:'flex', justifyContent:'space-between', my:3}}>
                        {(mostrar) && <Typography variant="h3">Total Cliente</Typography>}
                        {(cantidad <= 0 ) 
                            ?<Typography variant="h3"> $0 </Typography>
                            :<Typography variant="h3">{ `$${ separadorMillares(totalCliente) }` }</Typography>
                        }
                    </Grid>;

            case "distri": 
            return  <Grid container sx={{display:'flex', justifyContent:'space-between', my:3}}>
                        {(mostrar) && <Typography variant="h3" color={'primary'}>Total Distribuidor</Typography>}
                        {(cantidad <= 0 ) 
                            ?<Typography variant="h3" color={'primary'}> $0 </Typography>
                            :<Typography variant="h3" color={'primary'}>{ `$${ separadorMillares(totalDistri) }` }</Typography>
                        }
                    </Grid>;

            case "disenio": 
            return <Grid container sx={{display:'flex', justifyContent:'space-between', my:3}}>
                        {(mostrar) && <Typography variant="h3" color={'secondary'}>Total Publicista</Typography>}
                        {(cantidad <= 0)
                            ?<Typography variant="h3" color={'secondary'}>$0</Typography>
                            :<Typography variant="h3" color={'secondary'}>{ `$${ separadorMillares(totalPublicista) }` }</Typography>
                        }
                    </Grid>;

            case "empresa":
            return <Grid container sx={{display:'flex', justifyContent:'space-between', my:3}}>
                        {(mostrar) && <Typography variant="h3" color={'#b27d00'}>Total Empresa</Typography>}
                        {(cantidad <= 0)
                            ?<Typography variant="h3" color={'#b27d00'}>$0</Typography>
                            :<Typography variant="h3" color={'#b27d00'}>{ `$${ separadorMillares(totalEmpresa) }` }</Typography>
                        }
                    </Grid> 

            default:
                return;
        }
    }
    


    return (

        <>
            {(view) &&
            <> { handleSwitch(view) } </>
            }
        </>
    )
}
