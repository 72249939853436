import { fetchConToken, fetchConTokenCliente, fileForData } from "../helpers/fetch";
import { opsApi } from "../apis";
import { types } from "../types/types";

import { prepararFecha, prepararFechaPorId } from "../helpers/prepararFecha";
import { manejoDeErrores } from "../helpers/manejoDeErrores";

import Swal from "sweetalert2";





export const opPorID = ( id ) => {
    return async( dispatch ) => {
        try {
            const { data } = await opsApi.get(`/opxid/${id}`);
            dispatch( opActivo(data.op) );
        } catch (error) {
            const errores = manejoDeErrores(error.response.data)
            Swal.fire('Error', errores, 'error');
        }
    }
}




export const opStartDelete = ( id ) => {
    return async( dispatch ) => {
        try {
            const { data } = await opsApi.delete(`/${id}`);
            if( data.ok ){
                dispatch( opDelete(data.data) );
                Swal.fire('Exitoso', 'OP Eliminada', 'success');
                return true;
            }
            return false;
        } catch (error) {
            const errores = manejoDeErrores(error.response.data)
            Swal.fire('Error', errores, 'error');
            return false;
        }
    }
}





export const opListaStartTodas = ({ estado='TODAS', inicio='', final='', termino='', page=0 }) => {
    return async( dispatch ) => {
        try {
            const params = new URLSearchParams();
            params.append('page',  page.toString());
            params.append('termino',  termino);
            params.append('estado',  estado);
            params.append('inicio',  inicio);
            params.append('final',  final);

            const { data } = await opsApi.get(`/estados`, { params });
            if(data.ok){
                dispatch( totalOpsList(data.total) );
                return data.data;
            }
            return [];
        } catch (error) {
            return false;
        }
    }
}





export const updatePago = ( op ) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken(`op/cambio-pago/${ op.id }`, { pago: op.pago }, 'PUT');
            const body = await resp.json();

            if( body.ok ){
                op.pago = body.op.pago;
                dispatch( opUpdate(op) );
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            console.log(error);
        }
    }
}



export const opStartAddNew = ( op ) => {
    return async( dispatch, getState ) =>{
        const { uid, nombre } = getState().admin;

        try {
            const resp = await fileForData( 'op/new', op, 'POST' );
            const body = await resp.json();
            if( body.ok ) {
                Swal.fire(  
                    `Tu Op es: ${ body.op.op }`, 
                    `Todo trabajo se inicia con un abono de <b>50% del valor total</b> y el saldo a la entrega del mismo. No se hace devolución de dinero una vez iniciado el trabajo. No nos hacemos responsables por trabajos pasados <b>30 dias desde la fecha de solicitud.</b> en caso de correcciones, se validaran maximo al siguiente dia de la entrega. Los diseños realizados por nosotros no seran impresos sin antes ser <b>aprobados por el cliente, REVISE MUY BIEN LOS DISEÑOS, no nos hacemos responsables por errores de ortografia, redacción, alineación, diagramación, tamaño del texto, logos, numeros telefonicos, correos, direcciones, fuentes, colores y demas después de la APROBACIÓN E IMPRESIÓN.</b> Tenga en cuenta que los colores en pantalla tienden a variar en la impresión, <b>No</b> nos comprometemos a sacar colores ni tamaños exactos. Recuerde que según la disponibilidad de tiempo se programa una posible fecha de entrega  <b>por ende los trabajos para entrega inmediata o a domicilio tienen un costo adicional el cual se validara con su distribuidor autorizado o directamente con la empresa.</b>`, 
                    'success'
                );

                op.id = body.op.id;
                op.usuario = { id: uid, nombre : nombre };
                localStorage.removeItem('cliente');
                dispatch( opAddNew( op ) );
                dispatch( carritoListaCompra([]) );

                return true;
            } else {
                Swal.fire('Error', body.msg, 'error');
                return false;
            } 
        } catch (error) {
            console.log(error);
        }
    }
}





const totalOpsList = ( total ) => ({ 
    type: types.totalOpsList,
    payload: total
});




const opDelete = ( op ) => ({ 
    type: types.opDelete,
    payload: op
});




























































export const opListaPorEstados = ( estado ) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken(`op/xestados/${ estado }`);
            const body = await resp.json();

            if( body.ok ){
                const op = prepararFecha( body.op );
                dispatch( opLista(op) );
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}






export const opPorComprador = ( id ) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken(`op/opxcomprador/${ id }`);
            const body = await resp.json();

            if( body.ok ) {
                const op = prepararFecha( body.op );
                dispatch( opLista(op) );
            }else {
                Swal.fire('Error', body.msg, 'error');
            }

            
        } catch (error) {
            console.log(error);
        }
    }
}







export const updateEstadoActivoOp = ( uid ) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken(`op/estado-op/${ uid }`, {}, 'PUT');
            const body = await resp.json();

            if( body.ok ){
                dispatch( opDelete(body.op) );
                Swal.fire('Exitoso', 'Op Archivada', 'success');
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}



export const opListaStartDistriPropia = () => {
    return async( dispatch, getState ) => {

        const { uid } = getState().admin;

        try {
            const resp = await fetchConToken(`op/vistadeldistri/${ uid }`);
            const body = await resp.json();

            if( body.ok ){
                const op = prepararFecha( body.op );
                dispatch( opListaStartDistri(op) );
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}


export const opsDeMisClientes = () => {
    return async( dispatch, getState ) => {

        const { uid } = getState().admin;

        try {
            const resp = await fetchConToken(`op/opmisclientes/${ uid }`);
            const body = await resp.json();

            if( body.ok ){
                const op = prepararFecha( body.op );
                dispatch( opLista(op) );
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}



export const opPorCompradorCliente = ({ uid, estado }) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConTokenCliente(`op/opxcli/${ uid }/${ estado }`);
            const body = await resp.json();

            if( body.ok ) {
                const op = prepararFecha( body.op );
                dispatch( opLista(op) );
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}



export const updateEstadoActivoCliente = ( uid ) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConTokenCliente(`op//estado-cliente/${ uid }`, {}, 'PUT');
            const body = await resp.json();

            if( body.ok ){
                dispatch( opDelete(body.op) );
                Swal.fire('Exitoso', 'Op Archivada', 'success');
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}



const opListaStartDistri = ( op ) => ({
    type: types.opListaStartDistri,
    payload: op 
});


// Activar Proucto en el Redux
const opActivo = ( op ) => ({
    type: types.opActivo,
    payload: op
});



const opLista = ( op ) => ({
    type: types.opLista,
    payload: op 
});



const opAddNew = ( op ) => ({
    type: types.opAddNew,
    payload: op
});



const opUpdate = ( op ) => ({
    type: types.opUpdate,
    payload: op 
});



const carritoListaCompra = ( car ) => ({
    type: types.carritoListaCompra,
    payload: car
});


export const opStartActivo = ( op ) => ({
    type: types.opActivo,
    payload: op
});


export const opDeleteActivo = () => ({
    type: types.opDeleteActivo,
});





export const opListaStartDistribuidor = () => {
    return async( dispatch, getState ) => {

        const { uid } = getState().admin;

        try {
            const resp = await fetchConToken(`op/usuario/${ uid }`);
            const body = await resp.json();

            if( body.ok ){
                const op = prepararFecha( body.op );
                dispatch( opLista(op) );
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}



export const updateOpTodo = ( op ) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken(`op/${ op.id }`, op, 'PUT');
            const body = await resp.json();
            
            if( body.ok ){
                op.estado = body.op.estado;
                dispatch( opUpdate(op) );
                Swal.fire('Exitoso', 'O.P. Actualizada', 'success');
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}







export const opPorIDCliente = ( id ) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConTokenCliente(`op/opxid/${ id }`);
            const body = await resp.json();

            if( body.ok ){
                const op = prepararFechaPorId( body.op );
                dispatch( opActivo(op) );
            }else {
                Swal.fire('Error', body.msg, 'error');
            }

        } catch (error) {
            console.log(error);
        }
    }
}


