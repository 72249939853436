
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { LayoutReturn } from "../layouts";
import { separadorMillares } from "../../../hooks";
import { generarPDfCotizacion } from "../../../actions/cotizacion";
import { BackdropScreen } from "../../components";
import { descargarArchivo } from "../../../helpers/descargarArchivo";


import moment from "moment";
const empresa = require.context('../../../assets/empresa', true);





export const CotizarCliente = () => {

    const dispatch = useDispatch();
    const { cliente }  = useSelector( state => state.authCliente );
    const { carritoCliente } = useSelector( state => state.carrito );

    const [cargando, setCargando] = useState(false)
    const [totales, setTotales] = useState({ totalCliente: '', totalDistri: '' });
    const { totalCliente } = totales;


    useEffect( () => {
        if( carritoCliente ){
            const carritoTotal = carritoCliente.reduce( (total, prod) => total + prod.totalCliente, 0 );
            const distriTotal = carritoCliente.reduce( (total, prod) => total + prod.totalDistri, 0);
                
            setTotales({ totalCliente: carritoTotal, totalDistri: distriTotal });
        }
    }, [carritoCliente]);



    const handleGenerarPDF = async() => {
        setCargando(true);
        const resp = await dispatch(generarPDfCotizacion({
            usuario: cliente, 
            items: carritoCliente,
            fechaFactura: moment().format('YYYY-MM-DD HH:mm:ss'),
            formaPago: "CONTADO",
            view: "cliente",
        }));
        descargarArchivo(resp.data);
        setCargando(false);
    }



    return (
        <LayoutReturn titulo="Cotización" url="/comprar-cliente">

            <BackdropScreen cargando={cargando} titulo='Espere Por Favor Estamos Generando Su Cotización...' />

            <Grid container sx={{width:'100%', display:'flex', flexDirection:'column', alignContent:'center', justifyContent:'center', mt:4}}>
                <img src={ empresa(`./sevennegro.png`) } style={{width:110}} className="img-coti" />
                <Typography variant="h3" color="secondary" sx={{textAlign:'center'}}>Seven For Ever S.A.S.</Typography>
                <Typography variant="h3" color="secondary" sx={{textAlign:'center'}}>NIT: 901253145-3 Régimen Común</Typography>
                <Typography variant="h3" color="primary" sx={{textAlign:'center'}}>PUBLICIDAD PERSONALIZADOS BORDADOS</Typography>
            </Grid>

            <Typography variant="h3" color="secondary" sx={{my:2, fontSize:'17px'}}> 
                Atendiendo su solicitud estamos enviando cotización de los productos y servicios requeridos, para nosotros es un placer poner nuestra compañia a su servicio.
            </Typography>


            {cliente &&
            <TableContainer sx={{my:3}}>
                <Table sx={{ minWidth: 750 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>CEDULA</TableCell>
                            <TableCell>NOMBRE</TableCell>
                            <TableCell>CELULAR</TableCell>
                            <TableCell>CIUDAD</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>{cliente?.cedula}</TableCell>
                            <TableCell>{cliente.nombre}</TableCell>
                            <TableCell>{cliente.movil}</TableCell>
                            <TableCell>{cliente?.ciudad}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            }

            <TableContainer sx={{my:3}}>
                <Table sx={{ minWidth: 750 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>DESCRIPCIÓN</TableCell>
                            <TableCell>CANTIDAD</TableCell>
                            <TableCell>PRECIO UNI.</TableCell>
                            <TableCell>PRECIO TOTAL</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {carritoCliente.map( car => (
                            <TableRow key={ car.id }>
                                <TableCell>{ car.producto.nombre }</TableCell>
                                <TableCell>{ car.cantidad }</TableCell>
                                <TableCell>{`$${ separadorMillares(car.totalCliente / car.cantidad) }`}</TableCell>
                                <TableCell>{`$${ separadorMillares(car.totalCliente) }`}</TableCell>
                            </TableRow>
                        )) }
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>TOTAL</TableCell>
                            <TableCell colSpan="2">${ separadorMillares(totalCliente) }</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer> 

            <Typography variant="h3" color="secondary" sx={{my:2, fontSize:'17px'}}> 
                Nota: Todos Los valores en esta orden de pedido son antes de IVA.
            </Typography>

            <Grid container sx={{display:'flex', justifyContent:'center', my:3}}>
                <Grid item xs={ 10 } sm={ 5 }>
                    <Button variant='contained' fullWidth onClick={ handleGenerarPDF } >
                        Descargar
                    </Button>
                </Grid>
            </Grid>
        </LayoutReturn>
    )
}
