
import { useDispatch, useSelector } from "react-redux";

import { Box, Checkbox, Grid, Typography } from "@mui/material";
import { startActiveIva } from "../../../actions";
import { separadorMillares } from "../../../hooks";
import { useAdmin } from "../../../hooks/useAdmin";
import { ViewPreciosPorRol } from "../../components";




export const PagoOrdenOp = ({ setFormOp, formOp }) => {


    const { iva } = useSelector( state => state.iva );
    const dispatch = useDispatch();
    const admin = useAdmin();



    const handleCambioCheck = () => {
        dispatch(startActiveIva(!iva));
        localStorage.setItem('iva', JSON.stringify(!iva) );
    }



    return (

        <Grid container sx={{background:'#1E1E1E', display:'flex', flexDirection:'column', p:2, borderRadius:'10px'}}>
            <Box sx={{width:'100%', textAlign:'center'}}>
                <Typography variant="h4" sx={{fontSize:'25px'}}>Total de la Orden</Typography>
            </Box>

            <Box sx={{width:'100%', textAlign:'center'}}>
                <Typography variant="span" sx={{letterSpacing:'-0.3px'}}>Todos Los valores en esta orden de pedido son antes de IVA.</Typography>
            </Box>


            <Box sx={{mt:3, width:'100%', textAlign:'left'}}>
                <Checkbox sx={{p:0, mr:1}} checked={iva} onChange={handleCambioCheck} />
                <Typography variant="body" color={(iva) ? 'primary' : ''} >
                    Seleccione si desea Facturacion Electronica.
                </Typography>
            </Box>


            {(iva) &&
                <Grid item xs={12} sx={{display:'flex', justifyContent:'space-between', mt:2}}>
                    <Typography variant="h3">Iva (19%)</Typography>
                    <Typography variant="h3">{`$${separadorMillares(formOp.iva)}`}</Typography>
                </Grid>
            }

            {/* {(admin)
            ?   <MostrarPreciosRolModificables setFormOp={setFormOp} formOp={formOp} />
            :   <ViewPreciosPorRol formCarrito={formOp} />
            } */}

            <ViewPreciosPorRol formCarrito={formOp} />

        </Grid>

    )

}
