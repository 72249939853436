
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { gastosPorId, deleteGastoActivo } from "../../../actions/gastos";
import { LayoutReturn } from "../../layouts";
import { TablaProductosFactura } from "../components";
import { CargandoInformacion } from "../../components";

import moment from "moment";






export const DetallesGasto = () => {


    const { id } = useParams();
    const dispatch = useDispatch();
    const { activeGasto } = useSelector( state => state.gastos );


    useEffect(() => {
        if(id){
            dispatch(gastosPorId(id));
        }
        return () => dispatch(deleteGastoActivo());
    }, [id]);



    return (


        <LayoutReturn titulo={`Factura ${activeGasto?.factura}`} url='/super/gastos' >

            { ( activeGasto ) 

            ? <>
                <TableContainer sx={{ mt:5 }} >
                <Table sx={{ minWidth: 700 }} >

                    <TableHead >
                        <TableRow>
                            <TableCell>FECHA</TableCell>
                            <TableCell>FACTURA</TableCell>
                            <TableCell>PAGO</TableCell>
                            <TableCell>PROVEEDOR</TableCell>
                            <TableCell>TIPO</TableCell>
                        </TableRow>
                    </TableHead>

                
                    <TableBody>
                        <TableRow key={ activeGasto._id } >
                            <TableCell>{ moment(activeGasto.createdAt).format('lll') }</TableCell>
                            <TableCell>{ activeGasto.factura }</TableCell>   
                            <TableCell>{ activeGasto.tipoPago }</TableCell>
                            <TableCell>{ activeGasto.proveedor?.nombre }</TableCell>
                            <TableCell>{ activeGasto.tipo }</TableCell>
                        </TableRow>
                    </TableBody>
                

                </Table>
                </TableContainer>

                <TablaProductosFactura productos={activeGasto.items} />
                
            </>

            : <CargandoInformacion titulo="Cargando Gasto..." />

            }

            </LayoutReturn>

    )
}
