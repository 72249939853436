

import { Button, Grid } from "@mui/material"
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { useAdmin } from "../../../hooks/useAdmin";
import { VisuallyHiddenInput } from "../../../theme/originalTheme";
import { SelectSencillo, TextFormNumber } from "../../components";
import { iconos, mediosPago } from "../../../data";





export const BotonesDeCompletarOrden = ({ funcion, url, setFormOp, formOp }) => {


    const cliente = JSON.parse(localStorage.getItem('cliente') ) || '';

    const navigate = useNavigate();
    const admin = useAdmin();
    const { role } = useSelector( state => state.admin );


    const handleCambioAbono = ({ target }) => {
        setFormOp( (formOp) => ({
            ...formOp,
            abono: target.value
        }));
    };


    const handleCambioBanco = ({ target }) => {
        setFormOp( (formOp) => ({
            ...formOp,
            banco: target.value
        }));
    };



    const handlePictureClick = () => {
        document.querySelector('#fileSelector').click();
    }



    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if(file) {
            setFormOp( (formOp) => ({
                ...formOp,
                imgPago: file
            }));
        }
    }




    const handleCheckout = () => {
        navigate(`${url}`);
    }




    return (

        <Grid container sx={{background:'#1E1E1E', display:'flex', flexDirection:'column', px:2, pb:2, borderRadius:'10px'}}>

            {cliente && role === 'SUPERADMIN' &&
            <Grid container>
                {admin &&
                <Grid item xs={12} my={1}>
                    <TextFormNumber 
                        size="small"
                        fullWidth
                        autoComplete='off'
                        label="Aplicar Abono"
                        onChange={ handleCambioAbono } 
                    />
                </Grid>
                }

                <Grid item xs={12} my={1}>
                    <SelectSencillo 
                        size="small"
                        fullWidth
                        label="Seleccione Un Banco"
                        options={mediosPago}
                        onChange={ handleCambioBanco }
                    />
                </Grid>


                <input type="file" 
                    id="fileSelector"
                    className="boton-file"
                    onChange={ handleFileChange }
                    name="file"
                />


                <Button sx={{my:1}} variant={(formOp.imgPago) ? "contained" : "outlined"} color="secondary"
                    startIcon={iconos['subirimg']} fullWidth onClick={ handlePictureClick }>
                    Subir Imagen
                    <VisuallyHiddenInput type="file" />
                </Button>
            </Grid>
            }



            {(cliente)
            ?   <Grid item xs={12} sx={{my:2}}>
                    <Button fullWidth color='primary' variant="contained" onClick={ funcion }>
                        Completar Orden
                    </Button>
                </Grid>
            :   <Grid item xs={12}>
                    <Button fullWidth color='primary' variant="contained" sx={{my:2}} onClick={handleCheckout}>
                        Checkout
                    </Button>
                </Grid>
            }
        </Grid>
    )

    
}
