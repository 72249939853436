
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Button, Grid } from "@mui/material";
import { coloresCarrito, unidadesMedida } from "../../../data";
import { useCalcularPrecios, separadorMillares } from "../../../hooks";
import { AgregarTerminados, SelectorProductos,  } from "../components";
import { SelectSencillo, TextFormField, ViewPrecioRolMinimoSeis } from "../../components";
import { buscarProductoCorteVinilo } from "../../../helpers/FormulariosCarrito";

import Swal from "sweetalert2";
const basePrecio = +process.env.REACT_APP_PRECIO_BASE;




export const FormularioCorteVinilo = ({ formCarrito, setFormCarrito, funcionAgregar, titulo }) => {


    const  { activeProducto } = useSelector( state => state.producto );
    const { activeTerminado } = useSelector( state => state.producto );
    const { calcularPreciosTerminados } = useCalcularPrecios();
    const { activeCarritoUpdate } = useSelector( state => state.carrito );
    const { carritoCompra } = useSelector( state => state.carrito );

    const [ precioReal, setPrecioReal ] = useState(0);


    useEffect(() => {
        if(activeProducto){
            const { cliente, distri, empresa, publicista, precioReal }  = calcularPreciosTerminados( formCarrito.unidadMedida, formCarrito.alto, formCarrito.ancho, formCarrito.cantidad, activeProducto, activeTerminado);
            setPrecioReal( precioReal - cliente );
            setFormCarrito({
                ...formCarrito,
                totalCliente: (cliente < 1000) ? basePrecio : Math.ceil(cliente),
                totalDistri: (distri < 1000) ? basePrecio : Math.ceil(distri),
                totalEmpresa: (empresa < 1000) ? basePrecio : Math.ceil(empresa),
                totalPublicista: (publicista < 1000) ? basePrecio : Math.ceil(publicista),
            });
        }
    }, [activeTerminado, formCarrito.unidadMedida, formCarrito.alto, formCarrito.ancho, formCarrito.producto, formCarrito.cantidad, activeProducto]);




    useEffect(() => {
        if( formCarrito.color === '' ){ return }

        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })
        Toast.fire({ icon: 'question', title: 'Los Productos y Colores dependen de disponibilidad de stock' });
    }, [formCarrito.color]);




    const handleInputChange = ({ target }) => {
        setFormCarrito({
            ...formCarrito,
            [target.name]: target.value
        });
    }



    const handleAgregarAlCarro = (e) => {
        e.preventDefault();
        if( [formCarrito.cantidad, formCarrito.unidadMedida, formCarrito.alto, formCarrito.ancho, formCarrito.color].includes('') ) { 
            return Swal.fire('Error', 'La cantidad, unidad de medida, alto y ancho son campos obligatorios.', 'error') 
        }

        if( activeCarritoUpdate ) {
            return funcionAgregar(activeCarritoUpdate)
        }

        const product = buscarProductoCorteVinilo(carritoCompra, formCarrito.producto, 
            formCarrito.color, formCarrito.unidadMedida, formCarrito.alto, formCarrito.ancho);
        funcionAgregar(product)
    }




    return (

        <form onSubmit={ handleAgregarAlCarro }>

            <SelectorProductos formCarrito={formCarrito} setFormCarrito={setFormCarrito} />

            <Grid container sx={{width:'100%', mt:2, display:'flex', justifyContent:'space-between'}} >
                <Grid item xs={5.8}>
                    <TextFormField
                        size="small"
                        label={`Cantidad`}
                        type='number'
                        placeholder="Cantidad"
                        name="cantidad"
                        value={formCarrito.cantidad}
                        onChange={ handleInputChange } 
                    />
                </Grid>

                <Grid item xs={5.8}>
                    <SelectSencillo
                        size="small"
                        label="Unidad de Medidad"
                        name="unidadMedida"
                        value={ formCarrito.unidadMedida }
                        onChange={ handleInputChange } 
                        options={unidadesMedida}
                    />
                </Grid>

                <Grid item xs={5.8}>
                    <TextFormField
                        sx={{mt:2}}
                        size="small"
                        label='Ancho'
                        type='number'
                        placeholder="Ancho"
                        name="ancho"
                        value={formCarrito.ancho}
                        onChange={ handleInputChange } 
                    />
                </Grid>

                <Grid item xs={5.8}>
                    <TextFormField
                        sx={{mt:2}}
                        size="small"
                        label='Alto'
                        type='number'
                        placeholder="Alto"
                        name="alto"
                        value={formCarrito.alto}
                        onChange={ handleInputChange } 
                    />
                </Grid>


                <Grid item xs={12}>
                    <SelectSencillo
                        sx={{mt:2}}
                        size="small"
                        label="Color"
                        name="color"
                        value={formCarrito.color}
                        onChange={ handleInputChange } 
                        options={coloresCarrito}
                    />
                </Grid>

                <AgregarTerminados formCarrito={formCarrito} setFormCarrito={setFormCarrito} />

            </Grid>


            {/* Mostrar Precio Segun Rol */}
            <ViewPrecioRolMinimoSeis formCarrito={formCarrito} />


            <Grid container sx={{display:'flex', justifyContent:'space-between', mt:2}}>
                <Grid item xs={(precioReal <= 0) ? 12 : 5.8}>
                    <Button type="submit" color='primary' variant="contained" fullWidth>
                        { titulo }
                    </Button>
                </Grid>

                {(precioReal > 0) && (
                <Grid item xs={5.8}>
                    <Button color='secondary' variant="contained" fullWidth>
                        Ahorra { `$${ separadorMillares( precioReal ) }`}
                    </Button>
                </Grid>
                )}
            </Grid>
        </form> 
    )
}
