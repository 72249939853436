import { fetchConToken, fetchSinToken } from "../helpers/fetch";
import { prepararFecha, prepararFechaAbono, prepararFechaPorId } from "../helpers/prepararFecha";
import { types } from "../types/types";

import Swal from "sweetalert2";






export const busquedaDeOp = (categoria, termino) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchSinToken(`busqueda/ops/${termino}/${categoria}`);
            const body = await resp.json();

            if( body.ok ){
                const busqueda = prepararFecha(body.resultado);
                dispatch( opLista(busqueda) );
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}





export const busquedaDeItemsPorOpProduccion = ( termino ) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchSinToken(`busqueda/items/produccion/${ termino }`);
            const body = await resp.json();

            if( body.ok ){
                const busqueda = body.solucion;
                dispatch( carritoLista( busqueda ) );
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}



export const busquedaTodosProductos = ( buscar ) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchSinToken(`busqueda/producto/todos/${ buscar }`);
            const body = await resp.json();
            if( body.ok ){
                dispatch( productosLista(body.resultado) );
            }
        } catch (error) {
            console.log(error);
        }
    }
}



export const busquedaPorCategoriaSinTeminados = ( termino ) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchSinToken(`busqueda/categoria/venta/${ termino }`);
            const body = await resp.json();

            if( body.ok ){
                const busqueda = body.resultados;
                dispatch( categoriaLista(busqueda) );
            } else {
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            console.log(error);
        }
    }
}




export const busquedaDeClientesTodos = (datos) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchSinToken(`busqueda/cliente/todos`, datos, 'POST');
            const body = await resp.json();

            if( body.ok ){
                dispatch( clienteLista(body.resultado) );
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            console.log(error);
        }
    }
}



export const busquedaDeUsuariosAdmin = (datos) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchSinToken(`busqueda/admin/todos`, datos, 'POST');
            const body = await resp.json();
            if( body.ok ){
                dispatch( usuarioLista(body.resultado) );
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            console.log(error);
        }
    }
}



































export const busquedaDeItemsPorOp = ( termino ) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchSinToken(`busqueda/items/buscar/${ termino }`);
            const body = await resp.json();

            if( body.ok ){
                const busqueda = body.solucion;
                dispatch( carritoLista( busqueda ) );
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}




export const rastrearOpCliente = (termino) => {
    return async( dispatch, getState ) => {
        try {
            const resp = await fetchConToken(`busqueda/rastrear/cliente/${ termino }`);
            const body = await resp.json();

            if( body.ok ){
                const op = prepararFechaPorId( body.resultado);
                dispatch( opActivo(op) );
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}



export const busquedaPorFecha = ( fechas ) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchSinToken(`busqueda/caja/abonos?start=${fechas.start}&end=${fechas.end}&medio=${fechas.medio}&op=${fechas.op}`);
            const body = await resp.json();

            if( body.ok ){
                const busqueda = prepararFechaAbono(body.filtros);
                dispatch( abonosLista(busqueda) );
            }else {
                Swal.fire('Error', body.msg, 'error');
                dispatch( abonosLista([]) );
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}



export const busquedaPorGastosFecha = ( busqueda ) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchSinToken(`busqueda/gastos/fecha?start=${ busqueda.start }&end=${ busqueda.end }&tipo=${ busqueda.tipo }`);
            const body = await resp.json();

            if( body.ok ){
                const busqueda = prepararFechaAbono(body.filtros);
                dispatch( gastosLista(busqueda) );
            }else {
                Swal.fire('Error', body.msg, 'error');
                dispatch( abonosLista([]) );
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}









const gastosLista = ( gastos ) => ({
    type: types.gastosLista,
    payload: gastos 
});



const abonosLista = ( abono ) => ({
    type: types.abonosLista,
    payload: abono 
});



const opActivo = ( op ) => ({
    type: types.opActivo,
    payload: op
});




const opLista = ( op ) => ({
    type: types.opLista,
    payload: op 
});



const clienteLista = ( clientes ) => ({
    type: types.clienteLista,
    payload: clientes
});



const carritoLista = ( carrito ) => ({
    type: types.carritoLista,
    payload: carrito 
});



const productosLista = ( productos ) => ({
    type: types.productosLista,
    payload: productos 
});




const categoriaLista = ( categoria ) => ({
    type: types.categoriaLista,
    payload: categoria 
});










































export const buscarPorProductoSegunCategoria = ( buscar ) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchSinToken(`busqueda/categoria/${ buscar.id }/${ buscar.termino }`);
            const body = await resp.json();

            if( body.ok ){
                const busqueda = body.productos
                dispatch( productosLista(busqueda) );
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}


export const busquedaCompletaProductos = ( buscar ) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchSinToken(`busqueda/busqueda/completa?categ=${ buscar.categ }&termino=${ buscar.termino }`);
            const body = await resp.json();

            if( body.ok ){
                const busqueda = body.productos
                dispatch( productosLista(busqueda) );
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}









export const buscarOpPorPago = ( termino ) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchSinToken(`busqueda/pago/${ termino }`);
            const body = await resp.json();

            if( body.ok ){
                const busqueda = body.resultado;
                dispatch( opLista( busqueda ) );
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }

    }
}










export const busquedaDeItemsPorColaborador = ( termino ) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken(`busqueda/items/colaborador/${ termino }`);
            const body = await resp.json();

            if( body.ok ){
                const busqueda = body.solucion;
                dispatch( carritoLista( busqueda ) );
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}












export const busquedaDeClienteRed = ( termino ) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken(`busqueda/cliente-red/${ termino }`);
            const body = await resp.json();

            if( body.ok ){
                const busqueda = body.clientes;
                dispatch( clienteLista( busqueda ) );
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}






export const busquedaDeDistribuidor = ( termino ) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken(`busqueda/distribuidor/${ termino }`);
            const body = await resp.json();

            if( body.ok ){
                const busqueda = body.user;
                dispatch( usuarioLista( busqueda ) );
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}


const usuarioLista = ( usuarios ) => ({
    type: types.usuarioLista,
    payload: usuarios 
});