
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Grid } from "@mui/material"

import { LayoutReturn } from "../../layouts"
import { TrayectoriaPedidos, TablaDetallesItemsOP } from "../components"
import { carritoListaPorOP } from "../../../actions/items";
import { opPorID } from "../../../actions/op";






export const DetallesDeMiOP = () => {


    const { id } = useParams();
    const dispatch = useDispatch();
    const { activeOP } = useSelector( state => state.op );




    useEffect(() => {
        if( id ) {
            dispatch(opPorID( id ));
        }
    }, [id]);


    useEffect(() => {
        if( id ) {
            dispatch( carritoListaPorOP(id) );
        }
    }, [id] );


    return (

        <LayoutReturn titulo={`Detalles de OP: ${ (activeOP) ? activeOP.op : '....'}`} url='/super/mis-pedidos'>

            <Grid container my={15} justifyContent='center'>
                <TrayectoriaPedidos />
            </Grid>



            <TablaDetallesItemsOP />


        </LayoutReturn>

        
    )


}
