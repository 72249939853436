
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom";
import { deudaClienteListaStartCliente } from "../../actions/deudaCliente";
import { opPorIDCliente } from "../../actions/op";
import { separadorMillares } from "../../hooks/useMillares";


export const AbonosCliente = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const { activeOP } = useSelector( state => state.op );
    const { deudas } = useSelector(state => state.deudaCliente);


    const [ totalAbono, setTotalAbono ] = useState(0);

    useEffect(() => {
        if( id ) {
            dispatch( opPorIDCliente(id) );
            dispatch( deudaClienteListaStartCliente( id ));
        }
    }, [id, dispatch] );


    useEffect(() => {
        if( deudas ){
            const deudasTotal = deudas.reduce( (total, deu) => total + deu.debitar, 0 );
            setTotalAbono( deudasTotal );
        }
    }, [deudas]);


    const handleReturn = () => {
        navigate(-1);
    }


    return (

        <div className="formTodo">

            <h2 className="titleF">Abonos Realizados</h2>

            <div className="botones-abono">
                <button className="boton-redondo" 
                    onClick={ handleReturn } 
                >
                    <i className="fa-solid fa-circle-arrow-left"></i>
                </button> 
            </div>

            <table className="table table-bordered table-dark text-center mb-5">
                <thead>
                    <tr>
                        <th scope="col">OP</th>
                        <th scope="col">Deuda</th>
                        <th>Iva</th>
                        <th scope="col">Total Abonos</th>
                        <th scope="col">Saldo</th>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        { activeOP && (
                            <>
                            <td>{ activeOP.op }</td>
                            <td>{`$${ separadorMillares((activeOP.totalCliente))}`}</td>
                            <td>{`$${ separadorMillares((activeOP.iva))}`}</td>
                            <td>
                                {`$${ separadorMillares(totalAbono)}`}
                            </td>
                            <td>{`$${ separadorMillares( (activeOP.totalCliente - totalAbono))}`}</td>
                            </>
                        )}
                       
                    </tr>   
                </tbody>
            </table>

        
            <table className="table table-bordered table-dark text-center table-hover">
                <thead>
                    <tr>
                        <th scope="col">Fecha</th>
                        <th scope="col">Abono</th>
                        <th scope="col">Deuda Inicial</th>
                        <th scope="col">Deuda Final</th>
                    </tr>
                </thead>

                <tbody>
                    {
                    deudas.map( a => (
                    <tr key={ a.id } >
                        <td>{ a.start }</td>
                        <td>{`$${ separadorMillares(a.debitar)}`}</td>
                        <td>{`$${ separadorMillares(a.deudaInicial)}`}</td>
                        <td>{`$${ separadorMillares(a.deudaFinal)}`}</td>
                    </tr>   
                    ))
                    }
                </tbody>
            </table>
        </div>

    )

}
