
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { Grid, TextField } from "@mui/material"
import { buscarProductosEnInventario, todosStockList } from "../../../actions";



export const BuscadorProductosInventario = () => {

    
    const debounceRef = useRef();
    const dispatch = useDispatch();

    const [valor, setValor] = useState('');


    const hanldeInputChange = ({ target }) => {
        setValor( target.value );

        if( debounceRef.current )
            clearTimeout( debounceRef.current );

        debounceRef.current = setTimeout( () => {
            if( target.value.trim().length > 0 ){
                dispatch(buscarProductosEnInventario(valor));
            }else {
                dispatch(todosStockList());
            }
        }, 550);
    }



    return (

        <Grid
            container direccion='row'
            alignItems='center' mt={7}
        >
            <Grid item xs={ 12 } sm={ 6 }>
                <TextField
                    autoComplete="off"
                    label="Buscar Stock en Inventario..." 
                    variant="filled" 
                    fullWidth
                    color="secondary"
                    name="valor"
                    value={ valor }
                    onChange={ hanldeInputChange }
                />
            </Grid>
        </Grid>


    )

}
