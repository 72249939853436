
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { Grid } from "@mui/material";
import { startRolVistas } from "../../../actions";
import { categoriaListaSinTerminados } from "../../../actions/categoria";
import { BuscarCategoriaVentas, CardsCategorias } from "../components";
import { MensajeCargandoData } from "../../components";




export const CategoriasScreen = () => {


    const dispatch = useDispatch();
    const { categorias } = useSelector( state => state.categoria );
    const location = useLocation();
    const { state } = location;


    if (state?.view) {   
        dispatch(startRolVistas(state.view));
        localStorage.setItem("view", state?.view)
        localStorage.removeItem('cliente');
    }


    useEffect( () => {
        dispatch( categoriaListaSinTerminados() );
    }, [ dispatch ] );




    return (

       
        <>
            <Grid container>
                <BuscarCategoriaVentas />
            </Grid>

            <Grid container spacing={4} sx={{ display:'flex', justifyContent:'center', alignItems:'center'}}>

                {( categorias.length > 0 ) 
                    ? categorias.map( categoria => (
                        <CardsCategorias
                            url='/super/filtro'
                            key={ categoria.id } 
                            { ...categoria }
                        />
                    ))
                    : <MensajeCargandoData titulo="Cargando Datos De Categoria..." />
                }
            </Grid>
        </>

    )



}
