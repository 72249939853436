
import { useSelector } from "react-redux";







export const usePrecioSegunRol = () => {

    const { view } = useSelector( state => state.iva );


    const precioSegunRol = (carrito) => {
        switch(view){
            case "cliente":
                return carrito.totalCliente

            case "distri": 
                return carrito.totalDistri

            case "disenio": 
                return carrito.totalPublicista

            case "empresa":
                return carrito.totalEmpresa

            default:
                return carrito.totalCliente;
        }
    }




    const precioTotalSegunRol = (carrito) => {
        const cliente = carrito.reduce( (total, prod) => total + prod.totalCliente, 0);
        const distri = carrito.reduce( (total, prod) => total + prod.totalDistri, 0);
        const empresa = carrito.reduce( (total, prod) => total + prod.totalEmpresa, 0);
        const publicista = carrito.reduce( (total, prod) => total + prod.totalPublicista, 0);

        switch(view){
            case "cliente":
                return cliente;

            case "distri": 
                return distri;

            case "disenio": 
                return publicista;

            case "empresa":
                return empresa;

            default:
                return cliente;
        }
    }




    return {
        precioSegunRol,
        precioTotalSegunRol,
    }

    
}
