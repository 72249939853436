

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import "swiper/css/scrollbar";
// import required modules
import { Pagination, Navigation, Autoplay } from "swiper";
import { Link } from "react-router-dom";



const baseUrl = process.env.REACT_APP_API_URL;



export const CarrouselScreen = ({ titulo, producto }) => {


return (

    <div className="contenido-carrusel-2">
        <h2 className="title-carousel">{ titulo }</h2>
        <div className="contenedor-carusel">
            <Swiper
                slidesPerView={5}
                spaceBetween={10}
                navigation={true}
                slidesPerGroup={4}
                scrollbar={true}
                autoplay={{
                    delay: 7000,
                    disableOnInteraction: false,
                }}
                breakpoints={{
                "@0.00": {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                "@0.80": {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                "@1.10": {
                    slidesPerView: 4,
                    spaceBetween: 20,
                },
                "@1.40": {
                    slidesPerView: 5,
                    spaceBetween: 20,
                },
                }}
                modules={[ Navigation, Pagination, Autoplay ]} //Scrollbar
                className="mySwiper"
            >
                {
                producto?.map( produc => (
                    <SwiperSlide className="card-carrusel" key={ produc.id } >
                        <Link to={`/detalle-producto/${ produc.id }`} className="img-carrusel2">
                            <img src={ `${ baseUrl }/uploads/productos/${ produc.img }` } alt="" />
                            <div className="title-carrusel">{ produc.nombre }</div>
                        </Link>
                    </SwiperSlide>
                ))
                }
                
            </Swiper>
        </div>
    </div>


   


  )




}
