
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { MensajeAlertaDanger, TooltipAndIcon } from "../../components";
import { sumaDeArrayPagosInventario } from "../../../helpers/sumaDeArrayItems";
import { separadorMillares } from "../../../hooks";
import { gastosListaStart } from "../../../actions/gastos";

import moment from "moment";





export const TablaVerGastos = () => {


    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { gastos } = useSelector( state => state.gastos );

    const [total, setTotal] = useState(0)


    useEffect(() => {
        if(gastos.length < 1) return dispatch(gastosListaStart({}));
    }, [])



    useEffect(() => {
        if(gastos.length > 0) {
            const valor = gastos.reduce( (t, a) => {
                a.items.forEach( item => {
                    t += item.total;
                });
                return t;
            }, 0);

            setTotal(valor);
        }
    }, [gastos])



    const handleVerFactura = (id) => {
        navigate(`/super/factura/${id}`);
    }


    const handleAbonosFactura = (id) => {
        navigate(`/super/abonos/${id}/gasto`);
    }
    


    return (

        <>
        { (gastos.length > 0)
        ?   <>
            <TableContainer sx={{ mt:5 }}>
                <Table sx={{ minWidth: 700, }} aria-label="customized table">
                    <TableHead >
                        <TableRow>
                            <TableCell>Numero Gastos</TableCell>
                            <TableCell>Total Gasto</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>{ gastos.length }</TableCell>
                            <TableCell>{`$${separadorMillares(total)}`}</TableCell>
                        </TableRow>
                    </TableBody>

                </Table>
            </TableContainer>

            <TableContainer sx={{ mt:5 }}>
                    <Table sx={{ minWidth: 700, }} aria-label="customized table">
                        <TableHead >
                            <TableRow>
                                <TableCell>Fecha</TableCell>
                                <TableCell># Factura</TableCell>
                                <TableCell>Proveedor</TableCell>
                                <TableCell>Pago</TableCell>
                                <TableCell>Total Gasto</TableCell>
                                <TableCell>Acciones</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        { gastos.map( ( a ) => (
                            <TableRow  key={ a._id}>
                                <TableCell>{ moment(a.createdAt).format('ll') }</TableCell>
                                <TableCell>{ a.factura }</TableCell>
                                <TableCell>{ a.proveedor?.nombre }</TableCell>
                                <TableCell>{ a.tipoPago }</TableCell>
                                <TableCell>{ `$${sumaDeArrayPagosInventario(a.items)}` }</TableCell>
                                <TableCell>
                                    <Stack direction="row" spacing={1} justifyContent="center">
                                        <TooltipAndIcon
                                            titulo="Detalles de gasto"
                                            icono="ver"
                                            color="white"
                                            onClick={ () => handleVerFactura(a._id)}
                                        />  

                                        <TooltipAndIcon
                                            titulo="Abonos de gasto"
                                            icono="abono"
                                            color="white"
                                            onClick={ () => handleAbonosFactura(a._id)}
                                        />  
                                    </Stack>
                                </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>   
        

        :   <MensajeAlertaDanger titulo="No Existen Datos De Gastos" />
        }
    </>

    )
}
