

import { types } from "../types/types";




const initialState = {
    op: [],
    opDistri: [],
    activeOP: null,
    totalOp: 0,
};




export const opReducer = ( state = initialState, action ) => {

    switch ( action.type ) {

        case types.opAddNew:
            return {
                ...state,
                op: [ ...state.op, action.payload ]
            };

        case types.opLista:
            return {
                ...state,
                op: [ ...action.payload ]
            };    

        case types.opListaStartDistri:
            return {
                ...state,
                opDistri: [ ...action.payload ]
            }; 

        case types.opUpdate:
            return {
                ...state,
                op: state.op.map( o => ( o.id === action.payload.id ) ? action.payload : o )
            };


        case types.opDelete:
            return {
                ...state,
                op: state.op.filter( p => ( p.id !== action.payload.id ) ),
            };


        case types.opActivo:
            return {
                ...state,
                activeOP: action.payload
            };
            

        case types.opDeleteActivo:
            return {
                ...state,
                activeOP: null
            };

        case types.totalOpsList:
            return {
                ...state,
                totalOp: action.payload
            };
        


        default:
            return state;
    }

    

}
