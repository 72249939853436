


import { types } from "../types/types";


const initialState = {
    medidas: [ ],
    activeMedidas: null
};



export const unidadMedidaReducer = ( state = initialState, action ) => {

    switch ( action.type ) {

        case types.medidasNew:
            return {
                ...state,
                medidas: [
                    ...state.medidas,
                    action.payload
                ]
            };

        case types.medidasLista:
            return {
                ...state,
                medidas: [ ...action.payload ]
            };


        // case types.clienteActivo:
        //     return {
        //         ...state,
        //         activeCliente: action.payload
        //     };

        // case types.clienteActivoClear:
        //     return {
        //         ...state,
        //         activeCliente: null
        //     };

        // case types.clienteDelete:
        //     return {
        //         ...state,
        //         clientes: state.clientes.filter(
        //             e => ( e.id !== action.payload.id )
        //         ),
        //         activeCliente: null
        //     };

        // case types.clienteUpdate:
        //     return {
        //         ...state,
        //         clientes: state.clientes.map(
        //             e => ( e.id === action.payload.id ) ? action.payload : e
        //         )
        //     };


        default:
            return state;
    }

}