import { types } from "../types/types";




const initialState = {
    abonos: [ ],
    activeAbono: null
};







export const abonoFacturaReducer = ( state = initialState, action ) => {

    switch ( action.type ) {

        case types.abonosFacturaAddNew:
            return {
                ...state,
                abonos: [
                    action.payload,
                    ...state.abonos,
                ]
            };

        case types.abonosFacturaLista:
            return {
                ...state,
                abonos: [ ...action.payload ]
            };

        // case types.abonosFacturaDelete:
        //     return {
        //         ...state,
        //         abonos: state.abonos.filter(
        //             e => ( e.id !== action.payload.id )
        //         ),
        //         activeAbono: null
        //     };

        // case types.abonosFacturaActivo:
        //     return {
        //         ...state,
        //         activeAbono: action.payload
        //     };
            
        // case types.abonosFacturaDeleteActivo:
        //     return {
        //         ...state,
        //         activeAbono: null
        //     };

        default:
            return state;
    }

}
