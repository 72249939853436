
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, Grid, MenuItem, TextField, Typography } from "@mui/material";

import { categoriaListaStart } from "../../../actions/categoria";
import { productosStartAddNew } from "../../../actions/producto";

import { LayoutReturn } from "../../layouts";
import { NumberFormatCustom } from "../../../hooks";



const rolesPermitidos = ['SUPERADMIN', 'ADMIN'];
const initProducto = {
    nombre: "",
    codigo: "",
    costoMateria: "",
    costoProduccion: "",
    precioVenta: "",
    precioDistri: "",
    precioPublicista: "",
    precioEmpresa: "",
    categoria: "",
    recomendaciones: "",
    caracteristicas: "",
    uso: "",
    tipo: ""
};



export const CrearProducto = () => {



    const dispatch = useDispatch();
    const { usuario } = useSelector( state => state.admin );
    const { categorias } = useSelector( state => state.categoria );
    const millares = NumberFormatCustom;


    useEffect(() => {
        dispatch( categoriaListaStart() );
    }, [dispatch])



    const [formProducto, setFormProducto ] = useState( initProducto );


    const { 
        nombre,
        codigo,
        costoMateria,
        costoProduccion,
        precioVenta,
        precioDistri,
        precioPublicista,
        precioEmpresa,
        recomendaciones,
        caracteristicas,
        uso,
        tipo,
        categoria,    
    } = formProducto;


    const handleInputChange = ({ target }) => {
        setFormProducto({
            ...formProducto,
            [target.name]: target.value
        });
    }

    // Creacion del Producto
    const handleCrearProducto = (e) => {
        e.preventDefault();
        dispatch( productosStartAddNew( formProducto ) );
        setFormProducto( initProducto );
    }


    return (

        <LayoutReturn titulo='Crear Producto' url='/super/listaProducto'>

            <form className="formulario-total mt-5" onSubmit={ handleCrearProducto } >
            <Grid container spacing={ 4 }>

                <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                    <TextField required
                        variant="filled"
                        label='Nombre'
                        type='text'
                        placeholder='Nombre'
                        fullWidth
                        name="nombre"
                        value={ nombre }
                        onChange={ handleInputChange }
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                    <TextField required
                        variant="filled"
                        label='Codigo'
                        type='text'
                        placeholder='Codigo'
                        fullWidth
                        name="codigo"
                        value={ codigo }
                        onChange={ handleInputChange }
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                    <TextField required
                        variant="filled"
                        label='Recomendaciones'
                        type='text'
                        placeholder='Recomendaciones'
                        fullWidth
                        name="recomendaciones"
                        value={ recomendaciones }
                        onChange={ handleInputChange }
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                    <TextField required
                        variant="filled"
                        label='Caracteristicas'
                        type='text'
                        placeholder='Caracteristicas'
                        fullWidth
                        name="caracteristicas"
                        value={ caracteristicas }
                        onChange={ handleInputChange }
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                    <TextField required
                        variant="filled"
                        label='Uso'
                        type='text'
                        placeholder='Uso'
                        fullWidth
                        name="uso"
                        value={ uso }
                        onChange={ handleInputChange }
                    />
                </Grid>

                <Grid item xs={ 12 } sm={ 6 } md={4} mt={ 2 } >
                    <TextField 
                        variant="filled"
                        select
                        label="Seleccione Tipo"
                        fullWidth
                        name='tipo'
                        value={ tipo }
                        onChange={ handleInputChange }
                    >
                        <MenuItem value="">Seleccione Un Tipo</MenuItem>
                        <MenuItem value="TARJETAS-VOLANTES-MEMBRETES">TARJETAS-VOLANTES-MEMBRETES</MenuItem>
                        <MenuItem value="CARPETAS-PLEGABLES">CARPETAS-PLEGABLES</MenuItem>
                    </TextField>
                </Grid>

                <Grid item xs={ 12 } sm={ 6 } md={4} mt={ 2 } >
                    <TextField required
                        variant="filled"
                        select
                        label="Seleccione Categoria"
                        fullWidth
                        name='categoria'
                        value={ categoria }
                        onChange={ handleInputChange }
                    >
                        <MenuItem value=''>
                            Seleccione Categoria
                        </MenuItem>
                        {
                        categorias.map( g => (
                        <MenuItem key={ g.id } value={ g.id } >
                            { g.nombre }
                        </MenuItem>
                        ))
                        }
                    </TextField>
                </Grid>

                { ( rolesPermitidos.includes(usuario?.role) ) &&
                <>
                <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                    <TextField required
                        variant="filled"
                        label='Costo Materia'
                        placeholder='Costo Materia'
                        fullWidth
                        name="costoMateria"
                        value={ costoMateria }
                        onChange={ handleInputChange }
                        InputProps={{
                            inputComponent: millares,
                        }}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                    <TextField required
                        variant="filled"
                        label='Costo Produccion'
                        placeholder='Costo Produccion'
                        fullWidth
                        name="costoProduccion"
                        value={ costoProduccion }
                        onChange={ handleInputChange } 
                        InputProps={{
                            inputComponent: millares,
                        }}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                    <TextField required
                        variant="filled"
                        label='Precio Venta'
                        placeholder='Precio Venta'
                        fullWidth
                        name="precioVenta"
                        value={ precioVenta }
                        onChange={ handleInputChange } 
                        InputProps={{
                            inputComponent: millares,
                        }}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                    <TextField required
                        variant="filled"
                        label='Precio Distri'
                        placeholder='Precio Distri'
                        fullWidth
                        name="precioDistri"
                        value={ precioDistri }
                        onChange={ handleInputChange } 
                        InputProps={{
                            inputComponent: millares,
                        }}
                    />
                </Grid>  

                <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                    <TextField required
                        variant="filled"
                        label='Precio Publicista'
                        placeholder='Precio Publicista'
                        fullWidth
                        name="precioPublicista"
                        value={ precioPublicista }
                        onChange={ handleInputChange }
                        InputProps={{
                            inputComponent: millares,
                        }}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                    <TextField required
                        variant="filled"
                        label='Precio Empresa'
                        placeholder='Precio Empresa'
                        fullWidth
                        name="precioEmpresa"
                        value={ precioEmpresa }
                        onChange={ handleInputChange }
                        InputProps={{
                            inputComponent: millares,
                        }}
                    />
                </Grid>
                </>
                }

            </Grid>

                <Grid container sx={{ my:7, flexDirection:'column', justifyContent:'center' }}>
                    <Grid item xs={ 12 } sm={ 7 } >
                        <Button variant="contained" color="secondary" fullWidth type="submit" >
                            <Typography variant="span" color='white' >Crear Producto</Typography>
                        </Button>
                    </Grid>
                </Grid>


            </form>


        </LayoutReturn>

    )

    
}
