import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Button, Grid } from "@mui/material";
import { disenioCarrito } from "../../../data";
import { useCalcularPrecios, separadorMillares } from "../../../hooks";
import { AgregarTerminados, SelectorProductos } from "../components";
import { SelectSencillo, TextFormField, ViewPreciosPorRol } from "../../components";
import { buscarProducto } from "../../../helpers/FormulariosCarrito";


import Swal from "sweetalert2";




export const FormularioLitografia = ({ formCarrito, setFormCarrito, funcionAgregar, titulo }) => {


    const  { activeProducto } = useSelector( state => state.producto );
    const { activeTerminado } = useSelector( state => state.producto );
    const { calcularPrecioLitografias } = useCalcularPrecios();
    const { carritoCompra } = useSelector( state => state.carrito );

    const [ precioReal, setPrecioReal ] = useState(0);
    const [ millares, setMillares ] = useState(0);



    useEffect(() => {
        if( formCarrito.cantidad > 0 ){
            setMillares(formCarrito.cantidad * 1000);
        } else {
            setMillares(0)
        }
    }, [formCarrito.cantidad] );



    useEffect( () => {
        if( activeProducto ) {
            const { cliente, 
            distri, 
            empresa, 
            publicista, 
            precioReal } = calcularPrecioLitografias( formCarrito.disenio, activeProducto, formCarrito.cantidad, activeTerminado );
            setPrecioReal( precioReal - cliente );
            setFormCarrito({
                ...formCarrito,
                totalCliente: cliente,
                totalDistri: distri,
                totalEmpresa: empresa,
                totalPublicista: publicista,
            });
        }
    }, [activeProducto, formCarrito.cantidad, formCarrito.disenio, activeTerminado]);



    const handleInputChange = ({ target }) => {
        setFormCarrito({
            ...formCarrito,
            [target.name]: target.value
        });

        if(target.value === 'Sin Diseño') {
            Swal.fire('Importante', 'Las categorias sin diseño necesitan archivos de optima calidad editable en: pdf, corel o ilustrator, de lo contrario se cobrara el servicio de diseño. ', 'question');
        }
    }


    
    const handleAgregarAlCarro = (e) => {
        e.preventDefault();
        if( formCarrito.cantidad <= 0 ) { return Swal.fire('Error', 'Debes poner una cantidad', 'error') }

        if( formCarrito.disenio === '' ) { return Swal.fire('Error', 'Debes seleccionar con o sin diseño', 'error') }

        const product = buscarProducto(carritoCompra, formCarrito.producto);
        funcionAgregar(product)
    }




    return (

        <form onSubmit={ handleAgregarAlCarro }>

            <SelectorProductos formCarrito={formCarrito} setFormCarrito={setFormCarrito} />

            <Grid container sx={{width:'100%', mt:2, display:'flex', justifyContent:'space-between'}} >
                <Grid item xs={5.8}>
                    <TextFormField
                        size="small"
                        label={`Cantidad ${millares === 0 ? '' : `${millares} unidades`}`}
                        type='number'
                        placeholder="1 Equivale a 1000 unidades"
                        name="cantidad"
                        value={formCarrito.cantidad}
                        onChange={ handleInputChange } 
                    />
                </Grid>

                <Grid item xs={5.8}>
                    <SelectSencillo
                        size="small"
                        label="Diseño"
                        name="disenio"
                        value={formCarrito.disenio}
                        onChange={ handleInputChange } 
                        options={disenioCarrito}
                    />
                </Grid>


                <AgregarTerminados formCarrito={formCarrito} setFormCarrito={setFormCarrito} />

            </Grid>


            {/* Mostrar Precio Segun Rol */}
            <ViewPreciosPorRol formCarrito={formCarrito} />


            <Grid container sx={{display:'flex', justifyContent:'space-between', mt:2}}>
                <Grid item xs={(precioReal <= 0) ? 12 : 5.8}>
                    <Button type="submit" color='primary' variant="contained" fullWidth>
                        { titulo }
                    </Button>
                </Grid>

                {(precioReal > 0) && (
                <Grid item xs={5.8}>
                    <Button color='secondary' variant="contained" fullWidth>
                        Ahorra { `$${ separadorMillares( precioReal ) }`}
                    </Button>
                </Grid>
                )}
            </Grid>
        </form> 
    )
}
