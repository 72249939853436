

import { useSelector } from "react-redux";
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid, Button } from "@mui/material";
import moment from "moment";






export const InformacionItemXId = () => {


    const { activeOP } = useSelector( state => state.op );
    const { activeCarrito } = useSelector( state => state.carrito );
    


    return (

        <Grid container sx={{ justifyContent:'center', display:'flex' }}>
            {
            (activeOP?.infoPersonal) &&
            <TableContainer sx={{mt:5, mb:2}}>
                <Table sx={{ minWidth: 700, }} aria-label="customized table">
                    <TableHead>
                        <tr>
                            <TableCell sx={{backgroundColor:'secondary.main'}} colSpan={4}>Información de cliente</TableCell>
                        </tr>
                    </TableHead>

                    <TableHead >
                        <TableRow>
                            <TableCell>Fecha Compra</TableCell>
                            <TableCell>Nombre Cliente</TableCell>
                            <TableCell>Movil</TableCell>
                            <TableCell>Direccion</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        <TableRow >
                            <TableCell>{ moment(activeOP.startOp).format('lll') }</TableCell>
                            <TableCell>{ activeOP.infoPersonal?.nombre }</TableCell>
                            <TableCell>{ activeOP.infoPersonal?.movil }</TableCell>
                            <TableCell>{ activeOP.infoPersonal?.direccion }</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            }


            {(activeCarrito) &&
            <TableContainer>
                <Table sx={{ minWidth: 700, }} aria-label="customized table">
                    <TableHead>
                        <tr>
                            <TableCell sx={{backgroundColor:'secondary.main'}} colSpan={4}>Detalles del Item en Producción</TableCell>
                        </tr>
                    </TableHead>

                    <TableHead >
                        <TableRow>
                            <TableCell>Colaborador</TableCell>
                            <TableCell>Area de Trabajo</TableCell>
                            <TableCell>Trabajando</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        <TableRow >
                            <TableCell>
                                {
                                ( !activeCarrito.colaborador ) 
                                ? ( <span className="nocomenzo">No Tiene</span> )
                                : ( <span>{ activeCarrito.colaborador.nombre }</span> )
                                }
                            </TableCell>
                            <TableCell>
                                <Typography variant='h5'>{ activeCarrito.area }</Typography>    
                            </TableCell>
                            <TableCell>
                                { 
                                ( !activeCarrito.produccion )
                                ?<Button variant="contained" color="primary">
                                    Disponible
                                </Button>
                                :<Button variant="contained" color="info">
                                    En Trabajo
                                </Button> 
                                }
                            </TableCell>
                        </TableRow>
                    </TableBody>

                </Table>
            </TableContainer>
            }

        </Grid>
    )
}
