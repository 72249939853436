
import { useNavigate } from "react-router-dom";

import { Box, Grid, IconButton, Typography } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';






export const LayoutArchivado = ({ children, titulo = 'No hay Datos', url }) => {


    const navigate = useNavigate();



    const handleArchivado = () => {
        navigate(url);
    }




    return (

        <>
        
            <Grid container direccion='row' alignItems='center'>

                <Box flex={ 1 } />

                <Typography variant='h2' >{ titulo }</Typography>

                <Box flex={ 1 } />

                <IconButton
                    sx={{
                        color: '#fff',
                        backgroundColor: 'secondary.main',
                        ':hover': { backgroundColor: 'secondary.main', opacity: 0.3 },
                    }}
                    onClick={ handleArchivado } 
                >
                    <SaveIcon />
                </IconButton>

            </Grid>

            <Grid container alignItems='center' justifyContent='center' >


                { children }


            </Grid>
        
        
        
        </>


        
    )




}
