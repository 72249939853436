


import { useDispatch, useSelector } from 'react-redux';
import { startUploadingCategoria } from '../../../actions/uploads';



const baseUrl = process.env.REACT_APP_API_URL;


export const MostrarImagenCategoria = () => {
    
    
    const dispatch = useDispatch();
    const  { activeCategoria } = useSelector( state => state.categoria );


    const handlePictureClick = () => {
        document.querySelector('#fileSelector').click();
    }
    
    
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if( file ) {
            dispatch( startUploadingCategoria( activeCategoria.id, 'categorias', file ) );
        }
    }



    return (

        <div className="imagen-user">

            <div className="contenedor-image">
                {
                    ( activeCategoria )
                    
                    ? <img src={`${ baseUrl }/uploads/categorias/${ activeCategoria.img }`} alt="Imagen Categoria" />
                    : <img src={`${ baseUrl }/uploads/categorias/no-img.jpg`} alt="Imagen Categoria" />

                }
            </div>

            <button className="btn botonUpload"
                    onClick={ handlePictureClick }
            > 
                Subir Imagen
            </button>

            <input type="file" 
                id="fileSelector"
                className="boton-file"
                onChange={ handleFileChange }
                name="file"
            />

        </div>

    )
    
}
    

