
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Grid } from "@mui/material";
import { AutocompleteNombre } from "../../components";
import { obtenerTerminadoID, productosSoloTerminadosStart } from "../../../actions/producto";






export const AgregarTerminados = ({ formCarrito, setFormCarrito }) => {


    const dispatch = useDispatch();
    const { terminados } = useSelector( state => state.producto );



    useEffect(() => {
        dispatch( productosSoloTerminadosStart() );
    }, [ dispatch ]);

    


    const handleInputTerminados = (newValor) => {
        setFormCarrito({
            ...formCarrito,
            terminados: (newValor) ? newValor.id : null,
        });
        dispatch( obtenerTerminadoID(newValor.id) );
    }





    return (


        <Grid item xs={12} sx={{mt:2}}>
            <AutocompleteNombre
                size="small"
                label="Terminados"
                name="termina"
                value={formCarrito.terminados}
                onChange={ handleInputTerminados }
                options={terminados}
            />
        </Grid>
        
    )
}
