
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Stack } from "@mui/material";
import { MensajeAlertaDanger, TooltipAndIcon } from "../../components";
import { categoriaStartDelete } from "../../../actions/categoria";

import Swal from "sweetalert2";








export const TablaListadoCategoria = () => {


    const navigate = useNavigate();
    const { categorias } = useSelector( state => state.categoria );
    const dispatch = useDispatch();



    
    const handleEliminar = ( c ) => {
        Swal.fire({
            title: '¿Borrar Categoria?',
            text: `Esta Seguro De Borrar, ${ c.nombre }`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si, Borrar' 
        }).then( ( result ) => {
            if(result.value){
                dispatch( categoriaStartDelete( c ) );
            }
        })
    }



    const handleEditarCategoria = async( id ) => {
        navigate(`/super/actualizar-categ/${id}`);
    }





    return (
        <>
            {( categorias.length > 0 )
            ?   <Paper sx={{ width: '100%' }}>
                    <TableContainer sx={{ my:'15px' }}>
                        <Table sx={{ minWidth: 700, }} aria-label="customized table">
                            <TableHead >
                                <TableRow>
                                    <TableCell >Nombre</TableCell>
                                    <TableCell >Unidad</TableCell>
                                    <TableCell >2 A 6</TableCell>
                                    <TableCell >7 A 12</TableCell>
                                    <TableCell >13 A 24</TableCell>
                                    <TableCell >25 A 50</TableCell>
                                    <TableCell >51 A 99</TableCell>
                                    <TableCell >100 En Adelante</TableCell>
                                    <TableCell>Opciones</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {
                                categorias.map( (c) => (
                                <TableRow key={ c.id }>
                                    <TableCell>{ c.nombre }</TableCell>
                                    <TableCell>{ c.primero }</TableCell>
                                    <TableCell>{ c.segundo }</TableCell>
                                    <TableCell>{ c.tercero }</TableCell>
                                    <TableCell>{ c.cuarto }</TableCell>
                                    <TableCell>{ c.quinto }</TableCell>
                                    <TableCell>{ c.sexto }</TableCell>
                                    <TableCell>{ c.septimo }</TableCell>
                                    <TableCell>
                                    <Stack direction="row" spacing={1} justifyContent="center">
                                            <TooltipAndIcon
                                                titulo="Editar Categoria"
                                                icono="editar"
                                                color="white"
                                                onClick={ () => handleEditarCategoria(c.id) }
                                            />  

                                            <TooltipAndIcon
                                                titulo="Eliminar Categoria"
                                                icono="eliminar"
                                                color="red"
                                                onClick={ () => handleEliminar(c) }
                                            />  
                                        </Stack>
                                    </TableCell>
                                
                                </TableRow>
                                ))
                                }
                            </TableBody>

                        </Table>
                    </TableContainer>
                </Paper>

            :   <MensajeAlertaDanger titulo={'No Hay Categorias Disponibles...'} />

            }

        </>

    )



}
