

import { types } from "../types/types";




const initialState = {
    items: [],
    carritoCompra: [],
    carritoCliente: [],
    activeCarrito: null,
    activeCarritoUpdate: JSON.parse(localStorage.getItem('item-carrito')) || null,
};





export const carritoReducer = ( state = initialState, action ) => {

    switch ( action.type ) {
        // Estados de carrito antes de generar OP
        case types.carritoAddNew:
            return {
                ...state,
                carritoCompra: [ ...state.carritoCompra, action.payload ]
            };

        case types.carritoListaCompra:
            return {
                ...state,
                carritoCompra: [ ...action.payload ]
            };

        case types.carritoUpdate:
            return {
                ...state,
                carritoCompra: state.carritoCompra.map( 
                    e => ( e.id === action.payload.id ) ? action.payload : e
                )
            };

        case types.carritoDeleteCompra:
            return {
                ...state,
                carritoCompra: state.carritoCompra.filter(  p => ( p.id !== action.payload ) ),
            };

        case types.carritoActivo:
            return {
                ...state,
                activeCarrito: action.payload
            };

        
        case types.carritoActivoParaUdate:
            return {
                ...state,
                activeCarritoUpdate: action.payload
            };

            
            

        // Estado Carrito ya Listos en OP
        case types.itemListaStart:
            return {
                ...state,
                items: action.payload
            };


        case types.itemDeleteStart:
            return {
                ...state,
                items: state.items.filter(  p => ( p.id !== action.payload ) ),
            };

        case types.itemUpdateStart:
            return {
                ...state,
                items: state.items.map( 
                    e => ( e.id === action.payload.id ) ? action.payload : e
                )
            };

        case types.itemCreateStart:
            return {
                ...state,
                items: [ action.payload, ...state.items ]
            };


            



        // Estado Carrito del Cliente Antes de OP
        case types.carritoListaCliente:
            return {
                ...state,
                carritoCliente: [ ...action.payload ]
            };


        case types.carritoAddNewCliente:
            return {
                ...state,
                carritoCliente: [ ...state.carritoCliente, action.payload ]
            };

            
        case types.carritoUpdateCliente:
            return {
                ...state,
                carritoCliente: state.carritoCliente.map( 
                    e => ( e.id === action.payload.id ) ? action.payload : e
                )
            };

        case types.carritoDeleteCliente:
            return {
                ...state,
                carritoCliente: state.carritoCliente.filter(
                    p => ( p.id !== action.payload )
                ),
                activeProducto: null
            };
            

        default:
            return state;
    }

    

}
