
import { fetchConToken } from "../helpers/fetch";
import { types } from "../types/types";

import Swal from "sweetalert2";




const inventarioListado = ( inventario ) => ({
    type: types.listadoInventario,
    payload: inventario 
});





const inventarioAddNew = ( inventario ) => ({
    type: types.inventarioAddNew,
    payload: inventario
});



const inventarioUpdate = ( inventario ) => ({
    type: types.inventarioUpdate,
    payload: inventario
});



const inventarioActivado = ( inventario ) => ({
    type: types.inventarioActivo,
    payload: inventario
});


export const deleteInventarioActivo = ( ) => ({
    type: types.inventarioDeleteActivo,
});



export const startCreateStock = ( inventario ) => {
    return async( dispatch ) =>{
        try {
            const resp = await fetchConToken( 'inventario/new', inventario, 'POST' );
            const body = await resp.json();
            if( body.ok ) {
                Swal.fire('Exitoso', 'Stock Agregado', 'success');
                body.new ? dispatch(inventarioUpdate(body.data)) : dispatch(inventarioAddNew(body.data));
                return true;
            } else {
                Swal.fire('Error', body.msg, 'error');
                return false;
            } 
        } catch (error) {
            return false;
        }
    }
}




export const descontarInventario = ( inventario ) => {
    return async( dispatch ) =>{
        try {
            const resp = await fetchConToken(`inventario/descontar`, inventario, 'POST' );
            const body = await resp.json();
            if( body.ok ) {
                Swal.fire('Exitoso', 'Cantidad Descontada', 'success');
                dispatch( inventarioUpdate(body.data) );
                return true;
            } else {
                Swal.fire('Error', body.msg, 'error');
                return false;
            } 
        } catch (error) {
            return false;
        }
    }
}



export const todosStockList = () => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken(`inventario/todos`);
            const body = await resp.json();
            if( body.ok ){
                dispatch( inventarioListado(body.data) );
            }              
        } catch (error) {
            console.log(error);
        }
    }
}



export const inventarioStartUpdate = (inventario) => {
    return async( dispatch ) =>{
        try {
            const resp = await fetchConToken(`inventario/${inventario.id}`, inventario, 'PUT' );
            const body = await resp.json();
            if( body.ok ) {
                Swal.fire('Exitoso', 'Stock Actualizado', 'success');
                dispatch( inventarioUpdate(body.data) );
                return true;
            } else {
                Swal.fire('Error', body.msg, 'error');
                return false;
            } 
        } catch (error) {
            console.log(error);
            return false;
        }
    }
}



export const activarInvetario = ( id ) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken(`inventario/porid/${id}`);
            const body = await resp.json();
            if( body.ok ){
                dispatch( inventarioActivado(body.data) );
            }else {
                Swal.fire('Error', body.msg, 'error');
            }                
        } catch (error) {
            console.log(error);
        }
    }
}   




export const buscarProductosEnInventario = ( termino ) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken(`inventario/busqueda/${ termino }`);
            const body = await resp.json();

            if( body.ok ){
                dispatch( inventarioListado(body.data) );
            } else {
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            console.log(error);
        }
    }
}











// export const inventarioListaStart = ( tipo ) => {
//     return async( dispatch ) => {

//         try {
//             const resp = await fetchConToken(`inventario/tipo/${ tipo }`);
//             const body = await resp.json();

//             if( body.ok ){
//                 dispatch( inventarioListado(body.inventario) );
//             }else {
//                 Swal.fire('Error', body.msg, 'error');
//             }                
            
//         } catch (error) {
//             console.log(error);
//         }
//     }
// }





// export const inventarioListaStartDescontar = ( tipo ) => {
//     return async( dispatch ) => {

//         try {
//             const resp = await fetchConToken(`inventario/tipo/${ tipo }`);
//             const body = await resp.json();

//             if( body.ok ){
//                 dispatch( inventarioListadoDes(body.inventario) );
//             }else {
//                 Swal.fire('Error', body.msg, 'error');
//             }                
            
//         } catch (error) {
//             console.log(error);
//         }
//     }
// }



// const inventarioListadoDes = ( inventario ) => ({
//     type: types.listadoInventarioDes,
//     payload: inventario 
// });



// export const deleteInventarioDes = ( ) => ({
//     type: types.deleteInventarioDes,
// });
