
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { Box, Button, Grid, IconButton, TextField, Typography } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { mostrarClientePorId, clienteActivoClear, clienteStartUpdate } from "../../../actions/cliente";

import Swal from "sweetalert2";



const initCliente = {
    nombre: "",
    cedula: "",
    ciudad: "",
    direccion: "",
    movil: "",
    establecimiento: "",
    profesion: "",
    email: "",
};


export const ActualizarCliente = () => {

    const { id } = useParams();
    const navigate = useNavigate();
    const { activeCliente } = useSelector(state => state.cliente);
    const dispatch = useDispatch();


    const [ formCliente, setFormCliente ] = useState( initCliente );

    const handleInputChange = ({ target }) => {
        setFormCliente({
            ...formCliente,
            [target.name]: target.value
        });
    }

    const { nombre, cedula, ciudad, direccion, movil, establecimiento, email,
        profesion } = formCliente;




    useEffect( () => {
        if( id ) {
            dispatch( mostrarClientePorId( id ));
        }
    }, [dispatch, id] );


    useEffect( () => {
        if( activeCliente ){
            setFormCliente({
                ...activeCliente,
                nombre: activeCliente.nombre || "",
                cedula: activeCliente.cedula || "",
                ciudad: activeCliente.ciudad || "",
                direccion: activeCliente.direccion || "",
                movil: activeCliente.movil || "",
                establecimiento: activeCliente.establecimiento || "",
                email:  activeCliente.email || "",
                profesion: activeCliente.profesion || "",
            })
        }
    }, [ activeCliente ] );



    const handleActualizarCliente = (e) => {
        e.preventDefault();
        Swal.fire({
            title: 'Actualizar Cliente?',
            text: `Esta Seguro De Actualizar A ${ nombre }`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si, Actualizar' 
        }).then( ( result ) => {
            if(result.value){
                dispatch( clienteStartUpdate( formCliente ) );
                dispatch( clienteActivoClear() ); 
                navigate( -1 );
            }
        })
    }


    
    const handleReturn = () => {
        navigate( -1 );
        dispatch( clienteActivoClear() );  
    }



  return (

    <div>

        <Grid container direccion='row' alignItems='center'>

            <IconButton
                sx={{
                    color: '#fff',
                    backgroundColor: 'secondary.main',
                    ':hover': { backgroundColor: 'secondary.main', opacity: 0.3 },
                }}
                onClick={ handleReturn } 
            >
                <ArrowBackIcon />
            </IconButton>

            <Box flex={ 1 } />

            <Typography variant='h2' sx={{ fontWeight:'bold' }} >Actualizar Cliente</Typography>

            <Box flex={ 1 } />

        </Grid>

        <Grid container alignItems='center'>

        {/* <TextField id="filled-basic" label="Filled"  /> */}

        </Grid>


        <form className="formulario-total mt-5" onSubmit={ handleActualizarCliente } >
        <Grid container spacing={ 4 }>

            <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                <TextField required
                    variant="filled"
                    label='Nombre Cliente'
                    type='text'
                    placeholder='Nombre Cliente'
                    fullWidth
                    name="nombre"
                    value={ nombre }
                    onChange={ handleInputChange }
                />
            </Grid>

            <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                <TextField required
                    variant="filled"
                    label='Cedula Cliente'
                    type='text'
                    placeholder='Cedula Cliente'
                    fullWidth
                    name="cedula"
                    value={ cedula }
                    onChange={ handleInputChange }
                />
            </Grid>

            <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                <TextField required
                    variant="filled"
                    label='Correo Electronico'
                    type='text'
                    placeholder='Correo Electronico'
                    fullWidth
                    name="email"
                    value={ email }
                    onChange={ handleInputChange }
                />
            </Grid>

            <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                <TextField 
                    variant="filled"
                    label='Establecimiento'
                    type='text'
                    placeholder='Establecimiento'
                    fullWidth
                    name="establecimiento"
                    value={ establecimiento }
                    onChange={ handleInputChange } 
                />
            </Grid>

            <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                <TextField 
                    variant="filled"
                    label='Profesión'
                    type='text'
                    placeholder='Profesión'
                    fullWidth
                    name="profesion"
                    value={ profesion }
                    onChange={ handleInputChange } 
                />
            </Grid>

            <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                <TextField required
                    variant="filled"
                    label='Ciudad'
                    type='text'
                    placeholder='Ciudad'
                    fullWidth
                    name="ciudad"
                    value={ ciudad }
                    onChange={ handleInputChange } 
                />
            </Grid>

            <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                <TextField required
                    variant="filled"
                    label='Dirección'
                    type='text'
                    placeholder='Dirección'
                    fullWidth
                    name="direccion"
                    value={ direccion }
                    onChange={ handleInputChange }
                />
            </Grid>

            <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                <TextField required
                    variant="filled"
                    label='Movil'
                    type='text'
                    placeholder='Movil'
                    fullWidth
                    name="movil"
                    value={ movil }
                    onChange={ handleInputChange }
                />
            </Grid>

        </Grid>

            <Grid container 
                my={7}
                direccion='column'
                justifyContent='center' 
            >
                <Grid item xs={ 12 } sm={ 7 } >
                    <Button variant="contained" color="secondary" fullWidth type="submit" >
                        <Typography variant="span" color='white' >Actualizar Cliente</Typography>
                    </Button>
                </Grid>
            </Grid>


        </form>
    </div>

)



}









