import { fetchConTokenCliente, fileForDataCliente } from "../helpers/fetch";
import { prepararFecha } from "../helpers/prepararFecha";
import { types } from "../types/types";

import Swal from "sweetalert2";





export const carritoUpdateStartCliente = ( carrito ) => {
    return async( dispatch ) => {
        try {

            for (var clave in carrito) {
                if (carrito.hasOwnProperty(clave) && carrito[clave] === "") { carrito[clave] = null }
            }
            
            const resp = await fetchConTokenCliente(`carrito/${ carrito.id }`, carrito, 'PUT');
            const body = await resp.json();
            if( body.ok ) {
                dispatch( carritoUpdateCliente(body.carrito) );
                localStorage.setItem('item-carrito', JSON.stringify(body.carrito));
                Swal.fire('Exitoso', 'Carrito Actualizado', 'success');
            } else {
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            console.log(error);
        }
    }
}







export const carritoStartAddNewCliente = ( carrito ) => {
    return async( dispatch ) =>{
        try {

            for (var clave in carrito) {
                if (carrito.hasOwnProperty(clave) && carrito[clave] === "") { carrito[clave] = undefined }
            }

            const resp = await fetchConTokenCliente( 'carrito/new', carrito, 'POST' );
            const body = await resp.json();
            if( body.ok ) {
                dispatch( carritoAddNewCliente( body.carrito ) );
                Swal.fire('Exitoso', 'Producto Agregado Al Carrito', 'success');
            } else {
                Swal.fire('Error', body.msg, 'error');
            } 
        } catch (error) {
            console.log(error);
        }
    }
}



export const carritoListaCompraCliente = () => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConTokenCliente('carrito/carrito-cliente');
            const body = await resp.json();

            if( body.ok ){
                dispatch( carritoListaCliente(body.carrito) );
            }
        } catch (error) {
            console.log(error);
        }
    }
}



export const carritoStartDeleteCliente = (id) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConTokenCliente(`carrito/${ id }`, {}, 'DELETE');
            const body = await resp.json();

            if( body.ok ){
                dispatch( carritoDeleteCliente(id) );
                Swal.fire('Exitoso', 'Producto Eliminado del Carrito', 'success');
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            console.log(error);
        }
    }
}




const carritoDeleteCliente = ( id ) => ({ 
    type: types.carritoDeleteCliente,
    payload: id
});




const carritoListaCliente = ( car ) => ({
    type: types.carritoListaCliente,
    payload: car
})



const carritoAddNewCliente = ( carrito ) => ({
    type: types.carritoAddNewCliente,
    payload: carrito
});



const carritoUpdateCliente = ( carrito ) => ({
    type: types.carritoUpdateCliente,
    payload: carrito
});



























export const opStartAddNewCliente = ( op ) => {
    return async( dispatch, getState ) =>{

        const { uid, nombre } = getState().authCliente;

        try {
            const resp = await fileForDataCliente( 'op/new/cliente', op, 'POST' );
            const body = await resp.json();
            if( body.ok ) {
                Swal.fire( `Tu Orden de Pedido es ${ body.op.op }`, `Todo trabajo se inicia con un abono de <b>50% del valor total</b> y el saldo a la entrega del mismo. No se hace devolución de dinero una vez iniciado el trabajo. No nos hacemos responsables por trabajos pasados <b>30 dias desde la fecha de solicitud.</b> en caso de correcciones, se validaran maximo al siguiente dia de la entrega. Los diseños realizados por nosotros no seran impresos sin antes ser <b>aprobados por el cliente, REVISE MUY BIEN LOS DISEÑOS, no nos hacemos responsables por errores de ortografia, redacción, alineación, diagramación, tamaño del texto, logos, numeros telefonicos, correos, direcciones, fuentes, colores y demas después de la APROBACIÓN E IMPRESIÓN.</b> Tenga en cuenta que los colores en pantalla tienden a variar en la impresión, <b>No</b> nos comprometemos a sacar colores ni tamaños exactos. Recuerde que según la disponibilidad de tiempo se programa una posible fecha de entrega  <b>por ende los trabajos para entrega inmediata o a domicilio tienen un costo adicional el cual se validara con su distribuidor autorizado o directamente con la empresa.</b>`,  'success');
                op.id = body.op.id;
                op.cliente = {
                    id: uid,
                    nombre : nombre
                };

                const carrito = [];
                dispatch( opAddNew( op ) );
                dispatch( carritoListaCliente(carrito) );

                return true;

            } else {

                Swal.fire('Error', body.msg, 'error');
                return false;
                
            } 
            
        } catch (error) {
            console.log(error);
        }
    }
}




const opAddNew = ( op ) => ({
    type: types.opAddNew,
    payload: op
});




export const opListaStartCliente = ( uid ) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConTokenCliente(`op/cliente/${ uid }`);
            const body = await resp.json();

            if( body.ok ){
                const op = prepararFecha( body.op );
                dispatch( opLista(op) );
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}


const opLista = ( op ) => ({
    type: types.opLista,
    payload: op 
});
