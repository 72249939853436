
import { useState } from 'react';

import { Grid, TextField } from "@mui/material";

import { useDispatch } from 'react-redux';
import { busquedaDeItemsPorOp } from '../../../actions/busquedas';
import { todosCarrito } from '../../../actions/carrito';
// import { busquedaDeItemsPorOp } from '../../../actions/busquedas';
// import { todosCarrito } from '../../../actions/carrito';




export const BusquedaPorItems = () => {


    const dispatch = useDispatch();


    const [ buscartipo, setBuscartipo ] = useState('');

    const hanldeInputChange = (e) => {
        setBuscartipo(e.target.value);
        if( e.target.value.trim().length >= 1 ){
            dispatch( busquedaDeItemsPorOp(e.target.value) );
        }else {
            dispatch( todosCarrito() );
        }
    }


    return (

        <Grid 
            container direccion='row'
            alignItems='center' my={3}
        >
            <Grid item xs={ 12 } sm={ 6 }>
                <TextField  
                    autoComplete="off"
                    label="Buscar Items....." 
                    variant="filled" 
                    fullWidth
                    color="secondary"
                    name="buscartipo" 
                    value={ buscartipo } 
                    onChange={ hanldeInputChange }
                />
            </Grid>
        </Grid>


    )
}
