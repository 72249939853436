


import { useNavigate } from "react-router-dom";

import { Box, Grid, IconButton, Typography } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';







export const LayoutReturn = ({ children, titulo = 'No hay Datos', url }) => {


    const navigate = useNavigate();



    const handleReturn = () => {
        navigate(url);
    }


    

    return (

        <Grid sx={{px:7, minHeight:'calc(100vh - 90px)',}}>
            <Grid item sx={{width:'100%', height:'70px', mt:2, display:'flex', alignItems:'center'}}>
                <IconButton onClick={ handleReturn } 
                    sx={{color:'#fff', backgroundColor:'secondary.main', ':hover':{ backgroundColor:'secondary.main', opacity:0.3 }}}
                >
                    <ArrowBackIcon />
                </IconButton>
                <Box flex={ 1 } />
                <Typography variant='h1'>{ titulo }</Typography>
                <Box flex={ 1 } />
            </Grid>

            <Grid container sx={{width:'100%'}}>
                {children}
            </Grid>
        
        
        </Grid>
        
    )




}
