
import { useState } from "react";
import { useDispatch } from "react-redux";

import { Button, Grid, Typography } from "@mui/material";
import { AutocompleteNombre, BackdropScreen, DateFormScreen, SelectSencillo, TextFormField } from "../../components";
import { LayoutListados } from "../../layouts";
import { rolesClientes, tiposPago } from "../../../data";

import { AgregarItemsCotizacion } from "../components/AgregarItemsCotizacion";
import { descargarArchivo } from "../../../helpers/descargarArchivo";
import { userListCotizacion } from "../../../actions/usuario";
import { guardarCotizacion } from "../../../actions/cotizacion";

import moment from "moment";
import Swal from "sweetalert2";


const now = moment();



export const CotizacionNewScreen = () => {

    const dispatch = useDispatch();

    const [cargando, setCargando] = useState(false)
    const [user, setUser] = useState([]);
    const [data, setData] = useState([]);

    const [formUsuario, setFormUsuario ] = useState({
        tipoUser: '',
        usuario: '',
        fechaFactura: now.toDate(),
        formaPago: '',
        observacion: '',
        nombre: '',
    });
    const { tipoUser, usuario, fechaFactura, formaPago, observacion, nombre } = formUsuario;



    const handleInputChange = ({ target }) => {
        setFormUsuario({
            ...formUsuario,
            [target.name]: target.value
        });
    }


    const handleInputUser = (newValor) => {
        setFormUsuario({
            ...formUsuario,
            usuario: (newValor) ? newValor.id : null,
            nombre: (newValor) ? newValor.nombre : null,
        });
    }


    const handleChangeStart = (e) => {
        setFormUsuario({
            ...formUsuario,
            fechaFactura: e.$d
        });
    }



    const handleLlamadoDatosUser = async({ target }) => {
        const nombre = target.value
        setFormUsuario({
            ...formUsuario,
            tipoUser: nombre,
            usuario: ''
        });
        if( nombre ){
            const resp = await dispatch(userListCotizacion(nombre))
            setUser(resp)
            setData([])
        }
    }



    const handleEnviarInfo = async () => {
        if([tipoUser, usuario, fechaFactura, formaPago, observacion].includes('')){
            return Swal.fire('Error', 'Los Datos Iniciales Son Obligatorios.', 'error');
        }

        if(data.length <= 0){
            return Swal.fire('Error', 'Debe haber al menos un item agregado.', 'error');
        }
        
        const resp = await Swal.fire({
            title: 'Crear Cotización',
            text: `¿Esta Seguro De Crear Esta Cotización?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si' 
        });

        const newCarrito = data.map( item => {
            if (item.nombre) {
                item.producto.nombre = item.nombre;
            }
            return item
        });


        if(resp.value){
            const valor = `cotizacion-${nombre}`.toLowerCase().replace(/\s+/g, '');
            setCargando(true);
            const result = await dispatch(guardarCotizacion({ data: newCarrito, ...formUsuario }));
            descargarArchivo(result.data, valor);
            setCargando(false);
        }
    }



    return (

        <LayoutListados title={'Generar Cotización'}>

            <BackdropScreen cargando={cargando} titulo='Espere Por Favor Estamos Generando Su Cotización...' />

                <Grid container spacing={3} sx={{width:'100%', mt:3}}>
                    <Grid item xs={12} sm={6} md={4} >
                        <SelectSencillo 
                            required
                            variant="filled"
                            label="Seleccione Rol"
                            name='tipoUser'
                            value={ tipoUser }
                            onChange={ (e) => {
                                handleInputChange(e);
                                handleLlamadoDatosUser(e);
                            }}
                            options={rolesClientes}
                        />
                    </Grid>


                    <Grid item xs={ 12 } sm={ 6 } md={4} >
                        <AutocompleteNombre
                            variant="filled"
                            label="Seleccione Usuario"
                            name='usuario'
                            value={usuario}
                            onChange={ handleInputUser }
                            options={user}
                        />
                    </Grid>


                    <Grid item xs={ 12 } sm={6} md={4} >
                        <DateFormScreen 
                            label="Fecha de Factura"
                            value={ fechaFactura }
                            onChange={ handleChangeStart }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } md={4} >
                        <SelectSencillo 
                            required
                            variant="filled"
                            label="Forma de Pago"
                            name='formaPago'
                            value={ formaPago }
                            onChange={ handleInputChange }
                            options={tiposPago}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} md={8} >
                        <TextFormField 
                            variant="filled"
                            label='Observación'
                            type='text'
                            multiline
                            placeholder='Observación'
                            name="observacion"
                            value={ observacion }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                </Grid>

            {(tipoUser) &&
                <AgregarItemsCotizacion data={data} setData={setData} tipoUser={tipoUser} />
            }


            <Grid container  spacing={2} sx={{ direccion:'column', justifyContent:'center', my:7 }}>
                <Grid item xs={ 12 } sm={ 7 } >
                    <Button variant="contained" color="secondary" fullWidth type="submit" onClick={ handleEnviarInfo } >
                        <Typography variant="span" color='white' >Guardar y Generar Cotización</Typography>
                    </Button>
                </Grid>
            </Grid>

        </LayoutListados>
    )
}
