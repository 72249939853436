
import { useState, useEffect } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { separadorMillares, sumaDeTotalPagos } from "../../../hooks";




export const TablaProductosFactura = ({ productos }) => {


    const [totales, setTotales] = useState({ total: 0, subTotal: 0 });


    useEffect(() => {
        if(productos){
            const total = productos.reduce( (total, acu) => total + ((acu.porcentaje * acu.precioUnidad * acu.cantidad / 100) + acu.precioUnidad * acu.cantidad), 0);
            const subTotal = productos.reduce( (total, acu) => total +  acu.precioUnidad * acu.cantidad, 0);
            
            setTotales({ total, subTotal });
        }
    }, [productos]);
    




    return (

        <>
            <Typography variant='h2' sx={{ mt:5, mb:2, textAlign:'center' }} >
                Productos
            </Typography>

            <TableContainer>
                <Table sx={{ minWidth: 700, }} aria-label="customized table">
                    <TableHead >
                        <TableRow>
                            <TableCell>NOMBRE</TableCell>
                            <TableCell>CANTIDAD</TableCell>
                            <TableCell>PRECIO UNI.</TableCell>
                            <TableCell>IVA</TableCell>
                            <TableCell>TOTAL</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {productos.map( p => (
                        <TableRow key={ p._id } >
                            <TableCell>
                                {(p.producto) 
                                ?   p.producto.nombre
                                :   p.item
                                }
                            </TableCell>   
                            <TableCell>{ p.cantidad }</TableCell>
                            <TableCell>{ `$${ separadorMillares(p.precioUnidad) }` }</TableCell>
                            <TableCell>{ `${p.porcentaje}%` }</TableCell>
                            <TableCell>{ `$${ sumaDeTotalPagos(p.precioUnidad, p.cantidad, p.porcentaje) }` }</TableCell>
                        </TableRow>
                        ))}

                        <TableRow>
                            <TableCell colSpan={2} />
                            <TableCell colSpan={2}>Subtotal:</TableCell>
                            <TableCell align="right">{ `$${ separadorMillares(totales.subTotal) }` }</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell colSpan={2} />
                            <TableCell colSpan={2}>Iva</TableCell>
                            <TableCell align="right">{ `$${ separadorMillares(totales.total - totales.subTotal)}`}</TableCell>
                        </TableRow>
                    
                        <TableRow>
                            <TableCell colSpan={2} />
                            <TableCell colSpan={2}>Total:</TableCell>
                            <TableCell align="right">{ `$${ separadorMillares(totales.total) }` }</TableCell>
                        </TableRow>
                    </TableBody>

                </Table>
            </TableContainer>
        </>



    )



}
