
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { Button, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { MensajeAlertaDanger } from "../../components";



export const TablaOpsMisClientes = () => {

    const navigate = useNavigate();
    const { op } = useSelector( state => state.op );


    const handleTrayectoria = (op) => {
        navigate(`/super/trayectoria/${op.id}`);
    }


    

    return (

        <Grid container textAlign='center' justifyContent='center' my={2}>

            <Grid item my={2}>
                <Typography variant="h2">Pedidos de Mis Clientes</Typography>
            </Grid>

        {
          ( op.length > 0 ) 
          ?<TableContainer sx={{ mb:5 }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">

                  <TableHead >
                      <TableRow >
                        <TableCell>OP</TableCell>
                        <TableCell>PAGO</TableCell>
                        <TableCell>ESTADO</TableCell>
                        <TableCell>CLIENTE</TableCell>
                        <TableCell>MOVIL</TableCell>
                        <TableCell className="w-200">ACCION</TableCell>
                      </TableRow>
                  </TableHead>

                    <TableBody>
                    {op.map( a => (
                        <TableRow key={a.id} >

                            <TableCell >
                                <Typography variant="span">{ a.op }</Typography>
                            </TableCell>

                            <TableCell >
                                {
                                (a.pago === 'PENDIENTE') &&
                                <Button variant="outlined" size="small" color="error" sx={{ color:'red' }}>{a.pago}</Button>
                                }
                                {
                                (a.pago === 'REALIZADO') &&
                                <Button variant="outlined" size="small" color="success" sx={{ color:'green' }}>{a.pago}</Button>
                                }
                                {
                                (a.pago === 'CREDITO') &&
                                <Button variant="outlined" size="small" color="info" sx={{ color:'skyblue' }}>{a.pago}</Button>
                                }
                            </TableCell>

                            <TableCell >
                                <Typography variant="span">{ a.estado }</Typography>
                            </TableCell>

                            <TableCell >
                                <Typography variant="span">{ a.infoPersonal.nombre }</Typography>
                            </TableCell>

                            <TableCell >
                                <Typography variant="span">{ a.infoPersonal.movil }</Typography>
                            </TableCell>

                            <TableCell >
                                <IconButton className="icon-boton" onClick={ () => handleTrayectoria(a) }>
                                    <SearchIcon fontSize="small" />
                                </IconButton>
                            </TableCell>

                        </TableRow>
                        ))
                    }
                    </TableBody>
                </Table>
            </TableContainer>
        
            : <MensajeAlertaDanger titulo={'No Hay OPS De Tus Clientes'} />
        }

    </Grid>
        
    )

}
