import { types } from "../types/types";




const initialState = {
    productos: [ ],
    terminados: [],
    servicio: [],
    personalizado: [],
    publicidad: [],
    activeTerminado: null,
    activeProducto: null
};





export const productoReducer = ( state = initialState, action ) => {

    switch ( action.type ) {

        case types.productosAddNew:
            return {
                ...state,
                productos: [
                    ...state.productos,
                    action.payload
                ]
            };

        case types.productosSoloTerminados:
            return {
                ...state,
                terminados: [ ...action.payload ]
            }

        case types.terminadoActivo:
            return {
                ...state,
                activeTerminado: action.payload
            }

        case types.listadoServicioTecnico:
            return {
                ...state,
                servicio: action.payload
            }

        case types.listadoPersonalizados:
            return {
                ...state,
                personalizado: action.payload
            }

        case types.listadoPublicidad:
            return {
                ...state,
                publicidad: action.payload
            }

        case types.productosLista:
            return {
                ...state,
                productos: [ ...action.payload ]
            };


        case types.productosDelete:
            return {
                ...state,
                productos: state.productos.filter(
                    p => ( p.id !== action.payload.id )
                ),
                activeProducto: null
            };

        case types.productosActivo:
            return {
                ...state,
                activeProducto: action.payload
            };
            
        case types.productosDeleteActivo:
            return {
                ...state,
                activeProducto: null
            };

        case types.productosUpdate:
            return {
                ...state,
                productos: state.productos.map( 
                    e => ( e.id === action.payload.id ) ? action.payload : e
                )
            };

        case types.terminadoActivoDelete:
            return {
                ...state,
                activeTerminado: null
            }

        case types.productosListaDelete:
            return {
                ...state,
                productos: []
            };

        default:
            return state;
    }

    

}
