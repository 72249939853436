

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LayoutReturn } from "../layouts";
import { TablaOpsCliente } from "../components";
import { opPorCompradorCliente } from "../../../actions/op";






export const MisArchivados = () => {

    const dispatch = useDispatch();
    const { uid } = useSelector( state => state.authCliente );

    
    useEffect(() => {
        if( uid ){
            dispatch( opPorCompradorCliente({
                uid,
                estado: false
            }));
        }
    }, [ dispatch, uid ] );



    // const handleMostrarImagen = (op) => {
    //     navigate(`/foto-pago/${ op.id }`);
    // }

    // const handleVerRegistros = (op) => {
    //     navigate(`/abono-cliente/${ op.id }`);
    // }



    return (

            <LayoutReturn titulo='Mis Pedidos Archivados' url='/dashboard'>

            
                <TablaOpsCliente />

            
            </LayoutReturn>

    )



}
