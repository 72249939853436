
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, Grid, MenuItem, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { gastoStartAddNew } from "../../../actions/gastos";
import { LayoutReturn } from "../../layouts";
import { proveedorListaStart } from "../../../actions/proveedor";
import { AgregarItemsGastos } from "../components";

import Swal from "sweetalert2";
import moment from "moment";


const now = moment().toDate();
const initial = {
    factura: '',
    tipoPago: '',
    cuantosDias: '',
    proveedor: '',
    fecha: now,
}


export const FormularioCrearGasto = () => {

    const dispatch = useDispatch();
    const { proveedores } = useSelector(state => state.proveedor);

    const [data, setData] = useState([]);
    const [informacion, setInformacion] = useState(initial);
    const { factura, tipoPago, proveedor, fecha, cuantosDias } = informacion;


    useEffect( () => {
        dispatch( proveedorListaStart() );
    }, [ dispatch ] );


    const handleChangesInformacion = ({ target }) => {
        setInformacion( (resultado) => ({
            ...resultado,
            [target.name]: target.value
        }));
    }
    

    const handleChangeStart = (e) => {
        setInformacion( (resultado) => ({
            ...resultado,
            fecha: e.$d
        }));
    }


    const handleCrearGasto = async(e) => {
        e.preventDefault();
        if([factura, tipoPago, proveedor, fecha, cuantosDias].includes('')){
            return Swal.fire('Error', 'Todos los Campos Son Obligatorios', 'error');
        }

        if(data.length < 1){
            return Swal.fire('Error', 'Debes Añadir Al Menos Un Item', 'error');
        }

        const resp = await Swal.fire({
            title: 'Crear Gasto',
            text: `¿Esta Seguro De Crear Este Gasto?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si' 
        });

        if(resp.value){
            const resp = dispatch(gastoStartAddNew({informacion, data}));
            if( resp ) {
                setInformacion(initial);
                setData([]);
            }
        }
    }



    return (


        <LayoutReturn titulo='Crear Gastos Basicos' url="/super/gastos">

            <form autoComplete='off' style={{width:'100%'}} onSubmit={ handleCrearGasto }>
                <Grid container spacing={2} my={3}>

                    <Grid item xs={ 12 } sm={ 4 }>
                        <TextField required
                            fullWidth
                            label='Factura'
                            placeholder='Factura'
                            name="factura"
                            value={factura}
                            onChange={ handleChangesInformacion }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 4 }>
                        <TextField required
                            select
                            fullWidth
                            label='Tipo de Pago'
                            placeholder='Tipo de Pago'
                            name="tipoPago"
                            value={tipoPago}
                            onChange={ handleChangesInformacion }
                        >
                            <MenuItem value=''>
                                Seleccione Tipo de Pago
                            </MenuItem>
                            <MenuItem value="CREDITO" >
                                CREDITO
                            </MenuItem>
                            <MenuItem value="CONTADO" >
                                CONTADO
                            </MenuItem>
                        </TextField>
                    </Grid>

                    <Grid item xs={ 12 } sm={ 4 }>
                        <TextField 
                            label='Cuantos Dias'
                            type='number'
                            placeholder='Cuantos Dias'
                            fullWidth
                            name='cuantosDias'
                            value={ cuantosDias }
                            onChange={ handleChangesInformacion }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 4 }>
                        <TextField 
                            select
                            fullWidth
                            label='Proveedor'
                            placeholder='Proveedor'
                            name="proveedor"
                            value={proveedor}
                            onChange={ handleChangesInformacion }
                        >
                            <MenuItem value=''>
                                Seleccione un Proveedor
                            </MenuItem>
                            {
                            proveedores?.map((p) => (
                            <MenuItem value={p.id} key={p.id}>
                                {p.nombre}
                            </MenuItem>
                            )) 
                            }
                        </TextField>
                    </Grid>


                    <Grid item xs={ 12 } sm={4}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Fecha de Factura"
                                value={ fecha }
                                onChange={ handleChangeStart }
                                renderInput={(params) => <TextField {...params} fullWidth />}
                            />
                        </LocalizationProvider>
                    </Grid>

                </Grid>


                <AgregarItemsGastos data={data} setData={setData} />


                <Grid container sx={{display:'flex', justifyContent:'center', my:4}}>
                    <Grid item xs={12} sm={6}>
                        <Button variant='contained' fullWidth type="submit">
                            Agregar Gasto
                        </Button>
                    </Grid>
                </Grid>

            </form>

        </LayoutReturn>

    )

}
