
import { useSelector } from "react-redux";

import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { separadorMillares } from "../../../hooks/useMillares";
import { MensajeAlertaDanger } from "../../components";






export const QuienSolicitaOp = () => {


    const { activeOP } = useSelector( state => state.op );



  return (


        <Grid container textAlign='center'>

            <Grid item xs={12} mt={5} >
              <Typography variant="h2" >Quien la Solicito</Typography>
            </Grid>
        
            {

              ( activeOP ) 
              ?<TableContainer sx={{ mb:5 }}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">

                      <TableHead >
                          <TableRow >
                              <TableCell >FECHA</TableCell>
                              <TableCell>NOMBRE</TableCell>
                              <TableCell>ROL</TableCell>
                          </TableRow>
                      </TableHead>

                      <TableBody>

                          <TableRow >

                              <TableCell className="w-300">
                                  <Typography variant="span">{ activeOP.startOp }</Typography>
                              </TableCell>

                              <TableCell >
                                  <Typography variant="span">{ activeOP.usuario.nombre }</Typography>
                              </TableCell>

                              <TableCell >
                                  <Typography variant="span" >{ activeOP.usuario.role }</Typography>
                              </TableCell>

                          </TableRow>

                      </TableBody>

                  </Table>
              </TableContainer>
              
              : <MensajeAlertaDanger titulo={'No hay O.P. En Este Modulo'} />

            }

        </Grid>


  )
}
