

import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Grid, TextField } from "@mui/material";

import { busquedaDeClientesTodos } from "../../../actions/busquedas";
import { clienteListaStart } from "../../../actions/cliente";





export const BuscarCliente = ({ activo }) => {

    
    const debounceRef = useRef();
    const dispatch = useDispatch();
    const [valor, setValor] = useState('');



    const hanldeInputChange = ({ target }) => {
        setValor(target.value);
        if( debounceRef.current )
            clearTimeout( debounceRef.current );

        debounceRef.current = setTimeout( () => {
            if( target.value.trim().length > 0 ){
                dispatch( busquedaDeClientesTodos({activo, termino: target.value}));
            }else {
                dispatch( clienteListaStart(activo) );
            }
        }, 600);
    }



    return (
        <Grid
            container direccion='row'
            alignItems='center' my={3}
        >
            <Grid item xs={ 12 } sm={ 6 }>
                <TextField 
                    autoComplete="off"
                    label="Buscar Cliente nom, ciu, cedu, movil, dist..." 
                    variant="filled" 
                    fullWidth
                    color="secondary"
                    name="valor"
                    value={valor}
                    onChange={ hanldeInputChange }
                />
            </Grid>
        </Grid>
    )



}


