
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { LayoutCrearDoc } from "../../layouts";
import { BuscadorFechas, TablaVerGastos } from "../components";
import { gastosListaStart } from "../../../actions/gastos";



import moment from "moment";
const now = moment().hours(0).minutes(0).seconds(0);
const nowPlus1 = now.clone().add(1, 'days').subtract(1, 'seconds');





export const GastosScreen = () => {

    
    const navigate = useNavigate();
    const dispatch = useDispatch();


    const [formValues, setFormValues] = useState({
        start: now.toDate(),
        end: nowPlus1.toDate(),
        medio: '',
    });
    const { start, end } = formValues;


    const handleChangeStart = (e) => {
        setFormValues({
            ...formValues,
            start: e.$d
        });
    }


    const handleChangeEnd = (e) => {
        const resultado = moment(e.$d).hours(0).minutes(0).seconds(0);
        setFormValues({
            ...formValues,
            end: resultado.clone().add(24, 'hours').subtract(1, 'seconds').toDate(),
        });
    } 


    const handleConsultaFacturas = (e) => {
        e.preventDefault();
        dispatch( gastosListaStart(formValues) );
    }



    const handleCrearGastos = () => {
        navigate(`/super/gasto/create`)
    } 



    return (

        <LayoutCrearDoc titulo='Gastos Basicos' funcion={ handleCrearGastos }>
            <BuscadorFechas
                start={start}
                end={end}
                handleChangeStart={handleChangeStart}
                handleChangeEnd={handleChangeEnd}
                funcion={ handleConsultaFacturas }
            />


            <TablaVerGastos />
        </LayoutCrearDoc>

    )

}
