
import React from 'react';
import { TextField } from "@mui/material";
import NumberFormat from 'react-number-format';





export const TextFormNumber = ({ label, ...props }) => {


    return (
        <>
            <TextField
                autoComplete='off'
                {...props}
                label={label}
                InputProps={{
                    inputComponent: NumberFormatCustom
                }}
            />
        </>
    );
};





export const NumberFormatCustom = React.forwardRef( function NumberFormatCustom(props, ref) {

    const { onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
            onChange({
                target: {
                name: props.name,
                value: values.value,
                },
            });
            }}
            isNumericString
            allowNegative={false}
            allowLeadingZeros={false}
            thousandSeparator="."
            decimalSeparator=","
            prefix="$"
        />
    );
});