
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Button, Grid } from "@mui/material";
import { SelectorProductos } from "../components";
import { SelectSencillo, TextFormField, ViewPreciosPorRol } from "../../components";
import { coloresCarrito, generoCarrito, tallasCarrito } from "../../../data";
import { separadorMillares, useCalcularPrecios } from "../../../hooks";

import Swal from "sweetalert2";
import { buscarProductoPrendas } from "../../../helpers/FormulariosCarrito";





export const FormularioPrendas = ({ formCarrito, setFormCarrito, funcionAgregar, titulo }) => {


    const  { activeProducto } = useSelector( state => state.producto );
    const { calcularPrecioSoloCantidad } = useCalcularPrecios();
    const { activeCarritoUpdate } = useSelector( state => state.carrito );
    const { carritoCompra } = useSelector( state => state.carrito );

    const [ precioReal, setPrecioReal ] = useState(0);



    useEffect(() => {
        if( formCarrito.color === '' ){ return }

        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })
        Toast.fire({ icon: 'question', title: 'Los Productos y Colores dependen de disponibilidad de stock' });
    }, [formCarrito.color]);




    useEffect( () => {
        if( activeProducto ) {
            const { cliente, distri, empresa, publicista, precioReal } = calcularPrecioSoloCantidad(formCarrito.cantidad, activeProducto);
            setPrecioReal( precioReal - cliente );
            setFormCarrito({
                ...formCarrito,
                totalCliente: cliente,
                totalDistri: distri,
                totalEmpresa: empresa,
                totalPublicista: publicista,
            });
        }
    }, [activeProducto, formCarrito.cantidad]);




    const handleInputChange = ({ target }) => {
        setFormCarrito({
            ...formCarrito,
            [target.name]: target.value
        });
    }



    
    const handleAgregarAlCarro = (e) => {
        e.preventDefault();
        if( formCarrito.cantidad <= 0 ) { return Swal.fire('Error', 'Debes poner una cantidad', 'error') }

        if( formCarrito.color === '' || formCarrito.color === null ) { return Swal.fire('Error', 'Por favor escoger un color', 'error') }
        
        if( formCarrito.talla === '' ) { return Swal.fire('Error', 'Debes poner una talla', 'error') }

        if( formCarrito.genero === '' ) { return Swal.fire('Error', 'Debes poner una genero', 'error') }

        if( activeCarritoUpdate ) {
            return funcionAgregar(activeCarritoUpdate)
        }

        const product = buscarProductoPrendas(carritoCompra, 
                        formCarrito.producto, 
                        formCarrito.color, 
                        formCarrito.talla, 
                        formCarrito.genero );
                        
        funcionAgregar(product)
    }




    return (

        <form onSubmit={ handleAgregarAlCarro }>

            <SelectorProductos formCarrito={formCarrito} setFormCarrito={setFormCarrito} />

            <Grid container sx={{width:'100%', mt:2, display:'flex', justifyContent:'space-between'}}>
                <Grid item xs={5.8}>
                    <TextFormField
                        size="small"
                        label='Cantidad'
                        type='number'
                        placeholder="Cantidad"
                        name="cantidad"
                        min="1"
                        max="100"
                        value={formCarrito.cantidad}
                        onChange={ handleInputChange } 
                    />
                </Grid> 

                <Grid item xs={5.8}>
                    <SelectSencillo
                        size="small"
                        label="Talla"
                        name="talla"
                        value={formCarrito.talla}
                        onChange={ handleInputChange } 
                        options={tallasCarrito}
                    />
                </Grid>


                <Grid item xs={5.8}>
                    <SelectSencillo
                        sx={{mt:2}}
                        size="small"
                        label="Color"
                        name="color"
                        value={formCarrito.color}
                        onChange={ handleInputChange } 
                        options={coloresCarrito}
                    />
                </Grid>

                <Grid item xs={5.8}>
                    <SelectSencillo
                        sx={{mt:2}}
                        size="small"
                        label="Genero"
                        name="genero"
                        value={formCarrito.genero}
                        onChange={ handleInputChange } 
                        options={generoCarrito}
                    />
                </Grid>
            </Grid>


            {/* Mostrar Precio Segun Rol */}
            <ViewPreciosPorRol formCarrito={formCarrito} />


            <Grid container sx={{display:'flex', justifyContent:'space-between', mt:2}}>
                <Grid item xs={(precioReal <= 0) ? 12 : 5.8}>
                    <Button type="submit" color='primary' variant="contained" fullWidth>
                        { titulo }
                    </Button>
                </Grid>

                {(precioReal > 0) && (
                <Grid item xs={5.8}>
                    <Button color='secondary' variant="contained" fullWidth>
                        Ahorra { `$${ separadorMillares( precioReal ) }`}
                    </Button>
                </Grid>
                )}
            </Grid>
        </form> 
    )
}
