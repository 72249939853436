import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { carritoStartDeleteCompra } from "../../actions/carrito";
import { carritoStartDeleteCliente } from "../../actions/carritoCliente";
import { separadorMillares } from "../../hooks/useMillares";



const baseUrl = process.env.REACT_APP_API_URL;


export const CardsCarrito = ({ id, cantidad, producto, totalCliente, totalDistri, totalEmpresa, totalPublicista }) => {


const dispatch = useDispatch();
const { usuario } = useSelector( state => state.admin );
const { role } = useSelector( state => state.authCliente );

const [ view, setView ] = useState('');



const [formCantidad, setFormCantidad] = useState({
    cantidad2: cantidad
});
const { cantidad2 } = formCantidad;


const handleInputChange = ({ target }) => {
    setFormCantidad({
        ...formCantidad,
        [target.name]: target.value
    });
}


useEffect( () => {
    let view = localStorage.getItem("view");
    // let view = sessionStorage.getItem("view")
    setView(view);
}, []);



const handleDelete = ( id ) => {
    dispatch( carritoStartDeleteCompra(id) );
}


const handleDeleteCliente = ( id ) => {
    dispatch( carritoStartDeleteCliente(id) );
}


  return (

        <>
        { producto && 
        <tr className='comprar-carrito'>
            <td >
                <div className='img-comprar'>
                    <img src={ `${ baseUrl }/uploads/productos/${ producto.img }` }  style={{width:100}} />
                </div>
            </td>

            <td className='cantidad-carrito'>
                <h5 className="nombre-prod" style={{fontSize:'14px', color:'white'}}>{ producto.nombre }</h5>
                <input type="number" readOnly
                    value={ cantidad2 }
                    name="cantidad2"
                    onChange={ handleInputChange }
                />
            </td>

            <td className="precios">
                <div style={{fontSize:'14px', color:'white'}}>
                    {`$${ separadorMillares(totalCliente) }`}
                </div>
            </td>

            <td>
            {
                ( ( usuario ) && role !== 'CLIENTE' )

                ?   <div className="comprar-eliminar">
                        <i className="fa-solid fa-trash-can"
                            onClick={ () => handleDelete( id ) }
                        ></i>
                    </div>
                :   <div className="comprar-eliminar">
                        <i className="fa-solid fa-trash-can"
                            onClick={ () => handleDeleteCliente( id ) }
                        ></i>
                    </div>
            } 
            </td>
        </tr>

        }

        </>

    )
}
