
import { Visibility, Print, Paid, Edit, Delete, RequestQuote, PeopleAlt, QrCodeScanner, Category,LocalShippingRounded, SettingsSuggest, Addchart, Balance, PhotoCamera, Description, LoyaltyOutlined, RequestQuoteOutlined, CurrencyExchangeOutlined, QueryStatsOutlined, ReceiptLong, PieChartOutline, MonetizationOnOutlined, AddOutlined, PersonOutlineOutlined, EmailOutlined, TaskAltOutlined, CommentOutlined, PowerSettingsNew, StoreOutlined, FindInPageRounded, CheckCircleRounded, CancelRounded, SyncAltOutlined, Timeline, BarChart, PointOfSale, AccountCircle, PlayCircleFilled, StopCircle, FormatListNumbered, Search, SignalCellularAlt, Add, ArrowBack, Plagiarism, Engineering, ManageAccounts, GroupAdd, Info, CameraAlt, ProductionQuantityLimits, CloudUpload, Downloading, ShoppingCartCheckout } from '@mui/icons-material';


export const iconos = {

    persona: <PersonOutlineOutlined sx={{fontSize:'17px'}}/>,
    email: <EmailOutlined sx={{fontSize:'17px'}}/>,
    tarea: <TaskAltOutlined sx={{fontSize:'17px'}}/>,
    comentario: <CommentOutlined sx={{fontSize:'17px'}}/>,
    cerrar: <PowerSettingsNew sx={{fontSize:'17px'}}/>,
    
    
    
    eliminar: <Delete color='error'/>,
    editar: <Edit />,
    cliente: <GroupAdd />,
    micuente: <ManageAccounts />,
    colaborador: <Engineering />,
    abono: <Paid />,
    agregar: <AddOutlined />,
    apertura: <PlayCircleFilled />,
    aprobado: <CheckCircleRounded />,
    back: <ArrowBack />,
    busqueda: <Search />,
    categoria: <Category />,
    cierre: <StopCircle />,
    comercial: <LocalShippingRounded />,
    comprobante: <PhotoCamera/>,
    desprese: <Balance />,
    detalles: <Plagiarism />,
    error: <CancelRounded />,
    factura: <ReceiptLong />,
    gasto: <CurrencyExchangeOutlined />,
    gasto1: <CurrencyExchangeOutlined />,
    historico: <Timeline />,
    imprimir: <Print />,
    informe: <Addchart />,
    infotablas: <PieChartOutline />,
    inventario: <FindInPageRounded />,
    menu: <FormatListNumbered />,
    planilla: <Description />,
    plus: <Add />,
    presas: <Balance />,
    producto: <QrCodeScanner />,
    pronostico: <LoyaltyOutlined />,
    proveedor: <AccountCircle />,
    remision: <RequestQuoteOutlined />,
    separar: <Balance />,
    settings: <SettingsSuggest />,
    store: <StoreOutlined />,
    tabla: <QueryStatsOutlined />,
    tablas: <BarChart />,
    totales: <SignalCellularAlt />,
    traslado: <SyncAltOutlined />,
    user: <PeopleAlt />,
    valor: <RequestQuote/>,
    vender: <PointOfSale />,
    ventas: <MonetizationOnOutlined />,
    ver: <Visibility />,
    informacion: <Info />,
    informacion: <Info />,
    camara: <CameraAlt />,
    compras: <ProductionQuantityLimits />,
    subirimg: <CloudUpload />,
    descargar: <Downloading />,
    comprar: <ShoppingCartCheckout />,




}