
import { useSelector } from "react-redux";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

import moment from "moment";





export const TablaReporteInventario = () => {


    const { inventarios } = useSelector( state => state.reporteinventario );



    return (

        <TableContainer sx={{ mt:5 }}>
            <Table sx={{ minWidth: 700, }} aria-label="customized table">

                <TableHead >
                    <TableRow>
                        <TableCell>Factura</TableCell>
                        <TableCell>Envia</TableCell>
                        <TableCell>Recibe</TableCell>
                        <TableCell>Inicial</TableCell>
                        <TableCell>Cantidad</TableCell>
                        <TableCell>Final</TableCell>
                        <TableCell>Fecha</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                { inventarios.map( ( a ) => (
                    <TableRow  key={ a._id }>
                        <TableCell>
                            {(a.factura)
                            ? a.factura
                            : '--/---/--'
                            }
                        </TableCell>
                        <TableCell>
                            {(a.envia?.nombre)
                            ? a.envia.nombre
                            : '--/---/--'
                            }
                        </TableCell>
                        <TableCell>
                            {(a.recibe?.nombre)
                            ? a.recibe.nombre
                            : '--/---/--'
                            }
                        </TableCell>
                        <TableCell>{ a.inicial }</TableCell>
                        <TableCell style={{color: (a.tipo === 'DESCONTAR') ? '#F06969':'#68AB68'}}>
                            {(a.tipo === 'DESCONTAR') ? -a.cantidad : a.cantidad}
                        </TableCell>
                        <TableCell>{ a.final }</TableCell>
                        <TableCell>{ moment(a.createdAt).format('lll') }</TableCell>
                    </TableRow>
                ))}
                </TableBody>

            </Table>
        </TableContainer>
    )
}
