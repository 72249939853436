import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { recuperarPassword } from "../../actions/authCliente";


const empresa = require.context('../../assets/empresa', true);


export const OlvideMiPassword = () => {

    const dispatch = useDispatch()
    const [ email, setEmail ] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        if( email === '' || email.length < 5) {
            return Swal.fire('Error', 'El Email Es Obligatorio', 'error');
        }
        dispatch( recuperarPassword( { email } ));
    }


    return (
        <>
            <div className="login-cliente">


            <div className="img-cliente">
                <img src={ empresa(`./svtextoblanco.png`) } />
            </div>

            <div className="login">

                <h1>Recuperar Password</h1>
                <form onSubmit={ handleSubmit } >
                    <input type="email" name="email" placeholder="Email de Registro" required="required" 
                    onChange={ (e) => setEmail( e.target.value ) } value={ email }
                    />

                    <button type="submit" className="btn boton-cliente">
                        Enviar Instrucciones
                    </button>
                </form>
                
                <div className='registrarme'>
                    <p><Link to="/login">¿Ya Tienes Cuenta? Entrar Ahora</Link></p>
                </div>

            </div>
        
            </div>
        </>

    )

}
