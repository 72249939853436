
import { Grid } from "@mui/material";
import { BuscardorOpCategoria, TablaDeOpsXCategoria } from "../components";
import { CargandoMedio } from "../../components";
import { useOpTanStack } from "../../../hooks";






export const OpArchivadas = () => {


    const { page, chagePage, chageTermino, chageInicio, chageFinal, opsQuery } = useOpTanStack({ estado:'ARCHIVADAS'});
    
    

    return (

        <Grid container>
            <BuscardorOpCategoria chageTermino={chageTermino} chageInicio={chageInicio} chageFinal={chageFinal} /> 
            {(opsQuery.isLoading)
            ?   <CargandoMedio titulo="Cargando Información..." />
            :   <TablaDeOpsXCategoria data={opsQuery?.data} chagePage={ chagePage } page={ page } />
            }
        </Grid>
    
    )



}
