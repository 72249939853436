
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { superListaStart } from "../../../actions/usuario";
import { LayoutReturn } from "../../layouts"
import { BuscarUsuario, TablaListadoUsuarios } from "../components"






export const ListadoSociosArchivados = () => {


    const dispatch = useDispatch();



    useEffect( () => {
        dispatch( superListaStart({estado:false, user:'socios'}) );
    }, [dispatch]);


    return (

        <LayoutReturn titulo={'Listado de Socios Bloqueados'} url='/super/listadosocios'>
            <BuscarUsuario activo={false} usuario="socios" />
            <TablaListadoUsuarios />
        </LayoutReturn>

    )



    
}
