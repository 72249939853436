
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Drawer, List, Divider, ListItem, ListItemButton, ListItemIcon, ListItemText, Collapse, Avatar, Typography, Grid } from '@mui/material';
import { DrawerHeader } from '../../theme';
import { iconos } from '../../data';
import { ModalCarrito } from './ModalCarrito';
import { startRolVistas } from '../../actions';

const baseUrl = process.env.REACT_APP_API_URL;
const drawer = process.env.REACT_APP_DRAWER;
const drawerWidth = +drawer;





export const SlideShowMenu = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isSidebarOpen } = useSelector( state => state.iva );
  const { nombre, role, img, menu } = useSelector( state => state.admin );

  const [subMenu, setsubMenu] = useState(null);
  const [rutas, setRutas] = useState(null);


  const handleClick = (valor) => {
      const { titulo, ruta } = valor;
      setsubMenu(subMenu === titulo ? null : titulo);
      if( ruta ){
        navigate(`${ruta}`)
      }
  };



  const handleSubMenu = (menuopcion) => {
    setRutas( menuopcion );
    navigate(`${menuopcion}`);
  }



  const handleCahngeRolVenta = (view) => {
    if(view) {
      dispatch(startRolVistas(view));
      localStorage.setItem('view', view);
      localStorage.removeItem('cliente');
    }
  }



  return (

    
    <Drawer sx={{width:drawerWidth, flexShrink:0, height:'100vh', '& .MuiDrawer-paper':{width:drawerWidth, height:'100vh'} }} variant="persistent" anchor="left" open={isSidebarOpen}>

        <DrawerHeader sx={{width:'100%', minHeight:'300px', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', textAlign:'center', marginBottom:'1rem'}}>
              <Grid item sx={{ mt:3, mb:2 }}>
                {( img )
                ? <Avatar src={`${ baseUrl }/uploads/usuarios/${ img }`} sx={{ width:'130px', height:'130px' }} />
                : <Avatar src={`${ baseUrl }/uploads/usuarios/no-img.jpg`} sx={{ width:'130px', height:'130px' }} />
                }
              </Grid>

              <Grid item sx={{ mb:3 }}>
                  <Typography variant='h4'>{ nombre }</Typography>
                  <Typography variant='h5' sx={{fontSize:13}}>{ role }</Typography>
              </Grid>
        </DrawerHeader>
        <Divider />
        
        <List sx={{overflowX:'auto', flex: 1, pb:3, pt:2}}>
          {menu.map( (item) => (
            <div key={ item.titulo }>
              <ListItem disablePadding >
                <ListItemButton onClick={() => handleClick(item)}>
                  <ListItemIcon>
                    { iconos[`${item.icono}`] 
                    ? React.cloneElement(iconos[`${item.icono}`], { style:{ color:'white'}})
                    : React.cloneElement(iconos['settings'], { style:{ color:'white'}})
                  }
                    
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="h3" sx={{ml:-2, fontSize:'16px'}}>{item.titulo}</Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>

              {(item.submenu) &&
              <Collapse in={subMenu === item.titulo} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {item.submenu.map( (sub) => (
                    <ListItemButton key={sub.titulo} sx={{pl:2}} onClick={ () => { 
                        handleSubMenu(sub.url);
                        handleCahngeRolVenta(sub.view);
                    }}>
                      <ListItemIcon> </ListItemIcon>
                      <ListItemText sx={{ml:-3, color:(rutas === sub.ruta) ? 'red' : ''}} 
                        primary={<Typography variant="h6" sx={{fontSize:'15px'}}>{sub.titulo}</Typography>} 
                      />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
              }

            </div>
          ))}
        </List>

        <ModalCarrito />
        
      </Drawer>
  );
}