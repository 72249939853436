
import { useState } from "react";
import { Button, Grid, TextField } from "@mui/material";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import moment from "moment";



// const now = moment().hours(0).minutes(0).seconds(0);
// const nowPlus1 = now.clone().add(1, 'days').subtract(1, 'seconds');



export const BuscardorOpCategoria = ({ chageTermino, chageInicio, chageFinal }) => {



    const [formValues, setFormValues] = useState({
        termino: '',
        // start: now.toDate(),
        // end: nowPlus1.toDate()
        start: null,
        end: null,
    });
    const { start, end, termino } = formValues;



    const handleChangeStart = (e) => {
        setFormValues({
            ...formValues,
            start: e.$d
        });
    }


    const handleChangeEnd = (e) => {
        const resultado = moment(e.$d).hours(0).minutes(0).seconds(0);
        setFormValues({
            ...formValues,
            end: resultado.clone().add(24, 'hours').subtract(1, 'seconds').toDate(),
        });
    } 



    const hanldeInputChange = ({ target }) => {
        setFormValues( formValues => ({
            ...formValues,
            termino: target.value
        }));
    }


    
    
    
    const handleConsultarOps = (e) => {
        e.preventDefault();
        chageTermino(termino);
        chageInicio(start);
        chageFinal(end);
    }





    return (

        <form style={{width:'100%'}}>
            <Grid container direccion='row' alignItems='center' mt={2} mb={5} spacing={2}>
                <Grid item xs={12} sm={4}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Fecha de Inicio"
                            value={ start }
                            onChange={ handleChangeStart }
                            renderInput={(params) => <TextField {...params} fullWidth size='small' />}
                        />
                    </LocalizationProvider>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Fecha Final"
                            value={ end }
                            onChange={ handleChangeEnd }
                            minDate={ start }
                            renderInput={(params) => <TextField {...params} fullWidth size='small' /> }
                        />
                    </LocalizationProvider>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <TextField  
                        autoComplete="off"
                        label="Buscar OP....." 
                        fullWidth
                        color="secondary"
                        name="termino"
                        value={ termino }
                        onChange={ hanldeInputChange }
                        size='small'
                    />
                </Grid>

                <Grid item sm={12} sx={{display:'flex', justifyContent:'center'}}>
                    <Grid item xs={12} sm={4} >
                        <Button variant="contained" fullWidth onClick={ handleConsultarOps } >
                            Consultar
                        </Button>
                    </Grid>
                </Grid>

            </Grid>
        </form>
    )
}
