


import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { superListaStart } from "../../../actions/usuario";
import { LayoutReturn } from "../../layouts";
import { BuscarUsuario, TablaListadoUsuarios } from "../components";







export const ListadoUsuariosArchivados = () => {


    const dispatch = useDispatch();


    useEffect( () => {
        dispatch( superListaStart({estado: false}) );
    }, [dispatch]);


    return (
        
        <LayoutReturn titulo={'Listado de Usuarios Archivados'} url='/super/listado-super'>
            <BuscarUsuario activo={false} />
            <TablaListadoUsuarios />
        </LayoutReturn>


    )
}
