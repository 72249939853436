
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { clienteActivoClear } from "../../../actions/cliente";
import { openCrearCliente } from "../../../actions";
import { useEffect, useState } from "react";




export const HeaderDeCheckout = ({ children, title }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { view } = useSelector( state => state.iva );
    const { usuario } = useSelector( state => state.admin );
    const [role, setRole] = useState(true);


    const handleReturn = () => {
        navigate(-1);
        dispatch( clienteActivoClear() );
    }

    useEffect(() => {
        if(!usuario){ return }
        if(usuario.role === 'PUBLICISTA' || usuario.role === 'EMPRESA'){
            setRole(false);
        }else {
            setRole(true);
        }
    }, [usuario])
    



    const handleCrearCliente = () => {
    dispatch( openCrearCliente() );
    }






    return (

        
        <Grid container >
            <Grid container mt={2} >

                <IconButton
                        sx={{
                            color: '#fff',
                            backgroundColor: 'secondary.main',
                            ':hover': { backgroundColor: 'secondary.main', opacity: 0.3 },
                        }}
                        onClick={ handleReturn } 
                >
                    <ArrowBackIcon />
                </IconButton>

                <Box flex={ 1 } />

                <Typography variant='h2'>{ title }</Typography>

                <Box flex={ 1 } />
                
                {
                    (view === 'cliente' && role)
                    ?   <Button  variant="contained"
                            onClick={ handleCrearCliente }
                        >
                            Crear Cliente
                        </Button>
                    :   <Button disabled sx={{ background:"#000000" }}>
                        </Button>

                }
                

            </Grid>
            


            { children }



        </Grid>


    )



    
}
