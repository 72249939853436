
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { clientesStartPorDistri } from "../../../actions/cliente";
import { LayoutUsuarios } from "../layouts";
import { TablaListadoClientes } from "../components";





export const MisClientes = () => {


    const dispatch = useDispatch();
    // const [buscartipo, setBuscarTipo] = useState('');

    useEffect( () => {
        dispatch( clientesStartPorDistri() );
    }, [dispatch] );


    // const hanldeInputChange = (e) => {
    //     setBuscarTipo(e.target.value);
    //     if( e.target.value.trim().length > 0 ){
    //         dispatch( busquedaDeClienteRed(e.target.value) );
    //     }else{
    //         dispatch( clientesStartPorDistri() );
    //     }
    // }

    return (

        <>

        <LayoutUsuarios title={'Mi Red De Clientes'}>

            {/* <BuscarCliente /> */}

            <TablaListadoClientes />

        </LayoutUsuarios>
        
    </>

    )


}
