
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, IconButton } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { separadorMillares } from "../../../hooks/useMillares";
import { productosStartDelete } from "../../../actions/producto";
import { MensajeAlertaDanger } from "../../components";
import Swal from "sweetalert2";








export const TablaProductosCategoria = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { productos } = useSelector(state => state.producto);

    const paginaProduc = localStorage.getItem('pagpd') || 0;
    const pag = Number(paginaProduc);


    const [page, setPage] = useState(pag);
    const [ rowsPerPage ] = useState(25);


    const handleChangePage = (event, newPage) => {
        setPage( newPage );
        localStorage.setItem('pagpd', newPage);
    };


    useEffect(() => {
        if (pag !== null) {
            setPage(Number(pag));
        }
    }, [pag]);



    const handleProducto = (e) => {
        return navigate(`/super/actualizar-prod/${ e.id }`) 
    }


    
    const handleEliminar = ( p ) => {
        Swal.fire({
            title: '¿Borrar Producto?',
            text: `Esta Seguro De Borrar, ${ p.nombre }`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si, Borrar' 
        }).then( ( result ) => {
            if(result.value){
                dispatch( productosStartDelete( p ) );
            }
        })
    }



    return (

        <>
            {(productos.length > 0)
            ?   <Paper sx={{ width: '100%' }}>
                    <TableContainer sx={{ my:'15px' }}>
                        <Table sx={{ minWidth: 700, }}>
                            <TableHead >
                                <TableRow>
                                    <TableCell className="w-350">Nombre</TableCell>
                                    <TableCell className="w-200">Categoria</TableCell>
                                    <TableCell className="w-100">Costo Materia</TableCell>
                                    <TableCell className="w-100">Costo Produccion</TableCell>
                                    <TableCell className="w-100">Venta Cliente</TableCell>
                                    <TableCell className="w-100">Venta Distri</TableCell>
                                    <TableCell className="w-100">Acciones</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {productos.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map( (p) => (
                                <TableRow  key={ p.id }>

                                    <TableCell>{ p.nombre }</TableCell>
                                    <TableCell >
                                        {
                                        ( p.categoria === null )
                                        ? <Typography variant="span" color='error'>No Tiene</Typography>
                                        :  p.categoria.nombre 
                                        }
                                    </TableCell>
                                    <TableCell>{(p.costoMateria) ? `$${ separadorMillares(p.costoMateria)}` : 0}</TableCell>
                                    <TableCell>{(p.costoProduccion) ? `$${ separadorMillares(p.costoProduccion)}` : 0}</TableCell>
                                    <TableCell>{(p.precioVenta) ? `$${ separadorMillares(p.precioVenta)}` : 0}</TableCell>
                                    <TableCell>{(p.precioDistri) ? `$${ separadorMillares(p.precioDistri)}` : 0}</TableCell>

                                    <TableCell>

                                        <IconButton className="icon-boton" onClick={ () => handleProducto(p) } >
                                            <EditIcon fontSize="small" />
                                        </IconButton>

                                        <IconButton className="icon-boton" onClick={ () => handleEliminar(p) } >
                                            <DeleteIcon fontSize="small" />
                                        </IconButton>
                                    </TableCell>
                                
                                </TableRow>
                                ))
                                }
                            </TableBody>

                        </Table>
                    </TableContainer>

                    <TablePagination
                        rowsPerPageOptions={[ 25 ]}
                        component="div"
                        count={ productos.length }
                        rowsPerPage={ rowsPerPage }
                        page={ page }
                        onPageChange={ handleChangePage }
                    />
                </Paper>

            :   <MensajeAlertaDanger titulo={'No hay productos disponibles...'} />

            }
        </>

    )

}
