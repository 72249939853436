



export const descargarArchivo = (data, nombre='cotizacion') => {
    const uint8Array = new Uint8Array(data);
    const blob = new Blob([uint8Array], {type: 'application/pdf'});
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = nombre
    link.click()
}





export const descargarImagen = async(blob, nombre='imagen') => {
    const resp = await blob.blob();

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(resp);
    link.download = nombre;
    link.click();
};