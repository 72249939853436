import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";





export const TablaDeOp = () => {

    const { cliente }  = useSelector( state => state.authCliente );
    const { activeOP } = useSelector( state => state.op );

    const navigate = useNavigate();


    const handleVerOp = () => {
        if( cliente ){
            navigate('/mis-pedidos');
        } else {
            navigate('/login');
        }
    }


    return (

        <TableContainer sx={{ my:'15px' }}>
            <Table sx={{ minWidth: 700, }} aria-label="customized table">
                <TableHead >
                    <TableRow>
                        <TableCell className="w-150">O.P.</TableCell>
                        <TableCell className="w-200">Fecha</TableCell>
                        <TableCell>Nombre Comprador</TableCell>
                        <TableCell className="w-200">Ciudad</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    <TableRow onClick={ handleVerOp }>
                        <TableCell>{ activeOP.op}</TableCell>
                        <TableCell>{ activeOP.startOp }</TableCell>
                        <TableCell>{ activeOP.infoPersonal?.nombre }</TableCell>
                        <TableCell>{ activeOP.infoPersonal?.ciudad }</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>

    )



}
