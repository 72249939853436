
import { useNavigate } from 'react-router-dom';

import { CardActionArea, Grid, Card, CardContent, CardMedia, Typography } from '@mui/material';
import { originalTheme } from '../../../theme';


const baseUrl = process.env.REACT_APP_API_URL;




export const CardsCategorias = ({ nombre, id, img, url}) => {


    const navigate = useNavigate();


    const handleVerProductosCategoria = (id) => {
        navigate(`${url}/${ id }`);
    }


    return (


        <Grid item xs={12} sm={10} md={6} >
            <Card sx={{ width:'100%', height:'240px', borderRadius:'20px', border:`2px solid ${originalTheme.palette.primary.main}` }} onClick={ () => handleVerProductosCategoria(id) }>
                <CardActionArea>
                <CardMedia
                    component="img"
                    height="240px"
                    image={`${ baseUrl }/uploads/categorias/${ img }`}
                    alt={nombre}
                    sx={{
                        objectFit: 'cover',
                    }}
                />
                <CardContent className='style-modal2'>
                    <Typography gutterBottom variant="h2" component="div">
                        { nombre }
                    </Typography>
                </CardContent>
                </CardActionArea>
            </Card>

        </Grid>



    )


}
