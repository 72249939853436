
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Button, Grid, TextField, MenuItem } from '@mui/material';
import { NumberFormatCustom } from '../../../hooks';
import { closeStartForm } from '../../../actions';
import { abonoStartFacturaNew } from '../../../actions/abonoFactura';
import { mediosPago } from '../../../data';

import Swal from 'sweetalert2';





export const FormCrearAbonosGastos = () => {


    const { id } = useParams();
    const dispatch = useDispatch();
    // const { modalFacturas } = useSelector( state => state.modalDos );

    const [ formAbono, setFormAbono ] = useState({ valor: "", medio: "", observacion: "", });
    const { valor, medio, observacion } = formAbono;


    const handleInputChange = ({ target }) => {
        setFormAbono({
            ...formAbono,
            [target.name]: target.value
        });
    }

    


    const handleCrearAbono = async(e) => {
        e.preventDefault();

        if( [medio, valor].includes('') ) {
            return Swal.fire('Error', 'Abono y Medio de Pago Son Campos Obligatorios', 'error');
        }

        const resp = await dispatch(abonoStartFacturaNew({...formAbono, id}));
        if( resp ){
            setFormAbono({ valor: "", medio: "", observacion: "" });
            dispatch( closeStartForm() );
        }
    }



    return (

        
        <form onSubmit={ handleCrearAbono } >
            <Grid container spacing={ 2 }>

                <Grid item xs={ 12 } sm={ 12 } mt={ 2 }>
                    <TextField required
                        autoComplete='off'
                        label="Valor"
                        fullWidth
                        placeholder='Valor'
                        name="valor"
                        value={ valor }
                        onChange={ handleInputChange }
                        InputProps={{
                            inputComponent: NumberFormatCustom,
                        }}
                    >
                    </TextField>
                </Grid>

                <Grid item xs={ 12 } sm={12} mt={ 2 }>
                    <TextField required
                        select
                        label="Medio de Pago"
                        fullWidth
                        name="medio"
                        value={ medio }
                        onChange={ handleInputChange }  
                    >    
                        <MenuItem value=''>
                            Seleccione Medio de Pago
                        </MenuItem>
                        {mediosPago.map( item => (
                            <MenuItem key={item} value={item}>
                                {item}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>

                <Grid item xs={ 12 } sm={ 12 } mt={ 2 }>
                    <TextField
                        label="Observacion"
                        fullWidth
                        placeholder="Observacion"
                        multiline
                        name="observacion"
                        value={ observacion }
                        onChange={ handleInputChange } 
                    />
                </Grid>

                <Grid container  sx={{ mt:2 }}  direccion='column' justifyContent='center'  >
                    <Grid item xs={ 12 } sm={ 8 } display='flex' justifyContent='center'>
                        <Button variant='contained' fullWidth type='submit'>
                            Crear Abono
                        </Button>
                    </Grid>
                </Grid>

            </Grid>
        </form>

    )
}
