
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { Grid, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, MenuItem, Button } from '@mui/material';

import { cotizacionXId, opAddNewCotizacion } from "../../../actions/cotizacion";
import { LayoutReturn } from "../../layouts"
import Swal from "sweetalert2";




export const ModificarParaComprar = () => {


    const dispatch = useDispatch();
    const { id } = useParams();
    const navigate = useNavigate();
    const { activeCotizacion } = useSelector(state => state.cotizacion);

    const [data, setData] = useState([]);

    useEffect(() => {
        if(id){
            dispatch(cotizacionXId(id));
        }
    }, [id, dispatch])  


    useEffect(() => {
        if(activeCotizacion){
            setData( activeCotizacion.items )
        }
    }, [activeCotizacion])  




    const cambiarInformacion = (e, id) => {
        const newValor = e.target.value;
        const valor = e.target.name;

        const pedidoActualizado = data.map(( p ) => {
            if( p.id === id ){
                p = {
                    ...p, 
                    [valor]: newValor
                }
            }
            return p;
        });
        setData(pedidoActualizado);
    }



    const handleEnviarInfo = async () => {
        let { items, ...object } = activeCotizacion;
        const enviar = { info: object, data }

        const resp = await Swal.fire({
            title: 'Comprar Ahora',
            text: `¿Esta Seguro De Comprar Ahora?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si' 
        });

        if(resp.value){
            const resp2 = await Swal.fire({
                title: 'Crear O.P.',
                text: `¿Esta Seguro De Crear O.P. Y De Eliminar la Cotizacion.?`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Si' 
            });

            if( resp2.value ){
                const respuesta = await dispatch(opAddNewCotizacion(enviar));
                // console.log({enviar});
                if( respuesta ){
                    navigate('/super/cotizacion/all')
                }
            }
        }
    }





    return (

        <LayoutReturn titulo='Modificar Para Crear O.P.' url='/super/cotizacion/all'>


        {
        (data) &&
        <Grid container mt={4}>
            <TableContainer>
                <Table>
                <TableHead>
                    <TableRow>
                        <TableCell className="w-220">Nombre</TableCell>
                        <TableCell>Cantidad</TableCell>
                        <TableCell>Talla</TableCell>
                        <TableCell>Genero</TableCell>
                        <TableCell>Color</TableCell>
                        <TableCell>Unidad-Medida</TableCell>
                        <TableCell>Ancho</TableCell>
                        <TableCell>Alto</TableCell>
                        <TableCell>Disenio</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row) => (
                    <TableRow key={row.id}>

                        <TableCell>
                            { row.nombre }
                        </TableCell>

                        <TableCell>{ row.cantidad }</TableCell>

                        <TableCell>
                            <TextField 
                                variant="filled"
                                select
                                label="Talla"
                                fullWidth
                                name='talla'
                                value={ row.talla || '' }
                                onChange={ (e) => cambiarInformacion(e, row.id) }
                            >
                                <MenuItem value="">Talla</MenuItem>
                                <MenuItem value="0-3">0-3</MenuItem>
                                <MenuItem value="3-6">3-6</MenuItem>
                                <MenuItem value="6-12">6-12</MenuItem>
                                <MenuItem value="12-18">12-18</MenuItem>
                                <MenuItem value="2">2</MenuItem>
                                <MenuItem value="4">4</MenuItem>
                                <MenuItem value="6">6</MenuItem>
                                <MenuItem value="8">8</MenuItem>
                                <MenuItem value="10">10</MenuItem>
                                <MenuItem value="12">12</MenuItem>
                                <MenuItem value="14">14</MenuItem>
                                <MenuItem value="16">16</MenuItem>
                                <MenuItem value="S">S</MenuItem>
                                <MenuItem value="M">M</MenuItem>
                                <MenuItem value="L">L</MenuItem>
                                <MenuItem value="XL">XL</MenuItem>
                            </TextField>
                        </TableCell>

                        <TableCell>
                            <TextField 
                                variant="filled"
                                select
                                label="Genero"
                                fullWidth
                                name='genero'
                                value={ row.genero || '' }
                                onChange={ (e) => cambiarInformacion(e, row.id) }
                            >
                                <MenuItem value="">Genero</MenuItem>
                                <MenuItem value="Niño">Niño</MenuItem>
                                <MenuItem value="Mujer">Mujer</MenuItem>
                                <MenuItem value="Hombre">Hombre</MenuItem>
                            </TextField>
                        </TableCell>

                        <TableCell>
                            <TextField 
                                variant="filled"
                                select
                                label="Color"
                                fullWidth
                                name='color'
                                value={ row.color || '' }
                                onChange={ (e) => cambiarInformacion(e, row.id) }
                            >
                                <MenuItem value="">Color</MenuItem>
                                <MenuItem value="Negro">Negro</MenuItem>
                                <MenuItem value="Blanco">Blanco</MenuItem>
                                <MenuItem value="Morado">Morado</MenuItem>
                                <MenuItem value="Rosado">Rosado</MenuItem>
                                <MenuItem value="Fucsia">Fucsia</MenuItem>
                                <MenuItem value="Azul Oscuro">Azul Oscuro</MenuItem>
                                <MenuItem value="Azul Rey">Azul Rey</MenuItem>
                                <MenuItem value="Gris Claro">Gris Claro</MenuItem>
                                <MenuItem value="Gris Oscuro">Gris Oscuro</MenuItem>
                                <MenuItem value="Azul Cielo">Azul Cielo</MenuItem>
                                <MenuItem value="Rojo">Rojo</MenuItem>
                                <MenuItem value="Verde">Verde</MenuItem>
                                <MenuItem value="Vinotinto">Vinotinto</MenuItem>
                                <MenuItem value="Lila">Lila</MenuItem>
                                <MenuItem value="Naranja">Naranja</MenuItem>
                                <MenuItem value="Amarillo">Amarillo</MenuItem>
                            </TextField>
                        </TableCell>

                        <TableCell>
                            <TextField 
                                variant="filled"
                                select
                                label="Medida"
                                fullWidth
                                name='unidadMedida'
                                value={ row.unidadMedida || '' }
                                onChange={ (e) => cambiarInformacion(e, row.id) }
                            >
                                <MenuItem value="">Medida</MenuItem>
                                <MenuItem value="Centimetros">Centimetros</MenuItem>
                                <MenuItem value="Metros">Metros</MenuItem>
                            </TextField>
                        </TableCell>

                        <TableCell>
                            <TextField 
                                variant="filled"
                                label='Ancho'
                                type='number'
                                multiline
                                placeholder='Ancho'
                                fullWidth
                                name="ancho"
                                value={ row.ancho || '' }
                                onChange={ (e) => cambiarInformacion(e, row.id) }
                            />
                        </TableCell>
                    
                        <TableCell>
                            <TextField 
                                variant="filled"
                                label='Alto'
                                type='number'
                                multiline
                                placeholder='Alto'
                                fullWidth
                                name="alto"
                                value={ row.alto || '' }
                                onChange={ (e) => cambiarInformacion(e, row.id) }
                            />
                        </TableCell>

                        <TableCell>
                            <TextField 
                                variant="filled"
                                select
                                label="Diseño"
                                fullWidth
                                name='disenio'
                                value={ row.disenio || '' }
                                onChange={ (e) => cambiarInformacion(e, row.id) }
                            >
                                <MenuItem value="">Diseño</MenuItem>
                                <MenuItem value="Con Diseño">Con Diseño</MenuItem>
                                <MenuItem value="Sin Diseño">Sin Diseño</MenuItem>
                            </TextField>
                        </TableCell>
                    </TableRow>
                    ))}
                </TableBody>
                </Table>
            </TableContainer>

            <Grid item xs={ 12 } sm={ 7 } sx={{ mx:'auto', my:4 }} >
                <Button variant="contained" color="secondary" fullWidth onClick={ handleEnviarInfo } >
                    Crear O.P.
                </Button>
            </Grid>
        </Grid>
        }


        </LayoutReturn>

    )



}
