

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Autocomplete, Button, Grid, TextField } from "@mui/material";

import { mostrarClientePorId } from "../../../actions/cliente";




export const FormularioCompartidoCliente = ({ formCliente, onChange }) => {


    const user = JSON.parse(localStorage.getItem('cliente'));
    const { clientes } = useSelector(state => state.cliente);
    const dispatch = useDispatch();



    const [value, setValue] = useState(null);



    const isOptionEqualToValue = (option, value) => {
        // console.log({ option, value});
        return option.id === value.id;
    };


    useEffect(() => {
        if( value !== null && value ) {
            dispatch( mostrarClientePorId( value.id ) );
        }
    }, [value] );


    useEffect(() => {
        if( user ) {
            setValue({ 
                nombre: user.nombre, 
                id: user.uid,
                cedula: user.cedula,
                ciudad: user.ciudad,
                direccion: user.direccion,
                movil: user.movil,
            });
        }
    }, []);



    return (

        <Grid container spacing={ 4 }>

            <Grid item xs={ 12 } sm={ 6 } mt={ 2 } >
                <Autocomplete
                    options={ clientes }
                    renderInput={ (params) => <TextField {...params} label="Buscar Cliente" variant="filled" /> }
                    value={ value }
                    onChange={ (event, newValue) => setValue( newValue ) }
                    getOptionLabel={ (option) => option.nombre }
                    isOptionEqualToValue={ isOptionEqualToValue }
                />
            </Grid>


            <Grid item xs={12} sm={6}  mt={ 2 } >
                <TextField required
                    variant="filled"
                    label='Cedula'
                    type='text'
                    placeholder='Cedula'
                    fullWidth
                    name="cedula"
                    value={ formCliente.cedula }
                    onChange={ onChange } 
                />
            </Grid>

            <Grid item xs={12} sm={6}  mt={ 2 } >
                <TextField required
                    variant="filled"
                    label='Ciudad'
                    type='text'
                    placeholder='Ciudad'
                    fullWidth
                    name="ciudad"
                    value={ formCliente.ciudad }
                    onChange={ onChange } 
                />
            </Grid>

            <Grid item xs={12} sm={6}  mt={ 2 } >
                <TextField required
                    variant="filled"
                    label='Dirección'
                    type='text'
                    placeholder='Dirección'
                    fullWidth
                    name="direccion"
                    value={ formCliente.direccion }
                    onChange={ onChange }
                />
            </Grid>

            <Grid item xs={12} sm={6}  mt={ 2 } >
                <TextField 
                    variant="filled"
                    label='Dirección 2'
                    type='text'
                    placeholder='Dirección 2'
                    fullWidth
                    name="direccion2"
                    value={ formCliente.direccion2 }
                    onChange={ onChange } 
                />
            </Grid>

            <Grid item xs={12} sm={6}  mt={ 2 } >
                <TextField required
                    variant="filled"
                    label='Movil'
                    type='text'
                    placeholder='Movil'
                    fullWidth
                    name="movil"
                    value={ formCliente.movil }
                    onChange={ onChange }
                />
            </Grid>

            <Grid item xs={12} sm={6}  mt={ 2 } >
                <TextField required
                    variant="filled"
                    disabled
                    label='Distribuidor'
                    type='text'
                    placeholder='Distribuidor'
                    fullWidth
                    name="distri"
                    value={ formCliente.distri }
                    onChange={ onChange } 
                />
            </Grid>

            <Grid container 
                sx={{ my:7 }} 
                direccion='column'
                justifyContent='center' 
            >
                <Grid item xs={ 12 } sm={ 8 } >
                    <Button variant='contained' color="secondary" fullWidth type='submit'>
                        Revisar Pedido
                    </Button>
                </Grid>
            </Grid>

        </Grid>


    )

    
}
