
import { useSelector } from 'react-redux';

import { Box, Modal, Typography } from '@mui/material';
import { CargandoInformacion } from './CargandoInformacion';






export const ModalFormulario = ({ titulo, children, datos=null, cerrarModal }) => {


    const { modalForm } = useSelector( state => state.modal );


    return (

        <Modal open={modalForm ?? false}  onClose={ cerrarModal } >
            <>
                {(!datos)
                ?   <CargandoInformacion titulo="Cargando Datos..." />

                :   <Box className="style-modal" sx={{width:{xs:'75%', sm:'65%', md:'50%'},}}>
                        <Typography align='center' variant='h2' sx={{mb:2, mt:-2 }} >{ titulo }</Typography>

                        { children }

                    </Box>
                }
            </>
        </Modal>

    );







}