
import { useNavigate } from 'react-router-dom';
import { Avatar, Box, Card, CardContent, Grid, Typography, CardActionArea } from '@mui/material';

import WidgetsIcon from '@mui/icons-material/Widgets';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import MarkAsUnreadIcon from '@mui/icons-material/MarkAsUnread';
import ArchiveIcon from '@mui/icons-material/Archive';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';





export const MisOpciones = () => {


    const navigate = useNavigate();



    const handleIrOpcion = (url) => {
        navigate(url);
    }



    return (

        <>
            
            <Grid container direccion='row' alignItems='center'>
                <Box flex={ 1 } />
                <Typography variant='h2' sx={{ fontWeight:'bold' }}>Tu Cuenta</Typography>
                <Box flex={ 1 } />
            </Grid>


            <Grid container spacing={3} display='flex' justifyContent='center' mt={5}>

                <Grid item xs={12} sm={6} md={4}>
                    <Card sx={{width:'100%', height:150}}>
                        <CardActionArea sx={{ width: '100%', height: '100%', display: 'flex', justifyContent:'space-evenly' }} 
                            onClick={ () => handleIrOpcion('/super/mis-pedidos') }>
                            <Box pl={2}>
                                <Avatar sx={{ width: 65, height: 65 }}>
                                    <WidgetsIcon sx={{ fontSize:40 }} />
                                </Avatar>
                            </Box>

                            <CardContent sx={{ textAlign:'center' }}>
                                <Typography variant='h5'sx={{fontSize:20}}>
                                    Tus Pedidos
                                </Typography>
                                <Typography variant="body2">
                                    Mira y rastrea tus pedidos o compra algo de nuevo.
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>


                <Grid item xs={12} sm={6} md={4}>
                    <Card sx={{width:'100%', height:150}}>
                        <CardActionArea sx={{ width: '100%', height: '100%', display: 'flex', justifyContent:'space-evenly' }} >
                            <Box pl={2}>
                                <Avatar sx={{ width: 65, height: 65 }}>
                                    <PriceChangeIcon sx={{ fontSize:40 }} />
                                </Avatar>
                            </Box>

                            <CardContent sx={{ textAlign:'center' }}>
                                <Typography variant='h5' component="div" sx={{ fontSize:20 }}>
                                    Tus Pagos
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Ver todas las transacciones y metodos de pagos.
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>


                <Grid item xs={12} sm={6} md={4}>
                    <Card sx={{width:'100%', height:150}}>
                        <CardActionArea sx={{ width: '100%', height: '100%', display: 'flex', justifyContent:'space-evenly' }} 
                            onClick={ () => handleIrOpcion('/super/perfil') }>
                            <Box pl={2}>
                                <Avatar sx={{ width: 65, height: 65 }}>
                                    <PersonSearchIcon sx={{ fontSize:40 }} />
                                </Avatar>
                            </Box>

                            <CardContent sx={{ textAlign:'center' }}>
                                <Typography variant='h5' component="div" sx={{ fontSize:20 }}>
                                    Perfil
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Administrar, agregar o eliminar datos para una mejor experiencia.
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>


                <Grid item xs={12} sm={6} md={4}>
                    <Card sx={{width:'100%', height:150}}>
                        <CardActionArea sx={{ width: '100%', height: '100%', display: 'flex', justifyContent:'space-evenly' }} >
                            <Box pl={2}>
                                <Avatar sx={{ width: 65, height: 65 }}>
                                    <MarkAsUnreadIcon sx={{ fontSize:40 }} />
                                </Avatar>
                            </Box>

                            <CardContent sx={{ textAlign:'center' }}>
                                <Typography variant='h5' component="div" sx={{ fontSize:20 }}>
                                    Tus Mensajes
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Ver Mensajes de la empresa para un mejor servicio.
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>


                <Grid item xs={12} sm={6} md={4}>
                    <Card sx={{width:'100%', height:150}}>
                        <CardActionArea sx={{ width: '100%', height: '100%', display: 'flex', justifyContent:'space-evenly' }} >

                            <Box pl={2}>
                                <Avatar sx={{ width: 65, height: 65 }}>
                                    <ArchiveIcon sx={{ fontSize:40 }} />
                                </Avatar>
                            </Box>

                            <CardContent sx={{ textAlign:'center' }}>
                                <Typography variant='h5' component="div" sx={{ fontSize:20 }}>
                                    Pedidos Archivados
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    ver y administrar tus pedidos archivados.
                                </Typography>
                            </CardContent>

                        </CardActionArea>
                    </Card>
                </Grid>


                <Grid item xs={12} sm={6} md={4}>
                    <Card sx={{width:'100%', height:150}}>
                        <CardActionArea sx={{ width: '100%', height: '100%', display: 'flex', justifyContent:'space-evenly' }} 
                            onClick={ () => handleIrOpcion('/super/cambio-clave') }>
                            <Box pl={2}>
                                <Avatar sx={{ width: 65, height: 65 }}>
                                    <HealthAndSafetyIcon sx={{ fontSize:40 }} />
                                </Avatar>
                            </Box>

                            <CardContent sx={{ textAlign:'center' }}>
                                <Typography variant='h5' component="div" sx={{ fontSize:20 }}>
                                    Seguridad de Cuenta
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    cambia tu contraseña periodicamente para mejor seguridad.
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>

        </Grid>

        </>

    )



}
