
import { useState } from "react";
import { useDispatch } from "react-redux";


import { Button, Grid } from "@mui/material"
import { InputDePassword } from '../components/InputDePassword';

import { LayoutReturn } from "../layouts";
import { cambioClaveCliente } from "../../../actions/authCliente";
import Swal from "sweetalert2";



const initUser = {
    claveAntigua: "",
    password: "",
    confirmarClave: "",
};



export const CambioClave = () => {

    const dispatch = useDispatch();
    const [ cambioClave, setCambioClave ] = useState( initUser );
    const { claveAntigua, password, confirmarClave } = cambioClave;



    const handleInputChange = ({ target }) => {
        setCambioClave( prev => {
            return {
                ...prev,
                [target.name]: target.value
            }
        });
    }



    const handleCambioClave = (e) => {
        e.preventDefault();

        if( password !== confirmarClave ) {
          Swal.fire('Error', 'El Password y Confirmar Password Deben De Ser Iguales', 'error');
          return;
        }
        if( password.trim().length < 5){
          Swal.fire('Error', 'El Password debe ser mayor a 5 digitos', 'error');
          return;
        }
        dispatch(cambioClaveCliente( cambioClave ) );
        setCambioClave( initUser );
    }



    return (

        <LayoutReturn titulo='Cambio de Clave' url='/dashboard'>
            <form onSubmit={ handleCambioClave }>

            <Grid container spacing={ 4 } my={3} justifyContent='center'>

                <Grid item xs={12} md={ 7 }>
                    <InputDePassword value={claveAntigua}
                        name='claveAntigua'
                        placeholder={'Clave Anterior'} 
                        onChangeInput={ handleInputChange } 
                    /> 
                </Grid>

                <Grid item xs={12} md={ 7 }>
                    <InputDePassword value={password}
                        name='password'
                        placeholder={'Nuevo Password'} 
                        onChangeInput={ handleInputChange } 
                    /> 
                </Grid>


                <Grid item xs={12} md={ 7 }>
                    <InputDePassword value={confirmarClave}
                        name='confirmarClave'
                        placeholder={'Confirmar Password'} 
                        onChangeInput={ handleInputChange } 
                    /> 
                </Grid>


                <Grid container sx={{ width:'100%', my:4 }} direccion='column' justifyContent='center' alignItems='center'>
                    <Grid item xs={12} sm={6} md={4} >
                        <Button variant='contained' fullWidth type='submit'>
                            Cambiar Password
                        </Button>
                    </Grid>
                </Grid>


            </Grid>
            </form>
        </LayoutReturn>
        
    )



}
