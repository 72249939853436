import { types } from "../types/types";


const initialState = {
    clientes: [ ],
    activeCliente:null
};



export const clienteReducer = ( state = initialState, action ) => {

    switch ( action.type ) {

        case types.clienteAddNew:
            return {
                ...state,
                clientes: [
                    ...state.clientes,
                    action.payload
                ]
            };

        case types.clienteLista:
            return {
                ...state,
                clientes: [ ...action.payload ]
            };


        case types.clienteActivo:
            return {
                ...state,
                activeCliente: action.payload
            };

        case types.clienteActivoClear:
            return {
                ...state,
                activeCliente: null
            };

        case types.clienteDelete:
            return {
                ...state,
                clientes: state.clientes.filter(
                    e => ( e.id !== action.payload.id )
                ),
                activeCliente: null
            };

        case types.clienteUpdate:
            return {
                ...state,
                clientes: state.clientes.map(
                    e => ( e.id === action.payload.id ) ? action.payload : e
                )
            };


        default:
            return state;
    }

}