
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import { usuarioPorID } from "../../../actions/usuario";







export const FormCompartidoCheckout = ({ formCliente, onChange, titulo = '....' }) => {



    const user = JSON.parse(localStorage.getItem('cliente'));
    const dispatch = useDispatch();
    const { usuarios } = useSelector(state => state.user);

    const [value, setValue] = useState(null);


    const isOptionEqualToValue = (option, value) => {
        return option.id === value.id;
    };


    useEffect(() => {
        if(value) {
            dispatch( usuarioPorID( value.id ) );
        }
    }, [value] );




    useEffect(() => {
        if( user ) {
            setValue({ 
                nombre: user.nombre, 
                id: user.uid,
                cedula: user.cedula,
                ciudad: user.ciudad,
                direccion: user.direccion,
                movil: user.movil,
            });
        }
    }, []);




    return (


        <Grid container spacing={ 4 }>

            <Grid item xs={ 12 } sm={ 6 } mt={ 2 } >
                <Autocomplete
                    options={ usuarios }
                    renderInput={ (params) => <TextField {...params} label={`Buscar ${titulo}`} variant="filled" /> }
                    value={ value }
                    onChange={ (event, newValue) => setValue( newValue ) }
                    getOptionLabel={ (option) => option.nombre }
                    isOptionEqualToValue={ isOptionEqualToValue }
                />
            </Grid>


            <Grid item xs={12} sm={6}  mt={ 2 } >
                <TextField required
                    variant="filled"
                    label='Cedula'
                    type='text'
                    placeholder='Cedula'
                    fullWidth
                    name="cedula"
                    value={ formCliente.cedula }
                    onChange={ onChange } 
                />
            </Grid>

            <Grid item xs={12} sm={6}  mt={ 2 } >
                <TextField required
                    variant="filled"
                    label='Ciudad'
                    type='text'
                    placeholder='Ciudad'
                    fullWidth
                    name="ciudad"
                    value={ formCliente.ciudad }
                    onChange={ onChange } 
                />
            </Grid>

            <Grid item xs={12} sm={6}  mt={ 2 } >
                <TextField required
                    variant="filled"
                    label='Dirección'
                    type='text'
                    placeholder='Dirección'
                    fullWidth
                    name="direccion"
                    value={ formCliente.direccion }
                    onChange={ onChange }
                />
            </Grid>

            <Grid item xs={12} sm={6}  mt={ 2 } >
                <TextField required
                    variant="filled"
                    label='Movil'
                    type='text'
                    placeholder='Movil'
                    fullWidth
                    name="movil"
                    value={ formCliente.movil }
                    onChange={ onChange }
                />
            </Grid>

            <Grid container 
                sx={{ my:7 }} 
                direccion='column'
                justifyContent='center' 
            >
                <Grid item xs={ 12 } sm={ 8 } >
                    <Button variant='contained' color="secondary" fullWidth type='submit'>
                        Revisar Pedido
                    </Button>
                </Grid>
            </Grid>

        </Grid>


    )




}
