
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { MensajeAlertaDanger, TooltipAndIcon } from "../../components";
import { sumaDeArrayPagosInventario } from "../../../helpers/sumaDeArrayItems";
import { separadorMillares } from "../../../hooks";
import { pagoListaXFiltroFecha } from "../../../actions/pagosInventario";

import moment from "moment";




export const TablaPagosInventario = () => {


    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { pagosinventario } = useSelector(state => state.pagoinv);
    const [total, setTotal] = useState(0)



    useEffect(() => {
        if(pagosinventario.length < 1) return dispatch(pagoListaXFiltroFecha({}));
    }, [])




    useEffect(() => {
        if(pagosinventario.length > 0) {
            const valor = pagosinventario.reduce( (t, a) => {
                a.items.forEach( item => {
                    t += ((item.porcentaje * item.precioUnidad * item.cantidad / 100) + item.precioUnidad * item.cantidad);
                });
                return t;
            }, 0);
            setTotal(valor);
        }
    }, [pagosinventario])





    const handleVerFactura = (id) => {
        navigate(`/super/pagos/detalle/${id}/pago`);
    }



    return (

        <>
        {(pagosinventario.length > 0)

        ?   <>
            <TableContainer sx={{ mt:5 }}>
                <Table sx={{ minWidth: 700, }} aria-label="customized table">
                    <TableHead >
                        <TableRow>
                            <TableCell>Numero Pagos</TableCell>
                            <TableCell>Total Gasto</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>{ pagosinventario.length }</TableCell>
                            <TableCell>{`$${separadorMillares(total)}`}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        
            <TableContainer sx={{ mt:5 }}>
                <Table sx={{ minWidth: 700, }} aria-label="customized table">
                    <TableHead >
                        <TableRow>
                            <TableCell>Fecha</TableCell>
                            <TableCell># Factura</TableCell>
                            <TableCell>Proveedor</TableCell>
                            <TableCell>Pago</TableCell>
                            <TableCell>Total Pago</TableCell>
                            <TableCell>Acciones</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    { pagosinventario.map( ( a ) => (
                        <TableRow  key={ a.id}>
                            <TableCell>{ moment(a.fecha).format('ll') }</TableCell>
                            <TableCell>{ a.factura }</TableCell>
                            <TableCell>{ a.proveedor?.nombre }</TableCell>
                            <TableCell>{ a.tipoPago }</TableCell>
                            <TableCell>{ `$${sumaDeArrayPagosInventario(a.items)}` }</TableCell>
                            <TableCell>
                                <Stack direction="row" spacing={1} justifyContent="center">
                                    <TooltipAndIcon
                                        titulo="Detalles de pago"
                                        icono="ver"
                                        color="white"
                                        onClick={ () => handleVerFactura(a.id)}
                                    />  
                                </Stack>
                            </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
            </>

        :   <MensajeAlertaDanger titulo="No Existen Datos En Pagos De Inventario" />
        }
    </>

    )
}
