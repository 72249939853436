
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Grid } from "@mui/material";
import { FormularioCantidadColor, FormularioCantidadx10, FormularioCantidadx50, FormularioCorteVinilo, FormularioEstructuras, FormularioLitografia, FormularioPrendas, FormularioSoloCantidad } from "../../productos/formularios";
import { cantidadColor, cantidax10, cantidax50, corteVinilo, estructurasEcosolvente, litografia, nombreSoloCantidad, prendas } from "../../../data";

import { productosListaCategoriaStart } from "../../../actions/producto";
import { carritoActivoParaUpdate } from "../../../actions/carrito";
import { actualizarItemsXOpProduccion } from "../../../actions/items";
import { categoriaListaStart } from "../../../actions/categoria";
import { AutocompleteObjeto } from "../../components";
import { closeStartForm } from "../../../actions";


import Swal from "sweetalert2";





export const FormularioEditarItemOp = () => {


    const dispatch = useDispatch();
    const { activeCarritoUpdate } = useSelector( state => state.carrito );
    const { categorias } = useSelector( state => state.categoria );
    const { activeOP } = useSelector( state => state.op );


    const [categoria, setCategoria] = useState(null);
    const categoriaNombre = (Object.keys(activeCarritoUpdate).length > 0 && activeCarritoUpdate.producto?.categoria?.nombre) || categoria?.nombre;
    const existeCarrito = Object.keys(activeCarritoUpdate).length > 0;



    useEffect( () => {
        dispatch( categoriaListaStart() );
    }, [ dispatch ] );



    useEffect(() => {
        if(existeCarrito) {
            dispatch( productosListaCategoriaStart( activeCarritoUpdate.producto.categoria._id ) );
        }
    }, [activeCarritoUpdate]);



    useEffect(() => {
        if(categoria) {
            dispatch( productosListaCategoriaStart( categoria.id ) );
        }
    }, [categoria]);




    useEffect(() => {
        if(activeCarritoUpdate && existeCarrito) {
            const { producto, terminados, ...resto } = activeCarritoUpdate;
            setFormCarrito({ ...formCarrito, terminados: (terminados) ? terminados._id : '', 
                producto: producto._id, ...resto
            })
        }
        return () => {
            localStorage.removeItem('item-carrito');
            dispatch(carritoActivoParaUpdate(null));
        }
    }, [activeCarritoUpdate]);




    const handleInputCategoria = (_, valor2) => {
        setCategoria(valor2);
    }



    const [formCarrito, setFormCarrito] = useState({
        producto: '',
        usuario: '',
        cantidad: '',
        color: '',
        talla: '',
        genero: '',
        disenio: '',
        unidadMedida: '',
        alto: '',
        ancho: '',
        terminados: '',
        totalCliente: '',
        totalDistri: '',
        totalEmpresa: '',
        totalPublicista: '',
    });




    const handleAgregarAlCarro = async() => {
        const resp = await Swal.fire({
            title: 'Modificar  OP',
            text: `¿Esta seguro de ${(existeCarrito) ? 'actualizar' : 'crear'} este item.?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si'
        });
        if( resp.value ) {
            const resp = await dispatch(actualizarItemsXOpProduccion({ 
                ...formCarrito,
                id: (existeCarrito) ? activeCarritoUpdate.id : null,
                fase: (existeCarrito) ? 'update' : 'create', 
                op: activeOP
            }));
            if( resp ){
                dispatch( closeStartForm() );
                Swal.fire('Exitoso', 'Item de OP modificado.', 'success')
            }
        }
    }


    

    return (

        <div>
            <Grid container spacing={4}>

                {(Object.keys(activeCarritoUpdate).length < 1) &&
                <Grid item xs={12} sx={{mt:2, mb:-2}}>
                    <AutocompleteObjeto
                        size="small"
                        label="Tipos de Productos"
                        name="categoria"
                        value={categoria}
                        onChange={ handleInputCategoria }
                        options={categorias}
                    />
                </Grid>
                }


                {categoriaNombre &&
                <Grid item xs={12}>
                    {(prendas.includes(categoriaNombre)) &&
                    <FormularioPrendas
                        titulo={'Editar Item'}
                        formCarrito={formCarrito} 
                        setFormCarrito={setFormCarrito} 
                        funcionAgregar={handleAgregarAlCarro}
                    />
                    }

                    {(nombreSoloCantidad.includes(categoriaNombre)) &&
                    <FormularioSoloCantidad
                        titulo={'Editar Item'}
                        formCarrito={formCarrito} 
                        setFormCarrito={setFormCarrito} 
                        funcionAgregar={handleAgregarAlCarro}
                    />
                    }

                    {(cantidadColor.includes(categoriaNombre)) &&
                    <FormularioCantidadColor
                        titulo={'Editar Item'}
                        formCarrito={formCarrito} 
                        setFormCarrito={setFormCarrito} 
                        funcionAgregar={handleAgregarAlCarro}
                    />
                    }

                    {(litografia.includes(categoriaNombre)) &&
                    <FormularioLitografia
                        titulo={'Editar Item'}
                        formCarrito={formCarrito} 
                        setFormCarrito={setFormCarrito} 
                        funcionAgregar={handleAgregarAlCarro}
                    />
                    }

                    {(estructurasEcosolvente.includes(categoriaNombre)) &&
                    <FormularioEstructuras
                        titulo={'Editar Item'}
                        formCarrito={formCarrito} 
                        setFormCarrito={setFormCarrito} 
                        funcionAgregar={handleAgregarAlCarro}
                    />
                    }

                    {(cantidax10.includes(categoriaNombre)) &&
                    <FormularioCantidadx10
                        titulo={'Editar Item'}
                        formCarrito={formCarrito} 
                        setFormCarrito={setFormCarrito} 
                        funcionAgregar={handleAgregarAlCarro}
                    />
                    }

                    {(cantidax50.includes(categoriaNombre)) &&
                    <FormularioCantidadx50
                        titulo={'Editar Item'}
                        formCarrito={formCarrito} 
                        setFormCarrito={setFormCarrito} 
                        funcionAgregar={handleAgregarAlCarro}
                    />
                    }

                    {(corteVinilo.includes(categoriaNombre)) &&
                    <FormularioCorteVinilo
                        titulo={'Editar Item'}
                        formCarrito={formCarrito} 
                        setFormCarrito={setFormCarrito} 
                        funcionAgregar={handleAgregarAlCarro}
                    />
                    } 

                </Grid> 
                }
            </Grid>

        </div>
    )
}
