import Swal from "sweetalert2";
import { fetchConToken, fetchConTokenCliente } from "../helpers/fetch";
import { prepararFechaAbono } from "../helpers/prepararFecha";
import { types } from "../types/types";




export const debitarClienteStartNew = ( debitar ) => {
    return async( dispatch ) =>{

        try {
            const resp = await fetchConToken( `deuda/debitar`, debitar, 'POST' );
            const body = await resp.json();

            if( body.ok ) {
                Swal.fire('Exitoso', 'Abonado Realizado', 'success');
                dispatch( deudaClienteAddNew(body.deuda) );
                dispatch( opUpdate(body.op) );
            } else {
                Swal.fire('Error', body.msg, 'error');
            } 
            
        } catch (error) {
            console.log(error);
        }
    }
}




const deudaClienteAddNew = ( abono ) => ({
    type: types.deudaClienteAddNew,
    payload: abono
});

const opUpdate = ( op ) => ({
    type: types.opUpdate,
    payload: op 
});




















export const deudaClienteListaStart = ( id ) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken(`deuda/deuda/${ id }`);
            const body = await resp.json();

            if( body.ok ){
                const deudas = prepararFechaAbono(body.deuda);
                dispatch( deudaClienteLista(deudas) );
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}

const deudaClienteLista = ( deuda ) => ({
    type: types.deudaClienteLista,
    payload: deuda 
});



export const deudaClienteListaStartCliente = ( id ) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConTokenCliente(`deuda/deuda/${ id }`);
            const body = await resp.json();

            if( body.ok ){
                const deudas = prepararFechaAbono(body.deuda);
                dispatch( deudaClienteLista(deudas) );
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}