
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useNavigate } from 'react-router-dom';

import { carritoListaCompraCliente } from '../../actions/carritoCliente';
import { openModalCliente } from '../../actions/modalCarrito';
import { Badge, Box, IconButton } from '@mui/material';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { ShoppingCart } from '@mui/icons-material';

const empresa = require.context('../../assets/empresa', true);



export const Navbar = () => {


    const list = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { nombre } = useSelector( state => state.authCliente );
    const { carritoCliente } = useSelector( state => state.carrito );



    useEffect(() => {
        if(nombre) {
            dispatch(carritoListaCompraCliente());
        }
    }, [nombre, dispatch] );


    useEffect( () => {
        const listElements = document.querySelectorAll('.menu-show');
        const pantalla = () => {
            if( window.innerWidth > 800 ) {
                deleteStyle( listElements );
                if( list.current.classList.contains('menu-links-show') ){
                    list.current.classList.remove('menu-links-show');
                }
            }
            else {
                addClick( listElements );
            }
        }
        window.addEventListener('resize', pantalla);
        addClick( listElements );

        return () => {
            window.removeEventListener('resize', pantalla);
        }
    }, [] );


    const deleteStyle = ( link ) => {
        link.forEach( ele => {
            if( ele.children[1]?.getAttribute('style') ) {
                ele.children[1]?.removeAttribute('style');
                ele.classList.remove('menu-ative');
            }
        });
    }


    const addClick = ( links ) => {
        links.forEach( ele => {
            ele.addEventListener('click', () => {
                let submenu = ele?.children[1];
                let height = 0;

                ele.classList.toggle('menu-active');
                if( submenu?.clientHeight === 0 ) {
                    height = submenu?.scrollHeight
                }
                // submenu.style.height = `${ height }px`;
            })
        })
    }


    const hamburguesa = () => {
        list.current.classList.toggle('menu-links-show');
    }



    const abrilModal = () => {
        dispatch( openModalCliente() );
    }


    const handleRutaAdmin = () => { 
        navigate('/admin/login')
    }



  return (

        <nav className="barra">
            <div className="logo">
                <NavLink className="menu-link"
                        exact="true"
                        to="/"
                >
                    <img src={ empresa(`./svtextoblanco.png`) } alt="" />
                </NavLink>
            </div>

            
             {/* <div className="carrito carrito2">
                 <i className="fa-solid fa-cart-plus"
                     onClick={ abrilModal }
                 >
                 {(carritoCliente.length > 0 ) && 
                     <small className="cantidad-ver">{ carritoCliente.length }</small>
                 }
                 </i>
             </div> */}
            

            
            <ul className="menu-links" ref={ list }>

                <li className="menu-item menu-show">
                    <Link to="/"  className="menu-link">Inicio
                    </Link>
                    
                </li>

                <li className="menu-item menu-show">
                    <Link to="/categorias"  className="menu-link">Productos y Servicios
                    </Link>
                </li>

                <li className="menu-item menu-show">
                    <Link className="menu-link" to="/contactos" >
                        Contáctanos
                    </Link>
                </li>

                <li className="menu-item menu-show">
                    <Link className="menu-link" to="/restrearop" >
                        Rastrear O.P.
                    </Link>
                </li>

                <Box flex={1} />


                {(nombre)
                ?   <>
                        <IconButton sx={{mr:2, background:'inherit', '&:hover': {backgroundColor: 'inherit'},}} onClick={ abrilModal }>
                            <Badge badgeContent={carritoCliente.length || 0} color="secondary">
                                <ShoppingCart />
                            </Badge>
                        </IconButton>
                        <li className="menu-item menu-show menu-ingreso">
                            <NavLink className="menu-link"
                            exact="true"
                            to="/dashboard"
                            >
                                Mi Perfil
                            </NavLink>
                        </li>
                    </>

                :   <>
                        <li className="menu-item menu-show menu-ingreso">
                            <NavLink className="menu-link"
                            exact="true"
                            to="/login"
                            >
                                Ingreso Cliente
                            </NavLink>
                        </li>

                        {
                        ( !nombre ) &&
                        <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', marginRight:2 }}>
                            <IconButton onClick={ handleRutaAdmin }>
                                <AdminPanelSettingsIcon />
                            </IconButton>
                        </Box>
                        }
                    </>
                }
            </ul>

            <div className="menu-hamburguesa" onClick={ hamburguesa } >
                <i className="fas fa-bars menu-icon"></i>
            </div>
        </nav>


    )
}
