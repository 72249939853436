
import { useDispatch, useSelector } from "react-redux";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Stack, TextField, MenuItem, Typography, IconButton, Pagination } from "@mui/material";

import { updateEstadoActivoOp, updateOpTodo, updatePago } from "../../../actions/op";
import { separadorMillares } from "../../../hooks/useMillares";
import { useNavigate } from "react-router-dom";
import { MensajeAlertaDanger } from "../../components";
import { estadosOp, pagosOp } from "../../../data";


import Swal from "sweetalert2";
import moment from "moment";
const paginado = process.env.REACT_APP_PAGINADO;







export const TablaDeOpsXCategoria = ({ data, chagePage, page, }) => {


    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { totalOp } = useSelector( state => state.op );



    const handleEstadoOp = ( e, op ) => {
        op.estado = e.target.value;
        Swal.fire({
            title: 'Actualizar Estado',
            text: `¿Esta Seguro De Actualizar La Op ${ op.op }?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si, Actualizar' 
        }).then( ( result ) => {
            if(result.value){
                dispatch( updateOpTodo(op));
            }
        })
    }


    const handleUpdatePago = ( e, op ) => {
        op.pago = e.target.value;
        Swal.fire({
            title: 'Actualizar Pago?',
            text: `¿Esta Seguro De Actualizar Pago?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si, Actualizar' 
        }).then( ( result ) => {
            if(result.value){
                dispatch(updatePago(op) );
            }
        })
    }


    const handleTrueFalse = (op) => {
        Swal.fire({
            title: (op.activo) ? 'Archivar O.P.' : 'Activar O.P.',
            text: (op.activo) ? `¿Esta Seguro De Archivar Esta O.P.?` : `¿Esta Seguro De Activar Esta O.P.?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: (op.activo) ? 'Si, Archivar' : 'Si, Activar'
        }).then( ( result ) => {
            if(result.value){
                dispatch( updateEstadoActivoOp(op.id) );
            }
        });
    }


    const handleOpcionesOp = (uid) => {
        navigate(`/super/detalles-op/${ uid }`);
    }


    return (

        <>
            {( data.length > 0 )
            ?<Paper sx={{width:'100%', maxWidth:{xs:450, sm:'100%', md:'100%'}, overflowX:'auto'}}>
                <TableContainer>
                    <Table sx={{minWidth:700, overflowX:'auto'}}>
                        <TableHead >
                            <TableRow>
                                <TableCell>A.</TableCell>
                                <TableCell>O.P.</TableCell>
                                <TableCell>Fecha Compra</TableCell>
                                <TableCell className="w-150">Nombre</TableCell>
                                <TableCell>Iva</TableCell>
                                <TableCell className="w-200">Pago</TableCell>
                                <TableCell className="w-200">Estado</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {data.map( (ops) => (
                            <TableRow  key={ ops.id }>
                                <TableCell>
                                {
                                    ( ops.activo )
                                    ? <IconButton 
                                        className='boton-estado trru'
                                        onClick={ () => handleTrueFalse(ops) }
                                    />
                                    : <IconButton 
                                        className='boton-estado fall'
                                        onClick={ () => handleTrueFalse(ops) }
                                    />
                                }
                                </TableCell>
                                <TableCell className="verop" onClick={ () => handleOpcionesOp(ops.id) }>
                                    { ops.op }
                                </TableCell>
                                <TableCell>{moment(ops.startOp ).format('ll')}</TableCell>
                                <TableCell>{ ops.infoPersonal?.nombre }</TableCell>
                                <TableCell>
                                    {( !ops.iva || ops.iva === 0 )
                                    ?  <Typography variant="span" color='error'>No Pago</Typography>
                                    :  `$${separadorMillares(parseInt(ops.iva))}`
                                    }
                                </TableCell>
                                <TableCell>
                                    <TextField select required size="small" name="pagoOp"
                                        value={ ops.pago } onChange={ (e) => handleUpdatePago(e, ops) }>
                                        {pagosOp.map( item => (
                                            <MenuItem key={item} value={ item }>{ item }</MenuItem>
                                        ))}
                                    </TextField>
                                </TableCell>

                                <TableCell>
                                    <TextField select required size="small" name="estadoOp"
                                        value={ ops.estado } onChange={ (e) => handleEstadoOp(e, ops) }>
                                        {estadosOp.map( item => (
                                            <MenuItem key={item} value={ item }>{ item }</MenuItem>
                                        ))}
                                    </TextField>
                                </TableCell>
                            </TableRow>
                            ))
                            }
                        </TableBody>

                    </Table>


                    <Stack sx={{ py:2, display:'flex', justifyContent:'center', alignItems:'center' }}>
                        <Pagination count={Math.ceil(totalOp / +paginado)} disabled={Math.ceil(totalOp / +paginado) === 1}
                            variant="outlined" page={page + 1} color="primary" 
                            onChange={ (_, newValor) => chagePage(newValor) } 
                        />
                    </Stack>

                </TableContainer>
            </Paper>

            : <MensajeAlertaDanger titulo={'No hay O.P. En Este Modulo'} />
            
            }
        </>

    )
}
