import { types } from "../types/types";


const initialState = {
    cheking: true,
    // uid: null,
    // nombre: null
};




export const authClienteReducer = ( state = initialState, action ) => {


    switch ( action.type ) {

        case types.authClienteChekingLogin:
            return {
                ...state,
                ...action.payload,
                cheking: false
            };

        case types.authClienteChekingFinish:
            return {
                ...state,
                cheking: false
            };

        case types.authClienteLogout:
            return {
                cheking: false,
            };
            
    
        default:
            return state;

    }
}
