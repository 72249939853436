
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import moment from "moment";





export const CotizacionDatosPersonales = () => {

    const fecha = moment().format('D/MM/YYYY');
    const cliente = JSON.parse(localStorage.getItem('cliente') ) || '';



    return (
        
        <>
        {
            cliente &&
            <TableContainer sx={{ my:5 }}>
                <Table sx={{ width:'100%' }} aria-label="customized table">

                    <TableHead >
                        <TableRow >
                            <TableCell>NIT/CEDULA</TableCell>
                            <TableCell>CLIENTE</TableCell>
                            <TableCell>CELULAR</TableCell>
                            <TableCell>FECHA</TableCell>
                            <TableCell>CIUDAD</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        <TableRow >
                            <TableCell>
                                <Typography  variant="span">{ cliente.cedula }</Typography >
                            </TableCell>
                            <TableCell className="cantidad-comprar">
                                <Typography variant="span">{ cliente.nombre }</Typography >
                            </TableCell>
                            <TableCell className="cantidad-comprar">
                                <Typography variant="span">{ cliente.movil }</Typography >
                            </TableCell>
                            <TableCell className="cantidad-comprar">
                                <Typography variant="span">{ fecha }</Typography >
                            </TableCell>
                            <TableCell className="cantidad-comprar">
                                <Typography variant="span">{ cliente.ciudad }</Typography >
                            </TableCell>
                        </TableRow>
                    </TableBody>

                </Table>
            </TableContainer>
        }
        </>



    )


    
}
