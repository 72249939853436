

import { Box, Button, Grid,  TextField } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';





export const BuscadorFechas = ({ start, end, handleChangeStart, handleChangeEnd, funcion }) => {



    const handleFuncion = (e) => {
        funcion(e);
    }




    return (
        
        <Grid container sx={{display:'flex', alignItems:'center', justifyContent:'center', my:3}} spacing={2}>

            <Grid item xs={ 12 } sm={6} md={4} >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label="Fecha de Inicio"
                        value={ start }
                        onChange={ handleChangeStart }
                        renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                </LocalizationProvider>
            </Grid>

            <Box flex={ 1 } />

            <Grid item xs={ 12 } sm={6} md={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label="Fecha Final"
                        value={ end }
                        onChange={ handleChangeEnd }
                        minDate={ start }
                        
                        renderInput={(params) => <TextField {...params} fullWidth /> }
                    />
                </LocalizationProvider>
            </Grid>

            <Box flex={ 1 } />


            <Grid item xs={ 12 } sm={6} md={3} my={3}>
                <Button variant="contained" color="secondary" fullWidth onClick={ handleFuncion }>
                    Consultar
                </Button>
            </Grid>

        </Grid>
       

    )




}
