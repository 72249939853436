import { types } from "../types/types";




const initialState = {
    categorias: [ ],
    activeCategoria: null
};





export const categoriaReducer = ( state = initialState, action ) => {

    switch ( action.type ) {

        case types.categoriaAddNew:
            return {
                ...state,
                categorias: [
                    ...state.categorias,
                    action.payload
                ]
            };

        case types.categoriaLista:
            return {
                ...state,
                categorias: [ ...action.payload ]
            };


        case types.categoriaDelete:
            return {
                ...state,
                categorias: state.categorias.filter(
                    p => ( p.id !== action.payload.id )
                ),
                activeCategoria: null
            };

        case types.categoriaActivo:
            return {
                ...state,
                activeCategoria: action.payload
            };
            
        case types.categoriaDeleteActivo:
            return {
                ...state,
                activeCategoria: null
            };

        case types.categoriaUpdate:
            return {
                ...state,
                categorias: state.categorias.map( 
                    e => ( e.id === action.payload.id ) ? action.payload : e
                )
            };

        default:
            return state;
    }

    

}
