
import { fetchConToken } from "../helpers/fetch";
import { types } from "../types/types";
import Swal from "sweetalert2";




const gastosLista = ( gastos ) => ({
    type: types.gastosLista,
    payload: gastos 
});



const abonosLista = ( abono ) => ({
    type: types.abonosLista,
    payload: abono 
});



const pagosLista = ( pagos ) => ({
    type: types.pagosInventarioLista,
    payload: pagos 
});




const gastosAddNew = ( gasto ) => ({
    type: types.gastosAddNew,
    payload: gasto
});  



const gastoActivo = ( gastos ) => ({
    type: types.gastosActivo,
    payload: gastos 
});



export const deleteGastoActivo = () => ({
    type: types.gastosActivo,
    payload: null 
});



export const limpiarListaGasto = ( ) => ({
    type: types.gastosLista,
    payload: [] 
});





export const gastosListaStart = (fechas) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken(`gastos/todos?start=${fechas.start}&end=${fechas.end}`);
            const body = await resp.json();
            if( body.ok ){
                dispatch( gastosLista(body.data) );
            }                
        } catch (error) {
            Swal.fire('Error', error, 'error');
        }
    }
}




export const gastoStartAddNew = ( gastos ) => {
    return async( dispatch ) =>{
        try {
            const resp = await fetchConToken( 'gastos/new', gastos, 'POST' );
            const body = await resp.json();

            if( body.ok ) {
                Swal.fire('Exitoso', 'Gasto Creado', 'success');
                dispatch( gastosAddNew(body.data) );
                return true;
            } 
        } catch (error) {
            Swal.fire('Error', error, 'error');
            return false;
        }
    }
}




export const gastosPorId = ( id ) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken(`gastos/gasto/${id}`);
            const body = await resp.json();
            if( body.ok ){
                dispatch( gastoActivo(body.data) );
            }                
        } catch (error) {
            console.log(error);
            Swal.fire('Error', error, 'error');
        }
    }
}   



export const busquedaEnComparativa = ( fechas ) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken(`gastos/comparativa?start=${fechas.start}&end=${fechas.end}`);
            const body = await resp.json();

            if( body.ok ){
                dispatch( abonosLista(body.abonos) );
                dispatch( gastosLista(body.gastos) );
                dispatch( pagosLista(body.pagos) );
            }else {
                Swal.fire('Error', body.msg, 'error');
                dispatch( abonosLista([]) );
            }
        } catch (error) {
            console.log(error);
        }
    }
}












