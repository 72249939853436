
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { MensajeAlertaDanger } from "../../components";
import { useSelector } from "react-redux";
import { separadorMillares } from "../../../hooks";





export const TablaAbonosCliente = () => {

    const { deudas } = useSelector(state => state.deudaCliente);


    return (

        <Grid container textAlign='center' my={4}>

            <Grid item xs={12} mt={5} >
              <Typography variant="h2" mb={2}>Abonos</Typography>
            </Grid>

            {

              ( deudas.length > 0 ) 
              ?<TableContainer sx={{ mb:5 }}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">

                      <TableHead >
                          <TableRow >
                            <TableCell>FECHA</TableCell>
                            <TableCell>INICIAL</TableCell>
                            <TableCell>ABONO</TableCell>
                            <TableCell>FINAL</TableCell>
                            <TableCell className="w-200">OBSERVACIÓN</TableCell>
                          </TableRow>
                      </TableHead>

                        <TableBody>
                        {deudas.map( a => (
                            <TableRow key={a.id} >

                                <TableCell >
                                    <Typography variant="span">{ a.createdAt }</Typography>
                                </TableCell>

                                <TableCell >
                                    <Typography variant="span">{ `$${separadorMillares(a.deudaInicial)}` }</Typography>
                                </TableCell>

                                <TableCell >
                                    <Typography variant="span">{ `$${separadorMillares(a.abono)}` }</Typography>
                                </TableCell>

                                <TableCell >
                                    <Typography variant="span">{ `$${separadorMillares(a.deudaFinal)}` }</Typography>
                                </TableCell>

                                <TableCell >
                                    {
                                    (a.observacion)
                                    ? <Typography variant="span" >{ a.observacion }</Typography>
                                    : <Typography variant="span" >No Hay.</Typography>
                                    }
                                    
                                </TableCell>

                            </TableRow>
                            ))
                        }
                        </TableBody>
                    </Table>
                </TableContainer>
            
              : <MensajeAlertaDanger titulo={'No Hay Abonos A Esta O.P.'} />
            }

        </Grid>



    )

    
}
