
import { useNavigate } from "react-router-dom";

import { Box, Grid, IconButton, Typography } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';







export const LayoutReturnArchivados = ({ children, titulo = 'No hay Datos', url= '', url2 = '' }) => {


    const navigate = useNavigate();



    const handleReturn = () => {
        navigate(url);
    }


    const handleArchivados = () => {
        navigate(url2);
    }


    return (


        <>
        
            <Grid container direccion='row' alignItems='center'>

                <IconButton
                    sx={{
                        color: '#fff',
                        backgroundColor: 'secondary.main',
                        ':hover': { backgroundColor: 'secondary.main', opacity: 0.3 },
                    }}
                    onClick={ handleReturn } 
                >
                    <ArrowBackIcon />
                </IconButton>

                <Box flex={ 1 } />

                <Typography variant='h2'>{ titulo }</Typography>

                <Box flex={ 1 } />

                <IconButton
                    sx={{
                        color: '#fff',
                        backgroundColor: 'secondary.main',
                        ':hover': { backgroundColor: 'secondary.main', opacity: 0.3 },
                    }}
                    onClick={ handleArchivados } 
                >
                    <SaveIcon />
                </IconButton>

            </Grid>

            <Grid container alignItems='center' justifyContent='center' >


                { children }


            </Grid>
        
        
        
        </>

        
    )
}
