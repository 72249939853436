
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Grid, MenuItem, TextField } from '@mui/material';
import { AutocompleteParaStock } from '../../components';
import { superListaStart } from '../../../actions/usuario';
import { closeStartFormDos, descontarInventario } from '../../../actions';

import Swal from 'sweetalert2';





export const FormularioDescontarStock = () => {


    const dispatch = useDispatch();
    const { inventarios } = useSelector( state => state.inventario );
    const { usuarios } = useSelector(state => state.user);


    const [ formInventario, setFormInventario ] = useState({producto: "", cantidad: "", recibe: "", stock: ""});
    const { producto, cantidad, recibe } = formInventario;



    useEffect( () => {
        dispatch( superListaStart({estado: true}) );
    }, [dispatch]);




    const handleAutocompleteChange = (selectedOption) => {
        console.log({selectedOption})
        setFormInventario({
            ...formInventario, stock: selectedOption._id,
            producto: selectedOption ? selectedOption.producto.id : "",
        });
    }


    const handleInputChange = ({ target }) => {
        setFormInventario({
            ...formInventario,
            [target.name]: target.value
        });
    }



    const handleCrearInventario = async(e) => {
        e.preventDefault();

        if( [producto, cantidad, recibe].includes('') ) {
            return Swal.fire('Error', 'Todos Los Campos Son Obligatorios', 'error');
        }

        const resp = await Swal.fire({
            title: 'Descontar Stock',
            text: `¿Esta Seguro De Hacer Este Descuento?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si' 
        });

        if(resp.value){
            const resp = await dispatch(descontarInventario(formInventario));
            if( resp ) {
                setFormInventario({ producto: "", cantidad: "", recibe: "" });
                dispatch( closeStartFormDos() );
            }
        }
    }




    return (

        <form  onSubmit={ handleCrearInventario }>
            <Grid container spacing={ 2 }>

                <Grid item xs={ 12 } sm={ 12 } mt={ 2 }>
                    <AutocompleteParaStock
                        label="Producto"
                        type='text'
                        fullWidth
                        placeholder='Producto'
                        name="producto"
                        value={producto}
                        options={inventarios}
                        onChange={handleAutocompleteChange}
                    />
                </Grid>

                <Grid item xs={ 12 } sm={ 12 } mt={ 2 }>
                    <TextField
                        label="Cantidad"
                        type='number'
                        fullWidth
                        placeholder='Cantidad'
                        name="cantidad"
                        value={ cantidad }
                        onChange={ handleInputChange }
                    >
                    </TextField>
                </Grid>

                <Grid item xs={ 12 } sm={12} mt={ 2 }>
                    <TextField
                        select
                        label="Recibe"
                        fullWidth
                        name="recibe"
                        value={ recibe }
                        onChange={ handleInputChange }  
                    >    
                        <MenuItem value=''>
                            Seleccione Usuario
                        </MenuItem>
                        {
                        usuarios.map( (m) => (
                            <MenuItem value={ m.id } key={ m.id }>        
                                { m.nombre }
                            </MenuItem>
                        ))
                        }
                    </TextField>
                </Grid>
                

                <Grid container  sx={{ mt:2 }}  direccion='column' justifyContent='center'  >
                    <Grid item xs={ 12 } sm={ 8 } display='flex' justifyContent='center'>
                        <Button variant='contained' fullWidth type='submit'>
                            Descontar de Inventario
                        </Button>
                    </Grid>
                </Grid>

            </Grid>

        </form>

    )
}
