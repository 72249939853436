
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Grid } from "@mui/material"
import { TrayectoriaPedidos } from "../../dashboard/components";
import { LayoutReturn } from "../layouts";
import { opPorIDConsultaCliente } from "../../../actions/opCliente";
import { TablaDetallesItemsOPCliente } from "../components";
import { carritoListaPorOPCliente } from "../../../actions/items";






export const DetallesOpCliente = () => {


    const { id } = useParams();
    const dispatch = useDispatch();
    const { activeOP } = useSelector( state => state.op );




    useEffect(() => {
        if( id ) {
            dispatch(opPorIDConsultaCliente( id ));
        }
    }, [id]);


    useEffect(() => {
        if( id ) {
            dispatch(carritoListaPorOPCliente(id));
        }
    }, [id] );


    return (

        <LayoutReturn titulo={`Detalles de OP: ${ (activeOP) ? activeOP.op : '....'}`} url='/mis-pedidos'>

            <Grid container my={10} justifyContent='center'>
                <TrayectoriaPedidos />
            </Grid>



            <TablaDetallesItemsOPCliente />


        </LayoutReturn>

        
    )


}
