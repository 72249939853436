
import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { obtenerPublicidad } from '../../../actions/producto';
import { SlideShowScreen } from '../../ui/SlideShowScreen';
import { CarrouselScreen } from '../../ui/CarrouselScreen';







export const HomeScreen = () => {


    const dispatch = useDispatch();
    const { servicio } = useSelector( state => state.producto );
    const { personalizado } = useSelector( state => state.producto );
    const { publicidad } = useSelector( state => state.producto );



    
    useEffect(() => {
        dispatch( obtenerPublicidad() );
    }, [dispatch]);




    return (

        <>
            <SlideShowScreen />

            <CarrouselScreen titulo={ 'Publicidad' }  producto={ publicidad }/>

            <CarrouselScreen titulo={ 'Servicio Técnico' } producto={ servicio } />

            <CarrouselScreen titulo={ 'Personalizados' } producto={ personalizado } />
        </>

    )

}
