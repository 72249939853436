
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Button, Grid, MenuItem, TextField, Typography, Box, Backdrop, Modal } from '@mui/material';

import { closeModalAbonosOp } from '../../../actions';

import { NumberFormatCustom } from '../../../hooks';
import { abonoStartAddNew } from '../../../actions/abono';
import Swal from 'sweetalert2';



export const ModalAbonosOP = () => {


    const { id } = useParams();
    const dispatch = useDispatch();
    const { modalAbonoOp }  = useSelector( state => state.modal );
    const millares = NumberFormatCustom;


    useEffect(() => {
        if( id ) {
            setFormAbono({
                ...formAbono,
                op: id
            });
        }
    }, [id]);

    const [ formAbono, setFormAbono ] = useState({
        abono: "",
        medio: "",
        observacion: "",
        op: id,
    });
    const { abono, medio, observacion } = formAbono;


    const handleInputChange = ({ target }) => {
        setFormAbono({
            ...formAbono,
            [target.name]: target.value
        });
    }


    const handleCrearAbono = (e) => {
        e.preventDefault();
        if( [medio, abono].includes('') ) {
            cerrarModal();
            return Swal.fire('Error', 'Abono y Medio de Pago Son Campos Obligatorios', 'error');
        }

        dispatch(abonoStartAddNew({
            ...formAbono,
            // start: moment().format()
        }));
        setFormAbono({
            ...formAbono,
            abono: "",
            medio: "",
            observacion: "",
        });
        cerrarModal();
    }



    const cerrarModal = () => {
        dispatch( closeModalAbonosOp() );
    }



    return (


        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={ modalAbonoOp }
            onClose={ cerrarModal }
            closeAfterTransition
            BackdropComponent={ Backdrop }
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Box className="style-modal">

                <Typography align='center' variant='h1' sx={{ mb:2 }} >Crear Abono Para OP</Typography>

                <form  onSubmit={ handleCrearAbono } >
                    <Grid container spacing={ 2 }>

                        <Grid item xs={ 12 } sm={ 12 } mt={ 2 }>
                            <TextField required
                                label="Abono"
                                fullWidth
                                placeholder='Abono'
                                name="abono"
                                value={ abono }
                                onChange={ handleInputChange }
                                InputProps={{
                                    inputComponent: millares,
                                }}
                            >
                            </TextField>
                        </Grid>

                        <Grid item xs={ 12 } sm={12} mt={ 2 }>
                            <TextField required
                                select
                                label="Medio de Pago"
                                fullWidth
                                name="medio"
                                value={ medio }
                                onChange={ handleInputChange }  
                            >    
                                <MenuItem value=''>
                                    Seleccione Medio de Pago
                                </MenuItem>
                                <MenuItem value='Nequi'>        
                                    Nequi
                                </MenuItem>
                                <MenuItem value='Daviplata'>
                                    Daviplata
                                </MenuItem>
                                <MenuItem value='Bancolombia'>
                                    Bancolombia
                                </MenuItem>
                                <MenuItem value='Daviviendad'>
                                    Daviviendad
                                </MenuItem>
                                <MenuItem value='Efectivo'>
                                    Efectivo
                                </MenuItem>
                            </TextField>
                        </Grid>

                        <Grid item xs={ 12 } sm={ 12 } mt={ 2 }>
                            <TextField
                                label="Observacion"
                                fullWidth
                                placeholder="Observacion"
                                multiline
                                name="observacion"
                                value={ observacion }
                                onChange={ handleInputChange } 
                            />
                        </Grid>

                        <Grid container  sx={{ mt:2 }}  direccion='column' justifyContent='center'  >
                            <Grid item xs={ 12 } sm={ 8 } display='flex' justifyContent='center'>
                                <Button variant='contained' fullWidth type='submit'>
                                    Crear Abono
                                </Button>
                            </Grid>
                        </Grid>

                    </Grid>

                </form>
            </Box>
        </Modal>

    )

}
