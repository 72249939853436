
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Grid } from "@mui/material";
import { categoriaListaSinTerminados } from "../../../actions/categoria";
import { BuscarCategoriaVenta } from "../components";
import { CardsCategorias } from "../../productos/components";
import { MensajeCargandoData } from "../../components";





export const CategoriaScreenCliente = () => {


    const dispatch = useDispatch();
    const { categorias } = useSelector( state => state.categoria );


    useEffect( () => {
        dispatch( categoriaListaSinTerminados() );
    }, [ dispatch ] );




    return (

        <Grid>
            <BuscarCategoriaVenta />

            <Grid container spacing={4} sx={{display:'flex', justifyContent:'center'}}>
            {(categorias.length > 0) 
                ? categorias.map( categoria => (
                    <CardsCategorias
                        url='/filtro'
                        key={ categoria.id } 
                        { ...categoria }
                    />
                ))
                : <MensajeCargandoData titulo="Cargando Datos De Categoria..." />
            }
            </Grid>
        </Grid>

    )
}


