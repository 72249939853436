

import { types } from "../types/types";






// Modal de Formulario Uno
export const openStartForm = () => ({
    type: types.openModalForm
});

export const closeStartForm = () => ({
    type: types.closeModalForm
});


// Modal de Formulario Dos
export const openStartFormDos = () => ({
    type: types.openModalFormDos
});

export const closeStartFormDos = () => ({
    type: types.closeModalFormDos
});





















// Modal de abonos de OP Admin y distri
export const openModalAbonosOp = () => ({
    type: types.openModalAbonoOp
});

export const closeModalAbonosOp = () => ({
    type: types.closeModalAbonoOp
});


export const openModalAbonosOpCliente = () => ({
    type: types.openModalAbonoOpCliente
});


export const closeModalAbonosOpCliente = () => ({
    type: types.closeModalAbonoOpCliente
});




// Modal Imagen Pago de OP
export const openModalImagen = () => ({
    type: types.openModalImagen
});

export const closeModalImagen = () => ({
    type: types.closeModalImagen
});


// Modal de Crear Cliente
export const openCrearCliente = () => ({
    type: types.openCrearCliente
});

export const closeCrearCliente = () => ({
    type: types.closeCrearCliente
});


// Modal Imagen Abonos
export const openModalAbonos = () => ({
    type: types.openModalAbonos
});




// Modal de Unidad Medida
export const verCantidad = () => ({
    type: types.mostrarCantidad
});

export const noVerCantidad = () => ({
    type: types.quitarCantidad
});


















// Modal de Carrito
export const openModal = () => ({
    type: types.openModal
});

export const closeModalCarrito = () => ({
    type: types.closeModal
});


// Modal de Carrito Cliente
export const openModalCliente = () => ({
    type: types.openModalCliente
});

export const closeModalCliente = () => ({
    type: types.closeModalCliente
});





export const closeModalAbonos = () => ({
    type: types.closeModalAbonos
});



