
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Grid } from '@mui/material';
import { LayoutListados } from "../../layouts"
import { BusquedaFechaComparativa, CardsComparativa } from '../components';
import { sumarTotalOps } from '../../../helpers/sumaDeArrayItems';






export const ComparativaScreen = () => {


    const { gastos } = useSelector( state => state.gastos );
    const { abonos } = useSelector( state => state.abono );
    const { pagosinventario } = useSelector(state => state.pagoinv);
    
    
    const [totalGasto, setTotalGasto] = useState(0);
    const [totalPagos, setTotalPagos] = useState(0);
    const [totalAbono, setTotalAbono] = useState(0);
    const [totalVenta, setTotalVenta] = useState(0);

    


    useEffect(() => {
        if(gastos){
            const valor = gastos.reduce( (t, a) => {
                a.items.forEach( item => { t += item.total });
                return t;
            }, 0);
            setTotalGasto(valor);
        }
    }, [gastos]);




    useEffect(() => {
        if(pagosinventario){
            const valor = pagosinventario.reduce( (t, a) => {
                a.items.forEach( item => {
                    t += ((item.porcentaje * item.precioUnidad * item.cantidad / 100) + item.precioUnidad * item.cantidad);
                });
                return t;
            }, 0);
            setTotalPagos(valor);
        }
    }, [pagosinventario]);




    useEffect(() => {
        if(abonos){
            const total = abonos.reduce( (total, venta) => total + venta.abono, 0 ); 
            setTotalAbono(total);
        }
    }, [abonos]);



    useEffect(() => {
        if( abonos ) {
            const totalTodo = sumarTotalOps(abonos);
            setTotalVenta(totalTodo);
        }
    }, [abonos]);



    
    

    return (

        <LayoutListados title='Comparativa de caja'>

            <BusquedaFechaComparativa />


            <Grid container direccion='row' spacing={2}>


                <CardsComparativa color='#4caf50' 
                    url=''
                    titulo='Venta Bruta'
                    titulo2='Venta'
                    titulo3='Cantidad Abonos'
                    total={totalVenta}
                    cantidad={abonos.length}
                />


                <CardsComparativa color='#ed028b' 
                    url='/super/caja'
                    titulo='Recaudo Abonos'
                    titulo2='Recaudo'
                    titulo3='Cantidad Abonos'
                    total={totalAbono}
                    cantidad={abonos.length}
                />

                <CardsComparativa color='orange' 
                    url=''
                    titulo='Saldo de Abonos'
                    titulo2='Saldo'
                    titulo3='Cantidad Abonos'
                    total={totalVenta- totalAbono}
                    cantidad={abonos.length}
                />


                <CardsComparativa color='#55EFF4' 
                    url=''
                    titulo='Rentabilidad'
                    titulo2='Rentabilidad'
                    titulo3='Cantidad de Todo'
                    total={totalAbono - (totalGasto + totalPagos)}
                    cantidad={abonos.length + gastos.length + pagosinventario.length}
                />


                <CardsComparativa color='#f50057' 
                    url='/super/gastos'
                    titulo='Total Gastos'
                    titulo2='Gastos'
                    titulo3='Cantidad Gastos'
                    total={totalGasto}
                    cantidad={gastos.length}
                />

                <CardsComparativa color='#f50057' 
                    url='/super/pagos'
                    titulo='Total Pagos'
                    titulo2='Pagos'
                    titulo3='Cantidad Pagos'
                    total={totalPagos}
                    cantidad={pagosinventario.length}
                />

            </Grid>

        </LayoutListados>

    )

    
}
