
import { useEffect, useState } from "react";

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useSelector } from "react-redux";
import { separadorMillares } from "../../../hooks/useMillares";
import { MensajeAlertaDanger } from "../../components";
import { sumarTotalOps } from "../../../helpers/sumaDeArrayItems";



export const TablaCajaAbonos = () => {


    const { abonos } = useSelector( state => state.abono );
    const [ventas, setVentas] = useState(0);
    const [total, setTotal] = useState(0);



    useEffect(() => {
        if( abonos ) {
            const totalVenta = abonos.reduce( (total, venta) => total + venta.abono, 0 ); 
            setVentas( totalVenta );
        }
    }, [abonos]);



    useEffect(() => {
        if( abonos ) {
            const totalTodo = sumarTotalOps(abonos);
            setTotal(totalTodo);
        }
    }, [abonos]);


    return (
        <>
        {
            (abonos.length > 0) &&
            <TableContainer >
                <Table sx={{ minWidth: 700, }} aria-label="customized table">
                    <TableHead >
                        <TableRow>
                            <TableCell sx={{ width: 100 }}>Abonos</TableCell>
                            <TableCell>Total Venta</TableCell>
                            <TableCell>Total Abonos</TableCell>
                            <TableCell>Saldo Total</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        <TableRow >
                            <TableCell>{ abonos.length }</TableCell>
                            <TableCell>{`$${ separadorMillares(total)}`}</TableCell>
                            <TableCell>{ `$${ separadorMillares(ventas)}` }</TableCell>
                            <TableCell>{`$${ separadorMillares(total - ventas)}`}</TableCell>
                        </TableRow>
                    </TableBody>

                </Table>
            </TableContainer>
        }

        { (abonos.length > 0)
            ?<TableContainer sx={{ mt:5 }}>
                <Table sx={{ minWidth: 700, }} aria-label="customized table">

                    <TableHead >
                        <TableRow>
                            <TableCell>Fecha</TableCell>
                            <TableCell>O.P.</TableCell>
                            <TableCell>Medio de Pago</TableCell>
                            <TableCell>Total de OP</TableCell>
                            <TableCell>Iva</TableCell>
                            <TableCell>Abono</TableCell>
                            <TableCell>Saldo</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                    { abonos.map( ( a ) => (
                        <TableRow  key={ a.id}>
                            <TableCell>{ a.createdAt }</TableCell>
                            <TableCell>{ a.op.op }</TableCell>
                            <TableCell>{ a.medio }</TableCell>
                            <TableCell>{ `$${ separadorMillares(a.op.totalCliente)}` }</TableCell>
                            <TableCell>{ `$${ separadorMillares(a.op.iva)}` }</TableCell>
                            <TableCell>{ `$${ separadorMillares(a.abono)}` }</TableCell>
                            <TableCell>{ `$${ separadorMillares((a.deudaFinal))}` }</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>

            :<MensajeAlertaDanger titulo="No Existen Datos De Abonos" />
        }
        </>
    )
}
