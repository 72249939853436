

import { types } from "../types/types";




const initialState = {
    iva: JSON.parse(localStorage.getItem('iva')) || false,
    view: localStorage.getItem('view') || 'cliente',
    isSidebarOpen: true,
}


export const ivaReducer = ( state = initialState, action ) => {

    switch (action.type) {

        
        case types.ivaTrue:
            return {
                ...state,
                iva: action.payload
            };

        case types.viewRol:
            return {
                ...state,
                view: action.payload
            }; 

        case types.changeSidebar:
            return {
                ...state,
                isSidebarOpen: action.payload
            }; 


        default:
            return state;
    }

}