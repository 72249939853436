
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { HeaderDeCheckout } from "../layouts";
import { Button, Grid, TextField } from "@mui/material";
import Swal from "sweetalert2";




const initCliente = {
    uid: "",
    nombre: "",
    email: "",
    cedula: "",
    ciudad: "",
    direccion: "",
    movil: "",
}



export const DistriPropioCheckout = () => {
    

    const { usuario } = useSelector( state => state.admin );
    const navigate = useNavigate();


    const [ formCliente, setFormCliente ] = useState( initCliente );
    const { nombre, cedula, ciudad, direccion, movil } = formCliente;
    const handleInputChange = ({ target }) => {
        setFormCliente({
            ...formCliente,
            [target.name]: target.value
        });
    }


    useEffect(() => {
        if( usuario ) {
            setFormCliente({
                ...formCliente,
                nombre: usuario.nombre,
                email: usuario.email || 'No Registra',
                ciudad: usuario.ciudad,
                direccion: usuario.direccion,
                movil: usuario.movil,
                cedula: usuario.cedula,
                uid: usuario.id
            });
        }
    }, [usuario] );


    const  handleInfoCliente = ( e ) => {
        e.preventDefault();
        if( nombre.trim().length <= 0 ) {
            Swal('Error', 'El Nombre Es Obligatorio', 'error');
            return; 
        }
        if( direccion.trim().length <= 0 ) {
            Swal('Error', 'La Direccion Es Obligatoria', 'error');
            return; 
        }
        localStorage.setItem('cliente', JSON.stringify(formCliente) );
        navigate('/super/comprar-ahora');
    }



    return (


            <HeaderDeCheckout title={'Mi Informacion Personal'}>


                <form className="formulario-total mt-5" onSubmit={ handleInfoCliente } autoComplete="off" >
                    <Grid container spacing={ 4 }>

                        <Grid item xs={12} sm={6}  mt={ 2 } >
                            <TextField required
                                variant="filled"
                                label='Nombre'
                                type='text'
                                placeholder='Nombre'
                                fullWidth
                                name="nombre"
                                value={ nombre }
                                onChange={ handleInputChange } 
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}  mt={ 2 } >
                            <TextField required
                                variant="filled"
                                label='Cedula'
                                type='text'
                                placeholder='Cedula'
                                fullWidth
                                name="cedula"
                                value={ cedula }
                                onChange={ handleInputChange } 
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}  mt={ 2 } >
                            <TextField required
                                variant="filled"
                                label='Ciudad'
                                type='text'
                                placeholder='Ciudad'
                                fullWidth
                                name="ciudad"
                                value={ ciudad }
                                onChange={ handleInputChange } 
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}  mt={ 2 } >
                            <TextField required
                                variant="filled"
                                label='Dirección'
                                type='text'
                                placeholder='Dirección'
                                fullWidth
                                name="direccion"
                                value={ direccion }
                                onChange={ handleInputChange }
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}  mt={ 2 } >
                            <TextField required
                                variant="filled"
                                label='Movil'
                                type='text'
                                placeholder='Movil'
                                fullWidth
                                name="movil"
                                value={ movil }
                                onChange={ handleInputChange }
                            />
                        </Grid>

                        <Grid container 
                            sx={{ my:7 }} 
                            direccion='column'
                            justifyContent='center' 
                        >
                            <Grid item xs={ 12 } sm={ 8 } >
                                <Button variant='contained' color="secondary" fullWidth type='submit'>
                                    Revisar Pedido
                                </Button>
                            </Grid>
                        </Grid>

                    </Grid>
                </form>

            </HeaderDeCheckout>
        
    )

}
