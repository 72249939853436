
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { Button, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import { carritoListaPorOP } from "../../../actions/items";
import { LayoutReturn } from "../../layouts";
import { ItemsProduccionAndEditar } from "../components";




export const ItemsDeOpOpciones = () => {


    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { items } = useSelector( state => state.carrito );



    useEffect(() => {
        if( id ) {
            dispatch( carritoListaPorOP(id) );
        }
    }, [id] );


    



    return (

        
        <LayoutReturn titulo={'Informacion de Items de Producción'} url={`/super/detalles-op/${ id }`}>

            <ItemsProduccionAndEditar ver />

        </LayoutReturn>
    )
}
