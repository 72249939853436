


import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom";

// import { BusquedaPorItems } from "./BusquedaPorItems";
import { Button, IconButton, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import SignpostIcon from '@mui/icons-material/Signpost';


import { carritoUpdateArea, eliminarItemsDeOP } from "../../../actions/items";
import { trasaStartAddNew } from "../../../actions/trasabilidad";
import Swal from "sweetalert2";
import moment from "moment";
import { MensajeAlertaDanger } from "../../components";





export const TablaTodosItems = () => {


    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { items } = useSelector( state => state.carrito );
    const { uid } = useSelector( state => state.admin );



    // Paginacion de Tabla
    const [page, setPage] = useState(0);
    const [ rowsPerPage ] = useState(30);
    const handleChangePage = (event, newPage) => {
        setPage( newPage );
    };

    


    const handleAreaItem = ( e, item ) => {
        item.area = e.target.value;
        Swal.fire({
            title: 'Actualizar Area',
            text: `¿Esta Seguro De Actualizar el Item?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si, Actualizar' 
        }).then( ( result ) => {
            if(result.value){
                dispatch( carritoUpdateArea( item ) );
                dispatch(trasaStartAddNew({
                    item: item.id,
                    colaborador: uid,
                    start: moment(),
                    antes: 'Cambio hecho por el Admin',
                    ahora: item.area
                }));
            }
        })
    }



    const handleColaborador = (item) => {
        navigate(`/super/registro-cola/${ item.id }`);
    }



    const handleEliminarItems = (item) => {
        Swal.fire({
            title: 'Eliminar Items',
            text: `¿Esta Seguro De Eliminar Esta Items?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Obvio Si' 
        }).then( ( result ) => {
            if(result.value){
                dispatch( eliminarItemsDeOP(item) );
            }
        })
    }



    return (

        <>
        {( items.length > 0 )
            ?<Paper sx={{ width: '100%' }}>
                <TableContainer sx={{ my:'15px' }}>
                    <Table sx={{ minWidth: 700, }} aria-label="customized table">

                        <TableHead >
                            <TableRow>
                                <TableCell className="w-150">O.P.</TableCell>
                                <TableCell className="w-150">Fecha</TableCell>
                                <TableCell className="w-300">Producto</TableCell>
                                <TableCell className="w-300" >Nombre</TableCell>
                                <TableCell className="w-300">Area</TableCell>
                                <TableCell>Colaborador</TableCell>
                                <TableCell>Opciones</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {items
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map( item => (
                                <TableRow key={ item.id } >
                                    <TableCell>{ item.op?.op }</TableCell>
                                    <TableCell>{ moment(item.op?.startOp).format('ll') }</TableCell>
                                    <TableCell>{ item.producto?.nombre }</TableCell>
                                    <TableCell>{ item.op?.infoPersonal?.nombre }</TableCell>
                                    <TableCell>
                                        <TextField
                                            select required
                                            size="small"
                                            name="areaOp"
                                                value={ item.area }
                                                onChange={ (e) => handleAreaItem(e, item) }  
                                        >
                                            <MenuItem value="Diseño">Diseño</MenuItem>
                                            <MenuItem value="Pendiente Aprobacion">Pendiente Por Aprobación</MenuItem>
                                            <MenuItem value="Externos">Externos</MenuItem>
                                            <MenuItem value="Corte Laser">Corte Laser</MenuItem>
                                            <MenuItem value="Bordado">Bordado</MenuItem>
                                            <MenuItem value="Impresion Laminado">Impresion y Laminado</MenuItem>
                                            <MenuItem value="Impresion">Impresion</MenuItem>
                                            <MenuItem value="Tubos Plasticos">Tubos Plasticos</MenuItem>
                                            <MenuItem value="Tubos Metalicos">Tubos Metalicos</MenuItem>
                                            <MenuItem value="Arandelas">Arandelas</MenuItem>
                                            <MenuItem value="Terminado">Terminado</MenuItem>
                                            <MenuItem value="Entregado">Entregado</MenuItem>
                                        </TextField>
                                    </TableCell>

                                    <TableCell>
                                    {
                                        ( !item.colaborador )
                                        ? <Typography variant="span" color="red" >No Tiene</Typography>
                                        : <Typography variant="span">{ item.colaborador.nombre }</Typography>
                                    }
                                    </TableCell>

                                    <TableCell>
                                        <Tooltip title="Trasabilidad de Item" placement="top">
                                            <IconButton className="icon-boton"  onClick={ () => handleColaborador(item) } >
                                                <SignpostIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>

                                        <Tooltip title="Eliminar Item" placement="top">
                                            <IconButton className="icon-boton"  onClick={ () => handleEliminarItems(item) }  >
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))
                            }
                        </TableBody>

                    </Table>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[ 30 ]}
                    component="div"
                    count={ items.length }
                    rowsPerPage={ rowsPerPage }
                    page={ page }
                    onPageChange={ handleChangePage }
                />

            </Paper>

            : <MensajeAlertaDanger titulo='No Hay Items Disponibles' />

            }
        </>


    )

}
