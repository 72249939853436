import Swal from "sweetalert2";
import { fetchConToken } from "../helpers/fetch";
import { types } from "../types/types";





export const categoriasStartAddNew = ( categoria ) => {
    return async( dispatch ) =>{

        try {

            const resp = await fetchConToken( 'categoria/new', categoria, 'POST' );
            const body = await resp.json();


            if( body.ok ) {

                Swal.fire('Exitoso', 'Categoria Creada', 'success');
                categoria.id = body.categoria.id;

                dispatch( categoriaAddNew( categoria ) );

            } else {
                Swal.fire('Error', body.msg, 'error');
            } 
            
        } catch (error) {
            console.log(error);
        }
    }
}

const categoriaAddNew = ( categoria ) => ({
    type: types.categoriaAddNew,
    payload: categoria
});




export const categoriaListaStart = () => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken('categoria/todos');
            const body = await resp.json();

            if( body.ok ){
                const categoria = body.categorias;
                dispatch( categoriaLista(categoria) );
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}

const categoriaLista = ( categoria ) => ({
    type: types.categoriaLista,
    payload: categoria 
});



export const categoriaListaSinTerminados = () => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken('categoria/sin-terminado');
            const body = await resp.json();

            if( body.ok ){
                const categoria = body.categorias;
                dispatch( categoriaLista(categoria) );
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}




export const categoriaStartDelete = (categoria) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken(`categoria/${ categoria.id }`, {}, 'DELETE');
            const body = await resp.json();

            if( body.ok ){
                dispatch( categoriaDelete(categoria) );
                Swal.fire('Exitoso', 'Categoria Eliminada', 'success');
            }else {
                Swal.fire('Error', body.msg, 'error');
            }

            
        } catch (error) {
            console.log(error);
        }

    }
}

const categoriaDelete = ( categoria ) => ({ 
    type: types.categoriaDelete,
    payload: categoria
});



export const categoriaPorID = ( id ) => {
    return async(dispatch) => {

        try {

            const resp = await fetchConToken(`categoria/categoria/${ id }`);
            const body = await resp.json();

            if( body.ok ){
                dispatch( categoriaActivo(body.categoria) );
            }else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}

// Activar Categoria en el Redux
const categoriaActivo = ( categoria ) => ({
    type: types.categoriaActivo,
    payload: categoria
});

export const categoriaDeleteActivo = () => ({ type: types.categoriaDeleteActivo });




export const categoriaUpdateStart = (categoria) => {
    return async( dispatch ) => {

        try {

            const resp = await fetchConToken(`categoria/${ categoria.id }`, categoria, 'PUT');
            const body = await resp.json();

            if( body.ok ) {
                dispatch( categoriaUpdate(categoria) );
                Swal.fire('Exitoso', 'Categoria Actalizada', 'success');
            } else {
                Swal.fire('Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log(error);
        }

    }
}


const categoriaUpdate = ( categoria ) => ({
    type: types.categoriaUpdate,
    payload: categoria
});