
import { useSelector } from "react-redux";

import { Grid } from "@mui/material"

import { TrayectoriaPedidos } from "../../dashboard/components"
import { LayoutSoloTitulo } from "../layouts";
import { BuscarORastrearOP, TablaDeOp } from "../components";
import { MensajeAlertaDanger } from "../../components";




export const RastrearOP = () => {


    const { activeOP } = useSelector( state => state.op );



    return (

            <LayoutSoloTitulo titulo='Buscar O.P.' url='/'>

                <BuscarORastrearOP />

                {
                (activeOP) 
                ?<>
                    <Grid container my={10} justifyContent='center'>
                        <TrayectoriaPedidos />
                    </Grid>

                    <TablaDeOp />
                </>

                :<MensajeAlertaDanger titulo='Por Favor Anote El Numero de Su O.P.' />

                }
            
            </LayoutSoloTitulo>


    )


}
