
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { Grid, TextField } from "@mui/material";
import { carritoPorTrabajar } from "../../../actions/items";
import { busquedaDeItemsPorOpProduccion } from "../../../actions/busquedas";






export const BuscadorOpDisponibles = () => {


    const dispatch = useDispatch();
    const debounceRef = useRef();
    const [ buscartipo, setBuscartipo ] = useState('');


    // const hanldeInputChange = ({ target }) => {
    //     setBuscartipo( target.value );
    //     if( target.value.trim().length > 0 ){
    //         dispatch( busquedaDeItemsPorOpProduccion(target.value) );
    //     }else {
    //         dispatch( carritoPorTrabajar() );
    //     }
    // }



    const hanldeInputChange = ({ target }) => {
        setBuscartipo(target.value);
        if( debounceRef.current )
            clearTimeout( debounceRef.current );

        debounceRef.current = setTimeout( () => {
            if( target.value.trim().length > 0 ){
                dispatch( busquedaDeItemsPorOpProduccion(target.value) );
            }else {
                dispatch( carritoPorTrabajar() );
            }
        }, 600);
    }



    return (

        <>
        <Grid container direccion='row' alignItems='center' my={3}>
            <Grid item xs={ 12 } sm={ 6 }>
                <TextField  
                    autoComplete="off"
                    label="Buscar Items....." 
                    variant="filled" 
                    fullWidth
                    color="secondary"
                    name="buscartipo"
                    value={ buscartipo }
                    onChange={ hanldeInputChange }
                />
            </Grid>
        </Grid>
        </>
    )

}
