
import { types } from "../types/types";



const initialState = {
    cotizaciones: [],
    activeCotizacion: null,
};



export const cotizacionReducer = ( state = initialState, action ) => {

    switch ( action.type ) {

        case types.listadoCotizacion:
            return {
                ...state,
                cotizaciones: [ ...action.payload ]
            };
            


        case types.activeCotizacion:
            return {
                ...state,
                activeCotizacion: action.payload
            };


        case types.deleteActiveCotizacion:
            return {
                ...state,
                activeCotizacion: null
            };


        case types.cotizacionUpdate:
            return {
                ...state,
                cotizaciones: state.cotizaciones.map( 
                    e => ( e._id === action.payload._id ) ? action.payload : e
                )
            };



        case types.deleteCotizacion:
            return {
                ...state,
                cotizaciones: state.cotizaciones.filter(
                    p => ( p._id !== action.payload )
                ),
                activeCotizacion: null
            };


        default:
            return state;
    }

    

}
