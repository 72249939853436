



import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { HeaderDeCheckout } from "../layouts";
import { clienteActivoClear, clientesStartPorDistri } from "../../../actions/cliente";
import { FormularioCompartidoCliente } from "../components";

import Swal from "sweetalert2";



const initCliente = {
    uid: "",
    nombre: "",
    cedula: "",
    ciudad: "",
    direccion: "",
    direccion2: "",
    movil: "",
    distribuidor: "",
    distri: ""
}


export const ClienteCheckoutPorDistri = () => {

    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { activeCliente } = useSelector(state => state.cliente);




    const [ formCliente, setFormCliente ] = useState( initCliente );
    const handleInputChange = ({ target }) => {
      setFormCliente({
          ...formCliente,
          [target.name]: target.value
        });
    }



    useEffect( () => {
        dispatch( clientesStartPorDistri() );
    }, [ dispatch ] );



    useEffect(() => {
        if( activeCliente ) {
            setFormCliente({
                ...formCliente,
                uid: activeCliente.id,
                nombre: activeCliente.nombre,
                ciudad: activeCliente.ciudad,
                direccion: activeCliente.direccion,
                movil: activeCliente.movil,
                cedula: activeCliente.cedula,
                distri: activeCliente.distribuidor.nombre,
                distribuidor: activeCliente.distribuidor._id
            });
        }
    }, [activeCliente]);



    const  handleInfoCliente = ( e ) => {
        e.preventDefault();
    
        if( formCliente.nombre.trim().length <= 0 ) {
            Swal('Error', 'El Nombre Es Obligatorio', 'error');
            return; 
        }
        if( formCliente.direccion.trim().length <= 0 ) {
            Swal('Error', 'La Direccion Es Obligatoria', 'error');
            return; 
        }
        localStorage.setItem('cliente', JSON.stringify(formCliente) );
        navigate('/super/comprar-ahora');
        dispatch(clienteActivoClear());
    }




    return (

        
        <HeaderDeCheckout title={'Informacion Personal Cliente'}>

            <form className="formulario-total mt-5" onSubmit={ handleInfoCliente } autoComplete="off" >

                <FormularioCompartidoCliente formCliente={formCliente} onChange={handleInputChange} />

            </form>

        </HeaderDeCheckout>
        
    )

}
