import { types } from "../types/types";




const initialState = {
    abonos: [ ],
    activeAbono: null
};



export const abonoReducer = ( state = initialState, action ) => {

    switch ( action.type ) {

        case types.abonosAddNew:
            return {
                ...state,
                abonos: [
                    action.payload,
                    ...state.abonos,
                ]
            };

        case types.abonosLista:
            return {
                ...state,
                abonos: [ ...action.payload ]
            };

        case types.abonosDelete:
            return {
                ...state,
                abonos: state.abonos.filter(
                    e => ( e.id !== action.payload.id )
                ),
                activeAbono: null
            };

        case types.abonosActivo:
            return {
                ...state,
                activeAbono: action.payload
            };
            
        case types.abonosDeleteActivo:
            return {
                ...state,
                activeAbono: null
            };

        default:
            return state;
    }

}
