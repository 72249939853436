
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { useQuery } from '@tanstack/react-query'
import { opListaStartTodas } from '../../actions';





export const useOpTanStack = ({ estado }) => {


    const dispatch = useDispatch();

    const [page, setPage] = useState(0)
    // const [estado, setEstado] = useState('');
    const [termino, setTermino] = useState('');
    const [inicio, setInicio] = useState('');
    const [final, setFinal] = useState('');



    const opsQuery = useQuery(
        ['op', { estado, inicio, final, termino, page }],
        () => dispatch(opListaStartTodas({ estado, inicio, final, termino, page }))
    )



    const chagePage = (num) => {
        setPage(num - 1);
    }


    const chageTermino = (valor) => {
        setPage(0);
        setTermino(valor)
    }


    // const chageEstado = (valor) => {
    //     setPage(0);
    //     setEstado(valor)
    // }

    const chageInicio = (valor) => {
        setPage(0);
        setInicio(valor)
    }


    const chageFinal = (valor) => {
        setPage(0);
        setFinal(valor)
    }




    return {
        // Estados
        page,
        estado,
        termino,
        inicio,
        final,

        // Metodos
        chagePage,
        chageTermino,
        chageInicio,
        chageFinal,

        opsQuery,
    }


}
