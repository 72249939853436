





export const PrecioCotizacionSegunRol = (producto, rol) => {
    switch(rol){
        
        case 'CLIENTE':
            return producto.precioVenta;

        case 'DISTRIBUIDOR':
            return producto.precioDistri;
            
        case 'PUBLICISTA':
            return producto.precioPublicista;

        case 'EMPRESA':
            return producto.precioEmpresa;
    }
}