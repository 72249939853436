
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { clienteActivoClear } from "../../../actions/cliente";
import { openCrearCliente } from "../../../actions";




export const HeaderDeCheckoutAdmin = ({ children, title }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { view } = useSelector( state => state.iva );
    const { usuario } = useSelector( state => state.admin );



    const handleReturn = () => {
        navigate(-1);
        dispatch( clienteActivoClear() );
    }



    const handleCrearCliente = () => {
    dispatch( openCrearCliente() );
    }


    const handleCrearUsuario = () => {
        navigate('/super/crearUsuario')
    }




    return (

        
        <Grid container >
            <Grid container mt={2} >

                <IconButton
                        sx={{
                            color: '#fff',
                            backgroundColor: 'secondary.main',
                            ':hover': { backgroundColor: 'secondary.main', opacity: 0.3 },
                        }}
                        onClick={ handleReturn } 
                >
                    <ArrowBackIcon />
                </IconButton>

                <Box flex={ 1 } />

                <Typography variant='h2'>{ title }</Typography>

                <Box flex={ 1 } />
                
                {
                    (view === 'cliente')
                    ?   <Button  variant="contained"
                            onClick={ handleCrearCliente }
                        >
                            Crear Cliente
                        </Button>
                    :   <Button  variant="contained"
                            onClick={ handleCrearUsuario }
                        >
                            Crear Usuario
                        </Button>

                }
                

            </Grid>
            


            { children }



        </Grid>


    )



    
}
